import React from "react";
import { useLocation, Link } from "react-router-dom";
import { logout } from "../../store/actions/authActions";
import { useDispatch } from "react-redux";

export default function DashboardTopNavAdmin() {
	const dispatch = useDispatch();
	const location = useLocation();
	const titles = location.pathname.substring(1).split("/");
	const user = JSON.parse(localStorage.getItem("authRes") ?? {});
	return (
		<nav className="navbar navbar-expand-sm py-3 bg-transparent navbar-light">
			<div className="container">
				<button
					type="button"
					className="btn sidebar-toggle-btn ps-0"
					onClick={() => {
						dispatch({ type: "TOGGLE_SIDE_BAR" });
					}}
				>
					<span className="navbar-toggler-icon" />
				</button>
				<span href="#" className="navbar-brand fw-bold ms-3 text-capitalize">
					{titles.pop().replaceAll("-", " ")}
				</span>
				<button
					data-bs-toggle="modal"
					data-bs-target="#searchModal"
					className="btn form-control border mx-auto min-height w-25 text-start text-truncate text-nowrap"
				>
					<i className="fas fa-search" /> <span className="ps-3">Search</span>
				</button>
				<ul className="navbar-nav d-flex flex-row">
					{/* nav item */}
					<li className="nav-item">
						<a href="#" className="nav-link d-none d-sm-block">
							{`${user.profile.firstname ?? ""} ${
								user.profile?.lastname ?? ""
							}`}{" "}
						</a>
					</li>
					{/* nav item */}
					<li className="nav-item dropdown position-relative pe-0">
						<a
							className="nav-link btn bg-light"
							href="#"
							data-bs-toggle="dropdown"
						>
							<i className="far fa-bell" />
						</a>
						<ul className="dropdown-menu position-absolute shadow-sm border-0 rounded">
							{/* <li>
                <a className="dropdown-item active" href="#">
                  Action
                </a>
              </li> */}
							{/* <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li> */}
							<li>
								<Link
									className="dropdown-item"
									to="#"
									onClick={(e) => {
										e.preventDefault();
										dispatch(logout);
									}}
								>
									Logout
								</Link>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</nav>
	);
}
