import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../../components/partials/formikError";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Modal } from "antd";
import { _documents, saveDocs } from "../../../../store/actions/admin/senders";
import Pagination from "../../../../components/pagination/pagination";
import { useLocation } from "react-router-dom";

export default function Documents() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, docs, changed } = useSelector((state) => state.senders);
  const { msg } = useSelector((state) => state.error);
  const [viewFile, setView] = useState({ show: false, paths: [] });
  const [fileError, setFileError] = useState(false);
  const formRef = useRef();
  const toggleView = (el) => {
    setView({ show: !viewFile.show, paths: el });
  };


  useEffect(() => {
    if (changed) {
      dispatch(_documents(1, location?.state?.uuid));
      formRef.current.resetForm();
    } else {
      dispatch(_documents(1, location?.state?.uuid));
    }
  }, [changed]);

  return (
    <div>
      <div className="card rounded my-3 overflow-hidden">
        <div className="card-header bg-white">
          <h6 className="fw-bold">
            Upload user's payslip or equivalent document to increase their
            transaction limit{" "}
          </h6>
        </div>
        <div className="card-body">
          <Formik
            validationSchema={Yup.object({
              title: Yup.string().required("Enter title"),
              message: Yup.string().required("Enter message"),
              expires_at: Yup.string().required("Enter expiry"),
              docs: Yup.array().min(1, "Upload document"),
            })}
            innerRef={formRef}
            initialValues={{ title: "", message: "", expires_at: "", docs: [] }}
            onSubmit={(values) => {
              dispatch(
                saveDocs({
                  ...values,
                  uuid: location.state.uuid,
                })
              );
            }}
          >
            {(props) => {
              return (
                <form onSubmit={props.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Document Title</label>
                        <input
                          type="text"
                          className="form-control min-height"
                          placeholder="Document title Eg Payment Slip"
                          name="title"
                          value={props.values.title}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          id="title"
                        />
                        {props.touched.title && props.errors.title && (
                          <FormikError msg={props.errors.title} />
                        )}
                        <FormikError msg={msg?.title ?? ""} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Document Expiry Date</label>
                        <input
                          type="number"
                          className="form-control min-height"
                          placeholder="Enter in # of months. Enter number of months"
                          name="expires_at"
                          value={props.values.expires_at}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          id="expires_at"
                        />
                        {props.touched.expires_at &&
                          props.errors.expires_at && (
                            <FormikError msg={props.errors.expires_at} />
                          )}
                        <FormikError msg={msg?.expires_at ?? ""} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor className="mb-2">
                          Document <span className="text-red">*</span>
                        </label>
                        <div className="input-file-upload">
                          <div className="file-component">
                            <span className="d-block text-center w-100">
                              <i
                                className="fas fa-cloud-upload-alt"
                                style={{ fontSize: 20 }}
                              />
                              <p>
                                <span className="text-primary">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <span className="fw-lighter">
                                PDF or DOC (max 8mb)
                              </span>
                            </span>
                            <input
                              type="file"
                              name="docs"
                              className="form-control"
                              accept=".png,.jpg,.pdf,.jpeg"
                              onChange={(e) => {
                                let err = 0;
                                setFileError(false);
                                for (
                                  let i = 0;
                                  i < e.target.files.length;
                                  i++
                                ) {
                                  if (e.target.files[i].size > 8000000) {
                                    err = 1;
                                    setFileError(true);
                                    return;
                                  }
                                }

                                if (!err) {
                                  for (
                                    let i = 0;
                                    i < e.target.files.length;
                                    i++
                                  ) {
                                    props.setValues({
                                      ...props.values,
                                      docs: [
                                        ...props.values.docs,
                                        e.target.files[0],
                                      ],
                                    });
                                  }
                                }
                              }}
                              id="file"
                            />
                          </div>
                          {props.touched.docs && props.errors.docs && (
                            <FormikError msg={props.errors.docs} />
                          )}
                          <FormikError msg={msg?.docs ?? ""} />
                          {fileError && (
                            <FormikError msg="File must be less than 8MB" />
                          )}
                        </div>
                        <br />
                        <button className="btn btn-red" type="submit">
                          <i className="fas fa-upload" /> &nbsp; Upload{" "}
                          {loading && <Spin />}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Extra note</label>
                        <textarea
                          rows={5}
                          className="form-control"
                          placeholder="message"
                          defaultValue={""}
                          value={props.values.message}
                          name="message"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        {props.touched.message && props.errors.message && (
                          <FormikError msg={props.errors.message} />
                        )}
                        <FormikError msg={msg?.message ?? ""} />
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="table-responsive">
        {loading && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Message</th>
              <th className="text-muted text-uppercase">Status</th>
              <th className="text-muted text-uppercase">Valid till</th>
              <th className="text-muted text-uppercase">Uploaded at</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {docs.data?.map((el, i) => {
              let status = "pending";
              let icon = "fa-exclamation-triangle";
              if (el.status === "approved") {
                status = "success";
                icon = "fa-check-circle";
              }
              if (el.status === "rejected") {
                status = "danger";
                icon = "fa-close";
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{i + 1}</td>
                  <td className="wrap">
                    <strong>Title:</strong>{" "}
                    <span className="text-muted">{el.title}</span> <br />
                    <strong>Body:</strong>
                    <span className="text-muted">{el.message}</span>
                  </td>
                  <td>
                    <i className={`fas ${icon} text-${status}`} /> &nbsp;
                    <small className={`text-${status}`}>{el.status}</small>
                  </td>
                  <td>{el.valid_till}</td>
                  <td>{el.created_at}</td>
                  <td>
                    <a
                      href="#view"
                      className="btn btn-white"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleView(el.files);
                      }}
                    >
                      View
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {docs?.links && (
        <Pagination
          onPageChange={(page) => {
            if (docs !== null && docs.meta?.per_page) {
              dispatch(_documents(page, location.state.uuid));
            }
          }}
          data={docs}
        />
      )}
      <Modal
        visible={viewFile.show}
        onCancel={() => {
          toggleView();
        }}
        // width={"%"}
        footer={false}
      >
        {viewFile?.paths?.map((el, i) => {
          return (
            // <iframe
            //   src={el.file_path}
            //   title="docs"
            //   style={{ height: "80vh", width: "80vw" }}
            //   key={i}
            // />
            <a href={`${el.file_path}`} className="btn btn-red" target="_blank">
              Click to view document {i + 1}
            </a>
          );
        })}
      </Modal>
    </div>
  );
}
