import { Modal, Spin } from "antd";
import React from "react";
import { forceFaceVerify } from "../../../store/actions/admin/senders";
import { useDispatch, useSelector } from "react-redux";

const ConfirmForceVerifyModal = ({
  visible,
  handleClose,
  fullName,
  uuid,
  handleRefresh,
}) => {
  const { verifyingBiometrics } = useSelector((state) => state.senders);
  const dispatch = useDispatch();
  const handleProceed = () => {
    dispatch(forceFaceVerify(uuid, handleClose, handleRefresh));
  };
  return (
    <Modal
      open={visible}
      onCancel={() => {
        handleClose();
      }}
      bodyStyle={{
        padding: "0px !important",
        borderRadius: "20px !important",
        margin: "0px",
      }}
      closable={false}
      footer={false}
      // width="25%"
    >
      <div className="modal-body text-center">
        <h6 className="mb-4">
          Are you sure you want to force verify{" "}
          <span className="fw-bold">{fullName}</span>?
        </h6>
        <button
          className="btn btn-outline-red mx-2 px-4"
          type="button"
          onClick={() => {
            handleClose();
          }}
          disabled={verifyingBiometrics}
        >
          Cancel
        </button>
        <button
          disabled={verifyingBiometrics}
          className="btn btn-red mx-2 px-4"
          type="button"
          onClick={handleProceed}
        >
          Force Verify {verifyingBiometrics && <Spin />}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmForceVerifyModal;
