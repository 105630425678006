import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import DashboardSideNav from "../nav/dashboardSideNav";
import DashboardTopNav from "../nav/dashboardTopNav";
import DashboardTopNavAdmin from "../nav/dashboardTopNavAdmin";
import DashboardSideNavAdmin from "../nav/dashboardSideNavAdmin";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default function AppLayout() {
  const location = useLocation();
  const titles = location.pathname.substring(1).split("/");
  var classNames = require("classnames");
  const dispatch = useDispatch();

  const { openSideBar } = useSelector((state) => state.general);
  return (
    <section id="wrapper" className={classNames({ open: openSideBar })}>
      {JSON.parse(localStorage.getItem("authRes")).profile_type ===
        "aprofiles" ? (
        <DashboardSideNavAdmin />
      ) : (
        <DashboardSideNav />
      )}
      <section className="mainbody">
        {JSON.parse(localStorage.getItem("authRes")).profile_type ===
          "aprofiles" ? (
          <DashboardTopNavAdmin />
        ) : (
          <DashboardTopNav />
        )}
        <main>
          {JSON.parse(localStorage.getItem("authRes")).profile_type ===
            "aprofiles" && (
              <nav aria-label="breadcrumb" className="mt-3 mb-5">
                <div className="container">
                  <hr />
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard" className="text-red">
                        Dashboard
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-capitalize"
                      aria-current="page"
                    >
                      {titles.pop().replaceAll("-", " ")}
                    </li>
                  </ol>
                  <hr />
                </div>
              </nav>
            )}
          <div className="container">
            <Outlet />
            <a href="javascript:void(0)" className="sidebar-overlay" onClick={() => { dispatch({ type: "TOGGLE_SIDE_BAR" }) }}></a>
          </div>
        </main>
      </section>
    </section>
  );
}
