import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  verifyPhone,
  changeMyPhone,
  resendPhoneOtp,
  logout,
} from "../../../store/actions/authActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Spin } from "antd";
import FormikError from "../../../components/partials/formikError";
import { useNavigate } from "react-router-dom";
export default function VerifyPhone() {
  const navigate = useNavigate();
  const { regRes, loading, changedPhone, resendingOtp } = useSelector(
    (state) => state.signup
  );

  const authRes = JSON.parse(localStorage.getItem("authRes"));

  const { msg } = useSelector((state) => state.error);

  const [showModal, setShowModal] = useState(false);
  const [countDown, setCountDown] = useState(0);

  const toggleModal = (e) => {
    e.preventDefault();
    dispatch({ type: "CHANGED_PHONE", payload: false });
    setShowModal(!showModal);
  };

  const [newPhone, setNewPhone] = useState();
  const dispatch = useDispatch();

  const schema = Yup.object({
    phone_otp: Yup.number("Enter otp").required("Enter otp"),
  });

  const { handleSubmit, handleChange, handleBlur, touched, errors } = useFormik(
    {
      initialValues: {
        phone_otp: "",
      },
      validationSchema: schema,
      onSubmit: (values) => {
        dispatch(
          verifyPhone({
            ...values,
          })
        );
      },
    }
  );

  const schema2 = Yup.object({
    phone: Yup.string("Enter phone")
      .required("Phone is required")
      .max(10)
      .min(10),
  });

  const changePhone = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: schema2,
    onSubmit: (values) => {
      dispatch(changeMyPhone(values));
      setNewPhone(values.phone);
    },
  });
  return (
    <>
      <div>
        <div className="text-center mt-5 mb-4">
          <h2 className="header-4 fw-bold">Verify Phone Number</h2>
          <p className="text-muted mb-0">
            We’ve sent a six-digit verification code to{" "}
            <span className="text-dark">
              {newPhone
                ? newPhone
                : regRes?.profile?.mobile_phone
                ? regRes?.profile?.mobile_phone
                : authRes?.profile?.mobile_phone}
            </span>
            .
          </p>
          <p className="text-muted mb-5">
            Wrong phone?{" "}
            <a
              href="#changePhoneModal"
              data-bs-toggle="modal"
              className="text-red"
              onClick={(e) => {
                toggleModal(e);
              }}
            >
              Change
            </a>{" "}
            |{" "}
            <a
              className="text-red"
              onClick={(e) => {
                e.preventDefault();
                dispatch(logout);
                navigate("login");
              }}
            >
              Logout
            </a>
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            {/* Six digit code */}
            <div className="col-lg-6">
              <div className="form-group mb-4">
                <label className="mb-2">Six digit code</label>
                <input
                  type="text"
                  className="form-control min-height"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="phone_otp"
                />
                {touched.phone_otp && errors.phone_otp && (
                  <FormikError msg={errors.phone_otp} />
                )}
              </div>
              <button
                className="btn btn-red min-height w-100 mb-4"
                disabled={loading}
              >
                Verify {loading && <Spin />}
              </button>
              <p className="text-muted mt-2 text-center">
                Didn't recieve the code?
              </p>
              <button
                className="btn btn-outline-red min-height w-100"
                onClick={() => {
                  dispatch(resendPhoneOtp);
                }}
                disabled={resendingOtp || countDown > 1}
              >
                Resend code &nbsp; {countDown > 1 && `${countDown}s`}{" "}
                {resendingOtp && <Spin />}
              </button>
            </div>
          </div>
        </form>
      </div>

      <Modal
        visible={showModal && !changedPhone}
        footer={false}
        onCancel={(e) => {
          if (loading) return;
          toggleModal(e);
        }}
      >
        <div className="row justify-content-center mt-3">
          <div className="col-lg-11">
            <h6 className="fw-bold">Change Phone</h6>
            <p className="text-muted">Type in your correct Phone Number</p>
            <form onSubmit={changePhone.handleSubmit}>
              <div className="form-group mb-4">
                <label className="mb-2">Phone Number</label>
                <input
                  type="text"
                  className="form-control min-height"
                  name="phone"
                  onChange={changePhone.handleChange}
                  onBlur={changePhone.handleBlur}
                />
                {changePhone.touched.phone && changePhone.errors.phone && (
                  <FormikError msg={changePhone.errors.phone} />
                )}
                <FormikError msg={msg?.phone ?? ""} />
              </div>
              <button
                className="btn btn-red min-height w-100"
                disabled={loading}
              >
                Confirm {loading && <Spin />}
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
