import { Modal } from "antd";
import React from "react";
import {
  setBeneficiaryToEdit,
  toggleEditModal,
} from "../../../store/actions/beneficiaryActions";
import { useDispatch } from "react-redux";
import EditModal from "../../../pages/user/beneficiary/editModal";

function KassiErrorModal({ visible, onClose, uuid }) {
  const dispatch = useDispatch();
  return (
    <>
      <Modal visible={visible} footer={false} onCancel={onClose}>
        Sorry you can't send money to this beneficiary. Please update the
        <strong> beneficiary address</strong> and try again.
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            onClick={() => {
              onClose();
              localStorage.setItem("beneficiaryToEdit", uuid);
              dispatch(setBeneficiaryToEdit(uuid));
              dispatch(toggleEditModal(true));
            }}
          >
            Update Beneficiary
          </button>
        </div>
      </Modal>
      {/* <EditModal showFields /> */}
    </>
  );
}

export default KassiErrorModal;
