import * as actionTypes from "../../actionTypes/admin/payinActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";

export const list =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_PAYIN, payload: true });
      dispatch({ type: actionTypes.SAVED_PAYIN, payload: false });
      const res = await http.get(`admin/payins/list/paginate/10?page=${page}`);
      dispatch({ type: actionTypes.FETCHING_PAYIN, payload: false });
      dispatch({ type: actionTypes.SET_PAYIN, payload: res.data.data.payins });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_PAYIN, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const create = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_PAYIN, payload: true });
    const res = await http.post("admin/payins/create_payin", fd);
    dispatch({ type: actionTypes.SAVING_PAYIN, payload: false });
    dispatch({ type: actionTypes.SAVED_PAYIN, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SAVING_PAYIN, payload: false });
    dispatch({ type: actionTypes.SAVED_PAYIN, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const update = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_PAYIN, payload: true });
    dispatch({ type: actionTypes.UPDATED_PAYIN, payload: false });
    const res = await http.post("admin/payins/update_payin", fd);
    dispatch({ type: actionTypes.UPDATING_PAYIN, payload: false });
    dispatch({ type: actionTypes.UPDATED_PAYIN, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_PAYIN, payload: false });
    dispatch({ type: actionTypes.UPDATED_PAYIN, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleCreatModal = () => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_CREATE_MODAL });
};

export const _delete = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETING_PAYIN, payload: true });
    dispatch({ type: actionTypes.DELETED_PAYIN, payload: false });
    const res = await http.get(`admin/payins/delete/${fd}`);
    dispatch({ type: actionTypes.DELETING_PAYIN, payload: false });
    dispatch({ type: actionTypes.DELETED_PAYIN, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.DELETING_PAYIN, payload: false });
    dispatch({ type: actionTypes.DELETED_PAYIN, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleStatus = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.TOGGLING_PAYIN, payload: true });
    dispatch({ type: actionTypes.TOGGLED_PAYIN, payload: false });
    const res = await http.get(`admin/pop_message/toggle_pop/${fd}`);
    dispatch({ type: actionTypes.TOGGLING_PAYIN, payload: false });
    dispatch({ type: actionTypes.TOGGLED_PAYIN, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.TOGGLING_PAYIN, payload: false });
    dispatch({ type: actionTypes.TOGGLED_PAYIN, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const search = (params) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SEARCHING_PAYIN });
    const res = await http.post(`admin/payins/search?page=${params?.page}`, {
      param: params?.param,
    });
    dispatch({ type: actionTypes.SET_PAYIN, payload: res.data.data.payins });
    dispatch({ type: actionTypes.SEARCHING_PAYIN });
  } catch (error) {
    dispatch({ type: actionTypes.SEARCHING_PAYIN });
  }
};
