export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SWITCH_LOADING = "SWITCH_LOADING";
export const SET_REGISTER_RESPONSE = "SET_REGISTER_RESPONSE";
export const CHANGED_EMAIL = "CHANGED_EMAIL";
export const CHANGED_PHONE = "CHANGED_PHONE";
export const TOGGLE_RESENDING_OTP = "TOGGLE_RESENDING_OTP";
export const UPDATE_DONE_STEPS = "UPDATE_DONE_STEPS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const RESET_STEPS = "RESET_STEPS";
