import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminSearchBar from "../../../components/partials/adminSearchBar";
import { Spin } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchVerifiedBusinesses } from "../../../store/actions/admin/businesses";
import moment from "moment";
import Pagination from "../../../components/pagination/pagination";
import ViewBusinessModal from "./ViewBusinessModal";

function VerifiedBusinesses() {
  const [currentPage, setCurrentPage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const dispatch = useDispatch();

  useEffect(() => {
    // if (searchParam) {
    //   dispatch(
    //     search({
    //       param: searchParam,
    //       page: pageParam,
    //     })
    //   );
    // } else {
    dispatch(fetchVerifiedBusinesses(1));
    // }
    setCurrentPage(pageParam);
  }, [searchParam, pageParam]);
  const { verifiedBusinesses, loading } = useSelector(
    (state) => state.adminBusiness
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setData(data);
  };
  return (
    <>
      <div className="container-fluid">
        <h5 className="fw-bold">Verified Businesses</h5>
        <hr />
        <div className="row mb-4">
          <div className="col-sm-6 mb-3 d-sm-flex d-flex flex-sm-row flex-column ">
            {/* <AdminSearchBar callback={search} /> */}
            {/* <div className="px-2 export-container">
            <ExportButon status="suspended" />
          </div> */}
          </div>
        </div>
        <div className="table-responsive ">
          {loading ? <Spin /> : ""}
          <table className="table table-borderless">
            <thead>
              <tr>
                <th className="text-muted text-uppercase">#</th>
                <th className="text-muted text-uppercase">
                  Business Reference ID
                </th>
                <th className="text-muted text-uppercase">Business Name </th>
                <th className="text-muted text-uppercase">
                  Business Location{" "}
                </th>
                <th className="text-muted text-uppercase">Registration No.</th>
                <th className="text-muted text-uppercase">
                  incorporation date{" "}
                </th>
                <th className="text-muted text-uppercase">Business owner </th>
                <th className="text-muted text-uppercase">Status</th>
                <th className="text-muted text-uppercase">Action</th>
              </tr>
            </thead>
            <tbody>
              {/* row */}
              {verifiedBusinesses?.data?.map((el, i) => {
                let count = i + 1;
                if (currentPage > 1) {
                  if (count === 10) {
                    count = Number(`${currentPage - 1}${i}`) + 1;
                  } else {
                    count = `${currentPage - 1}${count}`;
                  }
                }

                return (
                  <tr className="border-bottom" key={i}>
                    <td>{count}</td>
                    {/* <i className="fa-sharp fa-solid fa-circle text-danger" /> */}
                    <td>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setShowModal(true);
                          setData(el);
                        }}
                      >
                        #{el.business_reference_id}
                      </a>
                    </td>
                    <td>{el.business_name}</td>
                    <td>{el.business_location}</td>
                    <td>{el.registration_number || "----"}</td>
                    <td>
                      {moment(el.incorporation_date).format("YYYY-MM-DD")}
                    </td>
                    <td>
                      <a
                        href="#view"
                        onClick={(e) => {
                          e.preventDefault();
                          // localStorage.setItem("senders_userUuid", el.uuid);
                          localStorage.setItem(
                            "senders_profile",
                            JSON.stringify({
                              ...el?.business_owner.profile,
                              sender_uuid: el?.business_owner?.uuid,
                            })
                          );
                          // navigate("/senders/details");
                          navigate(`/senders/details`, {
                            state: { uuid: el?.business_owner.uuid },
                          });
                        }}
                      >
                        {el?.business_owner?.profile?.firstname}{" "}
                        {el?.business_owner?.profile?.lastname}
                      </a>{" "}
                    </td>
                    <td>
                      <small
                        className={`business-${el?.business_verify_status} text-capitalize`}
                      >
                        {el.business_verify_status}
                      </small>
                    </td>
                    <td>
                      <div className="dropdown">
                        <a
                          className="btn dropdown-btn"
                          href="#"
                          data-bs-toggle="dropdown"
                        >
                          <i className="fas fa-ellipsis-v" />
                        </a>
                        <ul className="dropdown-menu shadow-sm rounded">
                          <li>
                            <a
                              className="dropdown-item py-2"
                              href="#detailsModal"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowModal(true);
                                setData(el);
                              }}
                            >
                              View
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item py-2"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                  `/businesses/business-documents?id=${el.user_uuid}`
                                );
                              }}
                            >
                              View business documents
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {verifiedBusinesses?.links && (
          <Pagination
            onPageChange={(page) => {
              setCurrentPage(page);
              if (
                verifiedBusinesses !== null &&
                verifiedBusinesses?.meta?.per_page
              ) {
                //   if (searchParam) {
                //     setSearchParams({
                //       param: searchParam,
                //       page,
                //     });
                //     dispatch(
                //       search({
                //         param: searchParam,
                //         page: page,
                //       })
                //     );
                //   } else {
                setSearchParams({ page });
                dispatch(fetchVerifiedBusinesses(page));
                //   }
              }
            }}
            data={verifiedBusinesses}
          />
        )}
      </div>

      <ViewBusinessModal
        visible={showModal}
        handleClose={handleCloseModal}
        data={data}
      />
    </>
  );
}

export default VerifiedBusinesses;
