import React, { useEffect } from "react";
import VerifyPhone from "./signup/verifyPhone";
import VerifyEmail from "./signup/verifyEmail";
import LogoDesign1 from "../../assets/images/components/Vector13.png";
import LogoDesign2 from "../../assets/images/components/Vector12.png";
import Logo from "../../assets/images/logo/logo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ReverifyPhone() {
  const navigete = useNavigate();
  localStorage.setItem("from_reverify", true);
  const { phoneVerified } = useSelector((state) => state.signup);

  useEffect(() => {
    if (phoneVerified) {
      const user = JSON.parse(localStorage.getItem("authRes")) ?? {};
      if (!user.phone_otp_verified_at || !user.email_otp_verified_at) {
        if (!user.phone_otp_verified_at) {
          navigete("/verify-phone");
        } else if (!user.email_otp_verified_at) {
          navigete("/verify-email");
        }
      } else {
        navigete("/dashboard");
      }
    }
  }, [phoneVerified]);

  return (
    <div>
      {/*
       *     N A V B A R     *
       */}
      <nav className="navbar navbar-light fixed-top bg-transparent">
        <div className="container">
          {/* Navbar brand */}
          <Link className="navbar-brand mx-auto" to="/">
            {/* logo img */}
            <img
              src={Logo}
              width={110}
              className="img-fluid my-4"
              alt="cosmoremit logo"
            />
          </Link>
        </div>
      </nav>
      {/*
       *     M A I N     B O D Y     *
       */}
      <main>
        <img
          src={LogoDesign1}
          className="login-design login-design-1"
          alt="logo design"
        />
        <img
          src={LogoDesign2}
          className="login-design login-design-2"
          alt="logo design"
        />
        <section className="login-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-md-6">
                <div className="text-center">
                  {/* <h2 className="header-4 fw-bold">Welcome Back!</h2>
                    <p className="text-muted mb-4">
                      New to CosmoRemit?{" "}
                      <Link to="/signup" className="text-red">
                        Sign up here
                      </Link>
                    </p> */}
                  <VerifyPhone />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
