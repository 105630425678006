import { useState } from "react";

export const PasswordInput = (props) => {
  const [hide, setHide] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const changePasswordType = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  return (
    <>
      <input
        type={passwordType}
        {...props}
        className="form-control border-end-0 min-height"
      />
      <button
        className="btn border border-start-0"
        type="button"
        onClick={changePasswordType}
      >
        {passwordType === "password" ? (
          <i className="far fa-eye" />
        ) : (
          <i className="far fa-eye-slash text-muted"></i>
        )}
      </button>
    </>
  );
};
