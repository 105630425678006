import React from 'react'
import Vec6 from "../../assets/images/components/Vector(6).png"
import Vec5 from "../../assets/images/components/Vector(5).png"
import Vec10 from "../../assets/images/icon/Vector(10)_bg.png"
import Send from "../../assets/images/icon/bi_send_bg.png"
import Vec9 from "../../assets/images/icon/Vector(9)_bg.png"
import Vec11 from "../../assets/images/icon/Vector(11)_bg.png"
import Group from "../../assets/images/icon/Group 837_bg.png"

export default function Helpcenter() {
  return (
    <main>
  {/* 
  *      M A S T H E A D     * 
    */}
  <section className="masthead">
    <div className="container">
      <div className="masthead-bg-content">
        <img src={Vec6} className="masthead-bg masthead-bg-1" alt="icon" />
        <img src={Vec6} className="masthead-bg masthead-bg-2" alt="icon" />
        <img src={Vec5} className="masthead-bg masthead-bg-3" alt="icon" />
      </div>
      <div className="masthead-content">
        <h2 className="header-3 text-red fw-bold">Hi, How can we help?</h2>
        <p className="mb-5">Select a category to find the help you need.</p>
        <div className="search-input">
          <div className="input-group rounded border-0 mb-5 shadow-sm">
            <span className="input-group-text border-0 bg-white">
              <i className="fas fa-search text-muted" />
            </span>
            <input type="text" className="form-control py-3 border-0" placeholder="Search for a question" />
          </div>
          {/* search suggestions */}
          <div className="search-suggestions" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-5">
    <div className="container">
      <div className="row">
        {/* card */}
        <div className="col-lg-4 col-sm-6">
          <div className="card my-3 help-card rounded">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <img className src={Vec10} alt="icon" />
              <h4 className="mb-3 mt-5">Getting Started</h4>
              <p className="text-muted">Signing up, Verification, KYC, Lorem Ipsum dolor repti</p>
              <a href="help-center/getting-started.html" className="stretched-link" />
            </div>
          </div>
        </div>
        {/* card */}
        <div className="col-lg-4 col-sm-6">
          <div className="card my-3 help-card rounded">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <img src={Send} alt="send icon" />
              <h4 className="mb-3 mt-5">Sending Money</h4>
              <p className="text-muted">Signing up, Verification, KYC, Lorem Ipsum dolor repti</p>
              <a href="#" className="stretched-link" />
            </div>
          </div>
        </div>
        {/* card */}
        <div className="col-lg-4 col-sm-6">
          <div className="card my-3 help-card rounded">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <img src={Vec9} alt="icon" />
              <h4 className="mb-3 mt-5">Increasing limits</h4>
              <p className="text-muted">Signing up, Verification, KYC, Lorem Ipsum dolor repti</p>
              <a href="#" className="stretched-link" />
            </div>
          </div>
        </div>
        {/* card */}
        <div className="col-lg-4 col-sm-6">
          <div className="card my-3 help-card rounded">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <img src={Vec11} alt="icon" />
              <h4 className="mb-3 mt-5">Transaction Bonus</h4>
              <p className="text-muted">Signing up, Verification, KYC, Lorem Ipsum dolor repti</p>
              <a href="#" className="stretched-link" />
            </div>
          </div>
        </div>
        {/* card */}
        <div className="col-lg-4 col-sm-6">
          <div className="card my-3 help-card rounded">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <img src={Vec10} alt="icon" />
              <h4 className="mb-3 mt-5">Referral</h4>
              <p className="text-muted">Signing up, Verification, KYC, Lorem Ipsum dolor repti</p>
              <a href="#" className="stretched-link" />
            </div>
          </div>
        </div>
        {/* card */}
        <div className="col-lg-4 col-sm-6">
          <div className="card my-3 help-card rounded">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <img src={Group} alt="icon" />
              <h4 className="mb-3 mt-5">Privacy Policy</h4>
              <p className="text-muted">Signing up, Verification, KYC, Lorem Ipsum dolor repti</p>
              <a href="#" className="stretched-link" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* 
  *     A C T I O N     B U T T O N     *
    */}
  <section className="section pt-0">
    <div className="container">
      <div className="text-center">
        <h4>Still need help?</h4>
        <p className="text-muted">Can’t find the answer you’re looking for? Explore other options</p>
        <div className="row justify-content-center mt-5">
          <div className="col-xl-7 col-md-8">
            <div className="d-flex flex-wrap justify-content-lg-between justify-content-center">
              <a href="#" className="btn btn-outline-red px-5 py-3 m-2">Visit Help Center</a>
              <a href="#" className="btn btn-outline-red px-5 py-3 m-2">Live Chat</a>
              <a href="#" className="btn btn-outline-red px-5 py-3 m-2">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

  )
}
