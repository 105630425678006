import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { IoCheckmark } from "react-icons/io5";
import "../../../../../assets/css/successfullCreateWallet.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeFormatter } from "../../../../../store/actions/utilityActions";
import { FaCheck } from "react-icons/fa6";
import { swapCurrency } from "./../../../../../store/actions/walletActions";
import { SET_RESPONSE_DATA } from "../../../../../store/actionTypes/walletActionTypes";

const CurrencySwapSuccessModal = ({
	showModal,
	handleCancel,
	fromCurrency,
	toCurrency,
}) => {
	const swapData = useSelector((state) => state.wallet.setResponseData);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleWalletButton = () => {
		// handleCancel();
		navigate("/wallet");
	};

	useEffect(() => {
		dispatch(swapCurrency);
	}, []);

	const {
		sent_amount,
		status,
		fee,
		received_amount,
		rate,
		created_at,
		reference,
	} = swapData;
	let to_currency = swapData?.to_currency?.iso3;
	let from_currency = swapData?.from_currency?.iso3;

	return (
		<>
			<section className="section_wrapper">
				<div className="cos_mail">
					<p>Cosmo Remit</p>
					<p>cosmoremit.com.au</p>
				</div>
				<section
					className="transaction_details_modal_receipt "
					style={{ width: "97%", margin: "auto" }}
				>
					<div className="logo_address">
						<div className="logo___receipt___">
							<img
								src="assets/images/logo/logo.png"
								alt="logo"
								className="receipt__logo_img"
							/>
						</div>
						<p>
							Level 14, 3 Parramatta Square, 153 Macquarie Street Parramatta NSW
							2150.
						</p>
						<p>Email: admin@cosmoremit.com.au</p>
						<p>Phone: (02) 96 159 832, (+61) 414 151 549, (+61) 470 628 369</p>
					</div>
					<div className="transaction_info">
						<div>
							<h1 className="text-center text-10">-{sent_amount} AUD</h1>
						</div>
						<div className="d-flex justify-content-center align-items-center m-auto">
							<div className="check_div">
								<FaCheck className="check" />
							</div>{" "}
							<span className="success_text">{status}</span>
						</div>
						<div className="amount_aud">
							{/* <p>Amount</p> */}
							<p>
								{/* {localStorage.getItem("trx_sendVal")}{" "}
								{localStorage.getItem("trx_fromCountryCurency")} */}
								{/* {transactionData?.for_payin?.send_amount} AUD */}
							</p>
						</div>
					</div>
					<div className="table_transaction_user_info">
						<div className="table_info">
							<h2>Transaction/Swapping Details</h2>
							<table>
								<tr>
									<td>Transaction Type</td>
									<td>
										<div className="transaction_result">
											<p>Swap Currency</p>
										</div>
									</td>
								</tr>
								<tr>
									<td>Transaction ID</td>
									<td>
										<div className="transaction_result">
											<p>{reference}</p>
										</div>
									</td>
								</tr>

								<tr>
									<td>Swapped From</td>
									<td>
										<div className="transaction_result">
											<p>{from_currency}</p>
										</div>
									</td>
								</tr>
								<tr>
									<td>Swapped To</td>
									<td>
										<div className="transaction_result">
											<p>{to_currency}</p>
										</div>
									</td>
								</tr>
								<tr>
									<td>Fee</td>
									<td>
										<div className="transaction_result">
											<p>{fee}</p>
										</div>
									</td>
								</tr>

								<tr>
									<td>Received Amount in {to_currency}</td>
									<td>
										<div className="transaction_result">
											<p>{received_amount}</p>
										</div>
									</td>
								</tr>

								<tr>
									<td>Exchange Rate</td>
									<td>
										<div className="transaction_result">
											<p>{rate}</p>
										</div>
									</td>
								</tr>

								<tr>
									<td>Transaction Date</td>
									<td>
										<div className="transaction_result">
											<p>
												{dateTimeFormatter(created_at).date},{" "}
												{dateTimeFormatter(created_at).time}
											</p>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>

					<div className="note">
						<h2>Note</h2>
						<p>
							Payment must come from the sender's account only, kindly input
							only your transaction reference number in your payment description
							to avoid delay in your transaction, your transaction reference
							number is {reference}
						</p>
					</div>
				</section>

				<div className="btn_share">
					<button
						type="submit"
						// type="button"
						style={{ cursor: "pointer" }}
						onClick={handleWalletButton}
						// onClick={() => {
						// console.log(transactionData.transaction_details);
						// dispatch({ type: "INITIALIZED", payload: false });
						// navigate("/dashboard");
						// }}
					>
						Go to Wallet
					</button>
				</div>
				<div className="footer_receipt_transaction">
					<small>2024 CosmoRemit | All Rights Reserved</small>
				</div>
			</section>
		</>
	);
};

export default CurrencySwapSuccessModal;
