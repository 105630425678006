import React, { useEffect } from "react";
import Pic from "../../../assets/images/components/Group905.png";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import SignUpStart from "./signupStart";
import VerifyPhone from "./verifyPhone";
import VerifyEmail from "./verifyEmail";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetSteps } from "../../../store/actions/authActions";

export default function SignUp() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { activeStep, steps } = useSelector((state) => state.signup);
	const { token } = useSelector((state) => state.auth);
	const stepContents = [
		{ content: <SignUpStart /> },
		{ content: <VerifyEmail /> },
		{ content: <VerifyPhone /> },
	];

	const stepBtns = [
		{ title: "Sign Up" },
		{ title: "Verify Email" },
		{ title: "Two-factor Authentication" },
	];

	useEffect(() => {
		const authRes = JSON.parse(localStorage.getItem("authRes"));
		// if (token) {
		//   if (
		//     !authRes.profile.address_details_at ||
		//     !authRes.profile.personal_details_at ||
		//     !authRes.profile.kyc_details_at
		//   ) {
		//     navigate("/my-profile");
		//   } else {
		//     navigate("/dashboard");
		//   }
		// }
		if (token) {
			if (authRes?.profile_type === "aprofiles") {
				if (!authRes.email_otp_verified_at) {
					navigate("/verify-email");
				} else {
					navigate("/dashboard");
				}
			}
			if (authRes?.profile_type === "profiles") {
				if (!authRes.phone_otp_verified_at || !authRes.email_otp_verified_at) {
					if (!authRes.phone_otp_verified_at) {
						navigate("/verify-phone");
					} else if (!authRes.email_otp_verified_at) {
						navigate("/verify-email");
					} else if (
						!authRes.profile.address_details_at ||
						!authRes.profile.personal_details_at ||
						!authRes.profile.kyc_details_at
					) {
						navigate("/my-profile");
					} else {
						navigate("/dashboard");
					}
				}
			}
			// if (!authRes.phone_otp_verified_at || !authRes.email_otp_verified_at) {

			// } else {
			//   if (authRes?.profile_type === "aprofiles") {
			//     navigate("/dashboard");
			//   } else {
			//     // if (state?.calledFrom === "send_money_on_landing_page") navigate("/");
			//     if (
			//       !authRes.profile.address_details_at ||
			//       !authRes.profile.personal_details_at ||
			//       !authRes.profile.kyc_details_at
			//     ) {
			//       navigate("/my-profile");
			//     } else {
			//       navigate("/dashboard");
			//     }
			//   }
			// }
		}
	}, [token, navigate]);

	useEffect(() => {
		dispatch(resetSteps);
	}, [dispatch]);

	var classNames = require("classnames");

	return (
		<>
			{" "}
			<div>
				<section className="side-card position-fixed d-none d-xl-block bg-pink py-5">
					<div className="container">
						<div className="text-center">
							<div className="row justify-content-center">
								<div className="col-xl-9">
									<h3 className="fw-light">
										The fastest way to send money abroad
									</h3>
								</div>
							</div>
							<img
								draggable="false"
								src={Pic}
								alt="cosmoremit "
								className="my-4 img-fluid"
							/>
							{/* <span className="text-muted">
                Lorem ipsum dolor sit adipisicing <br /> elit. Asperiores earum
                cumque.
              </span> */}
						</div>
					</div>
					<img
						draggable="false"
						src="../assets/images/components/Vector(12).png"
						alt="cosmoremit "
						className="side-card-bg side-card-bg-1"
					/>
					<img
						draggable="false"
						src="../assets/images/components/Vector(14).png"
						alt="cosmoremit "
						className="side-card-bg side-card-bg-2"
					/>
				</section>
				{/*
				 *     M A I N     B O D Y     *
				 */}
				<section className="auth-mainbody">
					{/*
					 *     N A V B A R     *
					 */}
					<nav className="navbar navbar-light bg-transparent">
						<div className="container">
							<a href="/" className="navbar-brand ms-auto">
								<img
									draggable="false"
									src="../assets/images/logo/logo.png"
									width={120}
									alt="cosmoremit "
								/>
							</a>
						</div>
					</nav>
					{/* M A I N */}
					<main>
						<section>
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-lg-9">
										{/* P R O G R E S S     N A V I G A T I O N     B A R */}
										<ul className="nav auth-nav">
											{/* nav item */}
											{stepBtns.map((el, index) => {
												const done = steps[index].done;
												return (
													<li className="nav-item">
														<Link
															className={classNames({
																"nav-link": true,
																done: done,
																active: activeStep === index,
															})}
															aria-current="page"
															to="/signup"
															onClick={(e) => {e.preventDefault()}}
														>
															<span className="nav-box">
																{done ? <FaCheck /> : index + 1}
															</span>
															&nbsp;
															<span className="nav-text">{el.title}</span>
														</Link>
													</li>
												);
											})}
										</ul>
										<hr />

										{stepContents[activeStep].content}
									</div>
								</div>
							</div>
						</section>
					</main>
				</section>
			</div>
		</>
	);
}
