import React from "react";
import List from "./list";
import {
  downloadSenders,
  list,
  search,
} from "../../../store/actions/admin/senders";
import FilterSearch from "../../../components/partials/filterSearch";
import { useDispatch, useSelector } from "react-redux";
import ExportButton from "../../../components/partials/exportButon";
import { useLocation } from "react-router-dom";
export default function Senders() {
  const location = useLocation();
  const { face_verified_count } = useSelector((state) => state.senders);

  
  return (
    <div className="container-fluid">
      <h5 className="fw-bold">Senders</h5>
      <p className="text-muted mb-4">
        Here is a table containing a list of all senders on this platform.{" "}
        <br /> You can view, edit, ban and suspend any sender using the action
        button on the right hand side.
      </p>
      {location.pathname === "/senders" && (
        <div className="col-xl-3 col-sm-6">
          <div className="card rounded my-3">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <small className="text-muted">Total Face Verified</small>{" "}
                  <br />
                  <h2 className="text-dark mb-0 d-inline-block">
                    {face_verified_count}
                  </h2>
                  <br />
                </div>
                <div className="dashboard-icon">
                  <i className="fas fa-users" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row justify-content-between mb-4">
        <div className="col-sm order-sm-1 text-end mb-3">
          <ExportButton
            btnText={"Export Record"}
            status={"compliant"}
            type="compliant"
          />
        </div>
        <div className="col-sm order-sm-0 mb-3">
          <FilterSearch
            options={["member_id", "phone", "email", "status", "name"]}
            callback={search}
            resetFunction={list}
          />
        </div>
      </div>

      <List />
    </div>
  );
}
