import * as actionTypes from "../../actionTypes/admin/sendersActionTypes";

export const senderReducer = (
	state = {
		fetching: true,
		senders: {},
		saved: false,
		saving: false,
		deleting: false,
		deleted: false,
		toggling: false,
		toggled: false,
		updating: false,
		updated: false,
		showCreateModal: false,
		searching: false,
		profile: null,
		fetchingProfile: true,
		updatingProfile: false,
		updatedProfile: false,
		loadingAction: false,
		completedAction: false,
		fetchingTransactions: false,
		transactions: {},
		fetchingBeneficiaries: false,
		beneficiaries: {},
		fetchingKycs: false,
		kycs: {},
		changedKyc: false,
		savingKyc: false,
		loading: false,
		notes: {},
		fetchingNotes: false,
		changed: false,
		fetchingBlackList: false,
		blacklists: {},
		toggledBlackList: false,
		fetchingDocs: false,
		docs: {},
		fetchingLimits: false,
		limits: {},
		profile_transaction_limit_date: "",
		sendersCount: {},
		complianceSenders: {},
		fetchingComplianceSenders: false,
		blacklisting: false,
		blacklisted: false,
		verifiedEmail: false,
		verifiedPhone: false,
		verifyingEmail: false,
		verifyingPhone: false,
		verifyingBiometrics: false,
		face_verified_count: 0,
	},
	action
) => {
	switch (action.type) {
		case actionTypes.VERIFYING_BIOMETRICS:
			return { ...state, verifyingBiometrics: action.payload };
		case actionTypes.SET_FACE_VERIFIED_COUNT:
			return { ...state, face_verified_count: action.payload };
		case actionTypes.VERIFYING_PHONE:
			return { ...state, verifyingPhone: action.payload };
		case actionTypes.VERIFYING_EMAIL:
			return { ...state, verifyingEmail: action.payload };
		case actionTypes.VERIFIED_PHONE:
			return { ...state, verifiedPhone: action.payload };
		case actionTypes.VERIFIED_EMAIL:
			return { ...state, verifiedEmail: action.payload };
		case actionTypes.BLACKLISTING:
			return { ...state, blacklisting: action.payload };
		case actionTypes.BLACKLISTED:
			return { ...state, blacklisted: action.payload };
		case actionTypes.SET_COMPLIANCE_SENDERS:
			return { ...state, complianceSenders: action.payload };
		case actionTypes.FETCHING_COMPLIANCE_SENDERS:
			return { ...state, fetchingComplianceSenders: action.payload };
		case actionTypes.SET_COUNT_SENDER:
			return { ...state, sendersCount: action.payload };
		case actionTypes.SET_LIMITS:
			return { ...state, limits: action.payload };
		case actionTypes.SET_PROFILE_TRANSACTION_LIMIT_DATE:
			return { ...state, profile_transaction_limit_date: action.payload };
		case actionTypes.FETCHING_LIMITS:
			return { ...state, fetchingLimits: action.payload };
		case actionTypes.SET_DOCS:
			return { ...state, docs: action.payload };
		case actionTypes.FETCHING_DOCS:
			return { ...state, fetchingDocs: action.payload };
		case actionTypes.TOGGLED_BLACK_LIST:
			return { ...state, toggledBlackList: action.payload };
		case actionTypes.SET_BLACK_LIST:
			return { ...state, blacklists: action.payload };
		case actionTypes.FETCHING_BLACK_LIST:
			return { ...state, fetchingBlackList: action.payload };
		case actionTypes.CHANGED:
			return { ...state, changed: action.payload };
		case actionTypes.FETCHING_NOTES:
			return { ...state, fetchingNotes: action.payload };
		case actionTypes.SET_NOTES:
			return { ...state, notes: action.payload };
		case actionTypes.SAVING_KYC:
			return { ...state, savingKyc: action.payload };
		case actionTypes.LOADING:
			return { ...state, loading: action.payload };
		case actionTypes.CHANGED_KYC:
			return { ...state, changedKyc: action.payload };
		case actionTypes.FETCHING_KYCS:
			return { ...state, fetchingKycs: action.payload };
		case actionTypes.SET_KYCS:
			return { ...state, kycs: action.payload };
		case actionTypes.FETCHING_SENDERS:
			return { ...state, fetching: action.payload };
		case actionTypes.FETCHING_TRANSACTIONS:
			return { ...state, fetchingTransactions: action.payload };
		case actionTypes.FETCHING_BENEFICIARIES:
			return { ...state, fetchingBeneficiaries: action.payload };
		case actionTypes.FETCHING_PROFILE:
			return { ...state, fetchingProfile: action.payload };
		case actionTypes.SET_TRANSACTIONS:
			return { ...state, transactions: action.payload };
		case actionTypes.SET_BENEFICIARIES:
			return { ...state, beneficiaries: action.payload };
		case actionTypes.LOADING_ACTION:
			return { ...state, loadingAction: action.payload };
		case actionTypes.COMPLETED_ACTION:
			return { ...state, completedAction: action.payload };
		case actionTypes.UPDATING_PROFILE:
			return { ...state, updatingProfile: action.payload };
		case actionTypes.UPDATED_PROFILE:
			return { ...state, updatedProfile: action.payload };
		case actionTypes.SET_PROFILE:
			return { ...state, profile: action.payload };
		case actionTypes.SEARCHING_SENDERS:
			return { ...state, searching: !state.searching };
		case actionTypes.SHOW_CREATE_MODAL:
			return { ...state, showCreateModal: !state.showCreateModal };
		case actionTypes.UPDATING_SENDERS:
			return { ...state, updating: action.payload };
		case actionTypes.UPDATED_SENDERS:
			return { ...state, updated: action.payload };
		case actionTypes.SAVING_SENDERS:
			return { ...state, saving: action.payload };
		case actionTypes.SAVED_SENDERS:
			return { ...state, saved: action.payload };
		case actionTypes.DELETING_SENDERS:
			return { ...state, deleting: action.payload };
		case actionTypes.TOGGLING_SENDERS:
			return { ...state, toggling: action.payload };
		case actionTypes.TOGGLED_SENDERS:
			return { ...state, toggled: action.payload };
		case actionTypes.DELETED_SENDERS:
			return { ...state, deleted: action.payload };
		case actionTypes.SET_SENDERS:
			return { ...state, senders: action.payload };

		default:
			return state;
	}
};
