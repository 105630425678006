import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Modal } from "antd";
import { _notes, saveNote } from "../../../../store/actions/admin/senders";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../../components/partials/formikError";
import Pagination from "../../../../components/pagination/pagination";
import { useLocation } from "react-router-dom";


export default function Notes() {
  const dispatch = useDispatch();
  const { notes, fetchingNotes, changed, loading } = useSelector(
    (state) => state.senders
  );
  const location = useLocation();
  const { msg } = useSelector((state) => state.error);
  useEffect(() => {
    dispatch(_notes(1, location?.state?.uuid));
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (changed) {
      dispatch(_notes(1, location.state.uuid));
      setModal(false);
      document.getElementById("noteForm")?.reset();
    }
  }, [changed]);

  return (
    <>
      <div className="text-end mb-3">
        <button className="btn btn-white min-height px-4" onClick={toggleModal}>
          Add Note
        </button>
      </div>
      {fetchingNotes && <Spin />}
      {notes.data?.map((el, i) => {
        return (
          <div className="card rounded my-3 overflow-hidden" key={i}>
            <div className="card-header bg-white">
              <h5 className="fw-bold">{el.title}</h5>
            </div>
            <div className="card-body">
              <p className="text-muted">{el.message}</p>
              <small className="blockquot-footer fst-italic">
                {`${el.created_by}, ${el.created_at}`}
              </small>
            </div>
          </div>
        );
      })}

            {notes?.links && (
              <Pagination
                onPageChange={(page) => {
                  if (notes !== null && notes.per_page) {
                    dispatch(_notes(page, location.state.uuid));
                  }
                }}
                data={notes}
              />
            )}

      <Modal
        visible={modal}
        onCancel={() => {
          toggleModal();
        }}
        // width={"30%"}
        footer={false}
        closable={false}
      >
        <div className="">
          <button className="btn-close float-end" data-bs-dismiss="modal" />
          <h6 className="fw-bold">Create Note</h6>
          {
            <Formik
              validationSchema={Yup.object({
                title: Yup.string().required("Enter title"),
                comment: Yup.string().required("Enter comment"),
              })}
              initialValues={{ title: "", comment: "" }}
              onSubmit={(values) => {
                dispatch(saveNote({...values,uuid:location.state.uuid}))
              }}
            >
              {(props) => {
                return (
                  <form
                    className="py-4"
                    id="noteForm"
                    onSubmit={props.handleSubmit}
                  >
                    <div className="form-group mb-3">
                      <label htmlFor>Title</label>
                      <input
                        type="text"
                        className="form-control min-height"
                        placeholder="Title"
                        name="title"
                        onChange={props.handleChange}
                        onBlur ={props.handleBlur}
                      />
                      {props.touched.title && props.errors.title && (
                        <FormikError msg={props.errors.title} />
                      )}
                      <FormikError msg={msg?.title ?? ""} />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor>Body</label>
                      <textarea
                        rows={3}
                        className="form-control"
                        placeholder="Body"
                        defaultValue={""}
                        name="comment"
                        onChange={props.handleChange}
                        onBlur ={props.handleBlur}
                      />
                      {props.touched.comment && props.errors.comment && (
                        <FormikError msg={props.errors.comment} />
                      )}
                      <FormikError msg={msg?.comment ?? ""} />
                    </div>
                    <div className="text-end">
                      <button
                        className="btn btn-outline-red ms-2 px-4"
                        onClick={toggleModal}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button className="btn btn-red ms-2 px-4" type="submit"  >
                        Save {loading && <Spin/>}
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          }
        </div>
      </Modal>
    </>
  );
}
