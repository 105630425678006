import React, { useEffect, useState } from "react";
import {
  fetchRoles,
  deleteRole,
  updateRole,
} from "../../../store/actions/admin/accessControl";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Modal } from "antd";
import { Formik } from "formik";
import FormikError from "../../../components/partials/formikError";
import * as Yup from "yup";

export default function List() {
  const dispatch = useDispatch();
  const {
    roles,
    fetchingAcl,
    savedRoles,
    deleting,
    deleted,
    permissions,
    updating,
    updated
  } = useSelector((state) => state.acl);
  const { msg } = useSelector((state) => state.error);
  const [initVals, setInitVals] = useState({});

  const [showModal, setShowModal] = useState(false);

  const [bnToDelete, setBnToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    dispatch(fetchRoles());
  }, []);


  const toggleModal = (acl = {}) => {
    let permsArr = [];
    acl?.permissions?.map((el) => {
      permsArr = [...permsArr, el.id]
      return true;
    })
    setInitVals({ role_id: acl.id, name: acl.name, permissions: permsArr })
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (savedRoles || deleted || updated) dispatch(fetchRoles());
    if (deleted) {
      setShowDeleteModal(false);
    }

    if (updated) {
      setShowModal(false);
    }
  }, [savedRoles, deleted, updated]);

  const toggleDeleteModal = (id) => {
    setBnToDelete(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const deleteBnHandler = () => {
    dispatch(deleteRole(bnToDelete));
  };


  return (
    <>
      <div className="table-responsive">
        {fetchingAcl && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Name</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((el, index) => (
              <tr className="border-bottom" key={index}>
                <td>{index + 1}</td>
                <td>{el.name}</td>
                <td>
                  <div className="dropdown">
                    <a
                      className="btn dropdown-btn"
                      href="#menu"
                      data-bs-toggle="dropdown"
                    >
                      <i className="fas fa-ellipsis-v" />
                    </a>
                    <ul className="dropdown-menu shadow-sm rounded">
                      <li>
                        <a
                          className="dropdown-item py-2"
                          href="#editRole"
                          onClick={(e) => {
                            e.preventDefault();
                            // setRoleToEdit(el);
                            toggleModal(el);
                          }}
                        >
                          View &amp; Edit
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item py-2"
                          href="#deleteModal"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleDeleteModal(el.id);
                          }}
                        >
                          Trash
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="row">
            <div className="col-md-6">
                <p>Showing 1 - 10 of 57 entries</p>
            </div>
            <div className="col-md-6">
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center justify-content-md-end">
                        <li className="page-item disabled"><a className="page-link" href="#">Previous</a></li>
                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item"><a className="page-link" href="#">Next</a></li>
                    </ul>
                </nav>
            </div>
        </div> */}

      {/* Delete Modal */}
      <Modal
        visible={showDeleteModal}
        onCancel={() => {
          toggleDeleteModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure you want to delete this role</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleDeleteModal();
            }}
            disabled={deleting}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={deleting}
            onClick={() => {
              deleteBnHandler();
            }}
          >
            Delete {deleting && <Spin />}
          </button>
        </div>
      </Modal>

      {/* Edit modal */}
      <Modal
        visible={showModal}
        onCancel={() => {
          toggleModal();
        }}
        style={{
          top: 20,
        }}
        width={"80%"}
        footer={false}
      >
        <div>
          <h5 className="fw-bold">Create Role</h5>
          <Formik
            onSubmit={(values) => {
              dispatch(updateRole(values));
            }}
            initialValues={initVals}
            validationSchema={Yup.object({
              name: Yup.string("").required("enter role name"),
              permissions: Yup.array().min(1, "select permissions"),
            })}
            enableReinitialize={true}
          >
            {(props) => (
              <form id="form" onSubmit={props.handleSubmit}>
                <div className="form-group mb-4">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    className="form-control min-height"
                    placeholder="eg. Rate Manager"
                    value={props.values.name}
                  />
                  {props.touched.name && props.errors.name && (
                    <FormikError msg={props.errors.name} />
                  )}
                  <FormikError msg={msg?.name ?? ""} />
                </div>
                <div className="row">
                  {permissions.map((el, index) => (
                    <div className="col-lg-3 col-md-4 col-6" key={index}>
                      <div className="form-check form-switch mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              props.setValues({
                                ...props.values,
                                permissions: [
                                  ...props.values.permissions,
                                  e.target.value,
                                ],
                              });
                            } else {
                              let arr = props.values.permissions.filter(
                                (el) => el !== e.target.value
                              );
                              props.setValues({
                                ...props.values,
                                permissions: arr,
                              });
                            }
                          }}
                          defaultChecked={props.values.permissions.includes(el.id)}
                          name="permission[]"
                          value={el.id}
                          role="switch"
                          id={el.name}
                        />
                        <label
                          className="form-check-label text-lowercase"
                          htmlFor={el.name}
                        >
                          {el.name}
                        </label>
                      </div>
                    </div>
                  ))}
                  {props.touched.permissions && props.errors.permissions && (
                    <FormikError msg={props.errors.permissions} />
                  )}
                  <FormikError msg={msg?.permissions ?? ""} />
                </div>
                <div className="text-end">
                  <button
                    className="btn btn-outline-red ms-2 px-4"
                    onClick={() => {
                      document.getElementById("form").reset();
                      toggleModal();
                    }}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red ms-2 px-4"
                    disabled={updating}
                    type="submit"
                  >
                    Submit {updating && <Spin />}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}
