import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Spin, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
	getUserOutbound,
	saveOutbound,
} from "../../../store/actions/profileActions";
import { Formik } from "formik";
import { useFormik } from "formik";
import { axios } from "axios";

const { Option } = Select;
export default function TransactionSetting() {
	const dispatch = useDispatch();
	const { loading, user_outbound_data } = useSelector(
		(state) => state.outbound
	);
	const { kycCompleted } = useSelector((state) => state.kyc);
	const { msg } = useSelector((state) => state.error);
	const { ratedCountries } = useSelector((state) => state.general);
	const { profile } = JSON.parse(localStorage.getItem("authRes"));

	const schema = Yup.object({
		outbounds: Yup.array()
			.min(1, "Please select at least one destination country")
			.of(Yup.string().required("Destination country is required")),

		estimated_monthly_send: Yup.number()
			.typeError("Estimated monthly send must be a number")
			.positive("Estimated monthly send must be a positive number")
			.integer("Estimated monthly send must be an integer")
			.required("Estimated monthly send is required"),
	});

	const outboundIds = user_outbound_data?.map((data) => data?.country?.id);

	const initialValues = {
		outbounds: outboundIds ?? [],
		estimated_monthly_send: profile?.estimated_monthly_send ?? "",
	};

	useEffect(() => {
		dispatch(getUserOutbound());
	}, []);

	const formRef = useRef();

	return (
		<div className="accordion-item border-bottom-0 w-auto h-auto">
			<h2 className="accordion-header" id="flush-headingOne">
				<button
					className="accordion-button collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#flush-collapseFour"
					disabled={kycCompleted ? false : true}
				>
					<div className="d-flex align-items-center">
						<div className="flex-shrink-0">
							<img
								src="../assets/images/icon/transfer-arrow.svg"
								alt
								className="w-auto object-contain  accordion_image_icon"
							/>
						</div>
						<div className="flex-grow-1 ms-3">
							<h6 className="fw-bold">4. Transaction Settings</h6>
							<p className="text-muted">
								Your transaction settings{" "}
								{/* <span className="text-red">
                  Why do we need this information
                </span> */}
							</p>
						</div>
					</div>
				</button>
			</h2>
			{kycCompleted && (
				<div
					id="flush-collapseFour"
					className="accordion-collapse collapse"
					aria-labelledby="flush-headingOne"
					data-bs-parent="#accordionFlushExample"
				>
					<div className="accordion-body border-bottom pt-1 pb-4">
						<Formik
							initialValues={initialValues}
							onSubmit={(values) => {
								dispatch(saveOutbound({ ...values }));
							}}
							enableReinitialize={true}
							innerRef={formRef}
							validationSchema={schema}
						>
							{(props) => (
								<form onSubmit={props.handleSubmit} className="ms-lg-5">
									<div className="col">
										<div className="col-xl-10 ">
											<div className="form-group mb-3">
												<label className="mb-2">
													Outbound Countries<span className="text-red">*</span>
												</label>

												<Select
													className="form-control min-height multi-selector"
													mode="multiple"
													allowClear
													name="outbounds"
													placeholder="Select Destination Countries"
													value={props.values?.outbounds}
													onBlur={props.handleBlur}
													onChange={(val) => {
														props.setValues({
															...props.values,
															outbounds: val,
														});
													}}
												>
													{ratedCountries?.to_countries?.map((el) => {
														return (
															<Option
																key={el.id}
																id={el.id}
																value={parseInt(el.id)}
															>
																<span
																	className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
																></span>
																{"\u00A0"}
																{el.name}
															</Option>
														);
													})}
												</Select>
												{props.touched.outbounds && props.errors.outbounds && (
													<FormikError msg={props.errors.outbounds} />
												)}
												<FormikError msg={msg?.outbounds ?? ""} />
											</div>
										</div>
										{/* Expected Amount monthly */}
										<div className="col-xl-10 ">
											<div className="form-group mb-3">
												<label className="mb-2">
													Expected Amount monthly{" "}
													<span className="text-red">*</span>
												</label>
												<input
													type="number"
													className="form-control min-height"
													name="estimated_monthly_send"
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													defaultValue={props.values.estimated_monthly_send}
												/>
												{props.touched.estimated_monthly_send &&
													props.errors.estimated_monthly_send && (
														<FormikError
															msg={props.errors.estimated_monthly_send}
														/>
													)}
												<FormikError msg={msg?.estimated_monthly_send ?? ""} />
											</div>
										</div>
										{/* Button */}
										<div className="col-xl-10">
											<div className="form-group text-end mb-3">
												<button
													className="btn btn-light px-5 min-height border"
													disabled={loading}
													type="submit"
												>
													Update {loading && <Spin />}
												</button>
											</div>
										</div>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			)}
		</div>
	);
}
