import * as actionTypes from "../actionTypes/settingsActionTypes";
export const settingsReducer = (
  state = {
    updatingPassword: false,
    updatingEmail: false,
    updatingPhone: false,
    updatingLanguage: false,
    updatingNotification: false,
    confirmingPhoneOtp: false,
    confirmingEmailOtp: false,
    updatedPhone: false,
    updatedEmail: false,
    confirmedPhoneOtp: false,
    confirmedEmailOtp: false,
    newEmail: "",
    newPhone: "",
    showDeactivateModal: false,
    deactivating: false,
    deactivated: false,
    cacelingDeactivation: false,
    canceledDeactivation: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATING_PASSSORD:
      return { ...state, updatingPassword: !state.updatingPassword };
    case actionTypes.DEACTIVATING_ACCOUNT:
      return { ...state, deactivating: !state.deactivating };
    case actionTypes.CANCELING:
      return { ...state, cacelingDeactivation: !state.cacelingDeactivation };
    case actionTypes.CANCELED:
      return { ...state, canceledDeactivation: action.payload };
    case actionTypes.DEACTIVATED_ACCOUNT:
      return { ...state, deactivated: action.payload };
    case actionTypes.TOGGLE_DEACTIVATE_MODAL:
      return { ...state, showDeactivateModal: !state.showDeactivateModal };
    case actionTypes.UPDATING_EMAIL:
      return { ...state, updatingEmail: !state.updatingEmail };
    case actionTypes.UPDATING_PHONE:
      return { ...state, updatingPhone: !state.updatingPhone };
    case actionTypes.UPDATING_LANGUAGE:
      return { ...state, updatingLanguage: !state.updatingLanguage };
    case actionTypes.UPDATING_NOTIFICATION:
      return { ...state, updatingNotification: !state.updatingNotification };
    case actionTypes.CONFIRMING_PHONE_OTP:
      return { ...state, confirmingPhoneOtp: !state.confirmingPhoneOtp };
    case actionTypes.CONFIRMING_EMAIL_OTP:
      return { ...state, confirmingEmailOtp: !state.confirmingEmailOtp };
    case actionTypes.UPDATED_EMAIL:
      return { ...state, updatedEmail: action.payload };
    case actionTypes.UPDATED_PHONE:
      return { ...state, updatedPhone: action.payload };
    case actionTypes.CONFIRMED_PHONE_OTP:
      return { ...state, confirmedPhoneOtp: action.payload };
    case actionTypes.CONFIRMED_EMAIL_OTP:
      return { ...state, confirmedEmailOtp: action.payload };
    case actionTypes.SET_EMAIL:
      return { ...state, newEmail: action.payload };
    case actionTypes.SET_PHONE:
      return { ...state, newPhone: action.payload };
    default:
      return state;
  }
};
