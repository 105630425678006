import React, { useEffect, useState } from "react";
import {
  fetchLimits,
  deleteLimit,
  updateLimit,
  searchLimit,
} from "../../../store/actions/admin/limit";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Modal, Select } from "antd";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Formik } from "formik";
import Pagination from "../../../components/pagination/pagination";
import { useSearchParams } from "react-router-dom";

export default function List() {
  const dispatch = useDispatch();
  const {
    savedLimit,
    fetchingLimit,
    limits,
    deleting,
    deleted,
    updatingLimit,
    updatedLimit,
    searchingLimit,
  } = useSelector((state) => state.limits);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(null);

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const [showEditModal, setEditModal] = useState({ visible: false, el: {} });
  useEffect(() => {
    if (searchParam) {
      dispatch(
        searchLimit({
          param: searchParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(fetchLimits(pageParam));
    }
  }, []);

  useEffect(() => {
    if (savedLimit || deleted || updatedLimit) {
      dispatch(fetchLimits());
    }
    if (deleted) {
      setShowDeleteModal(false);
    }
    if (updatedLimit) {
      setEditModal({ visible: false, el: {} });
      document?.getElementById("form")?.reset();
    }
  }, [savedLimit, deleted, updatedLimit]);

  const [bnToDelete, setBnToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteBnHandler = () => {
    dispatch(deleteLimit(bnToDelete));
  };

  const { msg } = useSelector((state) => state.error);

  const toggleDeleteModal = (id) => {
    setBnToDelete(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user.permissions;

  return (
    <>
      <div className="table-responsive">
        {fetchingLimit || searchingLimit ? <Spin /> : ""}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Country</th>
              <th className="text-muted text-uppercase">Limit Amount</th>
              <th className="text-muted text-uppercase">Limit Days</th>
              <th className="text-muted text-uppercase">Yearly limit</th>
              <th className="text-muted text-uppercase">Times</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {limits.data?.map((el, i) => {
              return (
                <tr className="border-bottom" key={i}>
                  <td>{i + 1}</td>
                  <td>
                    <span
                      class={`flag-icon flag-icon-${el.country.iso2} flag-icon-squared`}
                    ></span>
                    &nbsp; {el.country.name}
                  </td>
                  <td>
                    ₦ {new Intl.NumberFormat(undefined).format(el.amount)}
                  </td>
                  <td>{el.days}</td>
                  <td>
                    {new Intl.NumberFormat(undefined).format(el.yearly_amount)}
                  </td>
                  <td>{el.times}</td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        {permissions["edit limit"] && (
                          <li>
                            <a
                              className="dropdown-item py-2"
                              href="#editLimit"
                              onClick={() => {
                                console.log(el);
                                setEditModal({ visible: true, el: el });
                              }}
                            >
                              View &amp; Edit
                            </a>
                          </li>
                        )}
                        {permissions["delete limit"] && (
                          <li>
                            <a
                              className="dropdown-item py-2"
                              href="#deleteModal"
                              onClick={(e) => {
                                e.preventDefault();
                                toggleDeleteModal(el.id);
                              }}
                            >
                              Trash
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {limits?.links && (
        <Pagination
          onPageChange={(page) => {
            if (limits !== null && limits?.meta?.per_page) {
              if (searchParam) {
                setSearchParams({
                  param: searchParam,
                  page,
                });
                dispatch(
                  searchLimit({
                    param: searchParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });
                dispatch(fetchLimits(page));
              }
            }
          }}
          data={limits}
        />
      )}

      {/* Delete Modal */}
      <Modal
        visible={showDeleteModal}
        onCancel={() => {
          toggleDeleteModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleDeleteModal();
            }}
            disabled={deleting}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={deleting}
            onClick={() => {
              deleteBnHandler();
            }}
          >
            Delete {deleting && <Spin />}
          </button>
        </div>
      </Modal>
      {/* Edit Modal */}
      <Modal
        visible={showEditModal.visible}
        footer={false}
        onCancel={() => {
          setEditModal({ visible: false, el: {} });
        }}
      >
        <div>
          <h5 className="fw-bold">Edit Limit</h5>
          <Formik
            initialValues={{
              for_country: showEditModal.el.country_id,
              days: showEditModal.el.days,
              amount: showEditModal.el.amount,
              times: showEditModal.el.times,
              yearly_amount: showEditModal.el.yearly_amount,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object({
              for_country: Yup.string("").required("Select country"),
              days: Yup.string("").required("enter days"),
              amount: Yup.string("").required("enter amount"),
              times: Yup.string("").required("enter times"),
              yearly_amount: Yup.string("").required("enter yearly amount"),
            })}
            onSubmit={(values) => {
              dispatch(
                updateLimit({ ...values, limit_id: showEditModal.el.id })
              );
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} id="form">
                <div className="form-group mb-3">
                  <label>Select To Country</label>
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      return option.name
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    placeholder="Select country"
                    className="form-control min-height"
                    defaultValue={props.values.for_country}
                    onChange={(value) => {
                      props.setValues({ ...props.values, for_country: value });
                    }}
                  >
                    {JSON.parse(localStorage.getItem("cosmo_countries"))?.map(
                      (el) => {
                        return (
                          <Select.Option
                            key={el.id}
                            id={el.id}
                            countryCode={el.phonecode}
                            name={el.name}
                            value={el.id}
                          >
                            <span
                              className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                            ></span>
                            {"\u00A0"}
                            {el.name}
                          </Select.Option>
                        );
                      }
                    )}
                  </Select>
                  {props.touched.for_country && props.errors.for_country && (
                    <FormikError msg={props.errors.for_country} />
                  )}
                  <FormikError msg={msg?.for_country ?? ""} />
                </div>
                <div className="form-group mb-3">
                  <label>Limit Days</label>
                  <input
                    type="number"
                    className="form-control min-height"
                    name="days"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    defaultValue={showEditModal.el.days}
                  />
                  {props.touched.days && props.errors.days && (
                    <FormikError msg={props.errors.days} />
                  )}
                  <FormikError msg={msg?.days ?? ""} />
                </div>
                <div className="form-group mb-3">
                  <label>Limit Transaction Times</label>
                  <input
                    type="number"
                    className="form-control min-height"
                    name="times"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    defaultValue={showEditModal.el.times}
                  />
                  {props.touched.times && props.errors.times && (
                    <FormikError msg={props.errors.times} />
                  )}
                  <FormikError msg={msg?.times ?? ""} />
                </div>
                <div className="form-group mb-3">
                  <label>Amount</label>
                  <input
                    type="number"
                    className="form-control min-height"
                    name="amount"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    defaultValue={showEditModal.el.amount}
                  />
                  {props.touched.amount && props.errors.amount && (
                    <FormikError msg={props.errors.amount} />
                  )}
                  <FormikError msg={msg?.amount ?? ""} />
                </div>
                <div className="form-group mb-3">
                  <label>Yearly amount</label>
                  <input
                    type="number"
                    className="form-control min-height"
                    name="yearly_amount"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    defaultValue={showEditModal.el.yearly_amount}
                  />
                  {props.touched.yearly_amount &&
                    props.errors.yearly_amount && (
                      <FormikError msg={props.errors.yearly_amount} />
                    )}
                  <FormikError msg={msg?.yearly_amount ?? ""} />
                </div>
                <div className="text-end">
                  <button
                    className="btn btn-outline-red ms-2 px-4"
                    type="button"
                    onClick={() => {
                      setEditModal({ visible: false, el: {} });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red ms-2 px-4"
                    type="submit"
                    disabled={updatingLimit}
                  >
                    Submit{updatingLimit && <Spin />}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}
