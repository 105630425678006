import React, { useEffect, useState } from "react";
import { __fundingHistory } from "../../../store/actions/walletActions";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeFormatter } from "../../../store/actions/utilityActions";
import Pagination from "../../../components/pagination/pagination";
import { Spin } from "antd";

const FundWalletTable = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { loading, funding_history } = useSelector((state) => state.wallet);

  useEffect(() => {
    dispatch(__fundingHistory());
  }, []);
  
  return (
    <div className="table-responsive">
      {loading && <Spin />}
      <table className="table table-borderless">
        <thead>
          <tr>
            <th scope="col">S/N</th>
            <th scope="col">Reference</th>
            <th scope="col">Date & Time</th>
            <th scope="col">Amount Received</th>
            <th scope="col">Fee</th>
            <th scope="col">Method</th>
            <th scope="col">Status</th>
            {/* <th scope="col"></th> */}
          </tr>
        </thead>
        <tbody className="bg-white border-0 rounded">
          {/* data */}

          {funding_history?.data?.map((data, i) => {
            let status = "warning";
            if (data.status === "successful") status = "success";
            if (data.status === "failed" || data.status === "cancelled")
              status = "danger";

            let count = i + 1;
            if (currentPage > 1) {
              if (count === 10) {
                count = Number(`${currentPage - 1}${i}`) + 1;
              } else {
                count = `${currentPage - 1}${count}`;
              }
            }
            return (
              <tr key={i} className="border-bottom">
                <td className="py-4">{count}</td>
                {/* Reference */}
                <td className="py-4">{data.reference}</td>
                {/* Date and time */}
                <td className="text-muted py-4">
                  <p>{dateTimeFormatter(data.created_at).date}</p>
                  <p>{dateTimeFormatter(data.created_at).time}</p>
                </td>
                {/* Received Amount */}
                <td className="py-4">
                  <span
                    className={`flag-icon flag-icon-${data.from_currency.iso2} flag-icon-squared`}
                  ></span>
                  &nbsp;{" "}
                  {`${data.from_currency.currency} ${data.received_amount}`}
                </td>
                {/* Fee */}
                <td className="py-4">
                  <span
                    className={`flag-icon flag-icon-${data.from_currency.iso2} flag-icon-squared`}
                  ></span>
                  &nbsp; {`${data.from_currency.currency} ${data.fee}`}
                </td>
                {/* channel */}
                <td className="text-muted py-4">{data.payin}</td>
                <td className="py-4">
                  <span
                    className={`px-4 py-2 status-width rounded alert-${status} fw-bold text-capitalize`}
                  >
                    {data.status}
                  </span>
                </td>
                {/* <td>
                  <div className="dropdown">
                    <a
                      className="btn dropdown-btn"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      <i className="fas fa-ellipsis-v" />
                    </a>
                    <ul className="dropdown-menu shadow-sm rounded">
                      <li>
                        <a className="dropdown-item py-2" href="#detailsModal">
                          View
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item py-2" href="#addNoteModal">
                          Complaint
                        </a>
                      </li>
                    
                    </ul>
                  </div>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      {funding_history?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (funding_history !== null && funding_history?.meta?.per_page) {
              dispatch(__fundingHistory(page));
            }
          }}
          data={funding_history}
        />
      )}
    </div>
  );
};

export default FundWalletTable;
