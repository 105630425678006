import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPending,
  deleteUpload,
  approveUpload,
  rejectUpload,
  download,
} from "../../../store/actions/admin/uploads";
import { Spin, Modal } from "antd";
import Pagination from "../../../components/pagination/pagination";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
export default function Pending() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");

  const { pending, fetching, changed, loading } = useSelector(
    (state) => state.uploads
  );
  const navigate = useNavigate();

  const { msg } = useSelector((state) => state.error);

  useEffect(() => {
    dispatch(fetchPending(pageParam ?? 1));
    setCurrentPage(pageParam);
  }, [pageParam]);

  const [showDelete, setShowDelete] = useState({ show: false, id: "" });
  const [showApprove, setShowApprove] = useState({ show: false, id: "" });
  const [showReject, setShowReject] = useState({ show: false, id: "" });

  const toggleDelete = (id) => {
    setShowDelete({ show: !showDelete.show, id: id });
  };

  const toggleReject = (id) => {
    setShowReject({ show: !showReject.show, id: id });
  };

  const toggleApprove = (id) => {
    setShowApprove({ show: !showApprove.show, id: id });
  };

  const _delete = () => {
    dispatch(deleteUpload(showDelete.id));
  };

  const reject = (msg) => {
    dispatch(rejectUpload({ id: showReject.id, message: msg }));
  };

  const approve = (exp) => {
    dispatch(approveUpload({ id: showApprove.id, expiry_period: exp }));
  };

  useEffect(() => {
    if (changed) {
      setShowDelete({ show: false, id: "" });
      setShowApprove({ show: false, id: "" });
      setShowReject({ show: false, id: "" });
      dispatch(fetchPending());
      document.getElementById("rejectForm")?.reset();
    }
  }, [changed]);

  return (
    <div className="container-fluid">
      <h5 className="fw-bold mb-4">Pending Uploads</h5>
      <div className="row justify-content-between mb-4">
        <div className="col-sm order-sm-1 text-end mb-3">
          <button
            className="btn btn-red px-5 min-height"
            onClick={() => {
              dispatch(download("pending"));
            }}
          >
            Export Record
          </button>
        </div>
        <div className="col-sm order-sm-0 mb-3">
          <div className="input-group">
            <span className="input-group-text bg-white">
              <i className="fas fa-search" />
            </span>
            {/* Search text input */}
            <input
              type="text"
              className="form-control border-start-0 min-height"
              placeholder="Search for anything"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        {fetching && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Member Id</th>
              <th className="text-muted text-uppercase">Sender</th>
              <th className="text-muted text-uppercase">Message</th>
              <th className="text-muted text-uppercase">Status</th>
              <th className="text-muted text-uppercase">Created At</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {pending?.data?.map((el, i) => {
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  <td>{el.member_id}</td>
                  <td>{el.sender}</td>
                  <td className="wrap">
                    <strong>Title: </strong>{" "}
                    <span className="text-muted">{el.title}</span> <br />
                    <strong>Body: </strong>
                    <span className="text-muted">{el.message}</span>
                  </td>
                  <td>
                    <i className="fas fa-exclamation-triangle text-warning" />{" "}
                    &nbsp;
                    <small className="text-warning">pending</small>
                  </td>
                  <td>{el.created_at}</td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#approve"
                            onClick={() => {
                              toggleApprove(el.id);
                            }}
                          >
                            Approve
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#reject"
                            onClick={() => {
                              toggleReject(el.id);
                            }}
                          >
                            Reject
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#view"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/document-preview", {
                                state: { files: el.files },
                              });
                            }}
                          >
                            View
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deleteModal"
                            data-bs-toggle="modal"
                            onClick={() => {
                              toggleDelete(el.id);
                            }}
                          >
                            Trash
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Delete Modal */}
      <Modal
        visible={showDelete.show}
        onCancel={() => {
          toggleDelete();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure ?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleDelete();
            }}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={loading}
            onClick={() => {
              _delete();
            }}
          >
            Delete {loading && <Spin />}
          </button>
        </div>
      </Modal>

      {/* Approve Modal */}
      <Modal
        visible={showApprove.show}
        onCancel={() => {
          toggleApprove();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        title="Approve upload"
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body">
          <Formik
            onSubmit={(values) => {
              approve(values.expiry_period);
            }}
            initialValues={{ expiry_period: "" }}
            validationSchema={Yup.object({
              expiry_period: Yup.string("").required("Enter expiry period"),
            })}
          >
            {(props) => {
              return (
                <form onSubmit={props.handleSubmit} id="approveForm">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-4">
                        <label>Expiry period</label>
                        <input
                          type="number"
                          className="form-control min-height"
                          name="expiry_period"
                          rows={4}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.expiry_period}
                        />
                        {props.touched.expiry_period &&
                          props.errors.expiry_period && (
                            <FormikError msg={props.errors.expiry_period} />
                          )}
                        <FormikError msg={msg?.expiry_period ?? ""} />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-outline-red mx-2 px-4"
                    type="button"
                    onClick={() => {
                      toggleApprove("");
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red mx-2 px-4"
                    type="submit"
                    disabled={loading}
                  >
                    Approve {loading && <Spin />}
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>

      {/* Reject Modal */}
      <Modal
        visible={showReject.show}
        onCancel={() => {
          toggleReject();
        }}
        title="Reject upload"
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body ">
          <Formik
            onSubmit={(values) => {
              reject(values.message);
            }}
            initialValues={{ message: "" }}
            validationSchema={Yup.object({
              message: Yup.string("").required("Enter rejection message"),
            })}
          >
            {(props) => {
              return (
                <form onSubmit={props.handleSubmit} id="rejectForm">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-4">
                        <label>Message</label>
                        <textarea
                          type="text"
                          className="form-control min-height"
                          name="message"
                          rows={4}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.message}
                        />
                        {props.touched.message && props.errors.message && (
                          <FormikError msg={props.errors.message} />
                        )}
                        <FormikError msg={msg?.message ?? ""} />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-outline-red mx-2 px-4"
                    type="button"
                    onClick={() => {
                      toggleReject();
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red mx-2 px-4"
                    type="submit"
                    disabled={loading}
                  >
                    Reject {loading && <Spin />}
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>

      {pending?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (pending !== null && pending?.meta?.per_page) {
              setSearchParams({ page });
              dispatch(fetchPending(page));
            }
          }}
          data={pending}
        />
      )}
    </div>
  );
}
