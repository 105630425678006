import React from 'react'
export default function Faqs() {
  return (
    <main>
      {/* 
  *      M A S T H E A D     * 
    */}
      <section className="masthead">
        <div className="container">
          <div className="masthead-bg-content">
            <img src="./assets/images/components/Vector(6).png" className="masthead-bg masthead-bg-1" alt />
            <img src="./assets/images/components/Vector(6).png" className="masthead-bg masthead-bg-2" alt />
            <img src="./assets/images/components/Vector(5).png" className="masthead-bg masthead-bg-3" alt />
          </div>
          <div className="masthead-content">
            <h2 className="header-3 text-red fw-bold">Frequently asked questions</h2>
            <p className="mb-5">Have questions about CosmoRemit? We’ve got quick answers here </p>
            <div className="search-input">
              <div className="input-group rounded border-0 mb-5 shadow-sm">
                <span className="input-group-text border-0 bg-white">
                  <i className="fas fa-search text-muted" />
                </span>
                <input type="text" className="form-control py-3 border-0" placeholder="Search for a question" />
              </div>
              {/* search suggestions */}
              <div className="search-suggestions" />
            </div>
          </div>
        </div>
      </section>
      {/* 
  *      C U S T O M    N A V      * 
    */}
      <section className="custom-nav py-4">
        <div className="container">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
              <button className="nav-link active" id="pills-general-tab" data-bs-toggle="pill" data-bs-target="#pills-general" type="button">General</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="pills-getting-started-tab" data-bs-toggle="pill" data-bs-target="#pills-getting-started" type="button">Getting started</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="pills-sending-money-tab" data-bs-toggle="pill" data-bs-target="#pills-sending-money" type="button">Sending money</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="pills-increasing-your-limit-tab" data-bs-toggle="pill" data-bs-target="#pills-increasing-your-limit" type="button">Increasing your limit</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="pills-transaction-bonus-tab" data-bs-toggle="pill" data-bs-target="#pills-transaction-bonus" type="button">Transaction Bonus</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="pills-referral-tab" data-bs-toggle="pill" data-bs-target="#pills-referral" type="button">Referral</button>
            </li>
          </ul>
          <div className="tab-content py-4" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-general" tabIndex={0}>
              <div className="accordion questions accordion-flush mb-5" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      I’m unable to login to my account. What do I do?
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      If you are having technical issues when logging in to your CosmoRemit
                      account, please try the following options:
                      <ul>
                        <li>Try logging in with a different browser</li>
                        <li>Clear your cookies and website data on your device</li>
                        <li>Try logging in using a different device</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      How do I reset my password?
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <ul>
                        <li>Login to your profile and go to ‘Settings’</li>
                        <li>Select change password </li>
                        <li>Enter your ‘Current password’ and ‘New password’ </li>
                        <li>If you’ve forgotten your password, click on Forgot password when
                          login in</li>
                        <li> Reset your password by clicking the link you receive in your email</li>
                      </ul>

                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      How do I change my personal details?
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <ul>
                        <li>Login to your profile and click on ‘My profile’ on the top right
                          corner of your screen.</li>
                        <li>Update your details.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="G4">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#G4" aria-expanded="false" aria-controls="G4">
                      I have moved to a new address. Do I need to update my account?
                    </button>
                  </h2>
                  <div id="G4" className="accordion-collapse collapse" aria-labelledby="G4" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <ul>
                        <li>If you have moved to a new address, you are required to update
                          your CosmoRemit account and provide a ‘Proof of Address’ to
                          verify your account.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="G5">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#G5" aria-expanded="false" aria-controls="G5">
                      How do I contact support staff?
                    </button>
                  </h2>
                  <div id="G5" className="accordion-collapse collapse" aria-labelledby="G5" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <ul>
                        <li>Our support staff are available 24/7 via email. </li>
                        <li>Phone support will available during office hours only.  </li>
                        <li>You can find our contact details <href target="_blank">here</href>.   </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-getting-started" tabIndex={0}>
              <h4>Getting Started</h4>
            </div>
            <div className="tab-pane fade" id="pills-sending-money" tabIndex={0}>
              <h4>Sending Money</h4>
            </div>
            <div className="tab-pane fade" id="pills-increasing-your-limit" tabIndex={0}>
              <h4>Increasing Your limit</h4>
            </div>
            {/* <div className="tab-pane fade" id="pills-transaction-bonus" tabIndex={0}>
              <h4>Transaction Bonus</h4>
            </div>
            <div className="tab-pane fade" id="pills-referral" tabIndex={0}>
              <h4>Referral</h4>
            </div> */}
          </div>
        </div>
      </section>
      {/* 
  *     A C T I O N     B U T T O N     *
    */}
      <section className="section pt-0">
        <div className="container">
          <div className="text-center">
            <h4>Still need help?</h4>
            <p className="text-muted">Can’t find the answer you’re looking for? Explore other options</p>
            <div className="row justify-content-center mt-5">
              <div className="col-xl-7 col-md-8">
                <div className="d-flex flex-wrap justify-content-lg-between justify-content-center">
                  <a href="#" className="btn btn-outline-red px-5 py-3 m-2">Visit Help Center</a>
                  <a href="#" className="btn btn-outline-red px-5 py-3 m-2">Live Chat</a>
                  <a href="#" className="btn btn-outline-red px-5 py-3 m-2">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

  )
}
