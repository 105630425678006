import React, { useEffect, useRef } from "react";
import AdminSearchBar from "../../../components/partials/adminSearchBar";
import {
  adminTransfer,
  search,
} from "../../../store/actions/admin/transactions";
import TransferList from "./list";
import { Modal, Result, Select, Spin } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { useState } from "react";
import {
  SET_SHOW_TRFCONFIRM,
  SET_SHOW_TRFSTATUS,
  SET_TRANSFER_VALUES,
} from "../../../store/actionTypes/admin/transactionActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { getAllPayouts, list } from "../../../store/actions/admin/payout";
import { fetchBanks, fetchCountries } from "../../../store/actions/general";

function AdminTransfer() {
  const [showModal, setShowModal] = useState(false);
  // const [showConfirm, setShowConfirm] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [pinComplete, setPinComplete] = useState(false);
  // const [showResult, setShowResult] = useState(false);
  const [savedValues, setSavedValues] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedPayout, setSelectedPayout] = useState("");
  const [selectedBank, setSelectedBank] = useState("");

  const {
    adminTransferValues,
    transferSuccess,
    transferLoading,
    showTrfStatusPage,
    showTrfConfirmPage,
  } = useSelector((state) => state.adminTransactions);
  const { payouts } = useSelector((state) => state.payouts);
  const { banks, countries } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const formRef = useRef();

  const onClickBack = () => {
    dispatch({ type: SET_SHOW_TRFCONFIRM, payload: false });
    dispatch({ type: SET_SHOW_TRFSTATUS, payload: false });
    // setShowConfirm(false);
    // setShowResult(false);
    getSavedValues();
    setOtpValue("");
    setPinComplete(false);
  };
  const getSendData = (value) => {
    const transferData = {
      ...value,
      bankName: selectedBank,
      payoutName: selectedPayout,
      countryName: selectedCountry,
    };
    const { pin, ...newValue } = transferData;
    localStorage.setItem("transfer_values", JSON.stringify({ ...newValue }));

    dispatch({ type: SET_TRANSFER_VALUES, payload: transferData });
    // setShowConfirm(true);
    dispatch({ type: SET_SHOW_TRFCONFIRM, payload: true });
  };
  const getSavedValues = () => {
    const formValues = JSON.parse(localStorage.getItem("transfer_values"));

    setSavedValues(formValues);
  };
  const send = () => {
    dispatch(adminTransfer(adminTransferValues));
    // if (res) {
    //   setShowConfirm(false);
    //   setShowResult(true);
    // }
  };
  useEffect(() => {
    getSavedValues();
  }, []);

  useEffect(() => {
    dispatch(getAllPayouts());
    dispatch(fetchCountries);
  }, []);
  const cancelTransfer = () => {
    localStorage.removeItem("transfer_values");
    // formref.current.resetForm();
    setSavedValues({
      payout_uuid: "",
      account_number: "",
      bank_id: "",
      account_name: "",
      amount: "",
    });
    setOtpValue("");
    dispatch({ type: SET_SHOW_TRFCONFIRM, payload: false });
    dispatch({ type: SET_SHOW_TRFSTATUS, payload: false });

    // setShowConfirm(false);
    // setShowResult(false);
    setShowModal(false);
    setPinComplete(false);
  };

  function clearSavedValues() {
    localStorage.removeItem("transfer_values");
  }
  
  setTimeout(clearSavedValues, 600000);
  // const handlePinChange = (otp, props) => {
  //   // console.log(otp);
  //   const otpString = otp.toString();
  //   const otpArray = [];

  //   otpArray.push(otpString);

  //   console.log(otpArray);
  //   setOtpValue(
  //     (prevOtpValue) => prevOtpValue + otpString.charAt(otpString.length - 1)
  //   );

  //   props.setFieldValue("pin", otpValue.toString().slice(0, 4));

  //   if (otpString.length === 4 && otpValue.toString().length === 4) {
  //     setTimeout(() => {
  //       setPinComplete(true);
  //     }, 2000);
  //   } else {
  //     setPinComplete(false);
  //   }
  // };
  const openTransferModal = () => {
    setShowModal(true);
    getSavedValues();
  };
  return (
    <div className="container-fluid">
      <h5 className="fw-bold">Admin Transfer</h5>
      <p className="text-muted mb-4">Here you can view transfer records.</p>
      <div className="row justify-content-between mb-4">
        <div className="col-sm order-sm-1 text-end mb-3">
          <button
            onClick={openTransferModal}
            className="btn btn-red px-5 min-height"
          >
            Transfer Money
          </button>{" "}
          &nbsp;
        </div>
        <AdminSearchBar callback={search} />
      </div>
      <TransferList />
      {/* create modal */}
      <Modal
        destroyOnClose
        open={showModal}
        footer={false}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        {!showTrfConfirmPage && !showTrfStatusPage && (
          <div>
            <h5 className="fw-bold">Initiate Transfer</h5>
            <Formik
              initialValues={{
                payout_uuid: savedValues?.payout_uuid || "",
                account_number: savedValues?.account_number || "",
                bank_id: savedValues?.bank_id || "",
                account_name: savedValues?.account_name || "",
                country: savedValues?.country || "",
                amount: savedValues?.amount || "",
                pin: "",
              }}
              validationSchema={Yup.object({
                payout_uuid: Yup.string("").required("Select a payout"),
                account_number: Yup.string()
                  .required("Enter an account number")
                  .matches(
                    /^[0-9]{1,}$/g,
                    "Account number must be a valid number"
                  ),
                bank_id: Yup.string("").required("select a bank"),
                country: Yup.string("").required("select a country"),
                account_name: Yup.string("").required("Enter an account name"),
                amount: Yup.string()
                  .required("Enter amount")
                  .matches(/^[0-9]{1,}$/g, "Amount must be a valid number"),
                pin: Yup.string()
                  .required("Enter a 4-digit pin")
                  .matches(/^[0-9]{4}$/g, "Pin must be a 4-digit number"),
              })}
              onSubmit={getSendData}
              isInitialValid={false}
              enableReinitialize
              innerRef={formRef}
            >
              {(props) => (
                <form id="form" onSubmit={props.handleSubmit}>
                  <div className="row align-items-center">
                    <div className="">
                      {console.log(pinComplete)}
                      <div className="form-group mb-3">
                        <label htmlFor>Select Payout</label>
                        <Select
                          name="payout_uuid"
                          value={props.values.payout_uuid}
                          //  onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          className="form-select min-height"
                          showSearch
                          filterOption={(input, option) => {
                            return option.name
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          placeholder="Select Payout"
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              payout_uuid: value.key,
                            });
                            setSelectedPayout(value?.label);
                          }}
                          labelInValue
                        >
                          {payouts?.length > 0 &&
                            payouts?.map(
                              (payout, index) =>
                                !payout?.deactivated_at && (
                                  <Select.Option
                                    key={payout.uuid}
                                    id={payout.uuid}
                                    value={payout.uuid}
                                    name={payout.name}
                                  >
                                    {payout.name}
                                  </Select.Option>
                                )
                            )}
                        </Select>

                        {props.touched.payout_uuid &&
                          props.errors.payout_uuid && (
                            <FormikError msg={props.errors.payout_uuid} />
                          )}
                        {/* <FormikError msg={msg?.bootstrap_class ?? ""} /> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group mb-3">
                        <label htmlFor>Account Number</label>
                        <input
                          type="text"
                          defaultValue={props.values.account_number}
                          className="form-control min-height"
                          name="account_number"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </div>
                      {props.touched.account_number &&
                        props.errors.account_number && (
                          <FormikError msg={props.errors.account_number} />
                        )}
                      {/* <FormikError msg={msg?.display_name ?? ""} /> */}
                    </div>
                    <div className="">
                      <div className="form-group mb-3">
                        <label className="">Country</label>
                        {/* select box */}
                        <Select
                          showSearch
                          filterOption={(input, option) => {
                            return option.name
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          placeholder="Select country"
                          className="form-control min-height"
                          value={savedValues?.countryName}
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              country: value.key,
                            });
                            setSelectedCountry(value?.label[2]);
                            dispatch(fetchBanks(value.key));
                          }}
                          labelInValue
                        >
                          {countries?.map((el) => {
                            return (
                              <Select.Option
                                key={el.id}
                                id={el.id}
                                value={el.id}
                                countryCode={el.phonecode}
                                name={el.name}
                              >
                                <span
                                  className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                                ></span>
                                {"\u00A0"}
                                {el.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                        {props.touched.country && props.errors.country && (
                          <FormikError msg={props.errors.country} />
                        )}
                        {/* <FormikError msg={msg?.country ?? ""} />  */}
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group mb-3">
                        <label htmlFor>Select Bank</label>{" "}
                        <Select
                          name="bank_id"
                          value={savedValues?.bankName}
                          //  onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          className="form-select min-height"
                          showSearch
                          filterOption={(input, option) => {
                            return option.name
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          placeholder="Select Bank"
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              bank_id: value.key,
                            });
                            setSelectedBank(value?.label);
                          }}
                          labelInValue
                        >
                          {banks?.map((bank, index) => (
                            <Select.Option
                              key={bank.id}
                              id={bank.id}
                              value={bank.id}
                              name={bank.name}
                            >
                              {bank.name}
                            </Select.Option>
                          ))}
                        </Select>
                        {props.touched.bank_id && props.errors.bank_id && (
                          <FormikError msg={props.errors.bank_id} />
                        )}
                        {/* <FormikError msg={msg?.bootstrap_class ?? ""} /> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group mb-3">
                        <label htmlFor>Account Name</label>
                        <input
                          type="text"
                          defaultValue={props.values.account_name}
                          className="form-control min-height"
                          name="account_name"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </div>
                      {props.touched.account_name &&
                        props.errors.account_name && (
                          <FormikError msg={props.errors.account_name} />
                        )}
                      {/* <FormikError msg={msg?.display_name ?? ""} /> */}
                    </div>
                    <div className="">
                      <div className="form-group mb-3">
                        <label htmlFor>Amount</label>
                        <input
                          type="number"
                          defaultValue={props.values.amount}
                          className="form-control min-height"
                          name="amount"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </div>
                      {props.touched.amount && props.errors.amount && (
                        <FormikError msg={props.errors.amount} />
                      )}
                      {/* <FormikError msg={msg?.display_name ?? ""} /> */}
                    </div>
                    <div className="">
                      <div className="form-group mb-3">
                        <label htmlFor>Pin</label>
                        <input
                          type="password"
                          className="form-control min-height"
                          name="pin"
                          maxLength={4}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </div>
                      {props.touched.pin && props.errors.pin && (
                        <FormikError msg={props.errors.pin} />
                      )}
                      {/* <FormikError msg={msg?.display_name ?? ""} /> */}
                    </div>
                    {/* <div className="">
                      <div className="form-group mb-3 d-flex flex-column align-items-center">
                        <label htmlFor>Pin</label>

                        <StatefulPinInput
                          onChange={(e) => {
                            handlePinChange(e, props);
                          }}
                          onBlur={props.handleBlur}
                          mask
                          name="pin"
                          length={4}
                          initialValue={""}
                          type="number"
                          focusBorderColor=""
                          onComplete={() => {
                            setPinComplete(true);
                          }}
                          placeholder=""
                          validate={"/^d{4}$/"}
                          //   borderColor={props.touched.pin && !pinComplete ? "#e91908" : ""}
                        />
                      </div>
                     
                    </div> */}
                  </div>
                  <div className="text-end">
                    <button
                      className="btn btn-outline-red ms-2 px-4"
                      type="button"
                      onClick={cancelTransfer}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={!props.isValid}
                      className={`btn btn-red ms-2 px-4 ${
                        !props.isValid ? ".disabled" : ""
                      }`}
                      type="submit"
                      //   disabled={saving}
                    >
                      confirm
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
        {showTrfConfirmPage && !showTrfStatusPage && (
          <div>
            <div className="row align-items-center">
              <h5 className="fw-bold">
                <span className="me-3" type="button" onClick={onClickBack}>
                  <i className="fas fa-arrow-left "></i>
                </span>
                Confirm details
              </h5>
              <p className="col-md-6 fw-bold">Payout:</p>
              <p className="col-md-6">{adminTransferValues?.payoutName}</p>
              <p className="col-md-6 fw-bold">Account Name:</p>
              <p className="col-md-6">{adminTransferValues?.account_name}</p>
              <p className="col-md-6 fw-bold">Account Number:</p>
              <p className="col-md-6">{adminTransferValues?.account_number}</p>
              <p className="col-md-6 fw-bold">Bank Name:</p>
              <p className="col-md-6">{adminTransferValues?.bankName}</p>
              <p className="col-md-6 fw-bold">Amount:</p>
              <p className="col-md-6">Ngn {adminTransferValues?.amount}</p>
            </div>
            <button
              className={`btn btn-red ms-2 px-5`}
              onClick={send}
              type="submit"
              disabled={transferLoading}
            >
              Send <i class="fas fa-paper-plane"></i>
              {transferLoading && <Spin />}
            </button>
          </div>
        )}
        <div>
          {showTrfStatusPage && transferSuccess && (
            <Result
              status="success"
              title="Transaction Successful"
              extra={
                <button
                  className={`btn btn-red ms-2 px-5`}
                  onClick={cancelTransfer}
                >
                  Close
                </button>
              }
            />
          )}
          {showTrfStatusPage && !transferSuccess && (
            <Result
              status="error"
              title="Transaction Failed"
              extra={
                <button
                  className={`btn btn-red ms-2 px-5`}
                  onClick={cancelTransfer}
                >
                  Close
                </button>
              }
            />
          )}
          {/* {showTrfStatusPage && trxStatus === "pending" && (
            <Result
              status="success"
              title="Transaction Pending"
              subTitle="Please wait for sometime"
              extra={
                <button
                  className={`btn btn-red ms-2 px-5`}
                  onClick={cancelTransfer}
                >
                  Close
                </button>
              }
            />
          )} */}
        </div>
      </Modal>
    </div>
  );
}

export default AdminTransfer;
