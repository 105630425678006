export const FETCHING_POP_MESSAGES = "FETCHING_POP_MESSAGES";
export const SAVING_POP_MESSAGES = "SAVING_POP_MESSAGES";
export const SAVED_POP_MESSAGES = "SAVED_POP_MESSAGES";
export const DELETING_POP_MESSAGES = "DELETING_POP_MESSAGES";
export const DELETED_POP_MESSAGES = "DELETED_POP_MESSAGES";
export const UPDATING_POP_MESSAGES = "UPDATING_POP_MESSAGES";
export const UPDATED_POP_MESSAGES = "UPDATED_POP_MESSAGES";
export const SET_POP_MESSAGES = "SET_POP_MESSAGES";
export const SHOW_CREATE_MODAL = "SHOW_CREATE_MODAL";
export const SEARCHING_POP_MESSAGES = "SEARCHING_POP_MESSAGES";
export const TOGGLING_POP_MESSAGES = "TOGGLING_POP_MESSAGES";
export const TOGGLED_POP_MESSAGES = "TOGGLED_POP_MESSAGES";