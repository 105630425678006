import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../store/actions/authActions";

export default function useIdleLogout() {
  const dispatch = useDispatch();

  const checkInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");
    if(localStorage.getItem("authRes")){
        if (expireTime < Date.now()) {
            dispatch(logout);
          }
    }
    
  };

  const updateExpireTime = () => {
    // autologout after 30 mins of inactivity
    const expireTime = Date.now() + 1800000;
    localStorage.setItem("expireTime", expireTime);
  };

  const idleChecker = () => {
    const interval = setInterval(() => {
      checkInactivity();
    }, 5000);

    return () => clearInterval(interval);
  };

  const observer = () => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  };

  return { idleChecker, observer };
}
