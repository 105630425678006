export const FETCHING_RATES = "FETCHING_RATES";
export const SAVING_RATES = "SAVING_RATES";
export const SAVED_RATES = "SAVED_RATES";
export const DELETING_RATES = "DELETING_RATES";
export const DELETED_RATES = "DELETED_RATES";
export const SET_RATES = "SET_RATES";
export const UPDATING_RATES = "UPDATING_RATES";
export const UPDATED_RATES = "UPDATED_RATES";
export const SHOW_CREATE_MODAL = "SHOW_CREATE_MODAL";
export const FETCHING_PAYINS = "FETCHING_PAYINS";
export const FETCHING_PAYOUTS = "FETCHING_PAYOUTS";
export const SET_PAYINS = "SET_PAYINS";
export const SET_PAYOUTS = "SET_PAYOUTS";
export const SET_PAYINS_EDIT = "SET_PAYINS_EDIT";
export const SET_PAYOUTS_EDIT = "SET_PAYOUTS_EDIT";
export const SEARCHING_RATE = "SEARCHING_RATE";
export const SETTING_DEFAULT_RATES = "SETTING_DEFAULT_RATES";
export const SET_DEFAULT_RATES = "SET_DEFAULT_RATES";
export const FETCHING_RATE_MANAGERS = "FETCHING_RATE_MANAGERS";
export const SET_RATE_MANAGERS = "SET_RATE_MANAGERS";
export const FETCHING_SINGLE_RATE = "FETCHING_SINGLE_RATE";
export const SET_RATE_TO_VIEW = "SET_RATE_TO_VIEW";

