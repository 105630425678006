import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate,useSearchParams } from "react-router-dom";
import {
  requestedDeactivation,
  ban,
  suspend,
  activate,
  downloadTransaction,
  doBlacklist,
  searchDeactivated,
  approveDeactivation
} from "../../../store/actions/admin/senders";
import { Spin, Modal } from "antd";
import Pagination from "../../../components/pagination/pagination";
import moment from "moment";
import FormikError from "../../../components/partials/formikError";
import FilterSearch from "../../../components/partials/filterSearch";

export default function RequestedDeactivation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get("page");

  useEffect(() => {
    dispatch(requestedDeactivation(pageParam));
    setCurrentPage(pageParam)
  }, [pageParam]);
  const {
    senders,
    fetching,
    loadingAction,
    completedAction,
    blacklisting,
    blacklisted,
  } = useSelector((state) => state.senders);

  const [show, setShow] = useState(false);
  const [uuid, setUuid] = useState({});
  const [blaclist, setBlaclist] = useState({
    show: false,
    reason: "",
    uuid: "",
  });
  const [blackListErr, setBlaclistErr] = useState(false);
  const toggleModal = (id = "", type = "") => {
    setUuid({ uuid: id, type: type });
    setShow(!show);
  };

  useEffect(() => {
    if (completedAction) {
      setShow(false);
      dispatch(requestedDeactivation(pageParam));
    }

    if (blacklisted) {
      document?.getElementById("blackListForm")?.reset();
      setBlaclist({ show: false, reason: "", uuid: "" });
      dispatch(requestedDeactivation(pageParam));
    }
  }, [completedAction, blacklisted,pageParam]);

  const modalAction = () => {
    if (uuid.type === "ban") dispatch(ban(uuid.uuid));
    if (uuid.type === "activate") dispatch(activate(uuid.uuid));
    if (uuid.type === "suspend") dispatch(suspend(uuid.uuid));
    if(uuid.type==="approve_deactivation")dispatch(approveDeactivation(uuid.uuid));
  };

  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user.permissions;

  return (
    <div className="container-fluid">
      <h5 className="fw-bold">Senders</h5>
      <p className="text-muted mb-4">
        Here is a table containing a list of all banned senders. <br /> You can
        view, edit, ban and suspend any sender using the action button on the
        right hand side.
      </p>
      <div className="row justify-content-between mb-4">
        <div className="col-sm order-sm-1 text-end mb-3">
          <a
            href="#export"
            className="btn btn-red px-5 min-height"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Export Record
          </a>
        </div>
        <div className="col-sm order-sm-0 mb-3">
          <FilterSearch
            options={["member_id", "phone", "email", "status", "name"]}
            callback={searchDeactivated}
          />
        </div>
      </div>
      <div className="table-responsive">
        {fetching && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">member id</th>
              <th className="text-muted text-uppercase">name</th>
              <th className="text-muted text-uppercase">mobile phone</th>
              <th className="text-muted text-uppercase">email</th>
              <th className="text-muted text-uppercase">status</th>
              <th className="text-muted text-uppercase">Created at</th>
              {/* <th className="text-muted text-uppercase">id card</th> */}
              <th className="text-muted text-uppercase">action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {senders.data?.map((el, i) => {
              let txt = "success";
              if (el.account_status === "banned") txt = "danger";
              if (el.account_status === "deactivated") txt = "danger";
              if (el.account_status === "suspended") txt = "warning";
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }

              // console.log(el?.profile);

              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  <td>#{el?.profile?.member_id}</td>
                  <td>
                    <a
                      href="#view"
                      onClick={(e) => {
                        e.preventDefault();
                        // localStorage.setItem("senders_userUuid", el.uuid);
                        localStorage.setItem(
                          "senders_profile",
                          JSON.stringify({...el.profile, sender_uuid: el?.uuid})
                        );
                        // navigate("/senders/details");
                        navigate(`/senders/details`, {
                          state: { uuid: el.uuid },
                        });
                      }}
                    >
                      {el?.profile?.firstname
                        ? `${el?.profile?.firstname} ${el?.profile?.lastname}`
                        : "N/A"}
                    </a>
                  </td>
                  <td>{el?.profile?.mobile_phone}</td>
                  <td>{el.email}</td>
                  <td>
                    <small
                      className={`px-4 py-2 status-width rounded alert-${txt} fw-bold text-capitalize`}
                    >
                      {el.account_status}
                    </small>
                  </td>
                  <td> {moment(el.created_at.date).format("YYYY-MM-DD")}</td>
                  {/* <td>
                  <i className="fas fa-check-circle text-success" /> &nbsp;
                  <small className="text-success">confirmed</small>
                </td> */}
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#actions"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        {permissions["view user"] && (
                          <li>
                            <button
                              className="dropdown-item py-2"
                              // href="#"
                              onClick={() => {
                                // localStorage.setItem("senders_userUuid", el.uuid);
                                localStorage.setItem(
                                  "senders_profile",
                                  JSON.stringify(el.profile)
                                );
                                navigate(`/senders/details`, {
                                  state: { uuid: el.uuid },
                                });
                              }}
                            >
                              View &amp; Edit
                            </button>
                          </li>
                        )}
                        {/* <li>
                          <a
                            className="dropdown-item py-2"
                            href="#download"
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(downloadTransaction(el.uuid));
                            }}
                          >
                            Download Transaction Report
                          </a>
                        </li> */}
                        {/* {permissions["suspend"] && (
                          <li>
                            {el.account_status === "active" ? (
                              <a
                                className="dropdown-item py-2"
                                href="#suspend"
                                onClick={() => {
                                  toggleModal(el.uuid, "suspend");
                                }}
                              >
                                Suspend User
                              </a>
                            ) : (
                              <a
                                className="dropdown-item py-2"
                                href="#suspend"
                                onClick={() => {
                                  toggleModal(el.uuid, "activate");
                                }}
                              >
                                Activate
                              </a>
                            )}
                          </li>
                        )} */}
                        {
                        permissions["blacklist"] ? (
                          <li>
                            <a
                              className="dropdown-item py-2"
                              href="#ban"
                              onClick={() => {
                                toggleModal(el.uuid, "approve_deactivation");
                              }}
                            >
                              Approve deactivation
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {/* {permissions["blacklist"] && (
                          <li>
                            <a
                              className="dropdown-item py-2"
                              href="#ban"
                              onClick={() => {
                                setBlaclistErr(false);
                                setBlaclist({
                                  show: true,
                                  reason: "",
                                  uuid: el.uuid,
                                });
                              }}
                            >
                              {el.profile.black_listed
                                ? "Unblacklist"
                                : "Blacklist"}
                            </a>
                          </li>
                        )} */}
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {senders?.links && (
          <Pagination
            onPageChange={(page) => {
              setCurrentPage(page);
              setSearchParams({ page });

              if (senders !== null && senders?.meta?.per_page) {
                dispatch(requestedDeactivation(page));
              }
            }}
            data={senders}
          />
        )}

        <Modal
          visible={show}
          onCancel={() => {
            toggleModal();
          }}
          bodyStyle={{
            padding: "0px !important",
            borderRadius: "20px !important",
            margin: "0px",
          }}
          closable={false}
          footer={false}
          // width="25%"
        >
          <div className="modal-body text-center">
            <img
              src="../assets/images/components/rafiki.png"
              alt="delete icon"
              className="img-fluid"
            />
            <p>Are you sure?</p>
            <button
              className="btn btn-outline-red mx-2 px-4"
              type="button"
              onClick={() => {
                toggleModal();
              }}
              disabled={loadingAction}
            >
              Cancel
            </button>
            <button
              className="btn btn-red mx-2 px-4"
              type="button"
              disabled={loadingAction}
              onClick={() => {
                modalAction();
              }}
            >
              Proceed {loadingAction ? <Spin /> : ""}
            </button>
          </div>
        </Modal>
        <Modal
          visible={blaclist.show}
          footer={false}
          onCancel={() => {
            setBlaclistErr(false);
            setBlaclist({ show: false, reason: "", uuid: "" });
          }}
        >
          <div className="form-group mb-3">
            <label htmlFor>Black list reason</label>
            <form id="blackListForm">
              <textarea
                rows={3}
                className="form-control"
                placeholder="Reason"
                name="reason"
                onChange={(e) => {
                  setBlaclist({ ...blaclist, reason: e.target.value });
                }}
              />
            </form>

            {blackListErr && <FormikError msg="Enter reason" />}
          </div>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setBlaclist({ show: false, reason: "", uuid: "" });
            }}
            disabled={loadingAction}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={loadingAction}
            onClick={() => {
              if (!blaclist.reason) {
                setBlaclistErr(true);
                return;
              } else {
                dispatch(doBlacklist(blaclist));
              }
            }}
          >
            Blacklist {blacklisting ? <Spin /> : ""}
          </button>
        </Modal>
      </div>
    </div>
  );
}
