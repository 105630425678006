import React, { useState } from "react";
import FormikError from "../../../components/partials/formikError";
import { Formik } from "formik";
import { Modal, Spin } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocs } from "../../../store/actions/admin/businesses";

function UploadBusinessDocumentModal({ visible, onClose, name, id }) {
  const { msg } = useSelector((state) => state.error);
  const { uploadingDocument } = useSelector((state) => state.adminBusiness);

  const [fileError, setFileError] = useState(false);
  const dispatch = useDispatch();
  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title={
        <h5 className="fw-bold mb-3">Upload Document(s) for {name}</h5>
      }
      footer={false}
    >
      <Formik
        onSubmit={(values) => {
          dispatch(uploadDocs({ ...values, user_id: id }, onClose));
        }}
        initialValues={{
          title: "",
          file: [],
          message: "",
        }}
        validationSchema={Yup.object({
          title: Yup.string("").required("enter document title"),
          description: Yup.string("").required("enter description"),
          file: Yup.array().min(1, "minimum of 1 file is require"),
        })}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} id="form">
            {/* document type name */}
            <div className="form-group mb-3">
              <label htmlFor className="mb-2">
                Document title eg. Payslip
              </label>
              <input
                name="title"
                className="form-control min-height"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              {props.touched.title && props.errors.title && (
                <FormikError msg={props.errors.title} />
              )}
              <FormikError msg={msg?.title ?? ""} />
            </div>
            {/* Upload Documents */}
            <div className="form-group mb-3">
              <label htmlFor className="mb-2">
                Upload Business Document(s)
              </label>
              <div className="input-file-upload">
                <div className="file-component">
                  <span className="d-block text-center w-100">
                    <i
                      className="fas fa-cloud-upload-alt"
                      style={{ fontSize: 20 }}
                    />
                    <p>
                      <span className="text-primary">Click to upload</span> or
                      drag and drop
                    </p>
                    <span className="fw-lighter">PDF, JPG, PNG (max 2mb)</span>
                  </span>
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    accept=".png,.jpg,.pdf,.jpeg"
                    multiple
                    onChange={(e) => {
                      let err = 0;
                      setFileError(false);
                      for (let i = 0; i < e.target.files.length; i++) {
                        if (e.target.files[i].size > 2000000) {
                          err = 1;
                          setFileError(true);
                          return;
                        }
                      }

                      if (!err) {
                        for (let i = 0; i < e.target.files.length; i++) {
                          props.setValues({
                            ...props.values,
                            file: [...props.values.file, e.target.files[0]],
                          });
                        }
                      }
                    }}
                  />
                </div>
              </div>
              {props.values.file.length > 0 &&
                `${props.values.file.length} file(s) selected`}
              {fileError && (
                <FormikError msg="all files must be less than 2MB" />
              )}
              {props.touched.file && props.errors.file && (
                <FormikError msg={props.errors.file} />
              )}
            </div>
            {/* Description */}
            <div className="form-group mb-4">
              <label htmlFor className="mb-2">
                Description
              </label>
              <textarea
                className="form-control min-height"
                name="description"
                rows={5}
                defaultValue={""}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              {props.touched.description && props.errors.description && (
                <FormikError msg={props.errors.description} />
              )}
              <FormikError msg={msg?.description ?? ""} />
            </div>
            <div className="form-group mb-3">
              <button
                type="submit"
                className="min-height btn btn-red w-100"
                disabled={uploadingDocument}
              >
                Upload {uploadingDocument && <Spin />}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default UploadBusinessDocumentModal;
