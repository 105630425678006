import * as actionTypes from "../../actionTypes/admin/ratesActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";

export const fetchRates =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_RATES, payload: true });
      dispatch({ type: actionTypes.SAVED_RATES, payload: false });
      const res = await http.get(`admin/rates/list/paginate/10?page=${page}`);
      dispatch({ type: actionTypes.FETCHING_RATES, payload: false });
      dispatch({ type: actionTypes.SET_RATES, payload: res.data.data.rates });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_RATES, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const viewRate = (rate_id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_SINGLE_RATE });
    const res = await http.get(`admin/rates/view/${rate_id}`);
    dispatch({ type: actionTypes.SET_RATE_TO_VIEW, payload: res.data.data });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({ type: actionTypes.FETCHING_SINGLE_RATE });
  }
};

export const createRate = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_RATES, payload: true });
    dispatch({ type: actionTypes.SAVED_RATES, payload: false });
    const res = await http.post("admin/rates/create_rate", fd);
    dispatch({ type: actionTypes.SAVING_RATES, payload: false });
    dispatch({ type: actionTypes.SAVED_RATES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SAVING_RATES, payload: false });
    dispatch({ type: actionTypes.SAVED_RATES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const updateRate = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_RATES, payload: true });
    dispatch({ type: actionTypes.UPDATED_RATES, payload: false });
    const res = await http.post("admin/rates/update_rate", fd);
    dispatch({ type: actionTypes.UPDATING_RATES, payload: false });
    dispatch({ type: actionTypes.UPDATED_RATES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_RATES, payload: false });
    dispatch({ type: actionTypes.UPDATED_RATES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};
export const updateLocalRate = (fd) => async (dispatch) => { 
  try {
    dispatch({ type: actionTypes.UPDATING_RATES, payload: true });
    dispatch({ type: actionTypes.UPDATED_RATES, payload: false });
    const res = await http.post("admin/rates/update_local_rate", fd);
    dispatch({ type: actionTypes.UPDATING_RATES, payload: false });
    dispatch({ type: actionTypes.UPDATED_RATES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_RATES, payload: false });
    dispatch({ type: actionTypes.UPDATED_RATES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const deleteRate = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETING_RATES, payload: true });
    dispatch({ type: actionTypes.DELETED_RATES, payload: false });
    const res = await http.get(`admin/rates/delete/${fd}`);
    dispatch({ type: actionTypes.DELETING_RATES, payload: false });
    dispatch({ type: actionTypes.DELETED_RATES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.DELETING_RATES, payload: false });
    dispatch({ type: actionTypes.DELETED_RATES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const defaultRate = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SETTING_DEFAULT_RATES, payload: true });
    dispatch({ type: actionTypes.SET_DEFAULT_RATES, payload: false });
    const res = await http.get(`admin/rates/set_default/${fd}`);
    dispatch({ type: actionTypes.SETTING_DEFAULT_RATES, payload: false });
    dispatch({ type: actionTypes.SET_DEFAULT_RATES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SETTING_DEFAULT_RATES, payload: false });
    dispatch({ type: actionTypes.SET_DEFAULT_RATES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleCreateLocalRateModal = () => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_CREATE_MODAL });
};

export const getPayins = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_PAYINS, payload: true });
    const res = await http.get(`admin/payins/get_country_payins/${fd}`);
    dispatch({ type: actionTypes.FETCHING_PAYINS, payload: false });
    dispatch({ type: actionTypes.SET_PAYINS, payload: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.FETCHING_PAYINS, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const getRateManagers = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_RATE_MANAGERS });
    const res = await http.get(
      `admin/rates/rate_managers/${fd.fromCountry}/${fd.toCountry}`
    );
    dispatch({
      type: actionTypes.SET_RATE_MANAGERS,
      payload: res.data.data.managers,
    });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({ type: actionTypes.FETCHING_RATE_MANAGERS });
  }
};
export const getPayouts = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_PAYOUTS, payload: true });
    const res = await http.get(`admin/payouts/get_country_payouts/${fd}`);
    dispatch({ type: actionTypes.FETCHING_PAYOUTS, payload: false });
    dispatch({ type: actionTypes.SET_PAYOUTS, payload: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.FETCHING_PAYOUTS, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const getPayinsEdit = (fd, rateId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_PAYINS, payload: true });
    const res = await http.get(
      `admin/payins/get_country_payins/${fd}/${rateId}`
    );
    dispatch({ type: actionTypes.FETCHING_PAYINS, payload: false });
    dispatch({ type: actionTypes.SET_PAYINS_EDIT, payload: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.FETCHING_PAYINS, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const getPayoutsEdit = (fd, rateId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_PAYOUTS, payload: true });
    const res = await http.get(
      `admin/payouts/get_country_payouts/${fd}/${rateId}`
    );
    dispatch({ type: actionTypes.FETCHING_PAYOUTS, payload: false });
    dispatch({ type: actionTypes.SET_PAYOUTS_EDIT, payload: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.FETCHING_PAYOUTS, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const search = (params) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SEARCHING_RATE, payload: true });
    const res = await http.post(`admin/rates/search?page=${params?.page}`, {
      param: params?.param,
    });
    dispatch({ type: actionTypes.SET_RATES, payload: res.data.data.rates });
    dispatch({ type: actionTypes.SEARCHING_RATE, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.SEARCHING_RATE });
  }
};
