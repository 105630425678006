import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadTransactionHistorybyID,
  exportTransactionsXml,
  fetchTransactionReportsHistory,
  sequentialExportTransactionsXml,
} from "../../../store/actions/admin/transactions";
import Pagination from "../../../components/pagination/pagination";
import { Spin, Modal } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import AdminSearchBar from "../../../components/partials/adminSearchBar";
import TimeAgo from "react-timeago";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import FilterSelectWithCount from "../../../components/partials/filterSelectWithCount";
import ExportButton from "../../../components/partials/exportButon";

export default function TrxReports() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { downloading_trx_reports, loading_trx_reports, trxReports } =
    useSelector((state) => state.adminTransactions);

  const [currentPage, setCurrentPage] = useState(1);
  const [reportNoValue, setReportNoValue] = useState("");
  const [err, setErr] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSequentialModalOpen, setIsSequentialModalOpen] = useState(false);

  const pageParam = searchParams.get("page");
  // const searchParam = searchParams.get("param");

  const closeSequentialModal = () => {
    setIsSequentialModalOpen(false);
  };
  useEffect(() => {
    // if (searchParam) {
    //   dispatch(
    //     search({
    //       param: searchParam,
    //       page: pageParam,
    //     })
    //   );
    // } else {
    dispatch(fetchTransactionReportsHistory(pageParam ?? 1));
    // }
    setCurrentPage(pageParam);
  }, [pageParam]);

  const { msg } = useSelector((state) => state.error);

  const exportTransactions = (startDate, endDate, handleCloseModal) => {
    dispatch(exportTransactionsXml(startDate, endDate, handleCloseModal));
  };
  const downloadById = (id) => {
    dispatch(downloadTransactionHistorybyID(id));
  };

  const onValueChange = (e) => {
    setErr("");
    if (e.target.value) {
      setReportNoValue(e.target.value);
    } else {
      setErr("Enter a valid number");
    }
  };
  const handleExportSequentially = (report_no) => {
    if (report_no && !err) {
      dispatch(
        sequentialExportTransactionsXml(report_no, closeSequentialModal)
      );
    }
  };
  return (
    <div className="container-fluid">
      <h5 className="fw-bold">Transaction Reports</h5>
      <hr />
      <div className="d-flex flex-column flex-lg-row mb-4">
        {/* <div className="col-sm-5 mb-3">
          <AdminSearchBar
            callback={search}
            placeholder={"reference number"}
            options={options}
          />{" "}
        </div> */}
        {/* <div className="col-sm-5 mb-3">
          <FilterSelectWithCount
            options={options}
            callback={filterTransaction}
            placeholder="Filter By Country"
            status="completed"
          />
        </div> */}
        <div className=" mb-3">
          <div className="px-2 export-container">
            <ExportButton
              loading={downloading_trx_reports}
              btnText={"Export XML"}
              customDownloadFunction={exportTransactions}
              title={"XML transaction history"}
            />
          </div>
        </div>
        <div className=" mb-3">
          <div className="px-2 export-container">
            <button
              onClick={() => {
                setIsSequentialModalOpen(true);
              }}
              className={`py-2 px-4 border bg-white  button btn  min-height`}
            >
              Export XML sequentially<i class="fas fa-download"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        {(loading_trx_reports || downloading_trx_reports) && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">User ID</th>
              <th className="text-muted text-uppercase">Type </th>
              <th className="text-muted text-uppercase">No. Rows Requested</th>
              <th className="text-muted text-uppercase">No. Rows Fetched </th>
              <th className="text-muted text-uppercase">Date Range</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {trxReports?.data?.map((el, i) => {
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  <td>{el.user_id}</td>
                  <td>{el?.type}</td>
                  <td>{el.rows_requested}</td>
                  <td>{el.rows_fetched}</td>
                  <td>
                    {moment(el.from).format("YYYY-MM-DD")}-{" "}
                    {moment(el.to).format("YYYY-MM-DD")}
                  </td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#detailsModal"
                            onClick={(e) => {
                              e.preventDefault();
                              downloadById(el.id);
                            }}
                          >
                            Download record
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {trxReports?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (trxReports !== null && trxReports?.meta?.per_page) {
              // if (searchParam) {
              //   setSearchParams({
              //     param: searchParam,
              //     page,
              //   });
              //   dispatch(
              //     search({
              //       param: searchParam,
              //       page: page,
              //     })
              //   );
              // } else {
              setSearchParams({ page });
              dispatch(fetchTransactionReportsHistory(page));
              // }
            }
          }}
          data={trxReports}
        />
      )}

      <Modal
        title={`Export XML sequentially`}
        open={isSequentialModalOpen}
        onCancel={closeSequentialModal}
        footer={false}
      >
        <div className="d-flex flex-column w-100 justify-content-end mt-2 ">
          <div className="form-group mb-3">
            <label htmlFor>No. of reports to export</label>
            <input
              type="number"
              className="form-control min-height"
              placeholder="Enter No. of reports to export"
              onChange={onValueChange}
            />
            <FormikError msg={err ?? ""} />
          </div>
          <button
            disabled={downloading_trx_reports}
            onClick={() => handleExportSequentially(reportNoValue)}
            className="py-2 px-4 border bg-white rounded-1 button"
          >
            Download <i class="fas fa-download"></i>{" "}
            {downloading_trx_reports && <Spin />}
          </button>
        </div>
      </Modal>
    </div>
  );
}
