import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function TopNav() {
  return (
    <nav className="navbar navbar-expand-sm navbar-light fixed-top shadow-sm bg-transparent">
      <div className="container-fluid px-lg-5">
        {/* Navbar brand */}
        <Link className="navbar-brand" to="/">
          {/* logo img */}
          <img
            src="assets/images/logo/logo.png"
            width={200}
            className="img-fluid"
            alt="Cosmo Remit logo"
          />
        </Link>
        {/* sidebar toggle button */}
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* D E S K T O P     S I D E B A R     M E N U */}
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ms-auto">
            {/* nav item */}
            <li className="nav-item">
              {/* nav link */}
              <NavLink to="faqs" className="nav-link">
                FAQ
              </NavLink>
            </li>
          
            {/* nav item */}
            <li className="nav-item">
              {/* nav link */}
              {/* <NavLink to="help-center" className="nav-link" >
                Help Center
              </NavLink> */}
            </li>
            {/* nav item */}
            <li className="nav-item">
              {/* nav link */}
              <NavLink to="login" className="btn px-4 btn-white">
                Login
              </NavLink>
            </li>
            {/* nav item */}
            <li className="nav-item">
              {/* nav link */}
              <Link to="signup" className="btn px-3 btn-red">
                Sign Up
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
