import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchBanks, fetchToCountries } from "../../../store/actions/general";
import * as Yup from "yup";
import { Spin, Select } from "antd";
import { saveBeneficiary } from "../../../store/actions/beneficiaryActions";
import { FieldArray, Formik } from "formik";
import FormikError from "../formikError";
import AccountForm from "../../../pages/user/beneficiary/AccountForm";
import { getUserOutbound } from "../../../store/actions/profileActions";
import useGooglePlacesAutocomplete from "../../../hooks/googleAdress.hooks";

const google = (window.google = window.google ? window.google : {});

const AddBeneficiaryForm = ({ fromMobile }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { msg } = useSelector((state) => state.error);
	const { banks, countries } = useSelector((state) => state.general);
	const { loading, saved } = useSelector((state) => state.saveBeneficiary);
	const { user_outbound_data } = useSelector((state) => state.outbound);
	const [countryCode, setCountryCode] = useState("");
	const [countryIso, setCountryIso] = useState("");
	const [hasCode, setHasCode] = useState(false);
	const [hasExtraField, setHasExtraField] = useState(false);
	const [extraFieldName, setExtraFieldName] = useState("");
	const [codeName, setCodeName] = useState();
	const { showModal } = useSelector((state) => state.editBeneficiary);
	const [beneficiary, setBeneficiary] = useState(false);
	const { address } = useGooglePlacesAutocomplete(
		"address",
		true,
		true,
		showModal
	);
	const [addressFocused, setAddressFocused] = useState(false);
	const countrySelectHandler = (values, setValues) => (value, labelInValue) => {
		setValues({ ...values, country_id: value.value });
		setCountryCode("+" + labelInValue.countryCode);
		setCountryIso(labelInValue.countryIso);
		dispatch(fetchBanks(value.value));
	};

	const handleHasCode = (hasCodeValue, codeName) => {
		setHasCode(hasCodeValue);
		setCodeName(codeName);
	};
	const handleHasExtraField = (extraFieldValue, fieldName) => {
		setHasExtraField(extraFieldValue);
		setExtraFieldName(fieldName);
	};
	const formRef = useRef();

	useEffect(() => {
		if (formRef.current && address?.full_address && address?.suburb) {
			formRef.current.setValues({
				...formRef.current.values,
				address: address.full_address,
				suburb: address.suburb,
			});
		} else if (beneficiary && !beneficiary.to_update_address) {
			formRef?.current?.setValues({
				...formRef.current.values,
				address: beneficiary.address || "",
				suburb: beneficiary.suburb || "",
			});
		} else {
			formRef?.current?.setValues({
				...formRef.current.values,
				address: "",
				suburb: "",
			});
		}
	}, [address, showModal, beneficiary, addressFocused]);

	useEffect(() => {
		// dispatch(fetchToCountries);
		dispatch(getUserOutbound());
	}, []);

	useEffect(() => {
		// note this component is used from the mobile mode send component
		if (!fromMobile) {
			if (saved) navigate("/beneficiaries");
		}
	}, [saved, navigate, fromMobile]);

	const { profile, idtype } = JSON.parse(localStorage.getItem("authRes"));
	const validationSchema = Yup.object({
		fullname: Yup.string("").required("enter beneficiary fullname"),
		country_id: Yup.string("").required("select country"),
		phone_no: Yup.string("").required("enter beneficiary phone"),
		address: Yup.string("Enter address").required("enter beneficiary address"),
		suburb: Yup.string("Enter suburb").required("enter beneficiary suburb"),
		accounts: Yup.array().of(
			Yup.object().shape({
				account_name: Yup.string().required("enter account name"),
				bank_id: Yup.string().required("select bank"),
				account_number: Yup.number()
					.typeError("invalid account number")
					.required("enter account number"),
				bank_location: Yup.string().required("enter bank location"),
				extra_code: hasCode
					? Yup.string().required(`${codeName} is required`)
					: Yup.string().nullable(),
				extra_field: hasExtraField
					? Yup.string().required(`${extraFieldName} is required`)
					: Yup.string().nullable(),
			})
		),
	});

	const initialValues = {
		fullname: "",
		country_id: "",
		phone_no: "",
		address: address?.full_address || "",
		suburb: address?.suburb || "",
		accounts: [
			{
				account_name: "",
				bank_id: "",
				account_number: "",
				bank_location: "",
				extra_code: "",
				extra_field: "",
			},
		],
	};

	const onSubmit = (values) => {
		if (loading) return;
		dispatch(saveBeneficiary({ ...values, account: values.accounts }));
	};

	const addHandler = (values, setValues) => (e) => {
		e.preventDefault();
		const accounts = [
			...values.accounts,
			{ account_name: "", bank_id: "", account_number: "", bank_location: "" },
		];

		setValues({ ...values, accounts });
	};
	return (
		<Formik
			innerRef={formRef}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize={true}
		>
			{({
				handleSubmit,
				handleChange,
				handleBlur,
				touched,
				errors,
				values,
				setValues,
			}) => (
				<form onSubmit={handleSubmit}>
					<h5 className="fw-bold py-3">Personal Details</h5>
					{/* beneficairy full name */}
					<div className="form-group mb-3">
						<label className="mb-2">Fullname (As appears on ID card)</label>
						<input
							type="text"
							placeholder="Beneficiary fullname"
							className="form-control min-height"
							onChange={handleChange}
							onBlur={handleBlur}
							name="fullname"
						/>
						{touched.fullname && errors.fullname && (
							<FormikError msg={errors.fullname} />
						)}
						<FormikError msg={msg?.fullname ?? ""} />
					</div>
					<div className="row">
						{/* Country */}
						<div className="col-lg-6">
							<div className="form-group mb-3">
								<label className="mb-2">Country</label>
								{/* select box */}
								<Select
									showSearch
									filterOption={(input, option) => {
										return option.label
											.toLowerCase()
											.includes(input.toLowerCase());
									}}
									autocomplete={false}
									placeholder="Select country"
									className="form-control min-height"
									onChange={countrySelectHandler(values, setValues)}
									labelInValue
								>
									{user_outbound_data?.map((el) => {
										return (
											<Select.Option
												key={el.country.id}
												id={el.country.id}
												countryCode={el.country.phonecode}
												countryIso={el.country.iso2}
												label={el.country.name}
											>
												<span
													className={`flag-icon flag-icon-${el.country.iso2.toLowerCase()} flag-icon-squared`}
												></span>
												{"\u00A0"}
												{el.country.name}
											</Select.Option>
										);
									})}
								</Select>
								{touched.country_id && errors.country_id && (
									<FormikError msg={errors.country_id} />
								)}
								<FormikError msg={msg?.country_id ?? ""} />
							</div>
						</div>
						{/* Phone number */}
						<div className="col-lg-6">
							<div className="form-group mb-3">
								<label className="mb-2">Phone number</label>
								<div className="input-group">
									{/* input box */}
									<span className="text-muted input-group-text bg-white rounded-0">
										{countryCode}
									</span>
									<input
										type="text"
										placeholder="Phone number"
										className="form-control border-start-0 min-height"
										onChange={handleChange}
										onBlur={handleBlur}
										name="phone_no"
									/>
								</div>
								{touched.phone_no && errors.phone_no && (
									<FormikError msg={errors.phone_no} />
								)}
								<FormikError msg={msg?.phone_no ?? ""} />
							</div>
						</div>
					</div>
					{/* Address */}
					<div className="form-group mb-3">
						<label className="mb-2 address">Address</label>
						<input
							type="text"
							id="address"
							name="address"
							placeholder="Beneficiary Address"
							value={values.address}
							onChange={handleChange}
							onFocus={() => {
								setAddressFocused(true);
							}}
							onBlur={(e) => {
								handleBlur(e);
								setAddressFocused(false);
							}}
							className="form-control min-height address_autocomplete_google_address"
						/>
						{touched.address && errors.address && (
							<FormikError msg={errors.address} />
						)}
						<FormikError msg={msg?.address ?? ""} />
					</div>
					{/* Suburb */}
					<div className="form-group mb-3">
						<label className="mb-2 suburb">Suburb/City</label>
						<input
							type="text"
							value={values.suburb}
							placeholder="Beneficiary suburb"
							className="form-control min-height"
							onChange={handleChange}
							onBlur={handleBlur}
							name="suburb"
							disabled
						/>
						{touched.suburb && errors.suburb && (
							<FormikError msg={errors.suburb} />
						)}
						<FormikError msg={msg?.suburb ?? ""} />
					</div>
					<div className="py-3">
						<h5 className="fw-bold ">Bank Account Details </h5>{" "}
						<small className="text-red text-sm">
							Please enter the correct beneficiary account information to
							prevent potential delays in the transaction process.
						</small>
					</div>

					{/* {accountForms.map((el) => el)} */}
					<FieldArray name="accounts">
						<>
							{values.accounts.map((account, index) => {
								return (
									<AccountForm
										key={index}
										onChange={handleChange}
										onBlur={handleBlur}
										index={index}
										errors={errors}
										touched={touched}
										banks={banks}
										values={values}
										setValues={setValues}
										handleHasCode={handleHasCode}
										handleHasExtraField={handleHasExtraField}
									/>
								);
							})}
						</>
					</FieldArray>
					<div className="text-center py-4">
						<button
							className="btn text-red"
							onClick={addHandler(values, setValues)}
						>
							Add another account &nbsp;
							<i className="fas fa-plus" />
						</button>
					</div>
					<button
						type="submit"
						className="btn btn-red float-end min-height px-5"
						disabled={loading}
					>
						<span className="px-5">Submit {loading && <Spin />}</span>
					</button>
				</form>
			)}
		</Formik>
	);
};

export default AddBeneficiaryForm;
