import React, { useEffect } from "react";



export default function CanadaRegulator() {
  return (
    <span className="badge bg-red rounded-pill">
    Coming soon...
  </span>
  )
}
