export const FETCHING_PENDING = "FETCHING_PENDING";
export const SET_PENDING = "SET_PENDING";
export const FETCHING_COMPLETED = "FETCHING_COMPLETED";
export const SET_COMPLETED = "SET_COMPLETED";
export const FETCHING_FAILED = "FETCHING_FAILED";
export const FETCHING_FAILED_PAYOUTS = "FETCHING_FAILED_PAYOUTS";
export const SET_FAILED = "SET_FAILED";
export const SET_FAILED_PAYOUTS = "SET_FAILED_PAYOUTS";
export const FETCHING_INPROGRESS = "FETCHING_INPROGRESS";
export const SET_INPROGRESS = "SET_INPROGRESS";
export const FETCHING_INCOMPLETE = "FETCHING_INCOMPLETE";
export const SET_INCOMPLETE = "SET_INCOMPLETE";
export const FETCHING_MONITORING = "FETCHING_MONITORING";
export const SET_MONITORING = "SET_MONITORING";
export const FETCHING_SUSPENDEND = "FETCHING_SUSPENDEND";
export const SET_SUSPENDEND = "SET_SUSPENDEND";
export const FETCHING_REFUNDED = "FETCHING_REFUNDED";
export const SET_REFUNDED = "SET_REFUNDED";
export const FETCHING_TRANSACTION_COUNTS = "FETCHING_TRANSACTION_COUNTS";
export const SET_TRANSACTION_COUNTS = "SET_TRANSACTION_COUNTS";
export const FETCHING_KPIS = "FETCHING_KPIS";
export const SET_KPIS = "SET_KPIS";
export const SET_SUSPENDING = "SET_SUSPENDING";
export const SET_REFUNDING = "SET_REFUNDING";
export const SET_TRASHING = "SET_TRASHING";
export const SET_COMMENTING = "SET_COMMENTING";
export const SET_CONFIRMING = "SET_CONFIRMING";
export const SET_VERIFYING = "SET_VERIFYING";
export const SET_RE_VERIFYING = "SET_RE_VERIFYING";
export const SET_IS_SUSPENDED = "SET_IS_SUSPENDED";
export const SET_IS_VERIFIED = "SET_IS_VERIFIED";
export const SET_IS_RE_VERIFIED = "SET_IS_RE_VERIFIED";
export const SET_IS_TRASHED = "SET_IS_TRASHED";
export const SET_IS_COMMENTED = "SET_IS_COMMENTED";
export const SET_IS_REFUNDED = "SET_IS_REFUNDED";
export const SET_IS_CONFIRMED = "SET_IS_CONFIRMED";
export const SET_FORCING = "SET_FORCING";
export const SET_FORCED = "SET_FORCED";
export const SET_RETRYING = "SET_RETRYING";
export const SET_RETRIED = "SET_RETRIED";
export const SET_SENDING_TO_PENDING = "SET_SENDING_TO_PENDING";
export const SET_SENT_TO_PENDING = "SET_SENT_TO_PENDING";
export const GETTING_TRNX_PAYOUTS = "GETTING_TRNX_PAYOUTS";
export const SET_TRNX_PAYOUTS = "SET_TRNX_PAYOUTS";
export const SEARCHING_PENDING = "SEARCHING_PENDING";
export const SET_FORCE_FAILING = "SET_FORCE_FAILING";
export const SET_FORCE_FAILED = "SET_FORCE_FAILED";
export const SET_TRANSACTION_COUNTRIES = "SET_TRANSACTION_COUNTRIES";
export const SET_TRANSACTION_COUNTRY = "SET_TRANSACTION_COUNTRY";
export const EXPORTING_DATA = "EXPORTING_DATA";
export const SET_TRANSFER_VALUES = "SET_TRANSFER_VALUES";
export const SET_TRANSFER_LOADING = "SET_TRANSFER_LOADING";
export const SET_TRANSFER_SUCCESS_STATUS = "SET_TRANSFER_SUCCESS_STATUS";
export const SET_SHOW_TRFCONFIRM = "SET_SHOW_TRFCONFIRM";
export const SET_SHOW_TRFSTATUS = "SET_SHOW_TRFSTATUS";
export const FETCHING_WALLET_FUNDINGS = "FETCHING_WALLET_FUNDINGS";
export const SET_WALLET_FUNDINGS = "SET_WALLET_FUNDINGS";
export const FETCHING_WALLET_SWAPPINGS = "FETCHING_WALLET_SWAPPINGS";
export const SET_WALLET_SWAPPINGS = "SET_WALLET_SWAPPINGS";
export const LOADING_TRANSACTION_REPORTS = "LOADING_TRANSACTION_REPORTS"
export const SET_TRANSACTION_REPORTS = "SET_TRANSACTION_REPORTS"
export const DOWNLOADING_TRANSACTION_REPORTS = "DOWNLOADING_TRANSACTION_REPORTS"
