import * as actionTypes from "../../actionTypes/admin/limitActionTypes";

export const limitReducer = (
  state = {
    fetchingLimit: true,
    limits: {},
    savedLimit: false,
    savingLimit: false,
    deleting: false,
    deleted: false,
    updatingLimit: false,
    updatedLimit: false,
    showCreateModal: false,
    searchingLimit: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHING_LIMIT:
      return { ...state, fetchingLimit: action.payload };
    case actionTypes.SEARCHING_LIMIT:
      return { ...state, searchningLimit: !state.searchningLimit };
    case actionTypes.SHOW_CREATE_MODAL:
      return { ...state, showCreateModal: !state.showCreateModal };
    case actionTypes.UPDATING_LIMIT:
      return { ...state, updatingLimit: action.payload };
    case actionTypes.UPDATED_LIMIT:
      return { ...state, updatedLimit: action.payload };
    case actionTypes.SAVING_LIMIT:
      return { ...state, savingLimit: action.payload };
    case actionTypes.SAVED_LIMIT:
      return { ...state, savedLimit: action.payload };
    case actionTypes.DELETING_LIMIT:
      return { ...state, deleting: action.payload };
    case actionTypes.DELETED_LIMIT:
      return { ...state, deleted: action.payload };
    case actionTypes.SET_LIMIT:
      return { ...state, limits: action.payload };
    default:
      return state;
  }
};
