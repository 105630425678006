import React, { useEffect, useState } from "react";
import {
  list,
  _delete,
  update,
  toggleStatus,
  search,
} from "../../../store/actions/admin/payout";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Modal, Select } from "antd";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import Pagination from "../../../components/pagination/pagination";

import { Formik } from "formik";
import { useSearchParams } from "react-router-dom";

export default function List() {
  const { msg } = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const {
    saved,
    fetching,
    payouts,
    deleting,
    deleted,
    toggling,
    toggled,
    updating,
    updated,
    searching,
  } = useSelector((state) => state.payouts);
  const { loadingPayinClasses, payoutClasses } = useSelector(
    (state) => state.general
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(null);

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  useEffect(() => {
    if (searchParam) {
      dispatch(
        search({
          param: searchParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(list(pageParam));
    }
    setCurrentPage(pageParam);
  }, [pageParam, searchParam]);

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [bnToDelete, setBnToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteBnHandler = () => {
    dispatch(_delete(bnToDelete));
  };

  const toggleDeleteModal = (id) => {
    setBnToDelete(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const [initialValues, setInintialValues] = useState({
    name: "",
    bootstrap_class: "",
    inner_name: "",
    display_name: "",
    slug: "",
    eta: "",
    info: "",
    deactivated_at: "",
    country: "",
    is_default: false,
    is_plugin: false,
    fee: "",
    payin_uuid: "",
  });

  useEffect(() => {
    dispatch(list());
  }, []);

  useEffect(() => {
    if (saved || deleted || updated) dispatch(list());
    if (deleted) toggleDeleteModal();
    if (updated) {
      toggleModal();
    }
  }, [saved, deleted, updated]);

  return (
    <>
      <div className="table-responsive">
        {fetching || searching ? <Spin /> : ""}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Payout Name</th>
              <th className="text-muted text-uppercase">Display Name</th>
              {/* <th class="text-muted text-uppercase">Slug</th> */}
              <th className="text-muted text-uppercase">
                Eta <br /> (Estimate Time Arrival)
              </th>
              {/* <th class="text-muted text-uppercase">Default</th> */}
              <th className="text-muted text-uppercase">Fee</th>
              {/* <th class="text-muted text-uppercase">Is Plugin</th> */}
              <th className="text-muted text-uppercase">Info</th>
              <th className="text-muted text-uppercase">Status</th>
              <th className="text-muted text-uppercase">Country</th>
              <th className="text-muted text-uppercase">created at</th>
              <th className="text-muted text-uppercase">action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {payouts.data?.map((el, index) => {
              let count = index + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${index}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom">
                  <td>{count}</td>
                  <td>
                    {el.name}
                    <br />{" "}
                    <small className="text-muted">({el.inner_name})</small>
                  </td>
                  <td>{el.display_name}</td>
                  <td>{el.eta}</td>
                  <td>₦{el.fee}</td>
                  <td className="text-wrap">{el.info}</td>
                  <td>
                    {el.deactivated_at ? (
                      <small className="px-4 py-2 status-width rounded alert-danger fw-bold">
                        Inactive
                      </small>
                    ) : (
                      <small className="px-4 py-2 status-width rounded alert-success fw-bold">
                        Active
                      </small>
                    )}
                  </td>
                  <td>
                    <span
                      class={`flag-icon flag-icon-${el.countries[0].iso2} flag-icon-squared`}
                    ></span>
                    &nbsp; {el.countries[0].name}
                  </td>
                  <td>{el.created_at}</td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#actions"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#editPayout"
                            onClick={() => {
                              setInintialValues({
                                name: el.name,
                                bootstrap_class: el.bootstrap_class,
                                inner_name: el.inner_name,
                                display_name: el.display_name,
                                slug: el.slug,
                                eta: el.eta,
                                info: el.info,
                                deactivated_at: el.deactivated_at,
                                country: el.countries[0].id,
                                is_default: el.is_default,
                                is_plugin: el.is_plugin,
                                fee: el.fee,
                                payout_uuid: el.uuid,
                                manual: el.manual,
                                auto_settle: el.auto_settle,
                              });
                              toggleModal();
                            }}
                          >
                            View &amp; Edit
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deleteModal"
                            onClick={() => {
                              toggleDeleteModal(el.uuid);
                            }}
                          >
                            Trash
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* Delete Modal */}
        <Modal
          visible={showDeleteModal}
          onCancel={() => {
            toggleDeleteModal();
          }}
          bodyStyle={{
            padding: "0px !important",
            borderRadius: "20px !important",
            margin: "0px",
          }}
          closable={false}
          footer={false}
          // width="25%"
        >
          <div className="modal-body text-center">
            <img
              src="../assets/images/components/rafiki.png"
              alt="delete icon"
              className="img-fluid"
            />
            <p>Are you sure?</p>
            <button
              className="btn btn-outline-red mx-2 px-4"
              type="button"
              onClick={() => {
                toggleDeleteModal();
              }}
              disabled={deleting}
            >
              Cancel
            </button>
            <button
              className="btn btn-red mx-2 px-4"
              type="button"
              disabled={deleting}
              onClick={() => {
                deleteBnHandler();
              }}
            >
              Delete {deleting && <Spin />}
            </button>
          </div>
        </Modal>
        {/* edit modal */}
        <Modal
          visible={showModal}
          footer={false}
          onCancel={() => {
            dispatch(toggleModal());
          }}
        >
          <div>
            <h5 className="fw-bold">Update Payout</h5>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                name: Yup.string("").required("Payin name is required"),
                bootstrap_class: Yup.string("").required("Class is required"),
                inner_name: Yup.string("").required("Inner name is required"),
                display_name: Yup.string("").required(
                  "Display name is required"
                ),
                slug: Yup.string("").required("Slug is required"),
                eta: Yup.string("").required("Eta is required"),
                fee: Yup.string("").required("Fee is required"),
                info: Yup.string("").required("Info is required"),
                deactivated_at: Yup.string(""),
                is_default: Yup.string(""),
                is_plugin: Yup.string(""),
                country: Yup.string("").required("Country is required"),
              })}
              onSubmit={(values) => {
                console.log(initialValues);
                dispatch(update({ ...values }));
              }}
              enableReinitialize
            >
              {(props) => (
                <form id="form" onSubmit={props.handleSubmit}>
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Payin name</label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="name"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.name}
                        />
                        {props.touched.name && props.errors.name && (
                          <FormikError msg={props.errors.name} />
                        )}
                        <FormikError msg={msg?.name ?? ""} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Bootstrap class</label>
                        <select
                          name="bootstrap_class"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          className="form-select min-height"
                          defaultValue={props.values.bootstrap_class}
                        >
                          <option value="">--select--</option>
                          {payoutClasses.map((el, i) => {
                            return (
                              <option key={i} value={el}>
                                {el}
                              </option>
                            );
                          })}
                        </select>
                        {loadingPayinClasses && <i>...loading</i>}
                        {props.touched.bootstrap_class &&
                          props.errors.bootstrap_class && (
                            <FormikError msg={props.errors.bootstrap_class} />
                          )}
                        <FormikError msg={msg?.bootstrap_class ?? ""} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Inner name</label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="inner_name"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.inner_name}
                        />
                      </div>
                      {props.touched.inner_name && props.errors.inner_name && (
                        <FormikError msg={props.errors.inner_name} />
                      )}
                      <FormikError msg={msg?.inner_name ?? ""} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Display name</label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="display_name"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.display_name}
                        />
                      </div>
                      {props.touched.display_name &&
                        props.errors.display_name && (
                          <FormikError msg={props.errors.display_name} />
                        )}
                      <FormikError msg={msg?.display_name ?? ""} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor>slug</label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="slug"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.slug}
                        />
                      </div>
                      {props.touched.slug && props.errors.slug && (
                        <FormikError msg={props.errors.slug} />
                      )}
                      <FormikError msg={msg?.slug ?? ""} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Estimate Time Arrival</label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="eta"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.eta}
                        />
                      </div>
                      {props.touched.eta && props.errors.eta && (
                        <FormikError msg={props.errors.eta} />
                      )}
                      <FormikError msg={msg?.eta ?? ""} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Fee</label>
                        <input
                          type="number"
                          className="form-control min-height"
                          name="fee"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.fee}
                        />
                      </div>
                      {props.touched.fee && props.errors.fee && (
                        <FormikError msg={props.errors.fee} />
                      )}
                      <FormikError msg={msg?.fee ?? ""} />
                    </div>
                    <div className="col-md-6" />
                    <div className="col-md-6">
                      <div className="form-check form-switch mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="_default"
                          name="is_default"
                          value={props.values.is_default}
                          checked={props.values.is_default}
                          onChange={(e) => {
                            if (e.target.checked) {
                              props.setValues({
                                ...props.values,
                                is_default: true,
                              });
                            } else {
                              props.setValues({
                                ...props.values,
                                is_default: false,
                              });
                            }
                          }}
                          onBlur={props.handleBlur}
                        />

                        <label className="form-check-label" htmlFor="_default">
                          Default
                        </label>
                      </div>
                      {props.touched.is_default && props.errors.is_default && (
                        <FormikError msg={props.errors.is_default} />
                      )}
                      <FormikError msg={msg?.is_default ?? ""} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-check form-switch mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="_isplugin"
                          name="is_plugin"
                          onBlur={props.handleBlur}
                          value={props.values.is_plugin}
                          checked={props.values.is_plugin}
                          onChange={(e) => {
                            if (e.target.checked) {
                              props.setValues({
                                ...props.values,
                                is_plugin: true,
                              });
                            } else {
                              props.setValues({
                                ...props.values,
                                is_plugin: false,
                              });
                            }
                          }}
                        />

                        <label className="form-check-label" htmlFor="_isplugin">
                          Is Plugin
                        </label>
                      </div>
                      {props.touched.is_plugin && props.errors.is_plugin && (
                        <FormikError msg={props.errors.is_plugin} />
                      )}
                      <FormikError msg={msg?.is_plugin ?? ""} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-check form-switch mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="_auto_settle"
                          name="auto_settle"
                          onBlur={props.handleBlur}
                          value={props.values.auto_settle}
                          onChange={(e) => {
                            if (e.target.checked) {
                              props.setValues({
                                ...props.values,
                                auto_settle: true,
                              });
                            } else {
                              props.setValues({
                                ...props.values,
                                auto_settle: false,
                              });
                            }
                          }}
                        />

                        <label
                          className="form-check-label"
                          htmlFor="_auto_settle"
                        >
                          Auto settle
                        </label>
                      </div>
                      {props.touched.auto_settle &&
                        props.errors.auto_settle && (
                          <FormikError msg={props.errors.auto_settle} />
                        )}
                      <FormikError msg={msg?.auto_settle ?? ""} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-check form-switch mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="_manual"
                          name="manual"
                          onBlur={props.handleBlur}
                          value={props.values.manual}
                          onChange={(e) => {
                            if (e.target.checked) {
                              props.setValues({
                                ...props.values,
                                manual: true,
                              });
                            } else {
                              props.setValues({
                                ...props.values,
                                manual: false,
                              });
                            }
                          }}
                        />

                        <label className="form-check-label" htmlFor="_manual">
                          Manual
                        </label>
                      </div>
                      {props.touched.manual && props.errors.manual && (
                        <FormikError msg={props.errors.manual} />
                      )}
                      <FormikError msg={msg?.manual ?? ""} />
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label htmlFor>Info</label>
                        <textarea
                          rows={5}
                          className="form-control"
                          name="info"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.info}
                        />
                      </div>
                      {props.touched.info && props.errors.info && (
                        <FormikError msg={props.errors.info} />
                      )}
                      <FormikError msg={msg?.info ?? ""} />
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label htmlFor>Country</label>
                        <Select
                          showSearch
                          filterOption={(input, option) => {
                            return option.name
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          placeholder="Select country"
                          className="form-control min-height"
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              country: value,
                            });
                          }}
                          defaultValue={props.values.country}
                        >
                          {JSON.parse(
                            localStorage.getItem("cosmo_countries")
                          )?.map((el) => {
                            return (
                              <Select.Option
                                key={el.id}
                                id={el.id}
                                value={el.id}
                                countryCode={el.phonecode}
                                name={el.name}
                              >
                                <span
                                  className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                                ></span>
                                {"\u00A0"}
                                {el.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                        {props.touched.country && props.errors.country && (
                          <FormikError msg={props.errors.country} />
                        )}
                        <FormikError msg={msg?.country ?? ""} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-check form-switch mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="_suspenduser"
                          name="deactivated_at"
                          onBlur={props.handleBlur}
                          value={props.values.deactivated_at}
                          checked={props.values.deactivated_at}
                          onChange={(e) => {
                            if (e.target.checked) {
                              props.setValues({
                                ...props.values,
                                deactivated_at: true,
                              });
                            } else {
                              props.setValues({
                                ...props.values,
                                deactivated_at: false,
                              });
                            }
                          }}
                        />

                        <label
                          className="form-check-label"
                          htmlFor="_suspenduser"
                        >
                          Activate
                        </label>
                      </div>
                      {props.touched.deactivated_at &&
                        props.errors.deactivated_at && (
                          <FormikError msg={props.errors.deactivated_at} />
                        )}
                      <FormikError msg={msg?.deactivated_at ?? ""} />
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      className="btn btn-outline-red ms-2 px-4"
                      type="button"
                      onClick={() => {
                        toggleModal();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-red ms-2 px-4"
                      type="submit"
                      disabled={updating}
                    >
                      Submit {updating && <Spin />}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
      {payouts?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (payouts !== null && payouts?.meta?.per_page) {
              if (searchParam) {
                setSearchParams({
                  param: searchParam,
                  page,
                });
                dispatch(
                  search({
                    param: searchParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });
                dispatch(list(page));
              }
            }
          }}
          data={payouts}
        />
      )}
    </>
  );
}
