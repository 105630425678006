import { http } from "../../../config/axios";
import * as actionTypes from "../../actionTypes/admin/businessActionTypes";
import * as error from "../errorActions";
import * as notifications from "../../../components/notifications/notifications";
export const fetchBusinessCounts = async (dispatch) => {
  try {
    const res = await http.get("admin/senders/business/business_profile_kpis");

    dispatch({
      type: actionTypes.SET_BUSINESS_COUNTS,
      payload: {
        verified: res?.data?.data?.verified_business_count,
        unverified: res?.data?.data?.unverified_business_count,
      },
    });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  }
};

export const fetchVerifiedBusinesses =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.FETCHING_BUSINESSES,
        payload: true,
      });
      const res = await http.get(
        `/admin/senders/business/fetch_verified_business/paginate/10?page=${page}`
      );
      dispatch({
        type: actionTypes.SET_VERIFIED_BUSINESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({
        type: actionTypes.FETCHING_BUSINESSES,
        payload: false,
      });
    }
  };

export const fetchUnverifiedBusinesses =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.FETCHING_BUSINESSES,
        payload: true,
      });
      const res = await http.get(
        `/admin/senders/business/fetch_unverified_business/paginate/10?page=${page}`
      );
      dispatch({
        type: actionTypes.SET_UNVERIFIED_BUSINESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({
        type: actionTypes.FETCHING_BUSINESSES,
        payload: false,
      });
    }
  };
export const fetchUserBusiness = (userId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_USER_BUSINESSES });
    const res = await http.get(
      `admin/senders/business/fetch_user_business/${userId}`
    );
    dispatch({
      type: actionTypes.SET_BUSINESS_DATA,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({ type: actionTypes.FETCHING_USER_BUSINESSES });
  }
};

export const verifyBusiness = (uuid) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.VERIFYING_BUSINESSES });
    const res = await http.post(
      `/admin/senders/business/verify_business/${uuid}`
    );
    dispatch(fetchVerifiedBusinesses);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({ type: actionTypes.VERIFYING_BUSINESSES });
  }
};

export const fetchBusinessDocuments =
  (page = 1, userID) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.FETCHING_BUSINESS_DOCUMENTS,
        payload: true,
      });
      const res = await http.get(
        `/admin/senders/business/file-upload/paginate/10/${userID}?page=${page}`
      );
      dispatch({
        type: actionTypes.SET_BUSINESS_DOCUMENTS,
        payload: res.data.data.data,
      });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({
        type: actionTypes.FETCHING_BUSINESS_DOCUMENTS,
        payload: false,
      });
    }
  };

export const uploadDocs = (vals, successAction) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPLOADING_BUSINESS_DOCUMENTS, payload: true });
    const fd = new FormData();
    fd.append("title", vals.title);
    fd.append("description", vals.decription);
    for (let i = 0; i < vals.file.length; i++) {
      fd.append("files[]", vals.file[i]);
    }

    const res = await http.post(
      `admin/senders/business/file-upload/upload/${vals.user_id}`,
      fd
    );
    error.clearError(dispatch);
    successAction();
    notifications.success({ title: "Success", msg: res.data.message });
    dispatch(fetchBusinessDocuments(1, vals.user_id));
  } catch (err) {
    console.log(err);
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({
      type: actionTypes.UPLOADING_BUSINESS_DOCUMENTS,
      payload: false,
    });
  }
};
