import * as actionTypes from "../../actionTypes/admin/transactionActionTypes";

export const adminTrnxReducer = (
  state = {
    transactionsCounts: {},
    fetchingPending: false,
    fetchingFailedPayouts: false,
    pending: {},
    fetchingSuspended: false,
    suspended: {},
    fetchingCompleted: false,
    completed: {},
    failed: {},
    failedPayouts: {},
    fetchingFailed: false,
    incomplete: {},
    fetchingIncomplete: false,
    refunded: {},
    fetchingRefunded: false,
    inprogress: {},
    fetchingInprogress: false,
    kpis: {},
    fetchingKpis: false,
    suspending: false,
    verifying: false,
    trashing: false,
    commenting: false,
    refunding: false,
    isSuspended: false,
    isVerified: false,
    isTrashed: false,
    isCommented: false,
    isRefunded: false,
    searchingPending: false,
    isReverified: false,
    isConfirmed: false,
    reverifying: false,
    confirming: false,
    trnxPayouts: [],
    loadinTrnxPayouts: false,
    forcing: false,
    forced: false,
    retrying: false,
    retried: false,
    sendingToPending: false,
    sentToPending: false,
    forceFailing: false,
    isForceFailed: false,
    transactionsCountries: [],
    transactionsCountry: "",
    exportingData: false,
    adminTransferValues: {},
    transferLoading: false,
    transferSuccess: false,
    showTrfConfirmPage: false,
    showTrfStatusPage: false,
    wallet_fundings: {},
    fetching_wallet_fundings: false,
    wallet_swappings: {},
    fetching_wallet_swappings: false,
    loading_trx_reports: false,
    downloading_trx_reports: false,
    trxReports: [],
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_FORCE_FAILING:
      return { ...state, forceFailing: action.payload };
    case actionTypes.SET_FORCE_FAILED:
      return { ...state, isForceFailed: action.payload };
    case actionTypes.SET_FORCED:
      return { ...state, forced: action.payload };
    case actionTypes.SET_FORCING:
      return { ...state, forcing: action.payload };
    case actionTypes.SET_SENDING_TO_PENDING:
      return { ...state, sendingToPending: action.payload };
    case actionTypes.SET_SENT_TO_PENDING:
      return { ...state, sentToPending: action.payload };
    case actionTypes.SET_RETRYING:
      return { ...state, retrying: action.payload };
    case actionTypes.SET_RETRIED:
      return { ...state, retried: action.payload };
    case actionTypes.GETTING_TRNX_PAYOUTS:
      return { ...state, loadinTrnxPayouts: action.payload };
    case actionTypes.SET_TRNX_PAYOUTS:
      return { ...state, trnxPayouts: action.payload };
    case actionTypes.SET_TRANSACTION_COUNTS:
      return { ...state, transactionsCounts: action.payload };
    case actionTypes.SEARCHING_PENDING:
      return { ...state, searchingPending: action.payload };
    case actionTypes.SET_KPIS:
      return { ...state, kpis: action.payload };
    case actionTypes.SET_REFUNDING:
      return { ...state, refunding: action.payload };
    case actionTypes.SET_IS_CONFIRMED:
      return { ...state, isConfirmed: action.payload };
    case actionTypes.SET_IS_SUSPENDED:
      return { ...state, isSuspended: action.payload };
    case actionTypes.SET_IS_VERIFIED:
      return { ...state, isVerified: action.payload };
    case actionTypes.SET_IS_TRASHED:
      return { ...state, isTrashed: action.payload };
    case actionTypes.SET_IS_RE_VERIFIED:
      return { ...state, isReverified: action.payload };
    case actionTypes.SET_RE_VERIFYING:
      return { ...state, reverifying: action.payload };
    case actionTypes.SET_CONFIRMING:
      return { ...state, confirming: action.payload };
    case actionTypes.SET_IS_COMMENTED:
      return { ...state, isCommented: action.payload };
    case actionTypes.SET_IS_REFUNDED:
      return { ...state, isRefunded: action.payload };
    case actionTypes.SET_VERIFYING:
      return { ...state, verifying: action.payload };
    case actionTypes.SET_TRASHING:
      return { ...state, trashing: action.payload };
    case actionTypes.SET_TRANSACTION_COUNTRIES:
      return { ...state, transactionsCountries: action.payload };
    case actionTypes.SET_TRANSACTION_COUNTRY:
      return { ...state, transactionsCountry: action.payload };
    case actionTypes.SET_COMMENTING:
      return { ...state, commenting: action.payload };
    case actionTypes.SET_SUSPENDING:
      return { ...state, suspending: action.payload };
    case actionTypes.FETCHING_KPIS:
      return { ...state, fetchingKpis: action.payload };
    case actionTypes.SET_INPROGRESS:
      return { ...state, inprogress: action.payload };
    case actionTypes.FETCHING_INPROGRESS:
      return { ...state, fetchingInprogress: action.payload };
    case actionTypes.FETCHING_REFUNDED:
      return { ...state, fetchingRefunded: action.payload };
    case actionTypes.SET_REFUNDED:
      return { ...state, refunded: action.payload };
    case actionTypes.SET_INCOMPLETE:
      return { ...state, incomplete: action.payload };
    case actionTypes.FETCHING_INCOMPLETE:
      return { ...state, fetchingIncomplete: action.payload };
    case actionTypes.FETCHING_FAILED:
      return { ...state, fetchingFailed: action.payload };
    case actionTypes.FETCHING_FAILED_PAYOUTS:
      return { ...state, fetchingFailedPayouts: action.payload };
    case actionTypes.SET_FAILED:
      return { ...state, failed: action.payload };
    case actionTypes.SET_FAILED_PAYOUTS:
      return { ...state, failedPayouts: action.payload };
    case actionTypes.FETCHING_COMPLETED:
      return { ...state, fetchingCompleted: action.payload };
    case actionTypes.SET_COMPLETED:
      return { ...state, completed: action.payload };
    case actionTypes.FETCHING_PENDING:
      return { ...state, fetchingPending: action.payload };
    case actionTypes.SET_PENDING:
      return { ...state, pending: action.payload };
    case actionTypes.FETCHING_SUSPENDEND:
      return { ...state, fetchingSuspended: action.payload };
    case actionTypes.SET_SUSPENDEND:
      return { ...state, suspended: action.payload };
    case actionTypes.EXPORTING_DATA:
      return { ...state, exportingData: action.payload };
    case actionTypes.SET_TRANSFER_VALUES:
      return { ...state, adminTransferValues: action.payload };
    case actionTypes.SET_TRANSFER_LOADING:
      return { ...state, transferLoading: action.payload };
    case actionTypes.SET_TRANSFER_SUCCESS_STATUS:
      return { ...state, transferSuccess: action.payload };
    case actionTypes.SET_SHOW_TRFCONFIRM:
      return { ...state, showTrfConfirmPage: action.payload };
    case actionTypes.SET_SHOW_TRFSTATUS:
      return { ...state, showTrfStatusPage: action.payload };
    case actionTypes.SET_WALLET_FUNDINGS:
      return { ...state, wallet_fundings: action.payload };
    case actionTypes.FETCHING_WALLET_FUNDINGS:
      return { ...state, fetching_wallet_fundings: action.payload };
    case actionTypes.SET_WALLET_SWAPPINGS:
      return { ...state, wallet_swappings: action.payload };
    case actionTypes.FETCHING_WALLET_SWAPPINGS:
      return { ...state, fetching_wallet_swappings: action.payload };
    case actionTypes.LOADING_TRANSACTION_REPORTS:
      return { ...state, loading_trx_reports: action.payload };
    case actionTypes.DOWNLOADING_TRANSACTION_REPORTS:
      return { ...state, downloading_trx_reports: action.payload };
    case actionTypes.SET_TRANSACTION_REPORTS:
      return { ...state, trxReports: action.payload };
    default:
      return state;
  }
};
