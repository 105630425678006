import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  _failedPayouts,
  search,
  retryPayout,
  forceComplete,
  sendToPending,
  getTrnxPayouts,
} from "../../../store/actions/admin/transactions";
import Pagination from "../../../components/pagination/pagination";
import { Spin, Modal } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import AdminSearchBar from "../../../components/partials/adminSearchBar";
import TimeAgo from "react-timeago";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import ExportButton from "../../../components/partials/exportButon";

export default function FailedPayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    failedPayouts,
    fetchingFailedPayouts,
    searchingPending,
    retrying,
    forcing,
    sendingToPending,
    forced,
    retried,
    sentToPending,
    loadinTrnxPayouts,
    forceCompleteing,
    trnxPayouts,
  } = useSelector((state) => state.adminTransactions);

  useEffect(() => {
    trnxPayouts.map((el) => {
      if (Number(el.pivot.is_default) === 1) {
        setSelectedTrnxPayout(el.uuid);
      }
    });
  }, [trnxPayouts]);

  const [trnx, setTrnx] = useState({ show: false, trnx: {} });
  const [retry, setRetry] = useState({ show: false, data: "" });
  const [force, setForce] = useState({ show: false, data: "" });
  const [pend, setPend] = useState({ show: false, data: "" });
  const [verify, setVerify] = useState({ show: false, data: "" });
  const [selectedTrnxPayout, setSelectedTrnxPayout] = useState(null);
  const [checkVerify, setCheckVerify] = useState(false);
  const [payoutErr, setPayoutErr] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (searchParam) {
      dispatch(
        search({
          param: searchParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(_failedPayouts(pageParam ?? 1));
    }
    setCurrentPage(pageParam);
  }, [searchParam, pageParam]);
  useEffect(() => {
    if (forced) {
      setForce({ show: false, data: "" });
      dispatch(_failedPayouts(currentPage));
    }
    if (retried) {
      setRetry({ show: false, data: "" });
      dispatch(_failedPayouts(currentPage));
    }
    if (sentToPending) {
      setPend({ show: false, data: "" });
      dispatch(_failedPayouts(currentPage));
    }
  }, [dispatch, forced, retried, sentToPending, currentPage]);

  localStorage.setItem("searchFor", "failed_payout");

  return (
    <div className="container-fluid">
      <h5 className="fw-bold">Failed Payouts</h5>
      <hr />
      <div className="row mb-4">
        <div className="col-sm-6 mb-3 d-sm-flex d-flex flex-sm-row flex-column ">
          <AdminSearchBar callback={search} />
          <div className="px-2 export-container">
            <ExportButton status="failed_payout" title="failed payouts" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        {fetchingFailedPayouts || searchingPending ? <Spin /> : ""}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Reference</th>
              <th className="text-muted text-uppercase">Sender | Rate </th>
              <th className="text-muted text-uppercase">Payin</th>
              <th className="text-muted text-uppercase">Recipient </th>
              <th className="text-muted text-uppercase">Sent at</th>
              <th className="text-muted text-uppercase">Risk Score</th>
              <th className="text-muted text-uppercase">Status</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {failedPayouts.data?.map((el, i) => {
              const verified = el.verify_bank_transfer;
              let status = "warning";
              let time = el.created_at;
              if (verified) {
                status = "success";
                time = el.verify_bank_transfer;
              }
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }

              let black_listed = "";
              if (el.user.profile.black_listed) {
                black_listed = "danger";
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  {/* <i className="fa-sharp fa-solid fa-circle text-danger" /> */}
                  <td>
                    <>
                      {el.kaasi_metas && <span className="text-danger">k</span>}{" "}
                      {el?.reference}
                    </>
                    <p>{el.payout}</p>
                  </td>
                  <td>
                    <a
                      onClick={() => {
                        // localStorage.setItem("senders_userUuid", el.user.uuid);
                        localStorage.setItem(
                          "senders_profile",
                          JSON.stringify(el.user.profile)
                        );
                        navigate(`/senders/details`, {
                          state: { uuid: el.user.uuid },
                        });
                      }}
                    >
                      <span className={`${black_listed ? "text-danger" : ""}`}>
                        {el?.sender_fullname}
                      </span>
                    </a>{" "}
                    <br />
                    <small className="text-muted text-uppercase">
                      {el?.from_country?.currency}
                    </small>
                    {"\u00A0"}
                    <span
                      className={`flag-icon flag-icon-${el?.from_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    {"\u00A0"}
                    {el.send_amount} | {el.rate}
                  </td>
                  <td>{el.payin}</td>
                  <td>
                    {`${el.meta.beneficiary.first_name} ${el.meta?.beneficiary?.last_name}`}{" "}
                    <br />
                    <small className="text-muted text-uppercase">
                      {el?.to_country?.currency}
                    </small>
                    {"\u00A0"}
                    <span
                      className={`flag-icon flag-icon-${el?.to_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    {"\u00A0"}
                    {el.received_amount}
                  </td>
                  <td>{moment(el.created_at).format("YYYY-MM-DD")}</td>
                  <td
                    className={`${el?.risk_type
                      .split("_")[0]
                      .toLowerCase()}`}
                  >
                    {el?.risk_score}
                    <br />
                    <small>{el?.risk_type.replace("_", " ")}</small>
                  </td>
                  <td>
                    <small className={`text-${status} text-capitalize`}>
                      {verified ? "Verified" : el.status}
                    </small>
                    <br />
                    <TimeAgo date={time} />
                  </td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#detailsModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setTrnx({ show: true, trnx: el });
                            }}
                          >
                            View
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deleteModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setRetry({ show: true, data: el.uuid });
                            }}
                          >
                            Retry
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deleteModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setForce({ show: true, data: el.uuid });
                              dispatch(getTrnxPayouts(el.uuid));
                            }}
                          >
                            Force complete
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deleteModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setPend({ show: true, data: el.uuid });
                            }}
                          >
                            Send to pending
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {failedPayouts?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (failedPayouts !== null && failedPayouts?.meta?.per_page) {
              if (searchParam) {
                setSearchParams({
                  param: searchParam,
                  page,
                });
                dispatch(
                  search({
                    param: searchParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });

                dispatch(_failedPayouts(page));
              }
            }
          }}
          data={failedPayouts}
        />
      )}

      {/* Retry modal */}
      <Modal
        visible={retry.show}
        footer={false}
        onCancel={() => {
          setRetry({ show: false, data: {} });
        }}
      >
        {" "}
        <div className="modal-body text-center">
          <p>Are you sure you want to retry this transaction ?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setRetry({ show: false, data: {} });
            }}
            disabled={retrying}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={retrying}
            onClick={() => {
              dispatch(retryPayout(retry.data));
            }}
          >
            Retry {retrying && <Spin />}
          </button>
        </div>
      </Modal>
      {/* end retry modal */}

      {/* Force complete modal */}
      <Modal
        visible={force.show}
        footer={false}
        onCancel={() => {
          setForce({ show: false, data: {} });
        }}
      >
        {" "}
        <div className="modal-body text-center">
          <p>Are you sure you want to froce complete this transaction ?</p>
          <div className="form-check">
            <label className="mb-3">
              <input
                type="checkbox"
                checked={checkVerify}
                className="form-check-input"
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckVerify(true);
                  } else {
                    setCheckVerify(false);
                  }
                }}
              />{" "}
              {!checkVerify
                ? "Check to change payout to manual"
                : "Uncheck to if you dont want to use manual payout"}
            </label>
            <p></p>
          </div>

          {/* {loadinTrnxPayouts && (
            <>
              "Fetching payouts" <Spin />
              <br />
            </>
          )} */}
          {/* <div className="justify-content-center">
            <b className="mb-2">Select payout for this transaction</b>
            {!loadinTrnxPayouts &&
              trnxPayouts?.map((el) => {
                return (
                  <div>
                    <div className="form-group form-check mb-3">
                      <label
                        className="form-check-label text-muted"
                        htmlFor={`payout1Radio${el.id}`}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          value={el.uuid}
                          name="payout_option"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedTrnxPayout(e.target.value);
                            }
                          }}
                          checked={selectedTrnxPayout === el.uuid}
                          id={`payout1Radio${el.id}`}
                        />{" "}
                        <span>
                          {el.display_name} {"\u00A0"}
                        </span>
                      </label>
                    </div>
                  </div>
                );
              })}
          </div> */}

          {/* {payoutErr && <p className="text-danger">Select a payout</p>} */}
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setForce({ show: false, data: {} });
              setCheckVerify(true);
            }}
            disabled={forcing}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={forcing}
            onClick={() => {
              if (!selectedTrnxPayout) {
                setPayoutErr(true);
                return;
              }
              dispatch(forceComplete(force.data, checkVerify));
            }}
          >
            Force complete {forcing && <Spin />}
          </button>
        </div>
      </Modal>
      {/* end force complete modal */}
      {/* Send to pending  modal */}
      <Modal
        visible={pend.show}
        footer={false}
        onCancel={() => {
          setPend({ show: false, data: {} });
        }}
      >
        {" "}
        <div className="modal-body text-center">
          <p>Are you sure you want to send to pending?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setPend({ show: false, data: {} });
            }}
            disabled={sendingToPending}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={sendingToPending}
            onClick={() => {
              dispatch(sendToPending(pend.data));
            }}
          >
            Send to pending {forcing && <Spin />}
          </button>
        </div>
      </Modal>
      {/* Send to pending  modal */}

      {/* view trnx modal */}
      <Modal
        visible={trnx.show}
        footer={false}
        onCancel={() => {
          setTrnx({ show: false, trnx: {} });
        }}
      >
        <h6 className="fw-bold">Transaction Details</h6>

        <div className="py-4">
          <p className="text-muted">Reference: #{trnx.trnx.reference}</p>
          <div className="table-responsive">
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Bank Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Account number</th>
                  <td>{trnx.trnx?.meta?.account.account_number}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank name</th>
                  <td>{trnx.trnx?.bank_name}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank city</th>
                  <td>{trnx.trnx?.bank_city}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank country</th>
                  <td>{trnx.trnx?.bank_country}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Recipient details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Name</th>
                  <td>{`${trnx.trnx?.meta?.beneficiary.first_name} ${trnx.trnx?.meta?.beneficiary.last_name}`}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Mobile number</th>
                  <td>{trnx.trnx?.meta?.beneficiary?.phone_no}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Email</th>
                  <td>{trnx.trnx?.meta?.beneficiary.email}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Sender details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>MemberID</th>
                  <td>{trnx.trnx?.sender_memberid}</td>
                </tr>
                <tr className="border-bottom">
                  <th>FullName</th>
                  <td>{trnx.trnx?.sender_fullname}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Mobile number</th>
                  <td>{trnx.trnx?.sender_mobile_no}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Email</th>
                  <td>{trnx.trnx?.sender_email}</td>
                </tr>
                <tr className="border-bottom">
                  <th>ID type</th>
                  <td>{trnx.trnx?.sender_idtype}</td>
                </tr>
                <tr className="border-bottom">
                  <th>ID details</th>
                  <td>{trnx.trnx?.sender_iddetails}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Scanned ID</th>
                  <td>...</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Sent
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Amount</th>
                  <td>
                    {" "}
                    {trnx.trnx?.send_amount} {trnx.trnx?.from_country?.currency}{" "}
                    ({" "}
                    <span
                      className={`flag-icon flag-icon-${trnx.trnx?.from_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>{" "}
                    - {trnx.trnx?.from_country?.name})
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Rate</th>
                  <td>
                    {" "}
                    {trnx.trnx?.rate} ({trnx.trnx?.from_country?.currency} -{" "}
                    {trnx.trnx?.from_country?.name})
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Reason</th>
                  <td>{trnx.trnx?.comment}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center text-capitalize"
                    colspan="2"
                  >
                    {trnx.trnx?.status}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Amount</th>
                  <td>
                    {" "}
                    {trnx.trnx?.received_amount}{" "}
                    {trnx.trnx?.to_country?.currency} (
                    <span
                      className={`flag-icon flag-icon-${trnx.trnx?.to_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>{" "}
                    - {trnx.trnx.to_country?.name}){" "}
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Reference</th>
                  <td>#{trnx.trnx?.reference}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Transfer type</th>
                  <td>{trnx.trnx?.payin}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Transfer message</th>
                  <td>Completed</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-end">
            <button
              className="btn btn-red"
              type="button"
              onClick={() => {
                setTrnx({ show: false, trnx: {} });
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
