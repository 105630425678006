import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDocuments,
  searchDocs,
} from "../../../store/actions/documentUploadActions";
import { Formik } from "formik";
import * as Yup from "yup";
import { DatePicker, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination/pagination";

export default function List() {
  const { documents, loading } = useSelector((state) => state.documentUpload);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchDocuments());
  }, []);


  return (
    <>
      <div className="row my-4">
        {/* top search, date picker */}
        <Formik
          validationSchema={Yup.object({
            title: Yup.string("").nullable("enter  title"),
            date: Yup.string("").nullable("select date"),
          })}
          initialValues={{
            title: "",
            date: "",
          }}
          onSubmit={(values) => {
            dispatch(searchDocs(values));
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              {" "}
              <div className="col-xl-9">
                <div className="row justify-content-between">
                  <div className="col-xl-6 col-lg-5 mb-3">
                    <div className="input-group rounded-0">
                      <span className="input-group-text bg-white">
                        <i className="fas fa-search" />
                      </span>
                      <input
                        type="text"
                        placeholder="Search by title"
                        name="title"
                        className="form-control border-start-0"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6 mb-3">
                    <div className="input-group rounded-0">
                      <DatePicker
                        onChange={(date, dateString) => {
                          props.setValues({
                            ...props.values,
                            date: dateString,
                          });
                        }}
                        onBlur={props.handleBlur}
                        className="form-control border-end-0"
                        name="date"
                      />
                      <span className="input-group-text end bg-white">
                        <i className="far fa-calendar-alt" />
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-6 mb-3">
                    <button className="btn bg-white border px-4" type="submit">
                      <img
                        src="../assets/images/icon/clarity_filter-2-line.png"
                        className="ps-4"
                        alt="icon"
                      />
                      {/* &nbsp; */}
                      <span className="ps-2 pe-4">Filter</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      {/* table */}
      <div className="table-responsive">
        {loading && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th />
              <th>Document Title</th>
              <th>Status</th>
              <th>Submitted At</th>
              <th />
            </tr>
          </thead>
          <tbody className="bg-white border-0 rounded">
            {documents?.data?.map((el, index) => {
              let status = "alert-warning";
              if (el.status === "approved") status = "alert-success";
              if (el.status === "rejected") status = "alert-danger";
              return (
                <tr className="border-bottom" key={index}>
                  <td className="py-4 text-center">
                    <img
                      src="../assets/images/components/Group 603.png"
                      alt
                      className="img-fluid"
                    />
                  </td>
                  <td className="py-4">{el.title}</td>
                  <td className="py-4">
                    <span
                      className={`px-4 py-2 status-width rounded ${status} fw-bold`}
                    >
                      {el.status}
                    </span>
                    {el.valid_till && (
                      <small class="text-success d-block">
                        Valid till{" "}
                        <span class="text-dark">{el.valid_till}</span>
                      </small>
                    )}
                  </td>
                  <td className="text-muted py-4">{el.created_at}</td>
                  <td>
                    <span
                      className="text-red"
                      style={{cursor:"pointer"}}
                      onClick={() => {
                        navigate("/document-preview", {
                          state: { files: el.files },
                        });
                      }}
                    >
                      View Application
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {documents?.links && (
          <Pagination
            onPageChange={(page) => {
              if (documents !== null && documents.per_page) {
                dispatch(fetchDocuments(page));
              }
            }}
            data={documents}
          />
        )}
      </div>
    </>
  );
}
