import * as actionType from "../actionTypes/errorActionTypes";
import * as notifications from "../../components/notifications/notifications";

export const setErrorMsg =
	(errResponse, $customMsg = null) =>
	(dispatch) => {
		let msg = "Something went wrong";
		if (errResponse?.response?.status === 422) {
			dispatch({
				type: actionType.SET_ERROR_MESSAGE,
				payload: errResponse.response.data.errors,
			});
			notifications.error({
				title: "Error",
				msg:
					errResponse.response.data.message ??
					"Please ensure that you fill the required fields on the form",
			});
			showError(dispatch);
		} else if (
			errResponse?.response?.status === 400 ||
			errResponse?.response?.status === 409
		) {
			notifications.error({
				title: "Error",
				msg: errResponse?.response?.data?.message,
			});
		} else if (errResponse?.response?.status >= 500) {
			notifications.error({
				title: "Error",
				msg: msg,
			});
		} else if (errResponse?.response?.status === 404) {
			notifications.error({
				title: "Error",
				msg: msg,
			});
		}

		if (errResponse?.code === "ERR_NETWORK") {
			notifications.error({
				title: "Error",
				msg: "Network error",
			});
		}

		// dispatch({ type: actionType.SET_ERROR_MESSAGE, payload: msg });
	};

export const showError = (dispatch) => {
	dispatch({ type: actionType.SHOW_ERROR });
};

export const hideError = () => {
	return { type: actionType.HIDE_ERROR };
};

export const clearError = (dispatch) => {
	dispatch({ type: actionType.CLEAR_ERRORS });
};
