import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { poliSuccess } from "../../../store/actions/transaction";
import { useSearchParams } from "react-router-dom";

export default function TransactionSuccess() {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	useEffect(() => {
		dispatch({ type: "INITIALIZED", payload: false });
		poliSuccess(searchParams.get("token"));
	}, []);
	const receiver = JSON.parse(localStorage.getItem("receiver")) ?? {};
	const trxData = JSON.parse(localStorage.getItem("trx_initData"));
	return (
		<div className="container-fluid">
			<div className="card mx-auto success-card shadow rounded border-0">
				<div className="card-body py-4 text-center">
					{/* S U C C E S S */}
					<div>
						<div className="success-card-icon success">
							<i className="fas fa-check" />
						</div>
						<h4 className="fw-bold">Successful</h4>
						<p>Transaction Succesful</p>
					</div>
					{/* conversion details */}
					<ul className="list-unstyled hero-card-details my-3">
						{/* Amount */}
						<li className="d-flex px-4 justify-content-between py-2">
							<small className="text-muted">Amount</small>
							<span>
								{localStorage.getItem("trx_sendVal")}{" "}
								{localStorage.getItem("trx_fromCountryCurency")}
							</span>
						</li>
						{/* Recipent */}
						<li className="d-flex px-4 justify-content-between py-2">
							<small className="text-muted">Transaction reference</small>
							<span>{trxData?.transaction_details?.reference}</span>
						</li>
						<li className="d-flex px-4 justify-content-between py-2">
							<small className="text-muted">Recipent</small>
							<span>{receiver?.fullname}</span>
						</li>
						{/* Recipent gets */}
						<li className="d-flex px-4 justify-content-between py-2">
							<small className="text-muted">Recipent gets</small>
							<span>
								{localStorage.getItem("trx_receiveVal")}{" "}
								{localStorage.getItem("trx_toCurency")}
							</span>
						</li>
						{/* Payment Method */}
						<li className="d-flex px-4 justify-content-between py-2">
							<small className="text-muted">Payment Method</small>
							<span>{localStorage.getItem("trx_selectedPayinName")}</span>
						</li>
						{/* Guaranteed Rate (24 hrs) */}
						<li className="d-flex px-4 justify-content-between py-2">
							<small className="text-muted">
								Guaranteed Rate <span className="text-dark">(24 hrs)</span>
							</small>
							<span>
								1 {localStorage.getItem("trx_fromCountryCurency")} ={" "}
								{localStorage.getItem("trx_rate")}
								{localStorage.getItem("trx_toCurrency")}
							</span>
						</li>
						{/* fee */}
						<li className="d-flex px-4 justify-content-between py-2">
							<small className="text-muted">Fee</small>
							<span>
								{localStorage.getItem("trx_fee")}{" "}
								{localStorage.getItem("trx_fromCountryCurency")}
							</span>
						</li>
						{/* amount we'll convert too */}
						{/* <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Amount We'll Convert</small>
              <span>200,000.00 AUD</span>
            </li> */}
						{/* time */}
						<li className="d-flex px-4 justify-content-between py-2">
							<small className="text-muted">Estimated Time</small>
							<span>{localStorage.getItem("trx_eta")} mins</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
