import React, { useEffect, useState } from "react";
import {
  fetchPopMessage,
  deletePopMessage,
  updatePopMessage,
  toggleStatus,
} from "../../../../store/actions/admin/popMessages";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Modal, DatePicker } from "antd";
import * as Yup from "yup";
import FormikError from "../../../../components/partials/formikError";
import { Formik } from "formik";
import Pagination from "../../../../components/pagination/pagination";
import moment from "moment";

export default function List() {
  const { msg } = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const {
    savedPopMessages,
    fetchingPopMessages,
    popMessages,
    deleting,
    deleted,
    toggling,
    toggled,
    updatingPopMessages,
    updatedPopMessages,
    searchingpopMessages,
  } = useSelector((state) => state.popMessages);

  const [showEditModal, setEditModal] = useState({ visible: false, el: {} });
  const [bnToDelete, setBnToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [statusToChange, setStatusToChange] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);

  useEffect(() => {
    dispatch(fetchPopMessage());
  }, []);

  useEffect(() => {
    if (savedPopMessages) {
      dispatch(fetchPopMessage());
    }
  }, [savedPopMessages]);

  useEffect(() => {
    if (deleted) {
      setShowDeleteModal(false);
      dispatch(fetchPopMessage());
    }
    if (toggled) {
      toggleStatusModal({});
      dispatch(fetchPopMessage());
    }
    if (updatedPopMessages) {
      toggleUpdateModal({});
      dispatch(fetchPopMessage());
    }
  }, [deleted, toggled, updatedPopMessages]);

  const toggleDeleteModal = (id) => {
    setBnToDelete(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleUpdateModal = (item) => {
    setEditModal({ visible: !showEditModal.visible, el: item });
  };

  const toggleStatusModal = (item) => {
    setStatusToChange({ id: item.id, status: item.status });
    setShowStatusModal(!showStatusModal);
  };

  return (
    <>
      <div className="table-responsive">
        {fetchingPopMessages || searchingpopMessages ? <Spin /> : ""}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-uppercase text-muted">sn</th>
              <th className="text-uppercase text-muted">title</th>
              {/* <th className="text-uppercase text-muted">message</th> */}
              <th className="text-uppercase text-muted">status</th>
              <th className="text-uppercase text-muted">expires at</th>
              <th className="text-uppercase text-muted">created at</th>
              <th className="text-uppercase text-muted">action</th>
            </tr>
          </thead>
          <tbody>
            {popMessages.data?.map((el, i) => {
              return (
                <tr className="border-bottom" key={i}>
                  <td>{i + 1}</td>
                  <td>{el.title}</td>
                  {/* <td className="wrap">{el.message}</td> */}
                  <td>
                    <small
                      className={`px-4 py-2 status-width rounded alert-${
                        el.active === "no" ? "danger" : "success"
                      } fw-bold`}
                    >
                      {el.active === "no" ? "disabed" : "enabled"}
                    </small>
                  </td>
                  <td>{el.expired_at}</td>
                  <td>{el.created_at}</td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#actions"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#toggleStatus"
                            onClick={() => {
                              toggleStatusModal({
                                id: el.id,
                                status:
                                  el.active === "no" ? "Enable" : "Disable",
                              });
                            }}
                          >
                            {el.active === "no" ? "Enable" : "Disable"}
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#editMessage"
                            onClick={() => {
                              toggleUpdateModal(el);
                            }}
                          >
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deletePopMessage"
                            onClick={() => {
                              toggleDeleteModal(el.id);
                            }}
                          >
                            Trash
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {popMessages?.links && (
        <Pagination
          onPageChange={(page) => {
            if (popMessages !== null && popMessages?.meta?.per_page) {
              dispatch(fetchPopMessage(page));
            }
          }}
          data={popMessages}
        />
      )}

      {/* delete modal */}
      <Modal
        visible={showDeleteModal}
        onCancel={() => {
          toggleDeleteModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure you want to delete this recipent</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleDeleteModal();
            }}
            disabled={deleting}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={deleting}
            onClick={() => {
              dispatch(deletePopMessage(bnToDelete));
            }}
          >
            Delete {deleting && <Spin />}
          </button>
        </div>
      </Modal>

      {/* status modal */}
      <Modal
        visible={showStatusModal}
        onCancel={() => {
          toggleStatusModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <p>Are you sure you {statusToChange.status} message ?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleStatusModal({});
            }}
            disabled={toggling}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={toggling}
            onClick={() => {
              dispatch(toggleStatus(statusToChange.id));
            }}
          >
            Proceed {toggling && <Spin />}
          </button>
        </div>
      </Modal>

      {/* edit modal */}

      <Modal
        visible={showEditModal.visible}
        footer={false}
        onCancel={() => {
          toggleUpdateModal({});
        }}
      >
        <h6 className="fw-bold">Create Pop Message</h6>
        <Formik
          initialValues={{
            message: showEditModal.el.message,
            type: showEditModal.el.type,
            title: showEditModal.el.title,
            expired_at: showEditModal.el.expiry,
            purpose: showEditModal.el.purpose,

          }}
          validationSchema={Yup.object({
            message: Yup.string("").required("Enter message"),
            title: Yup.string("").required("Enter title"),
            type: Yup.string("").required("Select type"),
            expired_at: Yup.string("").required("Select expiry"),
            purpose: Yup.string("").required("Select purpose"),
          })}
          onSubmit={(values) => {
            dispatch(
              updatePopMessage({ ...values, pop_id: showEditModal.el.id })
            );
          }}
        >
          {(props) => (
            <form className="py-4" onSubmit={props.handleSubmit} id="form">
              <div className="form-group mb-3">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control min-height"
                  placeholder="Title"
                  name="title"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.title}
                />
                {props.touched.title && props.errors.title && (
                  <FormikError msg={props.errors.title} />
                )}
                <FormikError msg={msg?.title ?? ""} />
              </div>
              <div className="form-group mb-3">
                <label>Message</label>
                <textarea
                  rows={3}
                  className="form-control"
                  placeholder="Body"
                  defaultValue={""}
                  name="message"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.message}
                />
                {props.touched.message && props.errors.message && (
                  <FormikError msg={props.errors.message} />
                )}
                <FormikError msg={msg?.message ?? ""} />
              </div>
              <div className="form-group mb-3">
                <label>Expiry Date</label>
                <DatePicker
                  className="form-control min-height"
                  name="expired_at"
                  onChange={(date, dateString) => {
                    props.setValues({
                      ...props.values,
                      expired_at: dateString,
                    });
                  }}
                  value={moment(props.values.expired_at, "YYYY-MM-DD")}
                />
                {props.touched.expired_at && props.errors.expired_at && (
                  <FormikError msg={props.errors.expired_at} />
                )}
                <FormikError msg={msg?.expired_at ?? ""} />
              </div>
              <div className="form-group mb-3">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="warningCheck"
                    defaultValue="warning"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="type"
                    // value={props.values.title}
                    checked={props.values.type === "warning"}
                  />
                  <label
                    className="form-check-label text-warning"
                    htmlFor="warningCheck"
                  >
                    Warning
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="infoCheck"
                    defaultValue="info"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="type"
                    checked={props.values.type === "info"}
                  />
                  <label
                    className="form-check-label text-info"
                    htmlFor="infoCheck"
                  >
                    Info
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="dangerCheck"
                    defaultValue="danger"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="type"
                    checked={props.values.type === "danger"}
                  />
                  <label
                    className="form-check-label text-danger"
                    htmlFor="dangerCheck"
                  >
                    Danger
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="successCheck"
                    defaultValue="success"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="type"
                    checked={props.values.type === "success"}
                  />
                  <label
                    className="form-check-label text-success"
                    htmlFor="successCheck"
                  >
                    Success
                  </label>
                </div>
                <br />
                {props.touched.type && props.errors.type && (
                  <FormikError msg={props.errors.type} />
                )}
                <FormikError msg={msg?.type ?? ""} />
              </div>
              <div className="form-group mb-3">
                <label>Purpose</label><br/>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="popCheck"
                    defaultValue="pop"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="purpose"
                    checked={props.values.purpose === "pop"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="popCheck"
                  >
                    Pop
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="stickyCheck"
                    defaultValue="sticky"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="purpose"
                    checked={props.values.purpose === "sticky"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="stickyCheck"
                  >
                    Sticky
                  </label>
                </div><br/>
                {props.touched.purpose && props.errors.purpose && (
                  <FormikError msg={props.errors.purpose} />
                )}
                <FormikError msg={msg?.purpose ?? ""} />
              </div>
              <div className="text-end">
                <button
                  className="btn btn-outline-red ms-2 px-4"
                  data-bs-dismiss="modal"
                  type="button"
                  onClick={() => toggleUpdateModal({})}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-red ms-2 px-4"
                  disabled={updatingPopMessages}
                  type="submit"
                >
                  Save {updatingPopMessages && <Spin />}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
