import * as actionTypes from "../../actionTypes/admin/sendersActionTypes";

const initialState = {
	fetchingCheckoutMetas: false,
	fetchedCheckoutMetas: false,
	checkoutMetas: null,
};

export const admin_occupation_industry = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCHING_CHECKOUT_METAS:
			return { ...state, fetchingCheckoutMetas: action.payload };
		case actionTypes.FETCHED_CHECKOUT_METAS:
			return { ...state, fetchedCheckoutMetas: action.payload };
		case actionTypes.SET_CHECKOUT_METAS:
			return { ...state, checkoutMetas: action.payload };
		default:
			return state;
	}
};

export const admin_senderReducer = (
	state = { isLoading: false, outboundData: [], error: null },
	action
) => {
	switch (action.type) {
		case actionTypes.SET_DATA_OUTBOUNDS:
			return {
				...state,
				isLoading: true,
				error: null,
			};
		case actionTypes.OUTBOUNDS_DATA_SUCCESS:
			return {
				...state,
				outboundData: action.payload,
				isLoading: false,
				error: null,
			};
		case actionTypes.OUTBOUNDS_DATA_FAILURE:
			return {
				...state,
				isLoading: false,
				error: "Failed to fetch sender outbounds data.",
			};
		default:
			return state;
	}
};

export const fetch_rateds_countries__admin_reducer = (
	state = { loading_rateds_countries: false, fetch_rateds_countries: [] },
	action
) => {
	switch (action.type) {
		case actionTypes.LOADING_RATEDS_COUNTRIES:
			return { ...state, loading_rateds_countries: action.payload };
		case actionTypes.RATEDS_COUNTRIES_SUCCESSFUL:
			return {
				...state,
				fetch_rateds_countries: action.payload,
				loading_rateds_countries: false,
			};
		default:
			return state;
	}
};
