import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  complianceList,
  ban,
  suspend,
  activate,
  searchCompliance,
  fetchSendersKpis,
} from "../../../store/actions/admin/senders";
import { Spin, Modal } from "antd";
import Pagination from "../../../components/pagination/pagination";
import moment from "moment";

export default function ComplianceList() {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const filterParam = searchParams.get("filter");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSendersKpis);
  }, []);

  useEffect(() => {
    if (searchParam && filterParam) {
      dispatch(
        searchCompliance({
          param: searchParam,
          filter: filterParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(complianceList(pageParam));
    }
    setCurrentPage(pageParam);
  }, [pageParam, searchParam, filterParam, dispatch]);
  const {
    complianceSenders,
    fetchingComplianceSenders,
    suspending,
    banning,
    activating,
  } = useSelector((state) => state.senders);
  const [show, setShow] = useState(false);
  const [uuid, setUuid] = useState({});
  const toggleModal = (id = "", type = "") => {
    setUuid({ uuid: id, type: type });
    setShow(!show);
  };
  const [currentPage, setCurrentPage] = useState(null);
  useEffect(() => {
    if (suspending || banning || activating) setShow(false);
  }, [suspending, banning, activating]);

  const modalAction = () => {
    if (uuid.type === "ban") dispatch(ban(uuid.uuid));
    if (uuid.type === "activate") dispatch(activate(uuid.uuid));
    if (uuid.type === "suspend") dispatch(suspend(uuid.uuid));
  };

  return (
    <div className="table-responsive">
      {fetchingComplianceSenders && <Spin />}
      <table className="table table-borderless">
        <thead>
          <tr>
            <th className="text-muted text-uppercase">#</th>
            <th className="text-muted text-uppercase">member id</th>
            <th className="text-muted text-uppercase">name</th>
            <th className="text-muted text-uppercase">mobile phone</th>
            <th className="text-muted text-uppercase">email</th>
            <th className="text-muted text-uppercase">status</th>
            <th className="text-muted text-uppercase">created at</th>
            {/* <th className="text-muted text-uppercase">id card</th> */}
            <th className="text-muted text-uppercase">action</th>
          </tr>
        </thead>
        <tbody>
          {/* row */}
          {complianceSenders.data?.map((el, i) => {
            let txt = "success";
            if (el.account_status === "banned") txt = "danger";
            if (el.account_status === "deactivated") txt = "danger";
            if (el.account_status === "suspended") txt = "warning";

            let count = i + 1;
            if (currentPage > 1) {
              if (count === 10) {
                count = Number(`${currentPage - 1}${i}`) + 1;
              } else {
                count = `${currentPage - 1}${count}`;
              }
            }

            return (
              <tr className="border-bottom" key={i}>
                <td>{count}</td>
                <td>#{el?.profile?.member_id}</td>
                <td>
                  <a
                    href="#view"
                    onClick={(e) => {
                      e.preventDefault();
                      // localStorage.setItem("senders_userUuid", el.uuid);
                      localStorage.setItem(
                        "senders_profile",
                        JSON.stringify({...el.profile, sender_uuid: el?.uuid})
                      );
                      navigate(`/senders/details`, {
                        state: { uuid: el.uuid },
                      });
                    }}
                  >
                    {el?.profile?.firstname
                      ? `${el?.profile?.firstname} ${el?.profile?.lastname}`
                      : "N/A"}
                  </a>
                  <br />
                  <small
                    className={`${
                      el?.profile?.face_verified_at
                        ? "text-success"
                        : "text-danger"
                    }`}
                  >
                    Face:{" "}
                    {el?.profile?.face_verified_at
                      ? `${
                          el?.profile?.face_verification_mode === "Auto"
                            ? "Auto"
                            : "Manually"
                        } Verified `
                      : "Unverified"}
                  </small>
                </td>
                <td>{el?.profile?.mobile_phone}</td>
                <td>{el?.email}</td>
                <td>
                  <small
                    className={`px-4 py-2 status-width rounded alert-${txt} fw-bold text-capitalize`}
                  >
                    {el.account_status}
                  </small>
                </td>
                <td>{moment(el.created_at.date).format("YYYY-MM-DD")}</td>
                {/* <td>
                  <i className="fas fa-check-circle text-success" /> &nbsp;
                  <small className="text-success">confirmed</small>
                </td> */}
                <td>
                  <a
                    className="btn btn-white"
                    href="#view"
                    onClick={(e) => {
                      // localStorage.setItem("senders_userUuid", el?.uuid);
                      e.preventDefault();
                      localStorage.setItem(
                        "senders_profile",
                        JSON.stringify({...el.profile, sender_uuid: el?.uuid})
                      );
                      navigate(`/senders/details`, {
                        state: { uuid: el.uuid },
                      });
                    }}
                  >
                    View
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {complianceSenders?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (
              complianceSenders !== null &&
              complianceSenders?.meta?.per_page
            ) {
              if (searchParam && filterParam) {
                setSearchParams({
                  param: searchParam,
                  filter: filterParam,
                  page,
                });
                dispatch(
                  searchCompliance({
                    param: searchParam,
                    filter: filterParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });
                dispatch(complianceList(page));
              }
            }
          }}
          data={complianceSenders}
        />
      )}

      <Modal
        visible={show}
        onCancel={() => {
          toggleModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleModal();
            }}
            disabled={suspending || activating || banning}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={suspending || activating || banning}
            onClick={() => {
              modalAction();
            }}
          >
            Proceed {suspending || activating || banning ? <Spin /> : ""}
          </button>
        </div>
      </Modal>
    </div>
  );
}
