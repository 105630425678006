import * as actionTypes from "../../actionTypes/admin/sendersActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";
import { EXPORTING_DATA } from "../../actionTypes/admin/transactionActionTypes";
import { omit } from "lodash";
import { saveAs } from "file-saver";

export const list =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: true });
			dispatch({ type: actionTypes.SAVED_SENDERS, payload: false });
			const res = await http.get(`admin/senders/all/paginate/10?page=${page}`);
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
			dispatch({ type: actionTypes.SET_SENDERS, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const deactivated =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: true });
			dispatch({ type: actionTypes.SAVED_SENDERS, payload: false });
			const res = await http.get(
				`admin/senders/deactivated/paginate/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
			dispatch({ type: actionTypes.SET_SENDERS, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const requestedDeactivation =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: true });
			dispatch({ type: actionTypes.SAVED_SENDERS, payload: false });
			const res = await http.get(
				`admin/senders/requested_deactivations/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
			dispatch({ type: actionTypes.SET_SENDERS, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const banned =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: true });
			dispatch({ type: actionTypes.SAVED_SENDERS, payload: false });
			const res = await http.get(
				`admin/senders/banned/paginate/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
			dispatch({ type: actionTypes.SET_SENDERS, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const complianceList =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch({
				type: actionTypes.FETCHING_COMPLIANCE_SENDERS,
				payload: true,
			});
			dispatch({ type: actionTypes.SAVED_SENDERS, payload: false });
			const res = await http.get(
				`admin/senders/compliance_list/paginate/10?page=${page}`
			);
			dispatch({
				type: actionTypes.FETCHING_COMPLIANCE_SENDERS,
				payload: false,
			});
			dispatch({
				type: actionTypes.SET_COMPLIANCE_SENDERS,
				payload: res.data.data,
			});
		} catch (err) {
			dispatch({
				type: actionTypes.FETCHING_COMPLIANCE_SENDERS,
				payload: false,
			});
			dispatch(error.setErrorMsg(err));
		}
	};

export const search = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: true });
		const res = await http.post(`admin/senders/search`, {
			...fd,
			search: fd.param,
		});
		dispatch({ type: actionTypes.SET_SENDERS, payload: res.data.data });
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
	} catch (err) {
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const searchCompliance = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.FETCHING_COMPLIANCE_SENDERS, payload: true });
		const res = await http.post("admin/senders/search_compliance", {
			...fd,
			search: fd.param,
		});
		dispatch({
			type: actionTypes.SET_COMPLIANCE_SENDERS,
			payload: res.data.data,
		});
		dispatch({ type: actionTypes.FETCHING_COMPLIANCE_SENDERS, payload: false });
	} catch (err) {
		dispatch({ type: actionTypes.FETCHING_COMPLIANCE_SENDERS, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const searchDeactivated = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: true });
		const res = await http.post("admin/senders/search_deactivated", {
			...fd,
			search: fd.param,
		});
		dispatch({
			type: actionTypes.SET_SENDERS,
			payload: res.data.data,
		});
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
	} catch (err) {
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const searchBanned = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: true });
		const res = await http.post("admin/senders/search_banned", {
			...fd,
			search: fd.param,
		});
		dispatch({
			type: actionTypes.SET_SENDERS,
			payload: res.data.data,
		});
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
	} catch (err) {
		dispatch({ type: actionTypes.FETCHING_SENDERS, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const _profile = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.FETCHING_PROFILE, payload: true });
		const res = await http.get(`admin/senders/get/${uuid}`);
		dispatch({ type: actionTypes.SET_PROFILE, payload: res.data.data });
		dispatch({ type: actionTypes.FETCHING_PROFILE, payload: false });
	} catch (err) {
		dispatch({ type: actionTypes.FETCHING_PROFILE, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const suspend = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: true });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: false });
		const res = await http.post(`admin/senders/suspend/${uuid}`);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const ban = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: true });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: false });
		const res = await http.post(`admin/senders/ban/${uuid}`);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const approveDeactivation = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: true });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: false });
		const res = await http.post(`admin/senders/approve_deactivation/${uuid}`);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const resetPassword = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: true });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: false });
		const res = await http.post(`admin/senders/send_password_reset/${uuid}`);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const activate = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: true });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: false });
		const res = await http.post(`admin/senders/activate/${uuid}`);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch({ type: actionTypes.COMPLETED_ACTION, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_ACTION, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const updateProfile = (fd) => async (dispatch) => {
	try {
		const formData = new FormData();
		formData.append("firstname", fd.firstname);
		formData.append("lastname", fd.lastname);
		formData.append("othernames", fd.othernames);
		formData.append("email", fd.email);
		formData.append("address", fd.address_line_1);
		formData.append("gender", fd.gender);
		formData.append("mobile_phone", fd.mobile_phone);
		formData.append("occupation", fd.occupation);
		formData.append("dob", fd.dob);
		formData.append("unit_no", fd.unit_no);
		formData.append("street_no", fd.street_no);
		formData.append("street_name", fd.street_name);
		formData.append("state", fd.state);
		formData.append("surburb", fd.suburb);
		formData.append("postcode", fd.postcode);
		formData.append("id_issue_date", fd.id_issue_date);
		formData.append("id_expiry_date", fd.id_expiry_date);
		formData.append("id_number", fd.id_number);
		formData.append("id_type", fd.id_type);
		formData.append("issuer", "LICENSING SERVICES WA");
		formData.append("scanned_id_front", fd.scanned_id_front);
		formData.append("scanned_id_rear", fd.scanned_id_rear);
		formData.append("country", fd.country);
		formData.append("card_number", fd.card_number);
		formData.append("outbound", fd.outbound);
		formData.append("occupation_industry", fd.occupation_industry);
		formData.append("estimated_monthly_send", fd.estimated_monthly_send);
		const metas = { required: fd.idtype_metas };
		formData.append("idtype_metas", JSON.stringify(metas));

		error.clearError(dispatch);
		dispatch({ type: actionTypes.UPDATING_PROFILE, payload: true });
		dispatch({ type: actionTypes.UPDATED_PROFILE, payload: false });
		const res = await http.post(
			`admin/senders/update_sender/${fd.uuid}`,
			formData
		);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.UPDATED_PROFILE, payload: true });
		dispatch({ type: actionTypes.UPDATING_PROFILE, payload: false });
	} catch (err) {
		dispatch({ type: actionTypes.UPDATING_PROFILE, payload: false });
		dispatch({ type: actionTypes.UPDATED_PROFILE, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};
export const updateSenderEmail = (values) => async (dispatch) => {
	try {
		const formData = new FormData();
		formData.append("email", values.email);
		error.clearError(dispatch);
		const res = await http.post(
			`admin/senders/update_sender_email/${values.uuid}`,
			{ email: values.email }
		);
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch(error.setErrorMsg(err));
	}
};

export const _transactions =
	(page = 1, uuid) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_TRANSACTIONS, payload: true });
			const res = await http.get(
				`admin/senders/transactions/${uuid}/paginate/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_TRANSACTIONS, payload: false });
			dispatch({ type: actionTypes.SET_TRANSACTIONS, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_TRANSACTIONS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const _beneficiaries =
	(page = 1, uuid) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_BENEFICIARIES, payload: true });
			const res = await http.get(
				`admin/senders/beneficiaries/${uuid}/paginate/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_BENEFICIARIES, payload: false });
			dispatch({ type: actionTypes.SET_BENEFICIARIES, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_BENEFICIARIES, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const _kycs =
	(page = 1, uuid) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_KYCS, payload: true });
			const res = await http.get(
				`admin/senders/kycs/${uuid}/paginate/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_KYCS, payload: false });
			dispatch({ type: actionTypes.SET_KYCS, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_KYCS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const saveKyc = (fd) => async (dispatch) => {
	try {
		let fds = new FormData();
		// fds.append("sender_kyc_docs[]", fd.document);
		for (let i = 0; i < fd.document.length; i++) {
			fds.append("sender_kyc_docs[]", fd.document[i]);
		}

		dispatch({ type: actionTypes.SAVING_KYC, payload: true });
		dispatch({ type: actionTypes.CHANGED_KYC, payload: false });
		const res = await http.post(`admin/senders/upload_kyc/${fd.uuid}`, fds);
		dispatch({ type: actionTypes.SAVING_KYC, payload: false });
		dispatch({ type: actionTypes.CHANGED_KYC, payload: true });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.SAVING_KYC, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const verifyPhone = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.VERIFYING_PHONE, payload: true });
		dispatch({ type: actionTypes.VERIFIED_PHONE, payload: false });
		const res = await http.post(`admin/senders/verify_sender_phone/${uuid}`);
		dispatch({ type: actionTypes.VERIFYING_PHONE, payload: false });
		dispatch({ type: actionTypes.VERIFIED_PHONE, payload: true });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.VERIFYING_PHONE, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const verifyEmail = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.VERIFYING_EMAIL, payload: true });
		dispatch({ type: actionTypes.VERIFIED_EMAIL, payload: false });
		const res = await http.post(`admin/senders/verify_sender_email/${uuid}`);
		dispatch({ type: actionTypes.VERIFYING_EMAIL, payload: false });
		dispatch({ type: actionTypes.VERIFIED_EMAIL, payload: true });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.VERIFYING_EMAIL, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const deleteKyc = (uuid) => async (dispatch) => {
	const senders_profile = JSON.parse(localStorage.getItem("senders_profile"));
	try {
		dispatch({ type: actionTypes.LOADING, payload: true });
		dispatch({ type: actionTypes.CHANGED_KYC, payload: false });
		const res = await http.post(
			`admin/senders/kycs/delete/${senders_profile?.sender_uuid}/${uuid}`
		);
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch({ type: actionTypes.CHANGED_KYC, payload: true });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const _notes =
	(page = 1, uuid) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_NOTES, payload: true });
			const res = await http.get(
				`admin/senders/notes/${uuid}/paginate/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_NOTES, payload: false });
			dispatch({ type: actionTypes.SET_NOTES, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_NOTES, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const saveNote = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING, payload: true });
		dispatch({ type: actionTypes.CHANGED, payload: false });
		const res = await http.post(`admin/senders/notes/create/${fd.uuid}`, fd);
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch({ type: actionTypes.CHANGED, payload: true });

		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const updateCompliance = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING, payload: true });
		const res = await http.post(
			`admin/senders/update_compliance_status/${fd.uuid}`,
			fd
		);
		dispatch({ type: actionTypes.LOADING, payload: false });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const blacklistList =
	(page = 1, uuid) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_BLACK_LIST, payload: true });
			dispatch({ type: actionTypes.BLACKLISTED, payload: false });
			const res = await http.get(
				`admin/senders/sender_blacklist_history/${uuid}/10/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_BLACK_LIST, payload: false });
			dispatch({ type: actionTypes.BLACKLISTED, payload: true });

			dispatch({ type: actionTypes.SET_BLACK_LIST, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_BLACK_LIST, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const _documents =
	(page = 1, uuid) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_DOCS, payload: true });
			const res = await http.get(
				`admin/senders/docs/${uuid}/10/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_DOCS, payload: false });
			dispatch({ type: actionTypes.SET_DOCS, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_DOCS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const saveDocs = (fd) => async (dispatch) => {
	try {
		let fds = new FormData();
		fds.append("docs[]", fd.document);
		for (let i = 0; i < fd.docs.length; i++) {
			fds.append("docs[]", fd.docs[i]);
		}

		fds.append("title", fd.title);
		fds.append("message", fd.message);
		fds.append("expire_at", fd.expires_at);

		dispatch({ type: actionTypes.LOADING, payload: true });
		dispatch({ type: actionTypes.CHANGED, payload: false });
		const res = await http.post(
			`admin/senders/document_upload/${fd.uuid}`,
			fds
		);
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch({ type: actionTypes.CHANGED, payload: true });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const fetchLimits =
	(page = 1, uuid) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_LIMITS, payload: true });
			const res = await http.get(
				`admin/senders/get_user_limit_amount/${uuid}/10/10?page=${page}`
			);
			dispatch({ type: actionTypes.FETCHING_LIMITS, payload: false });
			dispatch({
				type: actionTypes.SET_LIMITS,
				payload: res?.data?.data?.transaction_limits,
			});
			dispatch({
				type: actionTypes.SET_PROFILE_TRANSACTION_LIMIT_DATE,
				payload: res?.data?.data?.others?.profile_transaction_limit_date,
			});
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_LIMITS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const saveLimit = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING, payload: true });
		dispatch({ type: actionTypes.CHANGED, payload: false });
		const res = await http.post(
			`admin/senders/update_user_limit/${fd.uuid}`,
			fd
		);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch({ type: actionTypes.CHANGED, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const fetchSendersCounts = async (dispatch) => {
	try {
		const res = await http.get(`admin/admin_metas/senders_count`);
		dispatch({ type: actionTypes.SET_COUNT_SENDER, payload: res.data.data });
	} catch (err) {
		dispatch(error.setErrorMsg(err));
	}
};

var fileDownload = require("js-file-download");
export const downloadSenders = async (dispatch) => {
	try {
		const res = await http.post(`admin/senders/export_senders`);
		fileDownload(res.data, `senders.xlxn`);
	} catch (err) {
		dispatch(error.setErrorMsg(err));
	}
};

export const downloadTransaction = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: EXPORTING_DATA, payload: true });
		const res = await http.get(
			`admin/reports/export_user_transaction/${uuid}`,
			{
				responseType: "blob",
			}
		);

		const contentDisposition = res.headers["content-disposition"];
		const fileName = contentDisposition
			.split(";")
			.find((part) => part.includes("filename"))
			.split("=")[1]
			.replace(/"/g, "");

		saveAs(new Blob([res.data]), fileName);
		dispatch({ type: EXPORTING_DATA, payload: false });
	} catch (err) {
		dispatch({ type: EXPORTING_DATA, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const forceFaceVerify = (uuid, close, refresh) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.VERIFYING_BIOMETRICS, payload: true });
		const res = await http.post(`/admin/senders/force_face_verify/${uuid}`);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.VERIFYING_BIOMETRICS, payload: false });
		close();
		dispatch(fetchSendersKpis);
		refresh();
	} catch (err) {
		dispatch({ type: actionTypes.VERIFYING_BIOMETRICS, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const fetchSendersKpis = async (dispatch) => {
	try {
		const res = await http.get(`/admin/senders/kpis`);
		dispatch({
			type: actionTypes.SET_FACE_VERIFIED_COUNT,
			payload: res.data.data.total_face_verified,
		});
	} catch (err) {
		dispatch(error.setErrorMsg(err));
	}
};
// export const downloadTransaction = (uuid) => async (dispatch) => {
//   try {
//     const res = await http.get(`admin/reports/export_user_transaction/${uuid}`);
//     fileDownload(res.data, `transactios.xlsx`);
//   } catch (err) {
//     dispatch(error.setErrorMsg(err));
//   }
// };

export const exportSenders = (type, countryId) => async (dispatch) => {
	try {
		dispatch({ type: EXPORTING_DATA, payload: true });
		const res = await http.get(
			`/admin/reports/export_users?type=${type}&country_id=${countryId}`,
			{
				responseType: "blob",
			}
		);

		const contentDisposition = res.headers["content-disposition"];
		const fileName = contentDisposition
			.split(";")
			.find((part) => part.includes("filename"))
			.split("=")[1]
			.replace(/"/g, "");

		saveAs(new Blob([res.data]), fileName);
		dispatch({ type: EXPORTING_DATA, payload: false });
	} catch (err) {
		dispatch({ type: EXPORTING_DATA, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const doBlacklist = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.BLACKLISTED, payload: false });
		dispatch({ type: actionTypes.BLACKLISTING, payload: true });
		const res = await http.post(
			`admin/senders/blacklist_or_unblacklist_user/${fd.uuid}`,
			{ reason: fd.reason }
		);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.BLACKLISTING, payload: false });
		dispatch({ type: actionTypes.BLACKLISTED, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.BLACKLISTING, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

// export const doBlacklist = (fd) => async (dispatch) => {
//   try {
//     dispatch({ type: actionTypes.BLACKLISTED, payload: false });
//     dispatch({ type: actionTypes.BLACKLISTING, payload: true });
//     const res = await http.post(
//       `admin/senders/blacklist_or_unblacklist_user/${fd.uuid}`,
//       { reason: fd.reason }
//     );
//     notifications.success({ title: "Success", msg: res.data.message });
//     dispatch({ type: actionTypes.BLACKLISTING, payload: false });
//     dispatch({ type: actionTypes.BLACKLISTED, payload: true });
//   } catch (err) {
//     dispatch({ type: actionTypes.BLACKLISTING, payload: false });
//     dispatch(error.setErrorMsg(err));
//   }
// };

export const fetchCheckoutMetas_admin = () => async (dispatch) => {
	try {
		error.clearError(dispatch);
		dispatch({ type: actionTypes.FETCHING_CHECKOUT_METAS, payload: true });
		dispatch({ type: actionTypes.FETCHED_CHECKOUT_METAS, payload: false });

		const res = await http.get("metas/checkout_metas");

		dispatch({ type: actionTypes.FETCHING_CHECKOUT_METAS, payload: false });
		dispatch({ type: actionTypes.FETCHED_CHECKOUT_METAS, payload: true });

		localStorage.setItem("checkoutMetas", JSON.stringify(res.data.data));

		dispatch({ type: actionTypes.SET_CHECKOUT_METAS, payload: res.data.data });
	} catch (err) {
		console.log(err);
		dispatch({ type: actionTypes.FETCHING_CHECKOUT_METAS, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const getUserOutbound__data = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.SET_DATA_OUTBOUNDS });
		const res = await http.get(`/admin/senders/get_sender_outbounds/${uuid}`);
		dispatch({
			type: actionTypes.SET_DATA_OUTBOUNDS,
			payload: res.data?.data?.data,
		});
		dispatch({
			type: actionTypes.OUTBOUNDS_DATA_SUCCESS,
			payload: res.data?.data?.data,
		});
	} catch (err) {
		dispatch({ type: actionTypes.OUTBOUNDS_DATA_FAILURE });
		dispatch(error.setErrorMsg(err));
	}
};

export const fetchRatedCountries__admin = () => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_RATEDS_COUNTRIES, payload: true });
		const res = await http.get("/rate_calculator/populate_rated_countries");
		dispatch({ type: actionTypes.LOADING_RATEDS_COUNTRIES, payload: false });
		dispatch({
			type: actionTypes.RATEDS_COUNTRIES_SUCCESSFUL,
			payload: res.data.data,
		});
	} catch (e) {
		dispatch({ type: actionTypes.LOADING_RATEDS_COUNTRIES, payload: false });
	}
};
