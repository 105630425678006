import React, { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch } from "react-redux";
import FormikError from "./formikError";
import FilterSelectWithCount from "./filterSelectWithCount";
import { useSearchParams } from "react-router-dom";

export default function FilterSearch({
  options = [],
  callback,
  placeholder,
  resetFunction,
}) {
  const [filter, setFilter] = useState("");
  const [param, setParam] = useState("");
  const [filterError, setFilterError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get("param");
  const filterParam = searchParams.get("filter");

  useEffect(() => {
    setFilter(filterParam ?? "");
    setParam(searchParam ?? "");
  }, []);
  const selectHandler = (e) => {
    if (e.target.value) setFilterError(false);
    setFilter(e.target.value);
    debounced();
  };
  const selectWithCountHandler = (value) => {
    if (value) setFilterError(false);
    setFilter(value);
    debounced();
  };

  const dispatch = useDispatch();

  const clearFilter = () => {
    if (!filter && !param) {
      return;
    } else {
      setFilter("");
      setParam("");
      searchParams.delete("filter");
      searchParams.delete("page");
      searchParams.delete("param");
      setSearchParams(searchParams);
      dispatch(resetFunction());
    }
  };
  const debounced = useDebouncedCallback(() => {
    setFilterError(false);
    if (!filter) {
      if (param) {
        setFilterError(true);
        return;
      } else {
        searchParams.delete("filter");
        searchParams.delete("param");
        setSearchParams(searchParams);
        dispatch(resetFunction());
      }
    }

    if (filter) {
      setSearchParams({ param, filter });
      dispatch(callback({ param: param, filter: filter }));
    }
  }, 1000);

  const changeHandler = (e) => {
    setParam(e.target.value);
    debounced();
  };

  return (
    <>
      <div className="d-flex flex-lg-row flex-column w-100 align-items-end align-items-lg-center">
        <div className="input-group">
          <span className="input-group-text bg-white">
            <i className="fas fa-search" />
          </span>
          {/* Search text input */}
          <input
            value={param}
            type="text"
            className="form-control border-start-0 min-height"
            placeholder={`Search for ${placeholder ?? "anything"}`}
            onChange={changeHandler}
          />

          <select
            value={filter}
            className="form-select min-height"
            onChange={(e) => {
              selectHandler(e);
            }}
          >
            <option value="">Filter By</option>
            {options.map((el, i) => {
              return (
                <option value={el} key={i} className="text-capitalize">
                  {el.replace("_", " ")}
                </option>
              );
            })}
          </select>
        </div>
        <button
          onClick={clearFilter}
          className="border-0 bg-transparent text-nowrap  ms-1"
        >
          Clear Filter
        </button>
      </div>

      {filterError && <FormikError msg="Select a filter" />}
    </>
  );
}
