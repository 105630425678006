import React, { useState } from "react";
import { Spin, DatePicker, Select } from "antd";
import WalletInfoCard from "../../../components/partials/walletComponents/WalletInfoCard";
import { HiOutlinePlus } from "react-icons/hi2";
import CreateWallet from "../../../components/partials/walletComponents/modals/CreateWallet/CreateWallet";
import { useDispatch, useSelector } from "react-redux";
import SuccessfulCreateWallet from "../../../components/partials/walletComponents/modals/success/SuccessfulCreateWallet";
import {
	closeWalletCreatedModal,
	search,
} from "../../../store/actions/walletActions";
import SwapWalletTable from "./swapWalletTable";
import FundWalletTable from "./fundWalletTable";
import { useDebouncedCallback } from "use-debounce";
import FormikError from "../../../components/partials/formikError";

export default function Wallet() {
	const [showModal, setShowModal] = useState(false);
	const [date, setDate] = useState([]);
	const [transactionView, setTransactionView] = useState("swappings");
	const [filterError, setFilterError] = useState(false);

	const { RangePicker } = DatePicker;
	const debounced = useDebouncedCallback((value) => {
		if (value.trim().length === 0) {
			params = "";
		} else {
			params = value;
		}

		dispatch(search({ reference: params }, transactionView));
	}, 1000);

	const {
		wallet_created,
		user_wallets,
		wallet_history,
		fetching_currencies_for_wallets,
		canUseWallet,
	} = useSelector((state) => state.wallet);

	const dispatch = useDispatch();

	const handleShowModal = () => {
		setShowModal(true);
	};
	const handleCancel = () => {
		setShowModal(false);
	};

	const cancelSuccessfulModal = () => {
		dispatch(closeWalletCreatedModal());
	};

	const handleTransactionViewChange = (e) => {
		setTransactionView(e);
	};
	if (fetching_currencies_for_wallets) {
		return <Spin />;
	}

	let params;

	const handleSearch = () => {
		if (date[0] && date[1]) {
			setFilterError(false);
			dispatch(
				search(
					{
						start_date: date[0],
						end_date: date[1],
					},
					transactionView
				)
			);
		} else {
			setFilterError(true);
		}
	};
	const changeHandler = (e) => {
		debounced(e.target.value);
	};
	return (
		<>
			{canUseWallet ? (
				<div className="container-fluid">
					<div className="col-xl-6 mb-4 ">
						<div className="wallet_info_container  pe-xxl-5 pe-xl-3 mb-4">
							<WalletInfoCard />
						</div>
						<button
							onClick={handleShowModal}
							className="d-flex border-0 bg-transparent "
						>
							{/* <GoPlus size={30} color="#eb4234" /> */}
							<HiOutlinePlus size={20} color="#eb4234" />
							<p className="text-red fw-bold">New Wallet</p>
						</button>
					</div>
					<div className="wallet_table_wrapper p-4 ">
						<div>
							<div className="row mb-4">
								{/* top search, date picker */}
								<div>
									<p className="fw-bold">Transactions History</p>
									<div className="my-3 d-flex flex-column flex-lg-row align-items-lg-center gap-4">
										<label htmlFor="transaction_type">
											Select transaction type
										</label>{" "}
										<Select
											id="transaction_type"
											value={transactionView}
											onChange={handleTransactionViewChange}
											className="form-control wallet-transaction-select"
										>
											<Select.Option value={"swappings"}>
												Swapping
											</Select.Option>
											<Select.Option value={"fundings"}>Funding</Select.Option>
										</Select>
									</div>
								</div>
								<div className="col-xl-9">
									<div className="row justify-content-between">
										<div className="col-xl-6 col-lg-5 mb-3">
											<div className="input-group rounded-0">
												<span className="input-group-text bg-white">
													<i className="fas fa-search" />
												</span>
												<input
													onChange={changeHandler}
													type="text"
													placeholder="Search by reference"
													className="form-control border-start-0"
												/>
											</div>
										</div>
										<div className="col-xl-5 col-lg-5 col-sm-6 mb-3">
											<div className="input-group rounded-0">
												<RangePicker
													onChange={(date, dateString) => {
														if (date[0] && date[1]) {
															setFilterError(false);
															setDate(dateString);
														}
													}}
													className="form-control wallet_search"
													name="filter_date"
													// suffixIcon={false}
												/>
												{/* <span className="input-group-text end bg-white">
                 <i className="far fa-calendar-alt" />
               </span> */}
											</div>
											{filterError && <FormikError msg="Select a date" />}
										</div>
										<div className="col-xl-1 col-lg-1 col-sm-6 mb-3">
											<button
												onClick={handleSearch}
												className={`py-1 wallet_search  px-4 border bg-white  button btn btn-red `}
											>
												Search
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* table */}
						{transactionView === "swappings" ? (
							<SwapWalletTable />
						) : (
							<FundWalletTable />
						)}
					</div>

					<CreateWallet showModal={showModal} handleCancel={handleCancel} />
					<SuccessfulCreateWallet
						showModal={wallet_created.created}
						handleCancel={cancelSuccessfulModal}
					/>
				</div>
			) : (
				""
			)}
		</>
	);
}
