import React from "react";
import moment from "moment";

const ProfileInfo = ({
	certificate_image,
	total_sent,
	business_name,
	business_location,
	registration_no,
	business_category,
	incorporation_date,
	status,
	// DeactivateBusinessAccount,
	// onClicked,
	onClickEdit,
	EditBusinessDetails,
}) => {
	return (
		<div>
			<div className="card rounded mb-4 border-0">
				<div className="w-100">
					<div className=" mb-3 mt-3 d-flex justify-content-end align-items-end px-5 ">
						<button
							type="button"
							className="text-danger border-0 fw-bold cursor-pointer bg-white"
							onClick={onClickEdit}
						>
							{/* {EditBusinessDetails && (
								<p>Edit Business Details</p>
							)} */}
							{EditBusinessDetails}
						</button>
					</div>
				</div>
				<div className="card-body px-lg-5 py-4">
					{/* profile image and name */}

					<div className="d-flex mb-5 align-items-start">
						<div className="flex-shrink-0">
							<div className="profile-image">
								{certificate_image !== "" ? (
									<img
										src={certificate_image}
										className="profile-img-tag img-fluid"
										alt="Profile"
									/>
								) : (
									<span className="profile-name-tag">{`${business_name[0]}`}</span>
								)}
							</div>
						</div>
						{/* <div className="d-flex justify-content-between align-items-center w-100"> */}
						{/* <div className="d-flex"> */}
						<div className="flex-grow-1 ms-4">
							<h6 className="fw-bolder mb-1">
								{business_name ?? ""}
								{/* If you have an image badge to display next to the business name */}
								{/* <img src="../assets/images/icon/icon-park_five-star-badge.png" alt="Badge" /> */}
							</h6>
							<p className="text-brown mb-2">Bronze Level Member</p>
							<p className="text-dark">
								Total Amount Sent -{" "}
								<span className="text-red">{total_sent ?? "0"}</span>
							</p>
							<div>
								<p className="">{status ?? ""}</p>
							</div>
						</div>
						{/* </div> */}
					</div>
					{/* other profile details */}
					<div className="row justify-content-between">
						{/* Business Address */}
						<div className="col-lg-2 col-sm-4">
							<p className="text-muted">Business Address</p>
							<p className="text-dark truncate-2">{business_location ?? ""}</p>
						</div>
						{/* Business Type */}
						<div className="col-lg-2 col-sm-4">
							<p className="text-muted">Registration No</p>
							<p className="text-dark">{registration_no ?? ""}</p>
						</div>
						{/* Business Category */}
						<div className="col-lg-2 col-sm-4">
							<p className="text-muted">Business Category</p>
							<p className="text-dark">{business_category ?? ""}</p>
						</div>
						{/* Status */}
						{/* <div className="col-lg-2 col-sm-4">
							<p className="text-muted">Status</p>
							<p className="text-dark">{status}</p>
						</div> */}
						{/* Member Since */}
						<div className="col-lg-2 col-sm-4">
							<p className="text-muted">Member Since</p>
							<p className="text-dark">
								{moment(incorporation_date).format("DD.MM.YYYY") ??""}
							</p>
						</div>
					</div>
				</div>
				{/* <div className="w-100 ">
					<div className=" mb-3 d-flex justify-content-end align-items-end px-5">
						<button
							type="button"
							className="text-danger border-0 fw-bold cursor-pointer bg-white"
							onClick={onClicked}
						> */}
				{/* {DeactivateBusinessAccount && (
								<p>Deactivate Business Account ?</p>
							)} */}
				{/* {DeactivateBusinessAccount} */}
				{/* </button> */}
				{/* </div> */}
				{/* </div>  */}
			</div>
		</div>
	);
};

export default ProfileInfo;
