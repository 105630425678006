import React from "react";
import classNames from "classnames";
import AddBeneficiaryForm from "../../../components/partials/beneficiaryComponents/addBeneficiaryForm";

export default function CreateBeneficiary({ fromMobile }) {
  return (
    <section>
      <div className={classNames({ container: fromMobile ? false : true })}>
        {/* B R E A D C R U M B */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {!fromMobile && (
              <li className="breadcrumb-item">
                <a href="#" className="text-red">
                  Back to beneficiaries
                </a>
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              Add New Beneficiary
            </li>
          </ol>
        </nav>

        <div
          className={classNames("card rounded border-0 ", {
            "my-5": fromMobile ? false : true,
          })}
        >
          <div
            className={classNames("card-body", {
              "px-lg-5": fromMobile ? false : true,
            })}
          >
            <AddBeneficiaryForm fromMobile={fromMobile} />
          </div>
        </div>
      </div>
    </section>
  );
}
