import * as actionTypes from "../actionTypes/businessActionTypes";
import * as error from "./errorActions";
import { http } from "../../config/axios";
import * as notifications from "../../components/notifications/notifications";
import { getUserProfile } from "./profileActions";

export const registerBusiness =
  (fd, handleSuccessulSubmit) => async (dispatch) => {
    const formData = new FormData();
    Object.keys(fd).forEach((key) => {
      formData.append(key, fd[key]);
    });
    try {
      dispatch({ type: actionTypes.CREATING_BUSINESS_ACCOUNT });
      error.clearError(dispatch);
      const res = await http.post("/business/register_business", formData);
      notifications.success({ title: "Success", msg: res.data.message });
      dispatch(getUserProfile(handleSuccessulSubmit));
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({ type: actionTypes.CREATING_BUSINESS_ACCOUNT });
    }
  };

export const update_BusinessAccount = (businessUUID, fd, updateSuccess) => {
  return async (dispatch) => {
    const formData = new FormData();
    Object.keys(fd).forEach((key) => {
      formData.append(key, fd[key]);
    });
    try {
      dispatch({ type: actionTypes.UPDATING_BUSINESS_DETAILS });
      error.clearError(dispatch);
      const response = await http.post(
        `/business/update_business/${businessUUID}`,
        formData
      );
      updateSuccess();
      notifications.success({ title: "Success", msg: response.data.message });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({ type: actionTypes.UPDATING_BUSINESS_DETAILS });
    }
  };
};

export const fetchBusinessCategories = async (dispatch) => {
  try {
    const response = await http.get(`/business/business-categories`);
    dispatch({
      type: actionTypes.SET_BUSINESS_CATEGORIES,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
  }
};

export const fetchBusinessJurisdictions = async (dispatch) => {
  try {
    const response = await http.get(`/business/jurisdiction-code`);
    dispatch({
      type: actionTypes.SET_BUSINESS_JURISDICTIONS,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
  }
};

export const fetchBusinessData = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCHING_BUSINESS_DETAILS });
    try {
      const response = await http.get(`/business/fetch_business`);
      dispatch({
        type: actionTypes.FETCH_DATA_BUSINESS_DETAILS_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({ type: actionTypes.FETCHING_BUSINESS_DETAILS });
    }
  };
};

export const deletingBusinessAccount = (deleteSuccess) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.DELETING_BUSINESS_ACCOUNT });
    try {
      const response = await http.post(`/business/delete_business`);

      notifications.success({
        title: "Success",
        msg: response.data.message,
      });
      dispatch({ type: "RESET_BUSINESS_DATA" });
      dispatch(getUserProfile());
      deleteSuccess();
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({ type: actionTypes.DELETING_BUSINESS_ACCOUNT });
    }
  };
};
