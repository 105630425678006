import * as actionTypes from "../actionTypes/generalActionTypes";

export const generalReducer = (
  state = {
    countries: [],
    states: JSON.parse(localStorage.getItem("states")) ?? null,
    idtypes: JSON.parse(localStorage.getItem("idtypes")) ?? null,
    banks:  null,
    reasons: JSON.parse(localStorage.getItem("reasons")) ?? null,
    loadingCountries: false,
    loadingStates: false,
    loadingBanks: false,
    loadingIdTypes: false,
    loadingReasons: false,
    loadingPayinClasses: false,
    loadingPayoutClasses: false,
    payinClasses: [],
    payoutClasses: [],
    openSideBar: false,
    ratedCountries: [],
    loadingRatedCountries: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.LOADING_RATED_COUNTRIES:
      return { ...state, loadingRatedCountries: action.payload };
    case actionTypes.SET_RATED_COUNTRIES:
      return { ...state, ratedCountries: action.payload };
    case actionTypes.SET_STATES:
      return { ...state, states: action.payload };
    case "TOGGLE_SIDE_BAR":
      return { ...state, openSideBar: !state.openSideBar };
    case actionTypes.SET_PAYOUT_CLASSES:
      return { ...state, payoutClasses: action.payload };
    case actionTypes.SET_PAYIN_CLASSES:
      return { ...state, payinClasses: action.payload };
    case actionTypes.LOADING_PAYIN_CLASSS:
      return { ...state, loadingPayinClasses: action.payload };
    case actionTypes.LOADING_PAYOUT_CLASSS:
      return { ...state, loadingPayoutClasses: action.payload };
    case actionTypes.SET_REASONS:
      return { ...state, reasons: action.payload };
    case actionTypes.SET_COUNTRIES:
      return { ...state, countries: action.payload };
    case actionTypes.SET_ID_TYPES:
      return { ...state, idtypes: action.payload };
    case actionTypes.SET_BANKS:
      return { ...state, banks: action.payload };
    case actionTypes.LOADING_COUNTRIES:
      return { ...state, loadingCountries: action.payload };
    case actionTypes.LOADING_REASONS:
      return { ...state, loadingReasons: action.payload };
    case actionTypes.LOADING_STATES:
      return { ...state, loadingStates: action.payload };
    case actionTypes.LOADING_BANKS:
      return { ...state, loadingBanks: action.payload };
    case actionTypes.LOADING_ID_TYPES:
      return { ...state, loadingIdTypes: action.payload };
    default:
      return state;
  }
};
