import React, { useEffect } from "react";

export default function AustraliaRegulator() {


  useEffect(() => {
    // console.log(window.location.href);
    // if (window.location.href === "http://localhost:3002/") {
    //   var Tawk_API = Tawk_API || {},
    //     Tawk_LoadStart = new Date();
    //   (function () {
    //     var s1 = document.createElement("script"),
    //       s0 = document.getElementsByTagName("script")[0];
    //     s1.async = true;
    //     s1.src = "https://embed.tawk.to/6170212df7c0440a591f2b02/1fif037aq";
    //     s1.charset = "UTF-8";
    //     s1.setAttribute("crossorigin", "*");
    //     s0.parentNode.insertBefore(s1, s0);
    //   })();
    // }
  }, []);

  return (
    <span className="badge bg-red rounded-pill">
    <img
      draggable="false"
      src="assets/images/icon/Group.png"
      alt="cosmoremit "
      className="img-fluid"
    />
    &nbsp; Regulated by AUSTRAC
  </span>
  );
}
