import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dateTimeFormatter } from "../../../store/actions/utilityActions";
import { FaCheck } from "react-icons/fa6";

const TransactionReceiptNew = ({ showReceipt }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const transactionData = JSON.parse(localStorage.getItem("trx_initData"));

	return (
		<>
			<section className="section_wrapper">
				<div className="cos_mail">
					<p>Cosmo Remit</p>
					<p>cosmoremit.com.au</p>
				</div>
				<section
					className="transaction_details_modal_receipt "
					style={{ width: "97%", margin: "auto" }}
				>
					<div className="logo_address">
						<div className="logo___receipt___">
							<img
								src="assets/images/logo/logo.png"
								alt="logo"
								className="receipt__logo_img"
							/>
						</div>
						<p>
							Level 14, 3 Parramatta Square, 153 Macquarie Street Parramatta NSW
							2150.
						</p>
						<p>Email: admin@cosmoremit.com.au</p>
						<p>Phone: (02) 96 159 832, (+61) 414 151 549, (+61) 470 628 369</p>
					</div>
					<div className="transaction_info">
						<div>
							<h1 className="text-center text-10">
								-{transactionData?.for_payin?.send_amount} AUD
							</h1>
						</div>
						<div className="d-flex justify-content-center align-items-center m-auto">
							<div className="check_div">
								<FaCheck className="check" />
							</div>{" "}
							<span className="success_text">
								{transactionData?.for_payin.status}
							</span>
						</div>
						<div className="amount_aud">
							{/* <p>Amount</p> */}
							<p>
								{/* {localStorage.getItem("trx_sendVal")}{" "}
								{localStorage.getItem("trx_fromCountryCurency")} */}
								{/* {transactionData?.for_payin?.send_amount} AUD */}
							</p>
						</div>
					</div>
					<div className="table_transaction_user_info">
						<div className="table_info">
							<h2>Transaction Details</h2>
							<table>
								<tr>
									<td>Transaction Type</td>
									<td>
										<div className="transaction_result">
											<p>Transfer</p>
										</div>
									</td>
								</tr>
								<tr>
									<td>Transaction ID</td>
									<td>
										<div className="transaction_result">
											<p>{transactionData.for_payin.reference}</p>
										</div>
									</td>
								</tr>

								<tr>
									<td>Payment Method</td>
									<td>
										<div className="transaction_result">
											<p>{transactionData?.transaction_details?.payin}</p>
										</div>
									</td>
								</tr>
								<tr>
									<td>Fee</td>
									<td>
										<div className="transaction_result">
											<p>{transactionData?.transaction_details?.commission}</p>
										</div>
									</td>
								</tr>

								<tr>
									<td className="td__1">Recipient Details</td>
									<td className="td__2">
										<div className="transaction_result">
											<p>
												{
													transactionData?.transaction_details?.meta?.account
														?.account_number
												}
												, (
												{
													transactionData?.transaction_details?.meta?.account
														?.account_name
												}
												)
											</p>
										</div>
									</td>
								</tr>

								<tr>
									<td>Exchange Rate</td>
									<td>
										<div className="transaction_result">
											<p>{transactionData?.transaction_details?.rate}</p>
										</div>
									</td>
								</tr>

								<tr>
									<td>Transaction Date</td>
									<td>
										<div className="transaction_result">
											<p>
												{
													dateTimeFormatter(
														transactionData?.transaction_details?.created_at
													).date
												}
												,{" "}
												{
													dateTimeFormatter(
														transactionData?.transaction_details?.created_at
													).time
												}
											</p>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>

					<div className="note">
						<h2>Note</h2>
						<p>
							Payment must come from the sender's account only, kindly input
							only your transaction reference number in your payment description
							to avoid delay in your transaction, your transaction reference
							number is {transactionData.for_payin.reference}
						</p>
					</div>
				</section>

				<div className="btn_share">
					<button
						type="button"
						style={{ cursor: "pointer" }}
						onClick={() => {
							console.log(transactionData.transaction_details);
							dispatch({ type: "INITIALIZED", payload: false });
							navigate("/dashboard");
						}}
					>
						Goto Dashboard
					</button>
				</div>
				<div className="footer_receipt_transaction">
					<small>2024 CosmoRemit | All Rights Reserved</small>
				</div>
			</section>
		</>
	);
};

export default TransactionReceiptNew;
