import React, { useState } from "react";
import PopMessage from "./pop-messages/popMessage";
import FormikError from "../../../components/partials/formikError";
import * as Yup from "yup";
import { Formik } from "formik";
import { PasswordInput } from "../../../components/partials/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { changePassword } from "../../../store/actions/authActions";

export default function Settings() {
  const [passwordType, setPasswordType] = useState("password");
  const { changingPassword } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const changePasswordType = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const schema = Yup.object({
    old_password: Yup.string("Enter Password").required("Enter old password"),
    password: Yup.string("Enter Password").required("Enter new password"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm new password is required"),
  });

  return (
    <div className="container">
      <h5 className="fw-bold">Admin Settings</h5>
      <hr />
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            data-bs-toggle="pill"
            data-bs-target="#messagePill"
            type="button"
          >
            Pop Message
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#paymentPill"
            type="button"
          >
            Payment System
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#securityPill"
            type="button"
          >
            Security
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#paymentaPill"
            type="button"
          >
            Paymenta (DSSc)
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#changePassword"
            type="button"
          >
            Change Password
          </button>
        </li>
      </ul>
      <div className="tab-content py-5">
        <PopMessage />
        <div className="tab-pane fade" id="paymentPill">
          <div className="table-responsive">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th className="text-uppercase text-muted">sn</th>
                  <th className="text-uppercase text-muted">transfer type</th>
                  <th className="text-uppercase text-muted">action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <td>1</td>
                  <td>Bank Transfer</td>
                  <td>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="btpCheck"
                      />
                      <label className="form-check-label" htmlFor="btpCheck">
                        Public
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="btfiCheck"
                      />
                      <label className="form-check-label" htmlFor="btfiCheck">
                        Fee Inclusive
                      </label>
                    </div>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>2</td>
                  <td>POLi</td>
                  <td>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="pCheck"
                      />
                      <label className="form-check-label" htmlFor="pCheck">
                        Public
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="pfiCheck"
                      />
                      <label className="form-check-label" htmlFor="pfiCheck">
                        Fee Inclusive
                      </label>
                    </div>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>3</td>
                  <td>Wallet</td>
                  <td>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="wCheck"
                      />
                      <label className="form-check-label" htmlFor="wCheck">
                        Public
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="wfiCheck"
                      />
                      <label className="form-check-label" htmlFor="wfiCheck">
                        Fee Inclusive
                      </label>
                    </div>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td>4</td>
                  <td>PayID/Bank transfer</td>
                  <td>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="pibtCheck"
                      />
                      <label className="form-check-label" htmlFor="pibtCheck">
                        Public
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="pibtfiCheck"
                      />
                      <label className="form-check-label" htmlFor="pibtfiCheck">
                        Fee Inclusive
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={3} className="text-end">
                    <button className="btn btn-red min-height">Update</button>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="tab-pane fade" id="securityPill">
          <h6 className="fw-bold">2 Factor Authentication</h6>
          <hr />
          <div className="d-flex justify-content-between">
            <p className="text-muted">
              ************ <br />
              If enabled, users will be compelled to perform 2Factor
              authentication before login
            </p>
            <div>
              {/* you can make this "btn-red" when enabled */}
              <button className="btn btn-white px-4">Enable</button>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="paymentaPill">
          <h6 className="fw-bold">Paymenta Payment Settings</h6>
          <hr />
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group mb-4">
                <label className="mb-2">Auto settle</label>
                <select className="form-select min-height">
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="text-end">
                <button className="btn btn-red px-4">Update Settings</button>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="changePassword">
          <h6 className="fw-bold">Change Password</h6>
          <hr />
          <Formik
            validateOnMount={true}
            initialValues={{
              old_password: "",
              password: "",
              confirm_password: "",
            }}
            onSubmit={(values, { resetForm }) => {
              dispatch(changePassword(values, resetForm))
            }}
            validationSchema={schema}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <label className="mb-2">Old Password</label>
                      <div className="input-group">
                        <PasswordInput
                          name="old_password"
                          value={props.values.old_password}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </div>
                      {props.touched.old_password &&
                        props.errors.old_password && (
                          <FormikError msg={props.errors.old_password} />
                        )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="mb-2">Confirm New Password</label>
                      <div className="input-group">
                        <PasswordInput
                          name="confirm_password"
                          value={props.values.confirm_password}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </div>
                      {props.touched.confirm_password &&
                        props.errors.confirm_password && (
                          <FormikError msg={props.errors.confirm_password} />
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <label className="mb-2">New password</label>
                      <div className="input-group">
                        <PasswordInput
                          name="password"
                          value={props.values.password}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </div>
                      {props.touched.password && props.errors.password && (
                        <FormikError msg={props.errors.password} />
                      )}
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      disabled={!props.isValid || changingPassword}
                      className={`btn btn-red px-4 ${
                        !props.isValid ? "red-btn-disabled" : ""
                      }`}
                    >
                      Update Password {changingPassword && <Spin />}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
