import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../../components/partials/formikError";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { updateCompliance } from "../../../../store/actions/admin/senders";
import { useLocation } from "react-router-dom";

export default function Compliance() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { loading, profile, fetchingProfile } = useSelector(
    (state) => state.senders
  );

  const { msg } = useSelector((state) => state.error);

  return (
    <>
      {!fetchingProfile && profile && (
        <div class="row">
          <div class="col-lg-6">
            <Formik
              initialValues={{
                compliance_status: profile?.profile?.not_compliance_at,
                bound_direction: profile?.profile?.bound_direction,
                compliance_reason: profile?.profile?.compliance_reason,
              }}
              validationSchema={Yup.object({
                compliance_status:
                  Yup.string("Select status").required("Select status"),
                bound_direction: Yup.string("Select bound direction").required(
                  "Select bound direction"
                ),
                compliance_reason:
                  Yup.string("Enter reason").required("Enter reason"),
              })}
              onSubmit={(values) => {
                dispatch(
                  updateCompliance({
                    ...values,
                    uuid: location.state.uuid,
                  })
                );
              }}
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <div>
                      <div className="form-group mb-3">
                        <label htmlFor>Issue with compliance? </label>
                        <select
                          name="compliance_status"
                          defaultValue={props.values.compliance_status}
                          className="form-select min-height"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        >
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                        {props.touched.compliance_status &&
                          props.errors.compliance_status && (
                            <FormikError msg={props.errors.compliance_status} />
                          )}
                        <FormikError msg={msg?.compliance_status ?? ""} />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor>Select bound direction </label>
                        <select
                          name="bound_direction"
                          defaultValue={props.values.bound_direction}
                          className="form-select min-height"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        >
                          <option value="">--Select an option--</option>
                          <option value="out">Outbound</option>
                          <option value="in">Inbound</option>
                        </select>
                        {props.touched.bound_direction &&
                          props.errors.bound_direction && (
                            <FormikError msg={props.errors.bound_direction} />
                          )}
                        <FormikError msg={msg?.bound_direction ?? ""} />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor>Reason for compliance issue</label>
                        <textarea
                          rows={3}
                          className="form-control"
                          placeholder="Reason"
                          value={props.values.compliance_reason}
                          name="compliance_reason"
                          defaultValue={""}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        {props.touched.compliance_reason &&
                          props.errors.compliance_reason && (
                            <FormikError msg={props.errors.compliance_reason} />
                          )}
                      </div>
                      <button
                        className="btn btn-red min-height px-4"
                        disabled={loading}
                        type="submit"
                      >
                        Update {loading && <Spin />}
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
}
