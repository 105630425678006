import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  list,
  ban,
  suspend,
  activate,
  downloadTransaction,
  doBlacklist,
  search,
  forceFaceVerify,
  fetchSendersKpis,
} from "../../../store/actions/admin/senders";
import { Spin, Modal } from "antd";
import Pagination from "../../../components/pagination/pagination";
import moment from "moment";
import FormikError from "../../../components/partials/formikError";
import ConfirmForceVerifyModal from "../../../components/partials/modals/confirmForceVerifyModal";

export default function List() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const filterParam = searchParams.get("filter");

  useEffect(() => {
    if (searchParam && filterParam) {
      dispatch(
        search({
          param: searchParam,
          filter: filterParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(list(pageParam));
    }
    setCurrentPage(pageParam);
  }, [pageParam, searchParam, filterParam, dispatch]);

  const {
    senders,
    fetching,
    loadingAction,
    completedAction,
    blacklisting,
    blacklisted,
  } = useSelector((state) => state.senders);

  const [show, setShow] = useState(false);
  const [showForceVerifyModal, setShowForceVerifyModal] = useState({
    visible: false,
    fullName: "",
    uuid: "",
  });

  const [uuid, setUuid] = useState({});
  const [blaclist, setBlaclist] = useState({
    show: false,
    reason: "",
    uuid: "",
  });
  const [blackListErr, setBlaclistErr] = useState(false);
  const toggleModal = (id = "", type = "") => {
    setUuid({ uuid: id, type: type });
    setShow(!show);
  };

  useEffect(() => {
    if (completedAction) {
      setShow(false);
      dispatch(list(pageParam));
    }

    if (blacklisted) {
      document?.getElementById("blackListForm")?.reset();
      setBlaclist({ show: false, reason: "", uuid: "" });
      dispatch(list(pageParam));
    }
  }, [completedAction, blacklisted, pageParam]);

  useEffect(() => {
    dispatch(fetchSendersKpis);
  }, []);
  const modalAction = () => {
    if (uuid.type === "ban") dispatch(ban(uuid.uuid));
    if (uuid.type === "activate") dispatch(activate(uuid.uuid));
    if (uuid.type === "suspend") dispatch(suspend(uuid.uuid));
  };

  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user.permissions;

  return (
    <div className="table-responsive">
      {fetching && <Spin />}
      <table className="table table-borderless">
        <thead>
          <tr>
            <th className="text-muted text-uppercase">#</th>
            <th className="text-muted text-uppercase">member id</th>
            <th className="text-muted text-uppercase">name</th>
            <th className="text-muted text-uppercase">mobile phone</th>
            <th className="text-muted text-uppercase">email</th>
            <th className="text-muted text-uppercase">status</th>
            <th className="text-muted text-uppercase">Created at</th>
            {/* <th className="text-muted text-uppercase">id card</th> */}
            <th className="text-muted text-uppercase">action</th>
          </tr>
        </thead>
        <tbody>
          {/* row */}
          {senders.data?.map((el, i) => {
            let txt = "success";
            if (el.account_status === "banned") txt = "danger";
            if (el.account_status === "deactivated") txt = "danger";
            if (el.account_status === "suspended") txt = "warning";
            let count = i + 1;
            if (currentPage > 1) {
              if (count === 10) {
                count = Number(`${currentPage - 1}${i}`) + 1;
              } else {
                count = `${currentPage - 1}${count}`;
              }
            }

            // console.log(el?.profile);
            let black_listed = "";
            if (el.profile?.black_listed) {
              black_listed = "danger";
            }
            let fullName = "N/A";
            if (el?.profile?.firstname && el?.profile?.firstname) {
              fullName = el?.profile?.firstname + " " + el?.profile?.lastname;
            }
            return (
              <tr className="border-bottom" key={i}>
                <td>{count}</td>
                <td>#{el?.profile?.member_id}</td>
                <td>
                  <a
                    href="#view"
                    onClick={(e) => {
                      e.preventDefault();
                      // localStorage.setItem("senders_userUuid", el.uuid);
                      localStorage.setItem(
                        "senders_profile",
                        JSON.stringify({ ...el.profile, sender_uuid: el?.uuid })
                      );
                      // navigate("/senders/details");
                      navigate(`/senders/details`, {
                        state: { uuid: el.uuid },
                      });
                    }}
                  >
                    <span className={`${black_listed ? "text-danger" : ""}`}>
                      {fullName}
                    </span>
                  </a>{" "}
                  <br />
                  <small
                    className={`${
                      el?.profile?.face_verified_at
                        ? "text-success"
                        : "text-danger"
                    }`}
                  >
                    Face:{" "}
                    {el?.profile?.face_verified_at
                      ? `${
                          el?.profile?.face_verification_mode === "Auto"
                            ? "Auto"
                            : "Manually"
                        } Verified `
                      : "Unverified"}
                  </small>
                </td>
                <td>{el?.profile?.mobile_phone}</td>
                <td>{el.email}</td>
                <td>
                  <small
                    className={`px-4 py-2 status-width rounded alert-${txt} fw-bold text-capitalize`}
                  >
                    {el.account_status}
                  </small>
                </td>
                <td> {moment(el.created_at.date).format("YYYY-MM-DD")}</td>
                {/* <td>
                  <i className="fas fa-check-circle text-success" /> &nbsp;
                  <small className="text-success">confirmed</small>
                </td> */}
                <td>
                  <div className="dropdown">
                    <a
                      className="btn dropdown-btn"
                      href="#actions"
                      data-bs-toggle="dropdown"
                    >
                      <i className="fas fa-ellipsis-v" />
                    </a>
                    <ul className="dropdown-menu shadow-sm rounded">
                      {permissions["view user"] && (
                        <li>
                          <button
                            className="dropdown-item py-2"
                            // href="#"
                            onClick={() => {
                              // localStorage.setItem("senders_userUuid", el.uuid);
                              localStorage.setItem(
                                "senders_profile",
                                JSON.stringify({
                                  ...el.profile,
                                  sender_uuid: el?.uuid,
                                })
                              );
                              navigate(`/senders/details`, {
                                state: { uuid: el.uuid },
                              });
                            }}
                          >
                            View &amp; Edit
                          </button>
                        </li>
                      )}
                      <li>
                        <a
                          className="dropdown-item py-2"
                          href="#download"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(downloadTransaction(el.uuid));
                          }}
                        >
                          Download Transaction Report
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item py-2"
                          href="#download"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowForceVerifyModal({
                              visible: true,
                              fullName: fullName,
                              uuid: el.uuid,
                            });
                          }}
                        >
                          Force Verify Biometrics
                        </a>
                      </li>
                      {permissions["suspend"] && (
                        <li>
                          {el.account_status === "active" ? (
                            <a
                              className="dropdown-item py-2"
                              href="#suspend"
                              onClick={() => {
                                toggleModal(el.uuid, "suspend");
                              }}
                            >
                              Suspend User
                            </a>
                          ) : (
                            <a
                              className="dropdown-item py-2"
                              href="#suspend"
                              onClick={() => {
                                toggleModal(el.uuid, "activate");
                              }}
                            >
                              Activate
                            </a>
                          )}
                        </li>
                      )}
                      {el.account_status !== "banned" &&
                      permissions["blacklist"] ? (
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#ban"
                            onClick={() => {
                              toggleModal(el.uuid, "ban");
                            }}
                          >
                            Ban User
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {permissions["blacklist"] && (
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#ban"
                            onClick={() => {
                              setBlaclistErr(false);
                              setBlaclist({
                                show: true,
                                reason: "",
                                uuid: el.uuid,
                              });
                            }}
                          >
                            {el.profile.black_listed
                              ? "Unblacklist"
                              : "Blacklist"}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {senders?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (senders !== null && senders?.meta?.per_page) {
              if (searchParam && filterParam) {
                setSearchParams({
                  param: searchParam,
                  filter: filterParam,
                  page,
                });
                dispatch(
                  search({
                    param: searchParam,
                    filter: filterParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });
                dispatch(list(page));
              }
            }
          }}
          data={senders}
        />
      )}

      <Modal
        visible={show}
        onCancel={() => {
          toggleModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleModal();
            }}
            disabled={loadingAction}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={loadingAction}
            onClick={() => {
              modalAction();
            }}
          >
            Proceed {loadingAction ? <Spin /> : ""}
          </button>
        </div>
      </Modal>

      <Modal
        visible={blaclist.show}
        footer={false}
        onCancel={() => {
          setBlaclistErr(false);
          setBlaclist({ show: false, reason: "", uuid: "" });
        }}
      >
        <div className="form-group mb-3">
          <label htmlFor>Black list reason</label>
          <form id="blackListForm">
            <textarea
              rows={3}
              className="form-control"
              placeholder="Reason"
              name="reason"
              onChange={(e) => {
                setBlaclist({ ...blaclist, reason: e.target.value });
              }}
            />
          </form>

          {blackListErr && <FormikError msg="Enter reason" />}
        </div>
        <button
          className="btn btn-outline-red mx-2 px-4"
          type="button"
          onClick={() => {
            setBlaclist({ show: false, reason: "", uuid: "" });
          }}
          disabled={loadingAction}
        >
          Cancel
        </button>
        <button
          className="btn btn-red mx-2 px-4"
          type="button"
          disabled={loadingAction}
          onClick={() => {
            if (!blaclist.reason) {
              setBlaclistErr(true);
              return;
            } else {
              dispatch(doBlacklist(blaclist));
            }
          }}
        >
          Blacklist {blacklisting ? <Spin /> : ""}
        </button>
      </Modal>
      <ConfirmForceVerifyModal
        visible={showForceVerifyModal.visible}
        fullName={showForceVerifyModal.fullName}
        uuid={showForceVerifyModal.uuid}
        handleRefresh={() => {
          dispatch(list());
        }}
        handleClose={() => {
          setShowForceVerifyModal({ fullName: "", visible: false, uuid: "" });
        }}
      />
    </div>
  );
}
