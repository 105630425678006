import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Profile from "../pages/user/profile/index";
import Dashboard from "../pages/user/dashboard/index";
import Beneficiary from "../pages/user/beneficiary";
import CreateBeneficiary from "../pages/user/beneficiary/create";
import Settings from "../pages/user/settings";
import DocumentUpload from "../pages/user/documentUpload/index";
import Complete from "../pages/user/transaction/complete";
import Finish from "../pages/user/transaction/finish";
import TransactionFailed from "../pages/user/transaction/failed";
import TransactionSuccess from "../pages/user/transaction/success";
import TransactionPending from "../pages/user/transaction/pending";
import Transactions from "../pages/user/transaction/transactions";
import ShuftiRes from "../pages/user/profile/shuftiRes";
import ReverifyPhone from "../pages/auth/ReverifyPhone";
import ReverifyEmail from "../pages/auth/ReverifyEmail";
import Wallet from "../pages/user/wallet";
import TransactionReceiptNew from "../pages/admin/transaction/transactionReceiptNew";
import CurrencySwapSuccessModal from "../components/partials/walletComponents/modals/success/currencySwapSuccess";
import FundWalletBankTransferReceipt from "../components/partials/walletComponents/modals/fundYourWallet/fundWalletBankTranferReceipt";
import BusinessDashboardProfile from "../pages/user/businessAccount/businessDashboardProfile";
import { useSelector } from "react-redux";
import CreateBusinessAccount from "../pages/user/businessAccount/creatBusinessAccount";
import EditBusinessBeneficiaryAccount from "../pages/user/businessAccount/editBusinessAccountCreated";

export default function UserRoutes() {
  const user = JSON.parse(localStorage.getItem("authRes")) ?? {};
  if (!localStorage.getItem("popMessages")) {
    localStorage.setItem("popMessages", JSON.stringify(user.pop_messages));
  }

  let screen = <Dashboard />;

  if (!user.phone_otp_verified_at || !user.email_otp_verified_at) {
    if (!user.phone_otp_verified_at) {
      screen = <ReverifyPhone />;
    } else if (!user.email_otp_verified_at) {
      screen = <ReverifyEmail />;
    }
  }

  // else {
  //   if (
  //     !user.profile.address_details_at ||
  //     !user.profile.personal_details_at ||
  //     !user.profile.kyc_details_at
  //   ) {
  //     screen = <Profile />;
  //   }
  // }

  // let complete_trnsac = <TransactionReceiptNew />;
  // let complete_finish = <Complete />;

  // if (complete_finish && complete_finish.type === "submit") {
  // 	return complete_trnsac;
  // }

  return JSON.parse(localStorage.getItem("authRes")).profile_type ===
    "profiles" &&
    user.phone_otp_verified_at &&
    user.email_otp_verified_at ? (
    <Routes>
      <Route path="my-profile" element={<Profile />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="beneficiaries" element={<Beneficiary />} />
      <Route path="wallet" element={<Wallet />} />
      <Route path="settings" element={<Settings />} />
      <Route path="transactions" element={<Transactions />} />
      <Route exact path="add-new-beneficiary" element={<CreateBeneficiary />} />
      <Route exact path="complete-transaction" element={<Complete />} />
      <Route exact path="transaction-notice" element={<Finish />} />
      <Route exact path="document-upload" element={<DocumentUpload />} />
      <Route exact path="transaction-failed" element={<TransactionFailed />} />
      <Route
        exact
        path="business-account"
        element={<BusinessDashboardProfile />}
      />

      <Route
        exact
        path="create-business-account"
        element={
          user?.has_business_account ? (
            <Navigate replace to={"/business-account"} />
          ) : (
            <CreateBusinessAccount />
          )
        }
      />
      <Route
        exact
        path="edit-business-details"
        element={
          !user?.has_business_account ? (
            <Navigate replace to={"/create-business-account"} />
          ) : (
            <EditBusinessBeneficiaryAccount />
          )
        }
      />
      <Route
        exact
        path="transaction-receipt-completed"
        element={<TransactionReceiptNew />}
      />
      <Route
        exact
        path="transaction-success"
        element={<TransactionSuccess />}
      />
      <Route
        exact
        path="funding-receipt"
        element={<FundWalletBankTransferReceipt />}
      />
      <Route
        exact
        path="transaction-cancelled"
        element={<TransactionPending />}
      />

      <Route exact path="swap-receipt" element={<CurrencySwapSuccessModal />} />
      <Route exact path="shuft-res" element={<ShuftiRes />} />
      <Route path="*" element="404" />
    </Routes>
  ) : (
    ""
  );
}
