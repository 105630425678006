import * as actionTypes from "../../actionTypes/admin/popMessagesActionTypes";

export const popMessagesReducer = (
  state = {
    fetchingPopMessages: true,
    popMessages: {},
    savedPopMessages: false,
    savingPopMessages: false,
    deleting: false,
    deleted: false,
    toggling: false,
    toggled: false,
    updatingPopMessages: false,
    updatedPopMessages: false,
    showCreateModal: false,
    searchingPopMessages: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHING_POP_MESSAGES:
      return { ...state, fetchingPopMessages: action.payload };
    case actionTypes.SEARCHING_POP_MESSAGES:
      return { ...state, searchningPopMessages: !state.searchningPopMessages };
    case actionTypes.SHOW_CREATE_MODAL:
      return { ...state, showCreateModal: !state.showCreateModal };
    case actionTypes.UPDATING_POP_MESSAGES:
      return { ...state, updatingPopMessages: action.payload };
    case actionTypes.UPDATED_POP_MESSAGES:
      return { ...state, updatedPopMessages: action.payload };
    case actionTypes.SAVING_POP_MESSAGES:
      return { ...state, savingPopMessages: action.payload };
    case actionTypes.SAVED_POP_MESSAGES:
      return { ...state, savedPopMessages: action.payload };
    case actionTypes.DELETING_POP_MESSAGES:
      return { ...state, deleting: action.payload };
    case actionTypes.TOGGLING_POP_MESSAGES:
      return { ...state, toggling: action.payload };
    case actionTypes.TOGGLED_POP_MESSAGES:
      return { ...state, toggled: action.payload };
    case actionTypes.DELETED_POP_MESSAGES:
      return { ...state, deleted: action.payload };
    case actionTypes.SET_POP_MESSAGES:
      return { ...state, popMessages: action.payload };
    default:
      return state;
  }
};
