import React from "react";
import { useLocation } from "react-router-dom";

export default function Preview() {
  const {
    state: { files },
  } = useLocation();
  return (
    <div width="100%" height="100%">
      {files?.map((el, i) => {
        return (
          <a href={`${el.file_path}`} className="btn btn-red me-2" target="_blank">
            Click to view document {i + 1}
          </a>
        );
        // return <iframe src={`${el.file_path}`} title="file" key={i} style={{ height: '90vh', width: "90vw" }} target="_parent" />
      })}
    </div>
  );
}
