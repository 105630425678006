import React from 'react'

function TransferList() {
  return (
    <div>
      <table className="table table-borderless">
        <thead>
          <tr>
            <th className="text-muted text-uppercase">#</th>
            <th className="text-muted text-uppercase">Sender</th>
            <th className="text-muted text-uppercase">From</th>
            <th className="text-muted text-uppercase">
            To
            </th>
            <th className="text-muted text-uppercase">Amount</th>
            <th className="text-muted text-uppercase">Status</th>
            <th className="text-muted text-uppercase">created at</th>
            {/* <th className="text-muted text-uppercase">action</th> */}
          </tr>
        </thead>
        </table>
    </div>
  )
}

export default TransferList
