import * as actionTypes from "../../actionTypes/admin/accessControlActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";

export const fetchRoles = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_ROLES, payload: true });
    const res = await http.get("/admin/access_control/get_roles");
    const perm = await http.get("/admin/access_control/get_permissions");
    dispatch({ type: actionTypes.FETCHING_ROLES, payload: false });
    dispatch({ type: actionTypes.SET_ROLES, payload: res.data.data });
    dispatch({ type: actionTypes.SET_PERMISSIONS, payload: perm.data.data });
  } catch (err) {
    dispatch({ type: actionTypes.FETCHING_ROLES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const saveRole = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_ROLES, payload: true });
    dispatch({ type: actionTypes.SAVED_ROLES, payload: false });
    const res = await http.post("/admin/access_control/store_role", fd);
    dispatch({ type: actionTypes.SAVING_ROLES, payload: false });
    dispatch({ type: actionTypes.SAVED_ROLES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SAVING_ROLES, payload: false });
    dispatch({ type: actionTypes.SAVED_ROLES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const updateRole = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_ROLES, payload: true });
    dispatch({ type: actionTypes.UPDATED_ROLES, payload: false });
    const res = await http.post("/admin/access_control/update_role", fd);
    dispatch({ type: actionTypes.UPDATING_ROLES, payload: false });
    dispatch({ type: actionTypes.UPDATED_ROLES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_ROLES, payload: false });
    dispatch({ type: actionTypes.UPDATED_ROLES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const deleteRole = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETING_ROLES, payload: true });
    dispatch({ type: actionTypes.DELETED_ROLES, payload: false });
    const res = await http.post(`/admin/access_control/delete/${fd}`);
    dispatch({ type: actionTypes.DELETING_ROLES, payload: false });
    dispatch({ type: actionTypes.DELETED_ROLES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.DELETING_ROLES, payload: false });
    dispatch({ type: actionTypes.DELETED_ROLES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};
