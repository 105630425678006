import React from "react";
import cosmoLogo from "../../assets/images/components/privacyIcon.png";
function Terms() {
	return (
		<div>
			<div className="terms-container">
				<img className="privacy-image" src={cosmoLogo} alt="logo" srcset="" />
				<h1>Terms and Conditions</h1>

				<section>
					<p>
						Welcome to Cosmo Remit, a leading online remittance company
						providing inbound and outbound transaction services to our valued
						customers. These Terms and Conditions ("Terms") govern your use of
						our services, website, and any related applications or platforms
						(collectively referred to as the "Service"). By accessing or using
						our Service, you agree to be bound by these Terms. Please read them
						carefully.
					</p>
				</section>
				<section className="w-full">
					<ol className="justify ">
						<li>
							<b>Account Registration and User Obligations</b>
							<ul>
								<li>
									Eligibility: To use our Service, you must be at least 18 years
									old and have the legal capacity to enter into a contract.
								</li>
								<li>
									Account Registration: To access certain features of our
									Service, you must register an account with us. You agree to
									provide accurate, current, and complete information during the
									registration process and to keep your account information
									updated.
								</li>
								<li>
									User Responsibilities: You are responsible for maintaining the
									confidentiality of your account credentials, including your
									username and password. You agree to notify us immediately of
									any unauthorized access or use of your account.
								</li>
							</ul>
						</li>
						<li>
							<b> Service Description</b>
							<ul>
								<li>
									Inbound and Outbound Transactions: Our Service allows
									customers to send and receive remittances through online
									transfers. We facilitate the secure and efficient transfer of
									funds, subject to applicable laws and regulations.
								</li>
								<li>
									Transaction Limits and Fees: The maximum transaction limit and
									applicable fees may vary depending on factors such as user
									verification, recipient country, and regulatory requirements.
									It is your responsibility to review and understand the fees
									associated with each transaction before initiating it.
								</li>
								<li>
									Exchange Rates: Exchange rates may fluctuate and are subject
									to market conditions. The exchange rate provided at the time
									of transaction initiation will be applicable, and any
									difference resulting from subsequent fluctuations will not be
									refunded.
								</li>
								<li>
									Transaction Processing Time: While we strive to process
									transactions promptly, the actual processing time may vary
									depending on factors such as banking systems, intermediary
									institutions, and regulatory compliance. We will make
									reasonable efforts to complete transactions within the
									estimated timeframes provided, but we shall not be held liable
									for any delays beyond our control.
								</li>
							</ul>
						</li>
						<li>
							<b>Compliance with Laws and Regulations</b>
							<ul>
								<li>
									Regulatory Compliance: Our Service operates in accordance with
									applicable laws, regulations, and guidelines governing
									remittance services, anti-money laundering (AML), and
									combating the financing of terrorism (CFT). You agree to
									provide accurate and verifiable information as required for
									compliance purposes.
								</li>
								<li>
									Prohibited Activities: You shall not use our Service for any
									illegal, fraudulent, or unauthorized purposes. This includes,
									but is not limited to, money laundering, financing of illegal
									activities, or engaging in transactions with sanctioned
									entities or jurisdictions.
								</li>
							</ul>
						</li>
						<li>
							<b>Disclaimers and Limitations of Liability</b>
							<ul>
								<li>
									Service Availability: While we strive to provide uninterrupted
									access to our Service, we do not guarantee its availability at
									all times. We may temporarily suspend or limit access for
									maintenance, upgrades, security enhancements, or due to
									circumstances beyond our control.
								</li>
								<li>
									Third-Party Services: Our Service may include links or
									integration with third-party websites, applications, or
									services. We are not responsible for the content,
									functionality, or security of these third-party services. Your
									use of such services is at your own risk, and you are subject
									to their respective terms and conditions.
								</li>
								<li>
									Limitation of Liability: To the fullest extent permitted by
									applicable law, CosmoRemit shall not be liable for any direct,
									indirect, incidental, consequential, or punitive damages
									arising out of or in connection with the use of our Service,
									including but not limited to loss of profits, data, or other
									intangible losses.
								</li>
							</ul>
						</li>
						<li>
							<b>Termination</b>
							<ul>
								<li>
									Termination by User: You may terminate your CosmoRemit account
									at any time by following the designated procedures within the
									Service. Termination will result in the deactivation or
									deletion of your account information, subject to our data
									retention policy.
								</li>
								<li>
									Termination by CosmoRemit: We reserve the right to suspend or
									terminate your account and access to the Service at our sole
									discretion, without prior notice, if we have reasonable
									grounds to believe that you have violated these Terms or
									applicable laws and regulations.
								</li>
							</ul>
						</li>
						<li>
							<b>Modifications to the Terms</b>
							<ul>
								<li>
									We reserve the right to modify or update these Terms at any
									time. Any material changes will be notified to you through our
									Service or by other means of communication. Continued use of
									the Service after such modifications constitutes your
									acceptance of the revised Terms.
								</li>
							</ul>
						</li>
						<li>
							<b>Governing Law and Dispute Resolution</b>
							<ul>
								<li>
									Governing Law: These Terms shall be governed by and construed
									in accordance with the laws of AUSTRAC. Any disputes arising
									out of or in connection with these Terms shall be subject to
									the exclusive jurisdiction of the courts.
								</li>
							</ul>
						</li>
						<li>
							<b>Sending Money</b>
							<ul>
								<li>
									The sender is responsible for providing accurate and complete
									information for the transaction, including the recipient's
									details and transfer amount. The sender is responsible for any
									fees associated with the transaction.
								</li>
							</ul>
						</li>
						<li>
							<b>Receiving Money</b>
							<ul>
								<li>
									The recipient must present valid identification and
									transaction details to receive the funds.
								</li>
							</ul>
						</li>
						<li>
							<b>Cancellation and Refunds</b>
							<ul>
								<li>
									The sender may cancel a transaction before it is paid out to
									the recipient.
								</li>
								<li>
									Refunds, if applicable, will be processed in accordance with
									our refund policy.
								</li>
								<li>
									Cosmoremit relying solely on technology for transactions, this
									can introduce vulnerabilities, including glitches or errors
									that may impact the accuracy or integrity of transactions. In
									such cases, Cosmoremit will Void all the transactions affected
									by system glitches. This is a common practice aimed at
									maintaining fairness and integrity in the transaction process.
									This helps to prevent potential exploitation of glitches for
									personal gain or unintended consequences. However, Cosmoremit
									will communicate these policies clearly to you to ensure
									transparency and fairness in our services.
								</li>
							</ul>
						</li>
					</ol>

					<p>
						These Terms and Conditions constitute the entire agreement between
						you and CosmoRemit regarding the use of our Service. If any
						provision of these Terms is deemed invalid or unenforceable, the
						remaining provisions shall remain in full force and effect. If you
						have any questions or concerns regarding these Terms, please contact
						our customer support.
					</p>
				</section>
			</div>
		</div>
	);
}

export default Terms;
