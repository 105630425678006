import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { fetchStaffs, searchStaffs } from "../../../store/actions/admin/staffs";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../../components/pagination/pagination";

export default function List() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(null);

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const filterParam = searchParams.get("filter");
  const { savedStaffs, fetchingStaffs, staffs } = useSelector(
    (state) => state.staff
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (searchParam && filterParam) {
      dispatch(
        searchStaffs({
          param: searchParam,
          filter: filterParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(fetchStaffs(pageParam));
    }
    setCurrentPage(pageParam);
  }, [pageParam, searchParam, filterParam, dispatch]);

  useEffect(() => {
    if (savedStaffs) dispatch(fetchStaffs());
  }, [savedStaffs]);

  return (
    <>
      <div className="table-responsive">
        {fetchingStaffs && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">name</th>
              <th className="text-muted text-uppercase">email</th>
              <th className="text-muted text-uppercase">Gender</th>
              <th className="text-muted text-uppercase">ID Card</th>
              <th className="text-muted text-uppercase">Created at</th>
              <th className="text-muted text-uppercase">action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {staffs.data?.map((staff, index) => {
              let count = index + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${index}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom" key={index}>
                  <td>{count}</td>
                  <td>{staff.fullname}</td>
                  <td>{staff.email}</td>
                  <td>{staff.gender}</td>
                  <td>
                    <i className="fas fa-check-circle text-success" /> &nbsp;
                    <small className="text-success">
                      {staff.id_card_status}
                    </small>
                  </td>
                  <td>{staff.created_at}</td>
                  <td>
                    <a
                      href="edit"
                      className="btn btn-white"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("edit", { state: { staff } });
                      }}
                    >
                      View
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {staffs?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (staffs !== null && staffs?.meta?.per_page) {
              if (searchParam && filterParam) {
                setSearchParams({
                  param: searchParam,
                  filter: filterParam,
                  page,
                });
                dispatch(
                  searchStaffs({
                    param: searchParam,
                    filter: filterParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });
                dispatch(fetchStaffs(page));
              }
            }
          }}
          data={staffs}
        />
      )}
    </>
  );
}
