import React, { useEffect, useState } from "react";
import List from "./list";
import { Spin, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import FormikError from "../../../components/partials/formikError";
import * as Yup from "yup";
import { saveRole } from "../../../store/actions/admin/accessControl";

export default function AccessControl() {
  const [showModal, setShowModal] = useState(false);
  const { permissions, savingRoles, savedRoles, deleting } = useSelector(
    (state) => state.acl
  );
  const { msg } = useSelector((state) => state.error);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  useEffect(() => {
    if (savedRoles) {
      document.getElementById("aclForm").reset();
      setShowModal(false);
    }
  }, [savedRoles]);

  return (
    <div className="container">
      <h5 className="fw-bold">Roles</h5>
      <p className="text-muted mb-4">
        Only those granted access can visit the Access Control Manager.
      </p>
      <div className="text-end mb-3">
        <button className="btn btn-red px-5 min-height" onClick={toggleModal}>
          Create Role
        </button>
      </div>
      <List />
      <Modal
        visible={showModal}
        onCancel={() => {
          toggleModal();
        }}
        style={{
          top: 20,
        }}
        width={"80%"}
        footer={false}
      >
        <div>
          <h5 className="fw-bold">Create Role</h5>
          <Formik
            onSubmit={(values) => {
              dispatch(saveRole(values));
            }}
            initialValues={{ name: "", permissions: [] }}
            validationSchema={Yup.object({
              name: Yup.string("").required("enter role name"),
              permissions: Yup.array().min(1, "select permissions"),
            })}
          >
            {(props) => (
              <form id="aclForm" onSubmit={props.handleSubmit}>
                <div className="form-group mb-4">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    className="form-control min-height"
                    placeholder="eg. Rate Manager"
                  />
                  {props.touched.name && props.errors.name && (
                    <FormikError msg={props.errors.name} />
                  )}
                  <FormikError msg={msg?.name ?? ""} />
                </div>
                <div className="row">
                  {permissions.map((el, index) => (
                    <div className="col-lg-3 col-md-4 col-6" key={index}>
                      <div className="form-check form-switch mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              props.setValues({
                                ...props.values,
                                permissions: [
                                  ...props.values.permissions,
                                  e.target.value,
                                ],
                              });
                            } else {
                              let arr = props.values.permissions.filter(
                                (el) => el !== e.target.value
                              );
                              props.setValues({
                                ...props.values,
                                permissions: arr,
                              });
                            }
                          }}
                          name="permission[]"
                          value={el.id}
                          role="switch"
                          id={el.name}
                        />
                        <label
                          className="form-check-label text-lowercase"
                          htmlFor={el.name}
                        >
                          {el.name}
                        </label>
                      </div>
                    </div>
                  ))}
                  {props.touched.permissions && props.errors.permissions && (
                    <FormikError msg={props.errors.permissions} />
                  )}
                  <FormikError msg={msg?.permissions ?? ""} />
                </div>
                <div className="text-end">
                  <button
                    className="btn btn-outline-red ms-2 px-4"
                    onClick={() => {
                      document.getElementById("form").reset();
                      toggleModal();
                    }}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red ms-2 px-4"
                    disabled={savingRoles}
                    type="submit"
                  >
                    Submit {savingRoles && <Spin />}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>

      {/* delete modal */}
    </div>
  );
}
