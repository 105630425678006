import React, { useState } from "react";
import { usePagination, DOTS } from "./usePagination";
import classnames from "classnames";

const Pagination = props => {
  const { onPageChange, data } = props;

  const siblingCount = 1;
  const totalCount = data?.meta?.total;
  const [pageSize, setPageSize] = useState(data !== null ? data?.meta?.per_page : 10);
  let currentPage = data?.meta?.links?.filter(el => el.active);
  currentPage = parseInt(currentPage?.[0]?.label);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const pageSizeChangeHandler = e => {
    const size = e.target.value;
    setPageSize(size);
    onPageChange(currentPage, size);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];
  let pageOptions = [10, 20, 50, 100, 200];
  pageOptions = pageOptions.sort(function (a, b) {
    return a - b;
  });


  return (
    <div className="row">
      { /* number of pages */}
      {/* <div className="my-2 col-4 col-sm-2 col-md-2 col-xl-2">
        <select
          className="custom-select text-center"
          style={ { maxWidth: 80 } }
          onChange={ pageSizeChangeHandler }
        >
          { pageOptions.map((el, index) => {
            return (
              <option value={ el } key={ index }>
                { el }
              </option>
            );
          }) }
        </select>
      </div> */}
      { /* showing */}
      <div className="col-md-6">
        Showing 1 - {pageSize} of {data?.meta?.total} entries
      </div>
      { /* pagination nav */}
      <div className="col-md-6">
        <nav className="table-nav text-right">
          <ul className="pagination justify-content-end">
            <li className="page-item">
              <button
                className="page-link button"
                disabled={currentPage === 1}
                aria-label="Previous"
                onClick={onPrevious}
              >
                <span aria-hidden="true">Previous</span>
              </button>
            </li>
            {paginationRange?.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return DOTS;
                // return <li className="pagination-item dots">&#8230;</li>;
              }

              return (
                <li
                  className={classnames("page-item", {
                    active: pageNumber === currentPage,
                  })}
                  key={index}
                >
                  <button
                    className="page-link"
                    onClick={() => { onPageChange(pageNumber) }}
                  >
                    {pageNumber}
                  </button>
                </li>
              );
            })}

            <li className="page-item">
              <button
                className="page-link button"
                href="#"
                aria-label="Next"
                disabled={currentPage === lastPage}
                onClick={onNext}
              >
                <span aria-hidden="true">Next</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Pagination;