export const LOADING_COUNTRIES = "LOADING_COUNTRIES";
export const LOADING_STATES = "LOADING_STATES";
export const LOADING_ID_TYPES = "LOADING_ID_TYPES";
export const LOADING_BANKS = "LOADING_BANKS";
export const SET_STATES = "SET_STATES";
export const SET_ID_TYPES = "SET_ID_TYPES";
export const SET_BANKS = "SET_BANKS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const LOADING_REASONS = "LOADING_REASONS";
export const SET_REASONS = "SET_REASONS";
export const LOADING_PAYIN_CLASSS = "LOADING_PAYIN_CLASSS";
export const LOADING_PAYOUT_CLASSS = "LOADING_PAYOUT_CLASSS";
export const SET_PAYIN_CLASSES = "SET_PAYIN_CLASSES";
export const SET_PAYOUT_CLASSES = "SET_PAYOUT_CLASSES";
export const SET_RATED_COUNTRIES = "SET_RATED_COUNTRIES";
export const LOADING_RATED_COUNTRIES = "LOADING_RATED_COUNTRIES";
