import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../../components/partials/formikError";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import {
  saveLimit,
  fetchLimits,
} from "../../../../store/actions/admin/senders";
import Pagination from "../../../../components/pagination/pagination";
import moment from "moment";
import { useLocation } from "react-router-dom";

export default function Settings() {
  const dispatch = useDispatch();
  const { loading, limits, profile_transaction_limit_date, changed, fetchingLimits } = useSelector(
    (state) => state.senders
  );
  const { msg } = useSelector((state) => state.error);
  const location = useLocation();
  useEffect(() => {
    if (changed) {
      document.getElementById("settingsform").reset();
      dispatch(fetchLimits(1, location?.state?.uuid));
    }
  }, [changed]);

  useEffect(() => {
    dispatch(fetchLimits(1, location?.state?.uuid));
  }, []);

  return (
    <div>
      <h5 className="fw-bold">Local Transaction Limit</h5>
      <p className="text-muted">
        
        Local Transaction Limit is the maximum amount of money a user can send
        to other users within a specific period of time.{" "}
      </p>
      
      {!fetchingLimits && limits?.data?.length < 1 ? (
        <h6 className="fw-bold">Current limit date {profile_transaction_limit_date}</h6>
      ) : (
        <h6 className="fw-bold">
          Current limit starts on{" "}
          <span className="text-muted">{limits?.data?.[0]?.created_at}</span>{" "}
          and ends on{" "}
          <span className="text-muted">{limits?.data?.[0]?.limit_date}</span>
        </h6>
      )}
      <div className="row">
        <div className="col-lg-6 mb-3">
          <Formik
            validationSchema={Yup.object({
              limit_amount: Yup.string().required("Enter amount"),
              limit_days: Yup.string().required("Enter days"),
              times: Yup.string().required("Enter times"),
            })}
            initialValues={{ limit_amount: "", limit_days: "", times: "" }}
            onSubmit={(values) => {
              dispatch(saveLimit({ ...values, uuid: location.state.uuid }));
            }}
          >
            {(props) => {
              return (
                <form id="settingsform" onSubmit={props.handleSubmit}>
                  <div className="form-group mb-3">
                    <label>Country</label>
                    <select className="form-select min-height" disabled>
                      <option value>Select Country</option>
                      <option value="Australia">Australia</option>
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label>Local Limit Amount</label>
                    <input
                      type="number"
                      className="form-control min-height"
                      name="limit_amount"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.limit_amount &&
                      props.errors.limit_amount && (
                        <FormikError msg={props.errors.limit_amount} />
                      )}
                    <FormikError msg={msg?.limit_amount ?? ""} />
                  </div>
                  <div className="form-group mb-3">
                    <label>Transaction Limit Days</label>
                    <input
                      type="number"
                      className="form-control min-height"
                      name="limit_days"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.limit_days && props.errors.limit_days && (
                      <FormikError msg={props.errors.limit_days} />
                    )}
                    <FormikError msg={msg?.limit_days ?? ""} />
                  </div>
                  <div className="form-group mb-3">
                    <label>Transaction Limit Times</label>
                    <input
                      type="number"
                      className="form-control min-height"
                      name="times"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.times && props.errors.times && (
                      <FormikError msg={props.errors.times} />
                    )}
                    <FormikError msg={msg?.times ?? ""} />
                  </div>
                  <button
                    className="btn btn-red px-4 min-height"
                    disabled={loading}
                    type="submit"
                  >
                    Update {loading && <Spin />}
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
        <div className="col-lg-6 mb-3">
          <h6 className="fw-bold mb-4">User Transaction Limit History</h6>
          <div className="table-responsive">
            {fetchingLimits && <Spin />}
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th className="text-muted text-uppercase">#</th>
                  <th className="text-muted text-uppercase">Limit amount</th>
                  <th className="text-muted text-uppercase">Limit end date</th>
                  <th className="text-muted text-uppercase">created at</th>
                </tr>
              </thead>
              <tbody>
                {limits?.data?.map((el, i) => {
                  return (
                    <tr key={`lim${i}`} className="border-bottom">
                      <td>{i + 1}</td>
                      <td>{el.limit_amount}</td>
                      <td> {moment(el.limit_date).format("YYYY-MM-DD")}</td>
                      <td>{el.created_at}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {limits?.links && (
            <Pagination
              onPageChange={(page) => {
                if (limits !== null && limits.per_page) {
                  dispatch(fetchLimits(page, location.state.uuid));
                }
              }}
              data={limits}
            />
          )}
        </div>
      </div>
    </div>
  );
}
