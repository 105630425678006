import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Spin } from "antd";
import {
  verifyEmail,
  changeMyEmail,
  resendEmailOtp,
  logout,
} from "../../../store/actions/authActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { useNavigate } from "react-router-dom";

export default function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const { regRes, loading, changedEmail, resendingOtp } = useSelector(
    (state) => state.signup
  );
  const { msg } = useSelector((state) => state.error);
  const [newEmail, setNewEmail] = useState();
  const authRes = JSON.parse(localStorage.getItem("authRes"));

  const toggleModal = (e) => {
    e.preventDefault();
    dispatch({ type: "CHANGED_EMAIL", payload: false });
    setShowModal(!showModal);
  };

  // const counter = setInterval(() => {
  //   const num = countDown - 1;
  //   if (countDown > 1) setCountDown(num);
  // }, 1000);

  // if (countDown <= 0) clearInterval(counter);

  const schema = Yup.object({
    email_otp: Yup.string("Enter otp").required("Enter otp"),
  });

  const { handleSubmit, handleChange, handleBlur, touched, errors } = useFormik(
    {
      initialValues: {
        email_otp: "",
      },
      validationSchema: schema,
      onSubmit: (values) => {
        dispatch(
          verifyEmail({
            ...values,
          })
        );
      },
    }
  );

  const schema2 = Yup.object({
    email: Yup.string("Enter your email or phone number")
      .required("Email is required")

      .test("Email", "Enter a valid email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        let isValidEmail = emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        return true;
      }),
  });

  const changeEmail = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: schema2,
    onSubmit: (values) => {
      dispatch(changeMyEmail(values));
      setNewEmail(values.email);
    },
  });

  return (
    <>
      <div className="text-center mt-5 mb-4">
        <h2 className="header-4 fw-bold">Verify Email</h2>
        <p className="text-muted mb-0">
          We’ve sent a six-digit verification code to{" "}
          <span className="text-dark">
            {newEmail
              ? newEmail
              : regRes?.email
              ? regRes?.email
              : authRes?.email}
          </span>
          .
        </p>
        <p className="text-muted mb-5">
          Wrong email?{" "}
          <a
            href="#changeEmailModal"
            className="text-red"
            onClick={(e) => {
              toggleModal(e);
            }}
          >
            Change
          </a>{" "}
          |{" "}
          <a
            className="text-red"
            onClick={(e) => {
              e.preventDefault();
              dispatch(logout);
              navigate("login");
            }}
          >
            Logout
          </a>
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          {/* Six digit code */}
          <div className="col-lg-6">
            <div className="form-group mb-4">
              <label className="mb-2">Six digit code</label>
              <input
                type="text"
                className="form-control min-height"
                onChange={handleChange}
                onBlur={handleBlur}
                name="email_otp"
              />
              {touched.email_otp && errors.email_otp && (
                <FormikError msg={errors.email_otp} />
              )}
            </div>
            <button
              className="btn btn-red min-height w-100 mb-4"
              disabled={loading}
            >
              Verify {loading && <Spin />}
            </button>
            <p className="text-muted mt-2 text-center">
              Didn't recieve the code?
            </p>
            <button
              className="btn btn-outline-red min-height w-100"
              onClick={(e) => {
                e.preventDefault();
                dispatch(resendEmailOtp);
              }}
              disabled={resendingOtp || countDown > 1}
            >
              Resend code &nbsp; {countDown > 1 && `${countDown}s`}{" "}
              {resendingOtp && <Spin />}
            </button>
          </div>
        </div>
      </form>

      {/* change email modal */}
      <Modal
        visible={showModal && !changedEmail}
        footer={false}
        onCancel={(e) => {
          if (loading) return;
          toggleModal(e);
        }}
      >
        <div className="row justify-content-center mt-3">
          <div className="col-lg-11">
            <h6 className="fw-bold">Change Email</h6>
            <p className="text-muted">Type in your correct email address</p>
            <form onSubmit={changeEmail.handleSubmit}>
              <div className="form-group mb-4">
                <label className="mb-2">Email Address</label>
                <input
                  type="text"
                  className="form-control min-height"
                  name="email"
                  onChange={changeEmail.handleChange}
                  onBlur={changeEmail.handleBlur}
                />
                {changeEmail.touched.email && changeEmail.errors.email && (
                  <FormikError msg={changeEmail.errors.email} />
                )}
                <FormikError msg={msg?.email ?? ""} />
              </div>
              <button
                className="btn btn-red min-height w-100"
                // disabled={loading}
                type="button"
              >
                Confirm {loading && <Spin />}
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
