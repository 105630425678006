import * as actionTypes from "../../actionTypes/admin/payoutActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";

export const list =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_PAYOUT, payload: true });
      dispatch({ type: actionTypes.SAVED_PAYOUT, payload: false });
      const res = await http.get(`/admin/payouts/list/?paginate=1&per_page=10&page=${page}`);
      dispatch({ type: actionTypes.FETCHING_PAYOUT, payload: false });
      dispatch({
        type: actionTypes.SET_PAYOUT,
        payload: res.data.data.payouts,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_PAYOUT, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const getAllPayouts = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_PAYOUT, payload: true });
    dispatch({ type: actionTypes.SAVED_PAYOUT, payload: false });
    const res = await http.get(`/admin/payouts/list/?paginate=0&per_page=10`);
    dispatch({ type: actionTypes.FETCHING_PAYOUT, payload: false });
    dispatch({
      type: actionTypes.SET_PAYOUT,
      payload: res.data.data.payouts,
    });
  } catch (err) {
    dispatch({ type: actionTypes.FETCHING_PAYOUT, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};
export const create = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_PAYOUT, payload: true });
    const res = await http.post("admin/payouts/create_payout", fd);
    dispatch({ type: actionTypes.SAVING_PAYOUT, payload: false });
    dispatch({ type: actionTypes.SAVED_PAYOUT, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SAVING_PAYOUT, payload: false });
    dispatch({ type: actionTypes.SAVED_PAYOUT, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const update = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_PAYOUT, payload: true });
    dispatch({ type: actionTypes.UPDATED_PAYOUT, payload: false });
    const res = await http.post("admin/payouts/update_payout", fd);
    dispatch({ type: actionTypes.UPDATING_PAYOUT, payload: false });
    dispatch({ type: actionTypes.UPDATED_PAYOUT, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_PAYOUT, payload: false });
    dispatch({ type: actionTypes.UPDATED_PAYOUT, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleCreatModal = () => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_CREATE_MODAL });
};

export const _delete = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETING_PAYOUT, payload: true });
    dispatch({ type: actionTypes.DELETED_PAYOUT, payload: false });
    const res = await http.get(`admin/payouts/delete/${fd}`);
    dispatch({ type: actionTypes.DELETING_PAYOUT, payload: false });
    dispatch({ type: actionTypes.DELETED_PAYOUT, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.DELETING_PAYOUT, payload: false });
    dispatch({ type: actionTypes.DELETED_PAYOUT, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleStatus = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.TOGGLING_PAYOUT, payload: true });
    dispatch({ type: actionTypes.TOGGLED_PAYOUT, payload: false });
    const res = await http.get(`admin/payouts/toggle_pop/${fd}`);
    dispatch({ type: actionTypes.TOGGLING_PAYOUT, payload: false });
    dispatch({ type: actionTypes.TOGGLED_PAYOUT, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.TOGGLING_PAYOUT, payload: false });
    dispatch({ type: actionTypes.TOGGLED_PAYOUT, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const search = (params) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SEARCHING_PAYOUT });
    const res = await http.post(`admin/payouts/search?page=${params?.page}`, {
      param: params.param,
    });
    dispatch({ type: actionTypes.SET_PAYOUT, payload: res.data.data.payouts });
    dispatch({ type: actionTypes.SEARCHING_PAYOUT });
  } catch (error) {
    dispatch({ type: actionTypes.SEARCHING_PAYOUT });
  }
};
