import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import { signupReducer, loginReducer } from "./reducers/authReducer";
import errorReducer from "./reducers/errorReducer";
import {
  kycReducer,
  personalReducer,
  addressReducer,
  outBoundReducer,
} from "./reducers/profileReducer";

import {
  admin_occupation_industry,
  admin_senderReducer,
  fetch_rateds_countries__admin_reducer,
  // admin_submitted_outObund_user_reducer,
} from "./reducers/admin/senderReducer";
import { generalReducer } from "./reducers/generalReducer";
import { documentUploadReducer } from "./reducers/documentUploadReducer";
import {
  indexReducer,
  saveBeneficiaryReducer,
  deleteBeneficiaryReducer,
  editBeneficiaryReducer,
} from "./reducers/beneficiaryReducer";
import { settingsReducer } from "./reducers/settingsReducer";
import { aclReducer } from "./reducers/admin/accessControlReducer";
import { staffReducer } from "./reducers/admin/staffReducer";
import { ratesReducer } from "./reducers/admin/ratesReducer";
import { limitReducer } from "./reducers/admin/limitReducer";
import { popMessagesReducer } from "./reducers/admin/popMessagesReducer";
import { payinReducer } from "./reducers/admin/payinReducer";
import { payoutReducer } from "./reducers/admin/payoutReducer";
import { senderReducer } from "./reducers/admin/sendersReducer";
import { transactionReducer } from "./reducers/transactionReducer";
import { uploadsReducer } from "./reducers/admin/uploadsReducer";
import { adminTrnxReducer } from "./reducers/admin/transactionsReducer";
import { walletReducer } from "./reducers/walletReducer";
import {
  businessRegistrationReducer,
  businessVerificationReducer,
  deactivateBusinessAccount,
  editDetailsReducer,
  fetchBusinessDataDetails,
} from "./reducers/businessReducer";
import { businessReducer } from "./reducers/admin/businessReducer";

export const reducer = combineReducers({
  signup: signupReducer,
  auth: loginReducer,
  error: errorReducer,
  kyc: kycReducer,
  address: addressReducer,
  personal: personalReducer,
  outbound: outBoundReducer,
  general: generalReducer,
  beneficiaryIndex: indexReducer,
  saveBeneficiary: saveBeneficiaryReducer,
  deleteBeneficiary: deleteBeneficiaryReducer,
  editBeneficiary: editBeneficiaryReducer,
  settings: settingsReducer,
  documentUpload: documentUploadReducer,
  acl: aclReducer,
  staff: staffReducer,
  rates: ratesReducer,
  limits: limitReducer,
  popMessages: popMessagesReducer,
  payins: payinReducer,
  payouts: payoutReducer,
  senders: senderReducer,
  transaction: transactionReducer,
  uploads: uploadsReducer,
  adminTransactions: adminTrnxReducer,
  wallet: walletReducer,
  admin__occupate_industry: admin_occupation_industry,
  admin_senderOutbound: admin_senderReducer,
  rated_countries_admin: fetch_rateds_countries__admin_reducer,
  businessRegistration: businessRegistrationReducer,
  edit_business_details: editDetailsReducer,
  fetchBusinessDataStore: fetchBusinessDataDetails,
  deactivatingBusinessAccount: deactivateBusinessAccount,
  adminBusiness: businessReducer,
});

export const middleware = [thunk];

export const store = createStore(reducer, applyMiddleware(...middleware));
