import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _kpis } from "../../../store/actions/admin/transactions";
export default function Dashboard() {
  const { kpis } = useSelector((state) => state.adminTransactions);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(_kpis);
  }, []);

  return (
    <div className="container-fluid">
      {/* DASHBOARD STATISTICS */}
      <h6 className="fw-bold text-dark mb-4">Dashboard Statistics</h6>
      <div className="row mb-5">
        {/* dashboard card */}
        <div className="col-xl-3 col-sm-6">
          <div className="card rounded my-3">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <small className="text-muted">Total Senders</small> <br />
                  <h2 className="text-dark mb-0 d-inline-block">
                    {new Intl.NumberFormat(undefined).format(
                      kpis?.senders_data?.total_senders ?? 0
                    )}
                       
                  </h2>{" "}
                  &nbsp;{" "}
                  <span className="badge alert-success text-success">
                    +{" "}
                    {`${new Intl.NumberFormat(undefined).format(
                      kpis?.senders_data?.total_senders_plus ?? 0
                    )}
                       `}
                  </span>
                  <br />
                  {/* <small className="text-muted">This month</small> */}
                </div>
                <div className="dashboard-icon">
                  <i className="fas fa-users" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* dashboard card */}
        <div className="col-xl-3 col-sm-6">
          <div className="card rounded my-3">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <small className="text-muted">Pending Transactions</small>{" "}
                  <br />
                  <h2 className="text-dark mb-0 d-inline-block">
                    {`${new Intl.NumberFormat(undefined).format(
                      kpis?.transactions_data?.total_pending ?? 0
                    )}
                       `}
                  </h2>{" "}
                  &nbsp;{" "}
                  <span className="badge alert-warning text-warning">
                    +
                    {`${new Intl.NumberFormat(undefined).format(
                      kpis?.transactions_data?.total_pending_plus ?? 0
                    )}
                       `}
                  </span>
                  <br />
                  {/* <small className="text-muted">This month</small> */}
                </div>
                <div className="dashboard-icon">
                  <i className="fas fa-th-list text-light-red" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* dashboard card */}
        <div className="col-xl-3 col-sm-6">
          <div className="card rounded my-3">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <small className="text-muted">Completed Transactions</small>{" "}
                  <br />
                  <h2 className="text-dark mb-0 d-inline-block">
                    {`${new Intl.NumberFormat(undefined).format(
                      kpis?.transactions_data?.total_completed ?? 0
                    )}
                       `}
                  </h2>{" "}
                  &nbsp;{" "}
                  <span className="badge alert-success text-success">
                    +
                    {`${new Intl.NumberFormat(undefined).format(
                      kpis?.transactions_data?.total_completed_plus ?? 0
                    )}
                       `}
                  </span>
                  <br />
                  {/* <small className="text-muted">This month</small> */}
                </div>
                <div className="dashboard-icon">
                  <i className="fas fa-check-circle" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* dashboard card */}
        <div className="col-xl-3 col-sm-6">
          <div className="card rounded my-3">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <small className="text-muted">Logged Users</small> <br />
                  <h2 className="text-dark mb-0 d-inline-block">
                    67
                  </h2> &nbsp;{" "}
                  <span className="badge alert-success text-success">+4</span>
                  <br />
                  {/* <small className="text-muted">This month</small> */}
                </div>
                <div className="dashboard-icon">
                  <i className="fas fa-chart-gantt text-light-red" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mb-5" />
      <div className="row">
        <div className="col-xl-7">
          <div className="d-flex flex-wrap justify-content-between mb-3">
            <div>
              <h6 className="fw-bold text-dark">Analytics</h6>
              <small className="text-muted">
                Successful transactions record
              </small>
            </div>
            <div className="d-inline-block">
              <select className="form-select d-inline-block">
                <option value={2022}>This year</option>
                <option value={2021}>Last year</option>
                <option value={2019}>2019</option>
                <option value={2018}>2018</option>
                <option value={2017}>2017</option>
                <option value={2016}>2016</option>
              </select>
            </div>
          </div>
          <div className="card rounded mb-3">
            <div className="card-body">
              <div className="my-3">
                <h4 className="fw-bold mb-0">$10,000</h4>
                <small className="text-muted">Total Amount Recieved</small>
              </div>
              <canvas id="line-chartcanvas" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
        <div className="col-xl-5">
          <div className="d-flex flex-wrap justify-content-between mb-3">
            <div>
              <h6 className="fw-bold text-dark">Recent Transactions</h6>
              <small className="text-muted">
                Here is a list of most recent transactions
              </small>
            </div>
            <a href="#" className="text-red">
              View record
            </a>
          </div>
          {/* recent transaction */}
          <div className="card rounded mb-3">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-inline-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="name-card fw-bold text-red">AA</span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <span className="d-block mb-1">Oreoluwa Jolaoso James</span>
                    <p className="text-muted mb-0">
                      <span className="pe-2">NGN account</span>
                      <span className="pe-2">USD account</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h6 className="fw-bold mb-0">$5,000</h6>
                  <small className="text-muted">a day ago</small>
                </div>
              </div>
            </div>
          </div>
          {/* recent transaction */}
          <div className="card rounded mb-3">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-inline-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="name-card fw-bold text-red">AA</span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <span className="d-block mb-1">Oreoluwa Jolaoso James</span>
                    <p className="text-muted mb-0">
                      <span className="pe-2">NGN account</span>
                      <span className="pe-2">USD account</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h6 className="fw-bold mb-0">$5,000</h6>
                  <small className="text-muted">a day ago</small>
                </div>
              </div>
            </div>
          </div>
          {/* recent transaction */}
          <div className="card rounded mb-3">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-inline-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="name-card fw-bold text-red">AA</span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <span className="d-block mb-1">Oreoluwa Jolaoso James</span>
                    <p className="text-muted mb-0">
                      <span className="pe-2">NGN account</span>
                      <span className="pe-2">USD account</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h6 className="fw-bold mb-0">$5,000</h6>
                  <small className="text-muted">a day ago</small>
                </div>
              </div>
            </div>
          </div>
          {/* recent transaction */}
          <div className="card rounded mb-3">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-inline-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="name-card fw-bold text-red">AA</span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <span className="d-block mb-1">Oreoluwa Jolaoso James</span>
                    <p className="text-muted mb-0">
                      <span className="pe-2">NGN account</span>
                      <span className="pe-2">USD account</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h6 className="fw-bold mb-0">$5,000</h6>
                  <small className="text-muted">a day ago</small>
                </div>
              </div>
            </div>
          </div>
          {/* recent transaction */}
          <div className="card rounded mb-3">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-inline-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span className="name-card fw-bold text-red">AA</span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <span className="d-block mb-1">Oreoluwa Jolaoso James</span>
                    <p className="text-muted mb-0">
                      <span className="pe-2">NGN account</span>
                      <span className="pe-2">USD account</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h6 className="fw-bold mb-0">$5,000</h6>
                  <small className="text-muted">a day ago</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
