import { Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import "../../assets/css/filterSelectwithCount.css";
import Completed from "../../pages/admin/transaction/completed";
import * as actionTypes from "../../store/actionTypes/admin/transactionActionTypes";
import { useSearchParams } from "react-router-dom";
const { Option } = Select;

const FilterSelectWithCount = ({
  options = [],
  placeholder,
  callback,
  status,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [filterError, setFilterError] = useState(false);
  const [filter, setFilter] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [param, setParam] = useState("");
  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const getPopupContainer = (node) => {
    return node.parentNode;
  };
  const selectWithCountHandler = (value) => {
    if (value) setFilterError(false);
    setFilter(value);
    dispatch({ type: actionTypes.SET_TRANSACTION_COUNTRY, payload: value });

    debounced();
  };
  const dispatch = useDispatch();

  const debounced = useDebouncedCallback(() => {
    setFilterError(false);
    if (!filter) {
      setFilterError(true);
      return;
    }

    if (filter === "0") {
      dispatch(callback("", status)); // Passing an empty string as country_code to indicate all countries
      setSearchParams({ param: "" });
      searchParams.delete("page");
      setSearchParams(searchParams);
    } else {
      const selectedOption = options.find((option) => option.value === filter);
      if (selectedOption) {
        const { id } = selectedOption;
        dispatch(callback(id, status));
      }
    }
  }, 1000);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );
  return (
    <Select
      showSearch
      placeholder={placeholder}
      onSelect={(value) => {
        searchParams.delete("param");
        searchParams.delete("page");
        setSearchParams(searchParams);
        selectWithCountHandler(value);
      }}
      dropdownClassName="custom-dropdown"
      style={{
        color: "black",
      }}
      className="form-control border-start-0 min-height"
      getPopupContainer={getPopupContainer}
      filterOption={false}
      onSearch={handleSearch}
    >
      <Option value="0">All Countries</Option>
      {filteredOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          <div className="option-label">
            <span
              className={`flag-icon flag-icon-${option.flag.toLowerCase()} flag-icon-squared`}
            ></span>
            &nbsp;&nbsp;{option.label}
          </div>
          <span className="badge option-count rounded-1 bg-secondary">
            {option.count}
          </span>
          {/* <div className="option-count">{option.count}</div> */}
        </Option>
      ))}
    </Select>
  );
};

export default FilterSelectWithCount;
