import React, { useEffect, useState } from "react";
import LandingSendCard from "../../components/countrySpecificComponents/general/landingsendCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import framed from "../../assets/images/components/Framed.png";
import AustraliaRegulator from "../../components/countrySpecificComponents/regulators/australiaRegulator";
import CanadaRegulator from "../../components/countrySpecificComponents/regulators/canadaRegulator";
import { Card, Carousel } from "antd";
import BlogCard from "../../components/common/BlogCard";

const countryComponents = {
	au: {
		name: "Australia",
		regulator: AustraliaRegulator,
	},
	ca: {
		name: "Canada",
		regulator: CanadaRegulator,
		email: "office@cosmoremit.ca",
		address: "111 2nd Avenue South, Unit 400, Saskatoon S7K 1K6",
		phone: [{ number: "+13063615977", extraText: "" }],
	},
	// Add entries for other countries and their respective components
};

export default function Home() {
	const { Meta } = Card;
	const [currentCountry, setCurrentCountry] = useState("au");

	const blogPosts = [
		{
			title: "The Benefits of Remittance Apps",
			img: "https://blog.cosmoremit.com.au/wp-content/uploads/2024/07/tech-daily-V06dt37iQFY-unsplash-768x512.jpg",
			description:
				"Remittance apps have revolutionized the way people transfer money across borders, providing numerous advantages over traditional methods....",
			url: "https://blog.cosmoremit.com.au/the-benefits-of-remittance-apps/51/",
		},
	];
	useEffect(() => {
		const currentDomain = window.location.hostname;

		if (currentDomain.includes(".au")) {
			setCurrentCountry("au");
		}

		if (currentDomain.includes(".ca")) {
			setCurrentCountry("ca");
		}
	}, []);
	const CountryLandingComponent = countryComponents[currentCountry];

	const onChange = (currentSlide) => {
		console.log(currentSlide);
	};
	return (
		<>
			<div>
				<section className="hero">
					<div className="container">
						{/* hero background contents */}
						<div className="hero-bg-content">
							<img
								draggable="false"
								src="assets/images/components/Frame.svg"
								className="img-fluid hero-map"
								alt="cosmoremit "
							/>
							<img
								draggable="false"
								src="assets/images/components/Vector(1).svg"
								className="img-fluid hero-vector-1"
								alt="cosmoremit "
							/>
							<img
								draggable="false"
								src="assets/images/components/Vector.svg"
								className="img-fluid hero-vector-2"
								alt="cosmoremit "
							/>
						</div>
						{/* hero content... Texts and others */}
						<div className="hero-content">
							<div className="row justify-content-center">
								<div className="col-lg-6 my-3">
									<h1 className="fw-bold text-dark text-capitalize">
										Send Money <br /> Without Borders
									</h1>
									<p className="text-dark py-2">
										Our industry-leading technology protects your money and{" "}
										<br /> guarantees it arrives safely everytime
									</p>
									{CountryLandingComponent && (
										<CountryLandingComponent.regulator />
									)}
								</div>
								<LandingSendCard />
							</div>
						</div>
					</div>
				</section>
				{/*  
W H Y    C H O O S E     C O S M O  R E M I T
*/}
				<section className="section">
					<div className="container">
						<h2 className="header-2 fw-bold mb-5">
							Why Choose <br /> CosmoRemit
						</h2>
						<div className="row">
							{/* item one */}
							<div className="col-lg-4">
								<div className="card why-choose my-3 border-0 rounded">
									<div className="card-body px-md-4 py-5">
										{/* background-icon */}
										<div className="position-absolute bg-icon">
											<img
												draggable="false"
												src="assets/images/icon/WhyChooseRemitbg1.png"
												alt="cosmoremit "
											/>
										</div>
										{/* card icon */}
										<img
											draggable="false"
											src="assets/images/icon/whyChooseRemit 1.png"
											alt="cosmoremit "
											width
											className="img-fluid card-icon"
										/>
										<img
											draggable="false"
											src="assets/images/icon/whyChooseRemit 1 - red.png"
											alt="cosmoremit "
											width={68}
											className="img-fluid card-icon-red"
										/>
										<h3 className="header-4 fw-bold my-5">
											Unbeatable <br /> Rates
										</h3>
										<p>
											We help customers save money by offering excellent
											exchange rates (bank-beating exchange rates). We are also
											transparent about pricing and fees (no hidden charges).
										</p>
									</div>
								</div>
							</div>
							{/* item two */}
							<div className="col-lg-4">
								<div className="card why-choose my-3 border-0 rounded">
									<div className="card-body px-md-4 py-5">
										{/* background-icon */}
										<div className="position-absolute bg-icon">
											<img
												draggable="false"
												src="assets/images/icon/WhyChooseRemitbg2.png"
												alt="cosmoremit "
											/>
										</div>
										{/* card icon */}
										<img
											draggable="false"
											src="assets/images/icon/whyChooseRemit 2.png"
											alt="cosmoremit "
											width
											className="img-fluid card-icon"
										/>
										<img
											draggable="false"
											src="assets/images/icon/whyChooseRemit 2 - red.png"
											alt="cosmoremit "
											width={48}
											className="img-fluid card-icon-red"
										/>
										<h3 className="header-4 fw-bold my-5">
											Extremely <br /> Fast
										</h3>
										<p>
											We help you to make fast, secure, and hassle-free
											transfers to beneficiaries overseas within minutes, 24/7.
										</p>
									</div>
								</div>
							</div>
							{/* item three */}
							<div className="col-lg-4">
								<div className="card why-choose my-3 border-0 rounded">
									<div className="card-body px-md-4 py-5">
										{/* background-icon */}
										<div className="position-absolute bg-icon">
											<img
												draggable="false"
												src="assets/images/icon/WhyChooseRemitbg3.png"
												alt="cosmoremit "
											/>
										</div>
										{/* card icon */}
										<img
											draggable="false"
											src="assets/images/icon/whyChooseRemit 3.png"
											alt="cosmoremit "
											width
											className="img-fluid card-icon"
										/>
										<img
											draggable="false"
											src="assets/images/icon/whyChooseRemit 3 - red.png"
											alt="cosmoremit "
											width={51}
											className="img-fluid card-icon-red"
										/>
										<h3 className="header-4 fw-bold my-5">
											Secure and <br /> Regulated
										</h3>
										<p>
											CosmoRemit is a duly licensed and credible partner to
											choose as a money remittance provider. We are regulated as
											a money transfer service and partner with financial
											institutions worldwide. We protect our customers and their
											privacy using multiple levels of security and secure
											payment technologies..
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* 
C O M P A N I E S    T H A T     T R U S T     C O S M O R E M I T
*/}
				<section className="section">
					<div className="container">
						<h2 className="text-center fw-bold mb-5">
							Companies that trust CosmoRemit
						</h2>
						<OwlCarousel
							className="owl-carousel owl-theme"
							autoplay={true}
							loop={true}
							margin={10}
							nav={false}
							responsive={{
								0: {
									items: 2,
								},
								768: {
									items: 3,
								},
								992: {
									items: 5,
								},
							}}
						>
							<div className="item">
								<img
									draggable="false"
									src="assets/images/logo/image 6.png"
									alt=""
									width="50"
								/>
							</div>

							{/* <div className="item">
								<img
									draggable="false"
									src="assets/images/logo/image 7.png"
									alt=""
									width="50"
								/>
							</div> */}

							<div className="item">
								<img
									draggable="false"
									src="assets/images/logo/image 10.png"
									alt=""
									width="50"
								/>
							</div>

							<div className="item">
								<img
									draggable="false"
									src="assets/images/logo/image 8.png"
									alt=""
									width="50"
								/>
							</div>
							{/* 
							<div className="item">
								<img
									draggable="false"
									src="assets/images/logo/image 11.png"
									alt=""
									width="50"
								/>
							</div> */}

							{/* <div className="item">
								<img
									draggable="false"
									src="assets/images/logo/Ripple.png"
									alt=""
									width="50"
								/>
							</div> */}
						</OwlCarousel>
					</div>
				</section>
				{/* 
H O W    D O E S    I T    W O R K ?
*/}
				<section className="section">
					<div className="container-fluid">
						<div className="row align-items-lg-center">
							<div className="col-xl-4 col-lg-5 col-md-6 ps-md-0">
								<div className="card my-3 bg-linear-pink border-0 shadow rounded">
									<div className="card-body px-md-4 pt-5 pb-0">
										<div className="row justify-content-end">
											<div className="col-lg-10">
												<h2 className="header-3 text-red fw-bold my-5">
													How does it work
												</h2>
												<p>
													We provide faster and secured remittance service.
													Follow these four simple steps to get started with us.
												</p>
												<div className="position-relative">
													<img
														draggable="false"
														src="assets/images/components/Frame 18.png"
														alt="cosmoremit "
														className="img-fluid position-relative"
														style={{ marginBottom: "-30px", zIndex: 1 }}
													/>
													<img
														draggable="false"
														src="assets/images/components/Group 15.png"
														alt="cosmoremit "
														className="img-fluid position-absolute bottom-0 start-0"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-7 col-md-6">
								<div className="row justify-content-end">
									{/* STEP ONE */}
									<div className="col-lg-6 col-md-12 col-sm-6">
										<div className="my-4 mx-lg-4">
											<div
												className="number rounded-circle bg-linear-pink d-flex align-items-center justify-content-center"
												style={{ width: 40, height: 40 }}
											>
												<h3 className="fw-bold">1</h3>
											</div>
											<h4 className="fw-bold my-4">Sign Up</h4>
											<p>Input your email address and a strong password. .</p>
										</div>
									</div>
									{/* STEP TWO */}
									<div className="col-lg-6 col-md-12 col-sm-6">
										<div className="my-4 mx-lg-4">
											<div
												className="number rounded-circle bg-linear-pink d-flex align-items-center justify-content-center"
												style={{ width: 40, height: 40 }}
											>
												<h3 className="fw-bold">2</h3>
											</div>
											<h4 className="fw-bold my-4">Get Yourself Verified</h4>
											<p>
												Get yourself verified- Fill out the necessary details
												and upload your ID, after which your profile will be
												verified.
											</p>
										</div>
									</div>
									{/* STEP THREE */}
									<div className="col-lg-6 col-md-12 col-sm-6">
										<div className="my-4 mx-lg-4">
											<div
												className="number rounded-circle bg-linear-pink d-flex align-items-center justify-content-center"
												style={{ width: 40, height: 40 }}
											>
												<h3 className="fw-bold">3</h3>
											</div>
											<h4 className="fw-bold my-4">Add a beneficiary</h4>
											<p>
												Once your account is verified, enter your beneficiary
												details. Make sure the beneficiary account details is
												accurate.
											</p>
										</div>
									</div>
									{/* STEP FOUR */}
									<div className="col-lg-6 col-md-12 col-sm-6">
										<div className="my-4 mx-lg-4">
											<div
												className="number rounded-circle bg-linear-pink d-flex align-items-center justify-content-center"
												style={{ width: 40, height: 40 }}
											>
												<h3 className="fw-bold">4</h3>
											</div>
											<h4 className="fw-bold my-4">Send Money</h4>
											<p>
												Initiate the transaction on your profile and complete
												the transaction by making the payment via POLi or Bank
												transfer/PayID method.
											</p>
										</div>
									</div>
									{/* ACTION BUTTON */}
									<div className="col-md-6">
										<div className="my-4 mx-lg-4">
											<a
												href="#"
												className="text-dark link text-decoration-none"
											>
												Get Started &nbsp; <i className="fas fa-arrow-right" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* 
W E    A R E     N O W    C L O S E R     T H A N     Y O U      T H I N K
*/}
				<section className="section banner position-relative bg-linear-red text-white">
					<div className="container">
						<div className="banner-bg-content">
							<img
								src="assets/images/components/mapping.png"
								alt="cosmoremit "
								className="img-fluid position-absolute top-0 end-0"
							/>
						</div>
						<div className="">
							<div className="row mb-5">
								<div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
									<h2 className="header-2 fw-bold text-white">
										We are now closer than you think
									</h2>
									<p className="my-4 text-white">
										We now remit to over 41 Countries in the world and still
										counting.
									</p>
									<a href="#" className="btn btn-red p-3 d-block">
										Send Money Now
									</a>
								</div>
							</div>
							{/* rating */}
							<div className="row">
								<div className="col-xl-2 col-lg-3 col-6 my-3">
									<h3 className="header-4 fw-bold text-white">40+</h3>
									<span>Countries</span>
								</div>
								<div className="col-xl-2 col-lg-3 col-6 my-3">
									<h3 className="header-4 fw-bold text-white">10k+</h3>
									<span>Customers</span>
								</div>
								<div className="col-xl-2 col-lg-3 col-6 my-3 text-white">
									<h3 className="header-4 fw-bold text-white ">100k+</h3>
									<span>Completed Transactions</span>
								</div>
								<div className="col-xl-6 col-lg-3 col-6 my-3">
									<span>
										<img
											src="assets/images/components/noto_star.png"
											width={23}
											alt="cosmoremit "
										/>
										<img
											src="assets/images/components/noto_star.png"
											width={23}
											alt="cosmoremit "
										/>
										<img
											src="assets/images/components/noto_star.png"
											width={23}
											alt="cosmoremit "
										/>
										<img
											src="assets/images/components/noto_star.png"
											width={23}
											alt="cosmoremit "
										/>
										<img
											src="assets/images/components/noto_star(1).png"
											width={23}
											alt="cosmoremit "
										/>
									</span>
									<br />
									<br />
									<span>4.85/5 Rating on Google reviews</span>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* 
C O U N T R I E S 
*/}
				<section className="section countries">
					<div className="container">
						<div className="row mb-5">
							<div className="col-lg-6">
								<h2 className="header-3 fw-bold">
									We can help you send these currencies
								</h2>
								<p className="lead fw-normal">
									with fast speed, we move your funds
								</p>
							</div>
							<div className="col-lg-6">
								{/* Currencies */}
								<ul className="list-unstyled d-flex flex-wrap">
									{/* currency */}
									<li className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
										<img
											src="assets/images/flags/australia.png"
											alt="cosmoremit "
											className="img-fluid"
										/>
										&nbsp;
										<span className="fw-bold">Australian Dollar</span>
									</li>
									{/* currency */}
									<li className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
										<img
											src="assets/images/flags/Canada.png"
											alt="cosmoremit "
											className="img-fluid"
										/>
										&nbsp;
										<span className="fw-bold">Canadian Dollar</span>
									</li>
									{/* currency */}
									<li className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
										<img
											src="assets/images/flags/New Zealand.png"
											alt="cosmoremit "
											className="img-fluid"
										/>
										&nbsp;
										<span className="fw-bold">New Zealand Dollar</span>
									</li>
									{/* currency */}
									<li className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
										<img
											src="assets/images/flags/Great Britain.png"
											alt="cosmoremit "
											className="img-fluid"
										/>
										&nbsp;
										<span className="fw-bold">British Pound</span>
									</li>
									{/* currency */}
									<li className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
										<img
											src="assets/images/flags/US.png"
											alt="cosmoremit "
											className="img-fluid"
										/>
										&nbsp;
										<span className="fw-bold">US Dollar</span>
									</li>
								</ul>
							</div>
						</div>
						<h2 className="header-3 fw-bold mb-4">Countries we send to</h2>
						{/* C O U N T R I E S */}
						<div className="row">
							<div className="col-xl-9">
								<div className="row">
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/ARG.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Argentina</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/BAN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Bangladesh</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/BRA.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Brazil</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/Canada.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Canada</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/CN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">China</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/New Zealand.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Christmas Island</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/Canada.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">DR Congo</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/EG.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Egypt</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/ETH.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Ethopia</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/FIJ.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Fiji</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/FRA.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">France</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/GRE.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Greece</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/New Zealand.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Hong Kong</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/IND.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">India</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/KEN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Kenya</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/KEN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Kenya</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/MT.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Malta</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/MU.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Mauritius</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/MN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Mongolia</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/NP.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Nepal</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/NP.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Nepal</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/NGN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Nigeria</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/PHI.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Philippines</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/WS.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Samoa</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/SIN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Singapore</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/SOL.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Solomon lsland</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/RSA.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">South Africa</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/SRI.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Sri Lanka</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/TAN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Tanzania</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/TON.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Tonga</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/TUR.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Turkey</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/UAE.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">United Arab Emirates</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/UK.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">United Kingdom</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/US.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">USA</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/VAN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Vanuatu</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/VTN.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Vietnam</span>
										</span>
									</div>
									{/* country */}
									<div className="col-lg-3 col-md-4 col-6">
										<span className="shadow-sm d-inline-block p-1 px-2 rounded-pill m-2 bg-white">
											<img
												src="assets/images/flags/ZIM.png"
												alt="cosmoremit "
												className="img-fluid"
											/>
											&nbsp;
											<span className="fw-bold">Zimbabwe</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* 
G E T    T H E    A P P
*/}
				<section className="section">
					<div className="container">
						<div className="bg-pink pt-5 px-lg-5 px-3 rounded">
							<div className="row align-items-center">
								<div className="col-xl-6 col-lg-7 mb-5 mb-lg-0">
									<h2 className="header-3 fw-bold">Get the app</h2>
									<p>
										Our industry-leading technology protects <br /> your money
										and guarantees it arrives safely <br /> every time.
									</p>
									<a
										href="https://play.google.com/store/apps/details?id=app.au.cosmoremit"
										className="btn border border-dark play_icon text-dark px-4 rounded me-2"
									>
										<img
											src="assets/images/icon/Playstore Icon.png"
											alt
											className="img-fluid"
										/>
										&nbsp; Playstore
									</a>
									{/* <a
                  href="#"
                  className="btn border border-dark text-dark px-4 rounded"
                >
                  <img
                    src="assets/images/icon/Apple icon.png"
                    alt
                    className="img-fluid"
                  />
                  &nbsp; Appstore
                </a> */}
								</div>
								<div className="col-xl-6 col-lg-5">
									<img src={framed} alt className="img-fluid" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<section className="section footer">
				<div className="container">
					<div className="bg-pink px-lg-5 py-5 py-xxl-2 px-3 rounded">
						<div className="row align-items-center">
							<div className="col-xl-6 col-lg-7 my-4 my-lg-0">
								<h2 className="header-3 fw-bold">
									We are the fastest way to send money abroad
								</h2>
								<p className="lead fw-normal">
									Register in less than 5 minutes
								</p>
								<a href="#" className="btn btn-red min-height">
									Send money now
								</a>
							</div>
							<div className="col-xl-6 col-lg-5 d-none d-lg-block">
								<img
									src="assets/images/components/Group 48095564.png"
									alt
									className="img-fluid footer-img"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="container">
				<div className="  pb-5 px-lg-5 px-3 ">
					<h2 className="header-5 fw-bold blog-section-header">Blog Posts</h2>

					<div className="blog-list">
						{blogPosts.map((blog, i) => (
							<BlogCard
								title={blog.title}
								imgUrl={blog.img}
								description={blog.description}
								url={blog.url}
							/>
						))}
					</div>
					<div className="mobile-blog-list">
						<Carousel
							className="carousel-width"
							afterChange={onChange}
							autoplay
						>
							{blogPosts.map((blog, i) => (
								<BlogCard
									title={blog.title}
									imgUrl={blog.img}
									description={blog.description}
									url={blog.url}
								/>
							))}
						</Carousel>
					</div>
				</div>
			</section>
			<footer className="py-5 ">
				<div className="container">
					<div className="d-flex flex-wrap justify-content-between">
						<a href="#" className="footer-brand mb-3 text-white">
							<span>CosmoRemit</span> Fintech Solutions
						</a>
						<ul className="footer-nav list-unstyled d-flex mb-3 align-items-center flex-wrap">
							{/* <li className="nav-item">
								<a href="#" className="text-white pe-3">
									About
								</a>
							</li> */}
							{/* <li className="nav-item">
								<a href="#" className="text-white pe-3">
									Blog
								</a>
							</li> */}
							<li className="nav-item">
								<a href="mailto:admin@cosmoremit.com.au" className="text-white pe-3">
									Contact Us
								</a>
							</li>
							<li className="nav-item pe-3">
								<a
									href="https://www.facebook.com/CosmoRemit?mibextid=9R9pXO"
									rel="noreferrer"
									className="btn btn-white"
									target="_blank"
								>
									<i className="fab fa-facebook-f" />
								</a>
							</li>
							<li className="nav-item pe-3">
								<a href="#" className="btn btn-white" rel="noreferrer">
									<i className="fab fa-twitter" />
								</a>
							</li>
							<li className="nav-item pe-3">
								<a
									href="https://www.linkedin.com/company/cosmo-remit-pty/"
									className="btn btn-white"
									rel="noreferrer"
									target="_blank"
								>
									<i className="fab fa-linkedin" />
								</a>
							</li>
							<li className="nav-item pe-3">
								<a
									href="https://www.instagram.com/cosmo_remit/?igshid=OGQ5ZDc2ODk2ZA"
									rel="noreferrer"
									target="_blank"
									className="btn btn-white"
								>
									<i class="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
					<hr />
					<div className="row py-4 justify-content-between">
						<div className="col-lg-4 col-sm-6 mb-3">
							<h4 className="mb-4 text-white">Help &amp; Support</h4>
							<ul className="list-unstyled">
								{/* <li className="mb-3">Lorem ipsum dolor sit amet.</li>
              <li className="mb-3">Lorem ipsum dolor sit amet.</li>
              <li className="mb-3">Lorem ipsum dolor sit amet.</li>
              <li className="mb-3">Lorem ipsum dolor sit amet.</li>
              <li className="mb-3">Lorem ipsum dolor sit amet.</li>
              <li className="mb-3">Lorem ipsum dolor sit amet.</li> */}
							</ul>
						</div>
						<div className="col-lg-4 col-sm-6 mb-3">
							<h4 className="mb-4 text-white">Australia Contact Info</h4>
							<ul className="list-unstyled">
								{/* <li className="mb-3">
                  Level 14, 3 Parramatta Square, 153 Macquarie Street Parramatta
                  NSW 2150.
                </li> */}

								<li className="mb-3">SWIFT Code: CSPYAU22</li>
								<li className="mb-3">Bank: COSMOREMIT PTY LTD</li>
								<li className="mb-3">ADDRESS: FLOOR 14 153 MACQUARIE STREET</li>
								<li className="mb-3">City: PARRAMATTA</li>
								<li className="mb-3">Postcode: 2150</li>
								<li className="mb-3">Country: Australia</li>
								<li className="mb-3">
									<a href="tel:+61406539757" className="text-white">
										{" "}
										(02) 96 159 832{" "}
									</a>
								</li>
								<li className="mb-3">
									<a href="tel:+61414151549" className="text-white">
										(+61) 414 151 549{" "}
									</a>
								</li>
								<li className="mb-3">
									<a href="tel:+61406539757" className="text-white">
										(+61) 470 628 369{" "}
									</a>
								</li>

								<li className="mb-3">
									<a
										href="mailto:admin@cosmoremit.com.au"
										className="text-white"
									>
										admin@cosmoremit.com.au{" "}
									</a>
								</li>
								<li className="mb-3">24/7</li>
							</ul>
						</div>
						<div className="col-lg-4 col-sm-6 mb-3">
							<h4 className="mb-4 text-white">
								{CountryLandingComponent?.name === "Australia"
									? "New Zealand"
									: CountryLandingComponent?.name}{" "}
								Contact Info
							</h4>
							<ul className="list-unstyled">
								<li className="mb-3">
									{CountryLandingComponent?.address ??
										"2/83 Sutherland Street, Dunedin Central 9016, New Zealand."}
								</li>
								{}
								{CountryLandingComponent?.phone ? (
									CountryLandingComponent.phone.map((contact) => (
										<li className="mb-3">
											{contact.number} {contact.extraText}
										</li>
									))
								) : (
									<>
										<li className="mb-3">(+64) 448 812 66</li>
										<li className="mb-3">(+64) 980 129 66</li>
										<li className="mb-3">(+64) 358 809 98 (After 7pm only)</li>
										<li className="mb-3">(+64) 204 083 2971 (WhatsApp only)</li>
									</>
								)}
								<li className="mb-3">
									{CountryLandingComponent?.email ?? (
										<address>admin@cosmoremit.com.au</address>
									)}
								</li>
								<li className="mb-3">24/7</li>
							</ul>
						</div>
					</div>
					<hr />
					<div className="d-flex flex-wrap justify-content-between">
						<a href="#" className="footer-brand mb-3 text-white">
							<small>We are a proud member of</small> <br />
							<img
								src="assets/images/logo/parramatta.png"
								className="img-fluid"
								alt
							/>
						</a>
						<ul className="footer-nav list-unstyled d-flex mb-3 align-items-center flex-wrap">
							<li className="nav-item">
								<a href="#" className="text-white pe-3">
									{new Date().getFullYear()} @Cosmoremit
								</a>
							</li>
							<li className="nav-item">
								<Link
									to="Privacy-policy"
									className="text-white pe-3 text-decoration-underline"
								>
									Privacy Policy
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="Terms"
									className="text-white pe-3 text-decoration-underline"
								>
									Terms & Conditions
								</Link>
							</li>
							<li className="nav-item">
								<a href="#" className="text-white pe-3">
									Powered by BucketCodes
								</a>
							</li>
						</ul>
					</div>
				</div>
			</footer>
			<Helmet>
				{/* <!--Start of Tawk.to Script--> */}
				<script type="text/javascript"></script>
			</Helmet>
		</>
	);
}
