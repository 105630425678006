import * as actionTypes from "../../actionTypes/admin/popMessagesActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";

export const fetchPopMessage =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_POP_MESSAGES, payload: true });
      dispatch({ type: actionTypes.SAVED_POP_MESSAGES, payload: false });
      const res = await http.get(`/admin/pop_message/list/paginate/10?page=${page}`);
      dispatch({ type: actionTypes.FETCHING_POP_MESSAGES, payload: false });
      dispatch({ type: actionTypes.SET_POP_MESSAGES, payload: res.data.data.pop_messages });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_POP_MESSAGES, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const createPopMessage = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_POP_MESSAGES, payload: true });
    const res = await http.post("/admin/pop_message/create_pop", fd);
    dispatch({ type: actionTypes.SAVING_POP_MESSAGES, payload: false });
    dispatch({ type: actionTypes.SAVED_POP_MESSAGES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SAVING_POP_MESSAGES, payload: false });
    dispatch({ type: actionTypes.SAVED_POP_MESSAGES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const updatePopMessage = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_POP_MESSAGES, payload: true });
    dispatch({ type: actionTypes.UPDATED_POP_MESSAGES, payload: false });
    const res = await http.post("/admin/pop_message/update_pop", fd);
    dispatch({ type: actionTypes.UPDATING_POP_MESSAGES, payload: false });
    dispatch({ type: actionTypes.UPDATED_POP_MESSAGES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_POP_MESSAGES, payload: false });
    dispatch({ type: actionTypes.UPDATED_POP_MESSAGES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleCreatePopMessageModal = () => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_CREATE_MODAL });
};

export const deletePopMessage = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETING_POP_MESSAGES, payload: true });
    dispatch({ type: actionTypes.DELETED_POP_MESSAGES, payload: false });
    const res = await http.get(`/admin/pop_message/delete/${fd}`);
    dispatch({ type: actionTypes.DELETING_POP_MESSAGES, payload: false });
    dispatch({ type: actionTypes.DELETED_POP_MESSAGES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.DELETING_POP_MESSAGES, payload: false });
    dispatch({ type: actionTypes.DELETED_POP_MESSAGES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleStatus = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.TOGGLING_POP_MESSAGES, payload: true });
    dispatch({ type: actionTypes.TOGGLED_POP_MESSAGES, payload: false });
    const res = await http.get(`/admin/pop_message/toggle_pop/${fd}`);
    dispatch({ type: actionTypes.TOGGLING_POP_MESSAGES, payload: false });
    dispatch({ type: actionTypes.TOGGLED_POP_MESSAGES, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.TOGGLING_POP_MESSAGES, payload: false });
    dispatch({ type: actionTypes.TOGGLED_POP_MESSAGES, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const searchPopMessage = (params) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SEARCHING_POP_MESSAGES });
    const res = await http.post(`/admin/transaction_limits/search`, {
      param: params,
    });
    dispatch({ type: actionTypes.SET_POP_MESSAGES, payload: res.data.data });
    dispatch({ type: actionTypes.SEARCHING_POP_MESSAGES });
  } catch (error) {
    dispatch({ type: actionTypes.SEARCHING_POP_MESSAGES });
  }
};
