import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { _beneficiaries } from "../../../../store/actions/admin/senders";
import { Spin, Modal } from "antd";
import Pagination from "../../../../components/pagination/pagination";
import { useLocation } from "react-router-dom";

export default function Beneficiaries() {
  const dispatch = useDispatch();
  const { fetchingBeneficiaries, beneficiaries } = useSelector(
    (state) => state.senders
  );
  const location = useLocation();

  useEffect(() => {
    dispatch(_beneficiaries(1, location?.state?.uuid));
  }, []);
  const [view, setView] = useState({ show: false, ben: {} });
  const [currentPage, setCurrentPage] = useState(null);
  

  return (
    <>
      <div className="table-responsive">
        {fetchingBeneficiaries && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-uppercase text-muted">#</th>
              <th className="text-uppercase text-muted">Name</th>
              <th className="text-uppercase text-muted">Country</th>
              <th className="text-uppercase text-muted">Address</th>
              <th className="text-uppercase text-muted">Phone</th>
              <th className="text-uppercase text-muted">Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {beneficiaries?.data?.map((el, i) => {
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  <td>{el.fullname}</td>
                  <td>{el.country}</td>
                  <td className="wrap">{el.address}</td>
                  <td>{`+${el.country_phonecode} ${el.phone_no}`}</td>
                  <td>{el.email}</td>
                  <td>
                    <a
                      className="btn btn-white"
                      href="#view"
                      onClick={(e) => {
                        e.preventDefault();
                        setView({ show: true, ben: el });
                      }}
                    >
                      View accounts
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {beneficiaries?.links && (
        <Pagination
          onPageChange={(page) => {
            if (beneficiaries !== null && beneficiaries?.meta?.per_page) {
              setCurrentPage(page);
              dispatch(
                _beneficiaries(page, location.state.uuid)
              );
            }
          }}
          data={beneficiaries}
        />
      )}

      <Modal
        visible={view.show}
        footer={false}
        onCancel={() => {
          setView({ show: false, ben: {} });
        }}
      >
        <div className="modal-body text-center">
          <span className="fw-bold"></span>
          {view.ben?.accounts?.map((el, i) => {
            return (
              <>
                <table className="table table-borderless mb-4">
                  <thead>
                    <tr>
                      <th
                        className="text-uppercase text-muted text-center"
                        colspan="2"
                      >
                        {view?.ben?.fullname} Accounts
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom">
                      <th>Account name</th>
                      <td>{el?.account_name}</td>
                    </tr>
                    <tr className="border-bottom">
                      <th>Account number</th>
                      <td>{el?.account_number}</td>
                    </tr>
                    <tr className="border-bottom">
                      <th>Bank location</th>
                      <td>{el?.bank_location}</td>
                    </tr>
                    <tr className="border-bottom">
                      <th>Bank name</th>
                      <td>{el?.bank_name}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            );
          })}
        </div>
      </Modal>
    </>
  );
}
