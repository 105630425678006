import axios from "axios";
import { store } from "../store/store";
import { logout } from "../store/actions/authActions";
export const baseURL = process.env.REACT_APP_API_BASE_URL;

let isLogoutDispatched = false;

export const http = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

// Request interceptors for API calls
http.interceptors.request.use(
  (request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    isLogoutDispatched = false;
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 && !isLogoutDispatched) {
      isLogoutDispatched = true;
      store.dispatch(logout);
    }

    return Promise.reject(error);
  }
);
