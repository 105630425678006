import { saveAs } from "file-saver";

export const uniCodeFlagToString = (flagEmoji) => {
  let flagString = Array.from(flagEmoji)
    .map((char) => String.fromCodePoint(char.codePointAt(0)))
    .join("");
  return flagString;
};

export const checkCountryId = (currency_wallets, country_id) => {
  const wallet = currency_wallets.find(
    (currency) => currency.country_id === country_id
  );

  return wallet;
};

export const dateTimeFormatter = (datetoFormat) => {
  const date = new Date(datetoFormat);
  const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // Time options
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
  return { date: formattedDate, time: formattedTime };
};

export const getPopupContainer = (node) => {
  return node.parentNode;
};

export function urltoFile(base64String) {
  const parts = base64String.split(";base64,");
  const mimeType = parts[0].split(":")[1];
  const imageData = window.atob(parts[1]);

  // Convert the binary data to a Uint8Array
  const bytes = new Uint8Array(imageData.length);
  for (var i = 0; i < imageData.length; i++) {
    bytes[i] = imageData.charCodeAt(i);
  }

  // Create a Blob from the data
  const blob = new Blob([bytes.buffer], { type: mimeType });

  // Create a File object from the Blob
  const file = new File([blob], "image." + mimeType.split("/")[1], {
    type: mimeType,
  });

  // Now you have a File object representing the image file
  return file;
}

export function download(res) {
  const contentDisposition = res.headers["content-disposition"];
  const fileName = contentDisposition
    .split(";")
    .find((part) => part.includes("filename"))
    .split("=")[1]
    .replace(/"/g, "");

  saveAs(new Blob([res.data]), fileName);
}

export function addCommasToNumber(number = 0) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function checkReferralEnabled(email) {
  const referralEmails = process.env.REACT_APP_REFERRAL_EMAIL?.trim()?.split(",");
  const isEnabled = referralEmails?.includes(email);
  if (referralEmails) {
    return isEnabled;
  } else {
    return [];
  }
}
