import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import FormikError from "../formikError";
import { useMoneyTransfer } from "../../../hooks/send.hooks";
import {
	closeSwapSuccessModal,
	swapCurrency,
} from "../../../store/actions/walletActions";
import { fetchDefaultRates } from "../../../store/actions/transaction";

const SwapMoney = ({ handleShowModal }) => {
	const [agreetoSwap, setAgreetoSwap] = useState(false);
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const {
		sendVal,
		inputChangeHandler,
		fromCountryIso,
		fromCountryCurency,
		fromSearch,
		fromCountryListFilter,
		setFromCountryIso,
		setFromCountryCurrency,
		countryFromChange,
		receiveVal,
		toCountryIso,
		toCurrency,
		toCountry,
		fromCurrency,
		toSearch,
		toCountryListFilter,
		setToCountryIso,
		setToCurrency,
		countryToChange,
		rate,
		fee,
		err,
		setFromCountry,
		currency_wallets,
		setToCountry,
		fromCountry,
		selectedPayin,
		setSelectedPayin,
		fetchedDefault,
		payinChange,
		toSwapCountry,
		toSwapCountryIso,
		toSwapCountryListFilter,
		toSwapCurrency,
		setRate,
		setFee,
		setToSwapCountry,
		setToSwapCountryIso,
		setToSwapCurrency,
		fetchingTrxData,
	} = useMoneyTransfer();
	const { user_wallets, swapping_currency } = useSelector(
		(state) => state.wallet
	);

	const checkUserWallet = (country_id) => {
		const userWallet = user_wallets.find(
			(wallet) => wallet.country.id === country_id ?? toCountry
		);
		return userWallet;
	};
	useEffect(() => {
		setRate(0);
		setFee(0);
		setToCountry();
		setToCountryIso();
		setToCurrency();
	}, []);
	useEffect(() => {
		const currentLocation = window.location.pathname;
		const defaultWallet = user_wallets?.find(
			(wallet) => wallet.default_wallet === 1
		);

		if (defaultWallet) {
			const randomToCountry = currency_wallets?.find(
				(wallet) => wallet?.country?.id !== defaultWallet?.country?.id
			);
			setToSwapCountry(randomToCountry?.country?.id);
			setToSwapCurrency(randomToCountry?.country?.currency);
			setToSwapCountryIso(randomToCountry?.country?.iso2);
			dispatch(
				fetchDefaultRates(
					currentLocation,
					defaultWallet?.country?.id,
					randomToCountry?.country?.id
				)
			);
		}

		setFromCountry(defaultWallet?.country?.id);
		setFromCountryCurrency(defaultWallet?.country?.currency);
		setFromCountryIso(defaultWallet?.country?.iso2);
	}, [currency_wallets, user_wallets]);

	return (
		<>
			<form action="auth/login.html" className="py-4 position-relative">
				{/* you send */}
				<div className="mb-4">
					<FormikError msg={err ?? ""} />
					<div className="input-group floating-group mb-2">
						<div className="form-floating">
							<input
								type="text"
								className="form-control rounded-end-0"
								id="youSend"
								placeholder={0}
								value={sendVal}
								name="up"
								onChange={inputChangeHandler}
							/>
							<label htmlFor="youSend">Amount to swap</label>
						</div>{" "}
						<div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
							<button
								className="btn form-control border-0 end  dropdown-toggle text-start"
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span
									className={`flag-icon flag-icon-${fromCountryIso?.toLowerCase()} flag-icon-squared`}
								></span>
								&nbsp;
								{fromCountryCurency}
							</button>
							<ul className="dropdown-menu p-3 position-absolute  country-dropdown country-dropdown-small shadow">
								<li>
									<div className="input-group mb-2">
										<span className="input-group-text text-muted">
											<i className="fas fa-search" />
										</span>
										<input
											type="text"
											placeholder="Search"
											onChange={(e) => {
												toSearch(e.target.value);
											}}
											className="form-control border-start-0 min-height"
										/>
									</div>
								</li>

								{user_wallets?.map((el, i) => {
									return (
										<li key={i}>
											<button
												className="dropdown-item py-3 pe-5"
												type="button"
												style={{ cursor: "pointer" }}
												onClick={() => {
													setFromCountryIso(el?.country.iso2);
													setFromCountryCurrency(el?.country.currency);
													countryFromChange(el?.country.id);
												}}
											>
												<span
													className={`flag-icon flag-icon-${el?.country.iso2.toLowerCase()} flag-icon-squared`}
												></span>
												&nbsp;
												{el?.country.currency}{" "}
												<small className="text-muted">
													{el?.country.currency}
												</small>
											</button>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>

				<div className="input-group position-relative mb-3">
					<div className="form-floating">
						<input
							type="text"
							className="form-control rounded-end-0"
							id="reciepientGets"
							placeholder={0}
							value={receiveVal}
							onChange={inputChangeHandler}
							name="down"
						/>
						<label htmlFor="reciepientGets">You get</label>
					</div>

					<div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
						<button
							className="btn form-control border-0 end dropdown-toggle text-start"
							type="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<span
								className={`flag-icon flag-icon-${toSwapCountryIso?.toLowerCase()} flag-icon-squared`}
							></span>
							&nbsp;
							{toSwapCurrency}
						</button>
						<ul className="dropdown-menu p-3 position-absolute  country-dropdown country-dropdown-small shadow">
							<li>
								<div className="input-group mb-2">
									<span className="input-group-text text-muted">
										<i className="fas fa-search" />
									</span>
									<input
										type="text"
										placeholder="Search"
										onChange={(e) => {
											toSearch(e.target.value);
										}}
										className="form-control border-start-0 min-height"
									/>
								</div>
							</li>
							{currency_wallets?.map((el, i) => {
								return (
									<li key={i}>
										<button
											className="dropdown-item py-3 pe-5"
											type="button"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setToSwapCountryIso(el?.country.iso2);
												setToSwapCurrency(el?.country.currency);
												countryToChange(el?.country.id);
											}}
										>
											<span
												className={`flag-icon flag-icon-${el?.country.iso2.toLowerCase()} flag-icon-squared`}
											></span>
											&nbsp;
											{el?.country.currency}{" "}
											<small className="text-muted">
												{el?.country.currency}
											</small>
										</button>
									</li>
								);
							})}
						</ul>
					</div>
				</div>

				{/* conversion details */}
				<ul className="list-unstyled hero-card-details my-3 swiper_container">
					<li className="d-flex px-4 justify-content-between py-2">
						<small className="text-muted">Rate</small>
						<span>
							{typeof fromCurrency !== "undefined" && (
								<span>
									1 {fromCountryCurency} = {rate} {toSwapCurrency}
								</span>
							)}
						</span>
					</li>
					{/* fee */}
					<li className="d-flex px-4 justify-content-between py-2">
						<small className="text-muted">Fee</small>
						<span>
							{fee} {fromCountryCurency}
						</span>
					</li>
				</ul>

				<div className="form-group">
					<div className="form-check mb-3">
						<input
							className="form-check-input"
							type="checkbox"
							name="checkbox0"
							id="checkbox0"
							value="true"
							onChange={(e) => {
								if (e.target.checked) {
									dispatch({ type: "SET_ERR", payload: "" });
								}
								setAgreetoSwap(e.target.checked);
							}}
						/>
						<label className="" htmlFor="checkbox0">
							By clicking on swap below you agree for us to create a{" "}
							<span className="fw-bold">Wallet</span> for you in{" "}
							<span className="fw-bold">{toSwapCurrency}</span>
						</label>
					</div>
				</div>

				<button
					type="submit"
					onClick={(e) => {
						e.preventDefault();
						navigate("/swap-receipt");
						console.log(err);
						if (err) return;

						dispatch({ type: "SET_ERR", payload: "" });
						if (!agreetoSwap) {
							// disabled
							dispatch({
								type: "SET_ERR",
								payload: "Please accept all terms and conditions",
							});

							return;
						}
						if (!checkUserWallet) {
							handleShowModal();
							return;
						}
						const sendingWalletUUID = checkUserWallet(fromCountry)?.uuid;
						const receivingWalletUUID = checkUserWallet(toCountry)?.uuid;

						dispatch(
							swapCurrency({
								from_wallet_uuid: sendingWalletUUID,
								to_wallet_uuid: receivingWalletUUID,
								amount: sendVal,
							})
						);
					}}
					className="btn min-height btn-red w-100"
					disabled={
						!process.env.REACT_APP_ENABLE_SEND ||
						swapping_currency ||
						err ||
						fetchingTrxData ||
						!agreetoSwap
					}
				>
					{fetchingTrxData ? "Loading..." : "Swap"}{" "}
					{swapping_currency && <Spin />}
				</button>
			</form>

			{/* <CurrencySwapSuccessModal
				showModal={swapSuccessful}
				fromCurrency={fromCountryCurency}
				toCurrency={toSwapCurrency}
				handleCancel={cancelSuccessfulSwapModal}
			/> */}
		</>
	);
};

export default SwapMoney;
