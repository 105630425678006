import * as actionTypes from "../../actionTypes/admin/uploadsActionTypes";
export const uploadsReducer = (
  state = {
    fetching: false,
    loading: false,
    changed: false,
    pending: {},
    approved: {},
    rejected: {},
    uploadCounts: {},
  },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHING:
      return { ...state, fetching: action.payload };
    case actionTypes.LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.CHANGED:
      return { ...state, changed: action.payload };
    case actionTypes.SET_APPROVED:
      return { ...state, approved: action.payload };
    case actionTypes.SET_PENDING:
      return { ...state, pending: action.payload };
    case actionTypes.SET_REJECTED:
      return { ...state, rejected: action.payload };
    case actionTypes.SET_COUNTS:
      return { ...state, uploadCounts: action.payload };
    default:
      return state;
  }
};
