// import React from "react";
// import { Modal } from "antd";

// const AddBeneficiaryComponent = ({
// 	showBeneficiaryModal,
// 	toggleBeneficiaryModal,
// 	content,
// }) => {
// 	return (
// 		<Modal
// 			visible={showBeneficiaryModal}
// 			onCancel={toggleBeneficiaryModal}
// 			footer={false}
// 		>
// 			<div className="d-flex">{content}</div>
// 		</Modal>
// 	);
// };

// export default AddBeneficiaryComponent;

import React from "react";
import { MdClose } from "react-icons/md";

const AddBeneficiaryComponent = ({
	showBeneficiaryModal,
	toggleBeneficiaryModal,
	content,
}) => {
	if (!showBeneficiaryModal) return null;

	return (
		<div className="popup-overlay">
			<div className="popup-content">
				<div className="button_div">
					<button
						className="btn btn-link text-dark bg-transparent border-0 d-flex justify-content-end align-items-end ms-auto"
						onClick={toggleBeneficiaryModal}
					>
						<MdClose className="fw-bold" style={{ fontSize: "25px" }} />
					</button>
				</div>
				{content}
			</div>
		</div>
	);
};

export default AddBeneficiaryComponent;
