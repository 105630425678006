export const FETCHING_PAYIN = "FETCHING_PAYIN";
export const SAVING_PAYIN = "SAVING_PAYIN";
export const SAVED_PAYIN = "SAVED_PAYIN";
export const DELETING_PAYIN = "DELETING_PAYIN";
export const DELETED_PAYIN = "DELETED_PAYIN";
export const UPDATING_PAYIN = "UPDATING_PAYIN";
export const UPDATED_PAYIN = "UPDATED_PAYIN";
export const SET_PAYIN = "SET_PAYIN";
export const SHOW_CREATE_MODAL = "SHOW_CREATE_MODAL";
export const SEARCHING_PAYIN = "SEARCHING_PAYIN";
export const TOGGLING_PAYIN = "TOGGLING_PAYIN";
export const TOGGLED_PAYIN = "TOGGLED_PAYIN";