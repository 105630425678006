import React, { useEffect, useState, useRef } from "react";
import {
	_profile,
	updateProfile,
	resetPassword,
	suspend,
	verifyPhone,
	verifyEmail,
	updateSenderEmail,
	activate,
} from "../../../../store/actions/admin/senders";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../../components/partials/formikError";
import moment from "moment";
import { DatePicker, Select, Spin, Modal, message } from "antd";
import {
	fetchCountries,
	fetchStates,
	fetchIdTypes,
} from "../../../../store/actions/general";
import { useLocation } from "react-router-dom";
import ConfirmForceVerifyModal from "../../../../components/partials/modals/confirmForceVerifyModal";
import {
	fetchCheckoutMetas_admin,
	getUserOutbound__data,
} from "../../../../store/actions/admin/senders";
import { getUserOutbound } from "./../../../../store/actions/profileActions";
import { fetchRatedCountries__admin } from "../../../../store/actions/admin/senders";

const Option = Select;
const google = (window.google = window.google ? window.google : {});
export default function Profile() {
	const dispatch = useDispatch();
	const {
		fetchingProfile,
		profile,
		updatingProfile,
		loadingAction,
		completedAction,
		verifiedEmail,
		verifiedPhone,
		verifyingEmail,
		verifyingPhone,
	} = useSelector((state) => state.senders);

	const { loadingIdTypes } = useSelector((state) => state.general);
	const [rearImgErr, setRearImgErr] = useState(false);
	const [frontImgErr, setFrontImgErr] = useState(false);
	const { msg } = useSelector((state) => state.error);
	const [showReset, setShowReset] = useState(false);
	const [showSuspend, setShowSuspend] = useState(false);
	const [showActivate, setShowActivate] = useState(false);
	const [selIdType, setSelIdType] = useState("");
	const [showNumber, setShowNumber] = useState(false);
	const [myIdTypes, setMyIdTypes] = useState([]);
	const [profilePrevId, setProfilePrevId] = useState();
	const location = useLocation();
	const [verifyEmailModal, setVerifyEmailModal] = useState(false);
	const [verifyPhoneModal, setVerifyPhoneModal] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState("");
	const [myStates, setMyStates] = useState([]);
	const [idMetas, setIdMetas] = useState("");
	const [metasObj, setMetasObj] = useState();
	const [frontImg, setFrontImg] = useState({});
	const [rearImg, setRearImg] = useState({});
	const [showFaceVerify, setShowFaceVerify] = useState(false);

	const [selectedValue, setSelectedValue] = useState("");
	const [modalVisible, setModalVisible] = useState(false);

	const openModal = () => {
		setModalVisible(true);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	const admin_rated_countries = useSelector(
		(state) => state.rated_countries_admin?.fetch_rateds_countries
	);
	useEffect(() => {
		dispatch(fetchRatedCountries__admin());
	}, [dispatch]);

	const newOutbounds = useSelector(
		(state) => state.admin_senderOutbound.outboundData
	);
	const newOutbound = newOutbounds?.map((data) => data?.country?.id.toString());

	useEffect(() => {
		if (profile?.uuid) {
			dispatch(getUserOutbound__data(profile.uuid));
		}
	}, [profile?.uuid, dispatch]);

	useEffect(() => {
		dispatch(getUserOutbound());
	}, [dispatch]);

	const occupation_admin_industry = JSON.parse(
		localStorage.getItem("checkoutMetas")
	);

	useEffect(() => {
		dispatch(fetchCheckoutMetas_admin());
	}, [dispatch]);

	useEffect(() => {
		const storedValue = JSON.parse(localStorage.getItem("senders_profile"));
		if (storedValue) {
			setSelectedValue(storedValue);
		}
	}, []);

	const idtypes = JSON.parse(localStorage.getItem("idtypes")) ?? null;
	const states = JSON.parse(localStorage.getItem("states")) ?? null;

	const [state, setState] = useState({
		// profile: staff.photo,
		id_front: profile?.profile?.idscan_path,
		id_rear: profile?.profile?.back_idscan_path,
	});
	useEffect(() => {
		setProfilePrevId(profile?.idtype?.id);
	}, [profile?.idtype]);

	const convertImageLinkToFile = async (imageUrl, fileName) => {
		try {
			const response = await fetch(imageUrl);
			const blob = await response.blob();
			const file = new File([blob], fileName, { type: blob.type });
			return file;
		} catch (error) {
			console.log(error);
		}
	};

	const handleFaceVerify = () => {
		setShowFaceVerify(true);
	};
	useEffect(() => {
		if (completedAction) {
			setShowReset(false);
			setShowSuspend(false);
			setShowActivate(false);
			dispatch(_profile(location?.state?.uuid));
		}
	}, [completedAction]);

	useEffect(() => {
		dispatch(_profile(location?.state?.uuid));
		dispatch(fetchCountries);
	}, []);

	useEffect(() => {
		if (selectedCountry !== "") {
			setMyIdTypes(
				idtypes?.filter(
					(el) => Number(el?.country_id) === Number(selectedCountry)
				)
			);

			// setMyStates(
			//   states?.filter(
			//     (el) => Number(el?.country_id) === Number(selectedCountry)

			//   )
			// );
			const filteredStates = states?.filter(
				(el) => Number(el?.country_id) === Number(selectedCountry)
			);
			setMyStates(filteredStates);
		} else {
			setMyStates(
				states?.filter(
					(el) => Number(el?.country_id) === Number(profile?.country?.id)
				)
			);
			setMyIdTypes(
				idtypes?.filter(
					(el) => Number(el?.country_id) === Number(profile?.country?.id)
				)
			);
		}
	}, [selectedCountry, profile]);

	// useEffect(() => {
	//   setMyStates(
	//     states?.filter(
	//       (el) => Number(el?.country_id) === Number(profile?.country?.id)
	//     )
	//   );
	// }, [states]);

	useEffect(() => {
		dispatch(fetchStates);
	}, []);

	useEffect(() => {
		dispatch(fetchIdTypes);
		setSelIdType(profile?.idtype?.id);
	}, []);

	useEffect(() => {
		if (verifiedEmail) {
			dispatch(_profile(location.state.uuid));
			setVerifyEmailModal(false);
		}
		if (verifiedPhone) {
			dispatch(_profile(location.state.uuid));
			setVerifyPhoneModal(false);
		}
	}, [verifiedEmail, verifiedPhone]);

	useEffect(() => {
		setState({
			id_front: profile?.profile?.idscan_path,
			id_rear: profile?.profile?.back_idscan_path,
		});
	}, [profile]);

	useEffect(() => {
		setSelIdType(profile?.idtype?.id);
	}, [fetchingProfile]);

	useEffect(() => {
		async function imageLinktoFile() {
			const frontImageFile = await convertImageLinkToFile(
				profile?.profile?.idscan_path,
				"FrontImage"
			);
			const backImageFile = await convertImageLinkToFile(
				profile?.profile?.back_idscan_path,
				"BackImage"
			);
			setRearImg({ image: backImageFile });
			setFrontImg({ image: frontImageFile });
		}
		imageLinktoFile();
	}, [profile?.profile?.back_idscan_path, profile?.profile?.idscan_path]);

	const addressRef = useRef();

	useEffect(() => {
		if (!fetchingProfile && !loadingIdTypes && profile?.email) {
			setTimeout(() => {
				const addressField = addressRef.current;
				let autocomplete = new google.maps.places.Autocomplete(addressField);

				autocomplete.addListener("place_changed", () => {
					formikRef.current.setValues({
						...formikRef.current.values,
						address: "",
						street_no: "",
						street_name: "",
						suburb: "",
						unit_no: "",
						postal_code: "",
						// state: "",
					});

					const obj = {};

					let place = autocomplete.getPlace();
					for (const pl of place.address_components) {
						if (pl.types[0] === "subpremise") {
							obj["unit_no"] = pl.long_name;
						}

						if (pl.types[0] === "street_number") {
							obj["street_no"] = pl.long_name;
						}

						if (pl.types[0] === "route") {
							obj["street_name"] = pl.long_name;
						}

						if (pl.types[0] === "locality") {
							obj["suburb"] = pl.long_name;
						}

						if (pl.types[0] === "postal_code") {
							obj["postal_code"] = pl.long_name;
						}
					}
					formikRef.current.setValues({
						...formikRef.current.values,
						address: addressField.value,
						street_no: obj.street_no ?? "",
						street_name: obj.street_name ?? "",
						suburb: obj.suburb ?? "",
						unit_no: obj.unit_no ?? "",
						postal_code: obj.postal_code ?? "",
						// state: obj.state ?? "",
						// country_id: country.id
					});
				});
			}, 2000);
		}
	}, [profile?.email, fetchingProfile, loadingIdTypes]);

	const formikRef = useRef();

	useEffect(() => {
		const check = myIdTypes?.find((el) => Number(el?.id) === Number(selIdType));
		setShowNumber(check?.card_no_required);

		setIdMetas(check?.metas);
		if (idMetas) {
			const metasCheck =
				profile?.idtype?.id === Number(selIdType)
					? profile?.profile?.idtype_metas
					: {};
			setMetasObj(metasCheck);
		}
		const formValues = {
			...formikRef?.current?.values,
			showCardNumber: check?.card_no_required ? true : false,
			idtype_metas: metasObj,
		};
		if (idMetas?.required !== null) {
			const requiredMetas = idMetas?.required || {};
			const formMetaEntries = Object.entries(requiredMetas).map(
				([key, value]) => [
					key,
					formValues.idtype_metas && formValues.idtype_metas[key]
						? formValues.idtype_metas[key]
						: "",
				]
			);
			const formMeta = Object.fromEntries(formMetaEntries);

			formValues.idtype_metas = formMeta;
		}

		try {
			formikRef?.current?.setValues(formValues);
		} catch (error) {
			console.error(`Error setting formik values: ${error}`);
		}
	}, [myIdTypes, idMetas, selIdType]);

	const check = myIdTypes?.find(
		(el) => Number(el.id) === Number(profile?.idtype?.id)
	)?.card_no_required
		? true
		: false;
	const schema = Yup.object({
		firstname: Yup.string("").required("Enter firstname"),
		lastname: Yup.string("").required("enter last"),
		othernames: Yup.string("").nullable(),
		email: Yup.string("Enter your email")
			.required("Email is required")
			.test("Email", "Enter a valid email", function (value) {
				const emailRegex =
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

				let isValidEmail = emailRegex.test(value);
				if (!isValidEmail) {
					return false;
				}

				return true;
			}),
		mobile_phone: Yup.string("Enter phone")
			.required("Phone is required")
			.max(11, "10 digits is required")
			.typeError("Phone is required"),
		address: Yup.string("").required("Enter address").typeError("Enter addres"),
		unit_no: Yup.string("").nullable(),
		street_no: Yup.string("")
			.required("Enter street no")
			.typeError("Enter street no"),
		street_name: Yup.string("")
			.required("Enter street name")
			.typeError("Enter street name"),
		state: Yup.string("").required("Select state").typeError("Select state"),
		id_type: Yup.string("")
			.required("Select id type")
			.typeError("Select id type"),
		suburb: Yup.string("").required("Enter suburb").typeError("Enter suburb"),
		postcode: Yup.string("")
			.required("Enter postcode")
			.typeError("Enter postcode"),
		id_number: Yup.string("")
			.required("Enter id number")
			.typeError("Enter id number"),
		id_expiry_date: Yup.string().required("Select valid date"),
		id_issue_date: Yup.string("").nullable(),
		gender: Yup.string("").required("Select gender").typeError("Select gender"),
		occupation: Yup.string("")
			.required("Occupation is required")
			.typeError("Enter occupation"),
		country: Yup.string("")
			.required("Country is required")
			.typeError("Select country"),
		dob: Yup.string("").required("Select date").typeError("Selecct date"),
		password: Yup.string("").min(6),
		scanned_id_rear: Yup.mixed(),
		scanned_id_front: Yup.mixed(),
		card_number: Yup.string("enter Id number")
			.nullable()
			.when("showNumber", {
				is: true,
				then: Yup.string()
					.required("Enter card number")
					.typeError("Enter card number"),
			}),
		showCardNumber: Yup.boolean(),
		idtype_metas: idMetas
			? Yup.object().shape(
					Object.fromEntries(
						Object.entries(idMetas.required).map(([key, value]) => [
							key,
							Yup.string().required(`Enter a valid ${value}`),
						])
					)
			  )
			: Yup.object().shape({}),

		occupation_industry: Yup.string("Select occupation industry")
			.required("occupation industry is required")
			.typeError("select occupation industry"),
		estimated_monthly_send: Yup.number()
			.min(0, "Estimated Monthly Send must be greater than or equal to 0")
			.required("Estimated Monthly Send is required"),
		outbound: Yup.array()
			.of(Yup.string().required("Destination country is required"))
			.min(1, "Please select at least one destination country")
			.nullable(),
	});

	const initialValues = {
		firstname: profile?.profile?.firstname,
		lastname: profile?.profile?.lastname,
		othernames: profile?.profile?.othernames ?? "",
		email: profile?.email,
		mobile_phone: profile?.profile?.mobile_phone,
		gender: profile?.profile?.gender,
		occupation: profile?.profile?.occupation,
		dob: profile?.profile?.dob,
		address: profile?.profile?.address_line_1 ?? "",
		unit_no: profile?.profile?.unit_no,
		street_no: profile?.profile?.street_no,
		street_name: profile?.profile?.street_name,
		state: profile?.state?.id,
		suburb: profile?.profile?.suburb,
		postcode: profile?.profile?.postcode,
		id_issue_date: profile?.profile?.id_issue_date || "",
		id_expiry_date: profile?.profile?.id_expiry_date || "",
		id_number: profile?.profile?.id_number,
		id_type: profile?.idtype?.id,
		country: profile?.country?.id,
		password: "",
		scanned_id_front: "",
		scanned_id_rear: "",
		photo: "",
		uuid: profile?.uuid,
		showCardNumber: check,
		card_number: showNumber ? profile?.profile?.card_number : "",
		idtype_metas: metasObj,
		occupation_industry: profile?.profile?.occupation_industry,
		estimated_monthly_send: profile?.profile?.estimated_monthly_send,
		outbound: newOutbound,
	};

	return (
		<>
			{fetchingProfile && <Spin />}
			{!fetchingProfile && profile && !loadingIdTypes && (
				<>
					<Formik
						initialValues={initialValues}
						validationSchema={schema}
						enableReinitialize
						onSubmit={(values) => {
							dispatch(
								updateProfile({
									...values,
									scanned_id_front: frontImg?.image,
									scanned_id_rear: rearImg?.image,
									address_line_1: values.address,
								})
							);
						}}
						innerRef={formikRef}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit}>
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<div className="profile-image">
												<img
													src={
														profile?.profile?.profile_photo !== ""
															? profile?.profile?.profile_photo
															: "../assets/images/avatar/avatar.jpeg"
													}
													className="profile-img-tag img-fluid"
													alt="avatar"
												/>

												<input
													type="file"
													className="form-control min-height"
													onClick={(e) => {
														e.preventDefault();
														openModal();
													}}
												/>
												<>
													{profile?.profile?.profile_photo ? (
														<div>
															<Modal
																visible={modalVisible}
																onCancel={closeModal}
																footer={null}
																centered
															>
																<img
																	src={profile?.profile?.profile_photo}
																	className="modal-image"
																	alt=""
																/>
															</Modal>
														</div>
													) : (
														<div>
															<Modal
																visible={modalVisible}
																onCancel={closeModal}
																footer={null}
																centered
															>
																<img
																	src="../assets/images/avatar/avatar.jpeg"
																	className="modal-image"
																	alt=""
																/>
															</Modal>
														</div>
													)}
												</>
											</div>
											<label htmlFor>Profile Picture</label>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="text-end mb-3">
											<button
												style={{ marginRight: "10px" }}
												className="btn btn-white min-height px-4"
												onClick={(e) => {
													e.preventDefault();
													setShowReset(true);
												}}
											>
												Reset Password
											</button>
											{!profile.suspended_at && (
												<button
													className="btn btn-red min-height px-4"
													onClick={(e) => {
														e.preventDefault();
														setShowSuspend(true);
													}}
												>
													Suspend Account
												</button>
											)}
											{profile.suspended_at && (
												<button
													className="btn btn-red min-height px-4"
													onClick={(e) => {
														e.preventDefault();
														setShowActivate(true);
													}}
												>
													Remove Suspension
												</button>
											)}
										</div>
										<div className="d-flex justify-content-end">
											<button
												onClick={(e) => {
													e.preventDefault();
													handleFaceVerify();
												}}
												className="text-success underline px-2 border-0 bg-white link-underline"
											>
												Force Verify Biometrics{" "}
											</button>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>First name</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Enter first name"
												defaultValue={props.values.firstname}
												name="firstname"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>
											{props.touched.firstname && props.errors.firstname && (
												<FormikError msg={props.errors.firstname} />
											)}
											<FormikError msg={msg?.firstname ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Last name</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Enter last name"
												defaultValue={props.values.lastname}
												name="lastname"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>
											{props.touched.lastname && props.errors.lastname && (
												<FormikError msg={props.errors.lastname} />
											)}
											<FormikError msg={msg?.lastname ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Other name</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Enter other name"
												defaultValue={props.values.othernames}
												name="othernames"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>
											{props.touched.othernames && props.errors.othernames && (
												<FormikError msg={props.errors.othernames} />
											)}
											<FormikError msg={msg?.othernames ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>
												Email{" "}
												{!profile?.email_otp_verified_at && (
													<a
														href="#verify"
														onClick={(e) => {
															e.preventDefault();
															setVerifyEmailModal(true);
														}}
													>
														Verify
													</a>
												)}
											</label>
											<div className="input-group">
												<span className="input-group-text bg-white">
													{profile?.email_otp_verified_at ? (
														<i className="fas fa-check-circle text-success" />
													) : (
														<i class="fas fa-exclamation-triangle text-warning"></i>
													)}
												</span>
												<input
													type="email"
													className="form-control min-height border-start-0"
													placeholder="Enter email"
													defaultValue={props.values.email}
													name="email"
													onChange={props.handleChange}
													onBlur={props.handleBlur}
												/>

												<button
													type="button"
													className="btn btn-white px-4"
													onClick={(e) => {
														e.preventDefault();
														dispatch(updateSenderEmail(props.values));
													}}
												>
													Update
												</button>
											</div>
											{props.touched.email && props.errors.email && (
												<FormikError msg={props.errors.email} />
											)}
											<FormikError msg={msg?.email ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Date of birth</label>
											<DatePicker
												onChange={(date, dateString) => {
													props.setValues({
														...props.values,
														dob: dateString,
													});
												}}
												onBlur={props.handleBlur}
												className="form-control min-height"
												name="dob"
												defaultValue={
													props.values.dob
														? moment(props.values.dob, "YYYY-MM-DD")
														: false
												}
											/>
											{props.touched.dob && props.errors.dob && (
												<FormikError msg={props.errors.dob} />
											)}
											<FormikError msg={msg?.dob ?? ""} />
										</div>
									</div>
								</div>

								<hr />
								<h5 className="fw-bold mt-5 mb-4">Other Info</h5>
								{/* Other Info */}
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Occupation</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Occupation"
												defaultValue={props.values.occupation}
												name="occupation"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>
											{props.touched.occupation && props.errors.occupation && (
												<FormikError msg={props.errors.occupation} />
											)}
											<FormikError msg={msg?.occupation ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Gender</label>
											<select
												className="form-select min-height"
												name="gender"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												defaultValue={props.values.gender}
											>
												<option value>Select Gender</option>
												<option value="male" selected>
													Male
												</option>
												<option value="female">Female</option>
											</select>
											{props.touched.gender && props.errors.gender && (
												<FormikError msg={props.errors.gender} />
											)}
											<FormikError msg={msg?.gender ?? ""} />
										</div>
									</div>

									<div className="col-xl-5 col-md-6">
										<div className="form-group mb-3">
											<label className="mb-2">
												Occupation Industry <span className="text-red">*</span>
											</label>
											<Select
												name="occupation_industry"
												className="form-control min-height"
												value={props?.values?.occupation_industry.toString()}
												onChange={(val) => {
													props.setValues({
														...props.values,
														occupation_industry: val,
													});
												}}
											>
												{occupation_admin_industry?.occupation_industry?.map(
													(single_occupation) => (
														<Select.Option
															key={single_occupation?.id}
															value={single_occupation?.id}
														>
															{single_occupation?.industry}
														</Select.Option>
													)
												)}
											</Select>

											{props.touched.occupation_industry &&
												props.errors.occupation_industry && (
													<FormikError msg={props.errors.occupation_industry} />
												)}
											<FormikError msg={msg?.occupation_industry ?? ""} />
										</div>
									</div>
								</div>
								<hr />
								<h5 className="fw-bold mt-5 mb-4">Address/Contact Info</h5>
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>
												Mobile Phone{" "}
												{!profile?.phone_otp_verified_at && (
													<a
														href="#verifyPhone"
														onClick={(e) => {
															e.preventDefault();
															setVerifyPhoneModal(true);
														}}
													>
														Verify
													</a>
												)}
											</label>
											<div className="input-group">
												<span className="input-group-text bg-white">
													{profile?.phone_otp_verified_at ? (
														<i className="fas fa-check-circle text-success" />
													) : (
														<i class="fas fa-exclamation-triangle text-warning"></i>
													)}
												</span>
												<input
													type="text"
													className="form-control min-height border-start-0"
													placeholder="Enter phone"
													defaultValue={props.values.mobile_phone}
													name="mobile_phone"
													onChange={props.handleChange}
													onBlur={props.handleBlur}
												/>
											</div>
											{props.touched.mobile_phone &&
												props.errors.mobile_phone && (
													<FormikError msg={props.errors.mobile_phone} />
												)}
											<FormikError msg={msg?.mobile_phone ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Address </label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Address "
												defaultValue={props.values.address}
												name="address"
												id="address"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												autoComplete="off"
												ref={addressRef}
											/>
											{props.touched.address && props.errors.address && (
												<FormikError msg={props.errors.address} />
											)}
											<FormikError msg={msg?.address_line_1 ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Unit No</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Unit No"
												defaultValue={props.values.unit_no}
												name="unit_no"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>
											{props.touched.unit_no && props.errors.unit_no && (
												<FormikError msg={props.errors.unit_no} />
											)}
											<FormikError msg={msg?.unit_no ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Street No</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Street No"
												defaultValue={props.values.street_no}
												name="street_no"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>
											{props.touched.street_no && props.errors.street_no && (
												<FormikError msg={props.errors.street_no} />
											)}
											<FormikError msg={msg?.street_no ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Street Name</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Street Name"
												name="street_name"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												defaultValue={props.values.street_name}
											/>
											{props.touched.street_name &&
												props.errors.street_name && (
													<FormikError msg={props.errors.street_name} />
												)}
											<FormikError msg={msg?.street_name ?? ""} />
										</div>
									</div>
									<div className="col-lg-6"></div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label className="mb-2">State</label>
											{/* select box */}
											<Select
												className="form-control min-height"
												defaultValue={profile?.state?.name}
												onChange={(value) => {
													props.setValues({ ...props.values, state: value });
												}}
											>
												{myStates?.map((el) => (
													<Select.Option value={el.id}>{el.name}</Select.Option>
												))}
											</Select>
											{props.touched.state && props.errors.state && (
												<FormikError msg={props.errors.state} />
											)}
											<FormikError msg={msg?.state ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Suburb</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Suburb"
												name="suburb"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												defaultValue={props.values.suburb}
											/>
											{props.touched.suburb && props.errors.suburb && (
												<FormikError msg={props.errors.suburb} />
											)}
											<FormikError msg={msg?.suburb ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Postcode/Zip-code</label>
											<input
												type="text"
												className="form-control min-height"
												placeholder="Postcode/Zip-code"
												name="postcode"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												defaultValue={props.values.postcode}
											/>
											{props.touched.postcode && props.errors.postcode && (
												<FormikError msg={props.errors.postcode} />
											)}
											<FormikError msg={msg?.postcode ?? ""} />
										</div>
									</div>
								</div>
								<hr />

								<div className="mt-3">
									<h4 className="font-weight-bold ">Transaction Settings</h4>
									<div className="d-flex gap-3 outbound_transaction-settings">
										<div className="col-xl-6">
											<div className="country">
												<label htmlFor="">Outbound Countries</label>
												<Select
													className="form-control min-height multi-selector"
													mode="multiple"
													allowClear
													name="outbound"
													placeholder="Select Destination Countries"
													value={props.values.outbound}
													onBlur={props.handleBlur}
													onChange={(val) => {
														props.setValues({
															...props.values,
															outbound: val,
														});
													}}
												>
													{!admin_rated_countries || !admin_rated_countries ? (
														<div>
															<p>Loading Countries......</p>
														</div>
													) : (
														admin_rated_countries?.to_countries?.map((data) => (
															<Option key={data.id}>
																<span
																	className={`flag-icon flag-icon-${data?.iso2.toLowerCase()} flag-icon-squared`}
																></span>
																{"\u00A0"}
																{data.name}
															</Option>
														))
													)}
												</Select>
												{props.touched.outbound && props?.errors?.outbound && (
													<FormikError msg={props.errors.outbound} />
												)}

												<FormikError msg={msg?.outbound ?? ""} />
											</div>
										</div>

										<div className="col-xl-6">
											<div className="form-group mb-3">
												<label className="">
													Expected Amount monthly{" "}
													<span className="text-red">*</span>
												</label>
												<input
													type="number"
													className="form-control min-height"
													name="estimated_monthly_send"
													value={
														props?.values?.selectedValue?.estimated_monthly_send
													}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													defaultValue={props.values.estimated_monthly_send}
												/>
												{props.touched.estimated_monthly_send &&
													props?.errors?.estimated_monthly_send && (
														<FormikError
															msg={props.errors.estimated_monthly_send}
														/>
													)}

												<FormikError msg={msg?.estimated_monthly_send ?? ""} />
											</div>
										</div>
									</div>
								</div>

								<hr />
								<h5 className="fw-bold mt-5 mb-4">ID Card</h5>
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>Type of ID</label>
											{/* <select
                          name="idtype"
                          className="form-select min-height"
                          // onChange={props.handleChange}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              id_type: e.target.value,
                            });
                            setSelIdType(e.target.value);
                          }}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.id_type}
                        >
                          <option value="">--Select--</option>
                          {myIdTypes?.map((el) => (
                            <option value={el.id} ky={el.id}>
                              {el.name}
                            </option>
                          ))}
                        </select> */}

											<Select
												className="form-control min-height"
												onClick={(value) => {}}
												onChange={(value) => {
													props.setValues({
														...props.values,
														id_type: value,
													});
													setSelIdType(value);
												}}
												value={props.values?.id_type}
											>
												{myIdTypes?.map((el) => (
													<Select.Option value={el?.id} key={el?.id}>
														{el?.name}
													</Select.Option>
												))}
											</Select>
											{props.touched.id_type && props.errors.id_type && (
												<FormikError msg={props.errors.id_type} />
											)}
											<FormikError msg={msg?.id_type ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor> ID Number</label>
											<input
												type="text"
												placeholder="ID Number"
												className="form-control min-height"
												name="id_number"
												autoComplete="off"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												defaultValue={props.values?.id_number}
											/>
											{props.touched.id_number && props.errors.id_number && (
												<FormikError msg={props.errors.id_number} />
											)}
											<FormikError msg={msg?.id_number ?? ""} />
										</div>
									</div>
									{/* Card number */}
									{showNumber ? (
										<div className="col-lg-6">
											<div className="form-group mb-3">
												<label className="mb-2">
													Card number <span className="text-red">*</span>
												</label>
												<input
													type="text"
													name="card_number"
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													value={showNumber ? props?.values?.card_number : ""}
													className="form-control min-height"
												/>
												{props.touched.card_number &&
													props.errors.card_number && (
														<FormikError msg={props.errors.card_number} />
													)}
												<FormikError msg={msg?.card_number ?? ""} />
											</div>
										</div>
									) : (
										""
									)}
									{/* <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label htmlFor>Issuer</label>
                        <input
                          type="text"
                          placeholder="Issuer"
                          className="form-control min-height"
                          defaultValue="LICENSING SERVICES WA"
                          readOnly
                        />
                      </div>
                    </div> */}
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>ID Issue Date</label>
											<div className="input-group rounded-0">
												<DatePicker
													onChange={(date, dateString) => {
														props.setFieldValue("id_issue_date", dateString);
													}}
													onBlur={props.handleBlur}
													className="form-control min-height"
													name="id_issue_date"
													defaultValue={
														props.values?.id_issue_date
															? moment(props.values.id_issue_date, "YYYY-MM-DD")
															: null
													}
												/>
												<span className="input-group-text end bg-white">
													<i className="far fa-calendar-alt" />
												</span>
											</div>
											{props.touched.id_issue_date &&
												props.errors.id_issue_date && (
													<FormikError msg={props.errors.id_issue_date} />
												)}
											<FormikError msg={msg?.id_issue_date ?? ""} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group mb-3">
											<label htmlFor>ID Expiry Date</label>
											<div className="input-group rounded-0">
												<DatePicker
													onChange={(date, dateString) => {
														props.setFieldValue("id_expiry_date", dateString);
													}}
													onBlur={props.handleBlur}
													className="form-control min-height"
													name="id_expiry_date"
													defaultValue={
														props.values?.id_expiry_date
															? moment(
																	props.values.id_expiry_date,
																	"YYYY-MM-DD"
															  )
															: null
													}
												/>
												<span className="input-group-text end bg-white">
													<i className="far fa-calendar-alt" />
												</span>
											</div>
											{props.touched.id_expiry_date &&
												props.errors.id_expiry_date && (
													<FormikError msg={props.errors.id_expiry_date} />
												)}
											<FormikError msg={msg?.id_expiry_date ?? ""} />
										</div>
									</div>
									{/* METAS INPUT */}
									{Object.entries(idMetas?.required || {}).map(
										([key, value]) => (
											<div key={key} className="col-xl-5 col-md-6">
												<div className="form-group mb-3">
													<label className="mb-2">
														{value} <span className="text-red">*</span>
													</label>
													<input
														type="text"
														name={`idtype_metas.${key}`}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={
															props.values?.idtype_metas
																? props.values?.idtype_metas[key]
																: ""
														}
														className="form-control min-height"
													/>
													{props.touched.idtype_metas?.[key] &&
														props.errors.idtype_metas?.[key] && (
															<FormikError
																msg={props.errors.idtype_metas[key]}
															/>
														)}
													{/* <FormikError msg={msg?.idtype_metas[key] ?? ""} /> */}
												</div>
											</div>
										)
									)}
									<div className="row">
										<div className="col-lg-6">
											<div className="form-group mb-3">
												<label htmlFor className="mb-2">
													Upload ID (front view){" "}
													<span className="text-red">*</span>
												</label>
												<div className="input-file-upload">
													<div className="file-component">
														<span className="d-block text-center w-100">
															<i
																className="fas fa-cloud-upload-alt"
																style={{ fontSize: 20 }}
															/>
															<p>
																<span className="text-primary">
																	Click to upload
																</span>{" "}
																or drag and drop
															</p>
															<span className="fw-lighter">
																JPG, PNG or JPEG (max 8mb)
															</span>
														</span>
														<input
															type="file"
															name="scanned_id_front"
															className="form-control"
															accept=".png,.jpg,.jfif,.jpeg"
															onChange={(e) => {
																let err = 0;
																setFrontImgErr(false);
																if (e.target.files[0].size > 2000000) {
																	err = 1;
																	setFrontImgErr(true);

																	return;
																}

																if (!err) {
																	props.setValues({
																		...props.values,
																		scanned_id_front: e.target.files[0],
																	});
																	setState({
																		...state,
																		id_front: URL.createObjectURL(
																			e.target.files[0]
																		),
																	});
																	setFrontImg({
																		image: e.target.files[0],
																	});
																}
															}}
														/>
													</div>
												</div>
												<img
													src={state.id_front}
													alt="id front pic"
													className="img-fluid my-3"
													width="300px"
													height="400px"
												/>
											</div>
											{frontImgErr && (
												<FormikError msg="image must be less than 2MB" />
											)}
											{props.touched.scanned_id_front &&
												props.errors.scanned_id_front && (
													<FormikError msg={props.errors.scanned_id_front} />
												)}
											<FormikError msg={msg?.scanned_id_front ?? ""} />
										</div>
										<div className="col-lg-6">
											<div className="form-group mb-3">
												<label htmlFor className="mb-2">
													Upload ID (Rear view){" "}
													<span className="text-red">*</span>
												</label>
												<div className="input-file-upload">
													<div className="file-component">
														<span className="d-block text-center w-100">
															<i
																className="fas fa-cloud-upload-alt"
																style={{ fontSize: 20 }}
															/>
															<p>
																<span className="text-primary">
																	Click to upload
																</span>{" "}
																or drag and drop
															</p>
															<span className="fw-lighter">
																JPG, PNG or JPEG (max 8mb)
															</span>
														</span>
														<input
															type="file"
															className="form-control"
															accept=".png,.jpg,.jfif,.jpeg"
															name="scanned_id_rear"
															onBlur={props.handleBlur}
															onChange={(e) => {
																let err = 0;
																setFrontImgErr(false);
																if (e.target.files[0].size > 2000000) {
																	err = 1;
																	setFrontImgErr(true);

																	return;
																}

																if (!err) {
																	props.setValues({
																		...props.values,
																		scanned_id_rear: e.target.files[0],
																	});
																	setState({
																		...state,
																		id_rear: URL.createObjectURL(
																			e.target.files[0]
																		),
																	});
																	setRearImg({
																		image: e.target.files[0],
																	});
																}
															}}
														/>
													</div>
												</div>
												<img
													src={state.id_rear}
													alt="id front pic"
													className="img-fluid my-3"
													width="300px"
													height="400px"
												/>
											</div>
											{frontImgErr && (
												<FormikError msg="image must be less than 2MB" />
											)}
											{props.touched.scanned_id_rear &&
												props.errors.scanned_id_rear && (
													<FormikError msg={props.errors.scanned_id_rear} />
												)}
											<FormikError msg={msg?.scanned_id_rear ?? ""} />
										</div>
									</div>
								</div>
								<div className="text-end my-4">
									<button
										className="btn btn-red px-4 min-height"
										type="submit"
										onClick={() => {
											if (Object.keys(props.errors).length > 0) {
												message.error("Please fix the errors in the form.");
											}
										}}
										disabled={updatingProfile}
									>
										<i className="fas fa-save" /> &nbsp; Update User{" "}
										{updatingProfile && <Spin />}
									</button>
								</div>
							</form>
						)}
					</Formik>

					<Modal visible={showActivate} footer={false}>
						<div>
							<h6 className="fw-bold">Activate Account</h6>
							<p className="text-muted pt-3">
								Are you sure you want to activate this user? if yes click{" "}
								<bold>Confirm</bold>, else <bold>CANCEL</bold>
							</p>
							<div className="text-end">
								<button
									className="btn btn-outline-red ms-2 px-4"
									data-bs-dismiss="modal"
									onClick={() => {
										setShowActivate(false);
									}}
									type="button"
								>
									Cancel
								</button>
								<button
									className="btn btn-red ms-2 px-4"
									disabled={loadingAction}
									type="button"
									onClick={() => {
										dispatch(activate(profile.uuid));
									}}
								>
									Confirm {loadingAction && <Spin />}{" "}
								</button>
							</div>
						</div>
					</Modal>
					<Modal visible={showReset} footer={false}>
						<div className="">
							<h6 className="fw-bold">Reset Password</h6>
							<p className="text-muted pt-3">
								This would send reset password link notification to the
								customer!. Are you sure of this operation?
							</p>
							<div className="text-end">
								<a
									href="#resetPassword"
									className="btn btn-outline-red ms-2 px-4"
									data-bs-dismiss="modal"
									type="button"
									onClick={(e) => {
										e.preventDefault();
										setShowReset(false);
									}}
								>
									Cancel
								</a>
								<button
									className="btn btn-red ms-2 px-4"
									type="button"
									disabled={loadingAction}
									onClick={() => {
										dispatch(resetPassword(profile.uuid));
									}}
								>
									Confirm {loadingAction && <Spin />}
								</button>
							</div>
						</div>
					</Modal>
					<Modal visible={showSuspend} footer={false}>
						<div>
							<h6 className="fw-bold">Suspend Account</h6>
							<p className="text-muted pt-3">
								This would remove this account and the customer/user would not
								be able to login. ARE YOU SURE?{" "}
							</p>
							<div className="text-end">
								<button
									className="btn btn-outline-red ms-2 px-4"
									data-bs-dismiss="modal"
									onClick={() => {
										setShowSuspend(false);
									}}
									type="button"
								>
									Cancel
								</button>
								<button
									className="btn btn-red ms-2 px-4"
									disabled={loadingAction}
									type="button"
									onClick={() => {
										dispatch(suspend(profile.uuid));
									}}
								>
									Confirm {loadingAction && <Spin />}{" "}
								</button>
							</div>
						</div>
					</Modal>

					{/* verify phone modal */}
					<Modal
						visible={verifyPhoneModal}
						footer={false}
						onCancel={() => {
							setVerifyPhoneModal(false);
						}}
					>
						<div className="modal-body text-center">
							<p>Are you sure you want to verify phone?</p>
							<button
								className="btn btn-outline-red mx-2 px-4"
								type="button"
								onClick={() => {
									setVerifyPhoneModal(false);
								}}
								disabled={verifyingPhone}
							>
								Cancel
							</button>
							<button
								className="btn btn-red mx-2 px-4"
								type="button"
								disabled={verifyingPhone}
								onClick={() => {
									dispatch(verifyPhone(location.state.uuid));
								}}
							>
								Verify {verifyingPhone && <Spin />}
							</button>
						</div>
					</Modal>
					{/* end verify phone modal */}

					{/* verify email modal */}
					<Modal
						visible={verifyEmailModal}
						footer={false}
						onCancel={() => {
							setVerifyEmailModal(false);
						}}
					>
						<div className="modal-body text-center">
							<p>Are you sure you want to verify email?</p>
							<button
								className="btn btn-outline-red mx-2 px-4"
								type="button"
								onClick={() => {
									setVerifyEmailModal(false);
								}}
								disabled={verifyingEmail}
							>
								Cancel
							</button>
							<button
								className="btn btn-red mx-2 px-4"
								type="button"
								disabled={verifyingEmail}
								onClick={() => {
									dispatch(verifyEmail(location.state.uuid));
								}}
							>
								Verify {verifyingEmail && <Spin />}
							</button>
						</div>
					</Modal>
					{/* end verify email modal */}

					{/* Face verify Modal */}
					<ConfirmForceVerifyModal
						visible={showFaceVerify}
						fullName={`${profile?.profile?.firstname} ${profile?.profile?.lastname}`}
						uuid={profile?.uuid}
						handleRefresh={() => {
							dispatch(_profile(profile?.uuid));
						}}
						handleClose={() => {
							setShowFaceVerify(false);
						}}
					/>
				</>
			)}
		</>
	);
}
