import React, { useEffect, useState } from "react";
import { IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import FundWallet from "./modals/fundYourWallet/FundWallet";
import { useDispatch, useSelector } from "react-redux";
import { geUserWallets } from "../../../store/actions/walletActions";
import { Select, Spin } from "antd";

const WalletInfoCard = ({ location }) => {
	const [showModal, setShowModal] = useState(false);
	const [selectedWallet, setSelectedWallet] = useState("");
	const [selectedWalletInfo, setSelectedWalletInfo] = useState({});

	const dispatch = useDispatch();
	const { fetching_user_wallets, user_wallets } = useSelector(
		(state) => state.wallet
	);
	const defaultWallet = user_wallets?.find(
		(wallet) => wallet.default_wallet === 1
	);
	useEffect(() => {
		dispatch(geUserWallets());
	}, [dispatch]);
	useEffect(() => {
		setSelectedWallet(defaultWallet?.uuid);
		setSelectedWalletInfo(findSelectedWallet(defaultWallet?.uuid));
	}, [defaultWallet]);
	const handleShowModal = () => {
		setShowModal(true);
	};
	const handleCancel = () => {
		setShowModal(false);
	};
	const findSelectedWallet = (uuid) => {
		const userWallet = user_wallets.find((wallet) => wallet.uuid === uuid);
		return userWallet;
	};
	const handleWalletChange = (e) => {
		setSelectedWallet(e);
		setSelectedWalletInfo(findSelectedWallet(e));
	};

	return (
		<div className="card rounded  bg-red-fade  border-0">
			<div className="wallet_red_card_inner card-body py-3 d-flex align-items-sm-center flex-row ">
				{fetching_user_wallets ? (
					<Spin />
				) : (
					<>
						{user_wallets.length > 1 ? (
							<div className="col">
								{/* <h6 className="wallet_inner_heading">Wallet balance</h6> */}
								<Select
									onChange={handleWalletChange}
									value={selectedWallet}
									className="wallet_select"
								>
									{user_wallets?.map((wallet) => (
										<Select.Option key={wallet.uuid} value={wallet.uuid}>
											{wallet.country.currency} Wallet Balance
										</Select.Option>
									))}
								</Select>

								<h5 className="fw-bold fs-5 mt-3 balance__amount">
									{selectedWalletInfo?.balance ?? 0}{" "}
									{selectedWalletInfo?.country?.currency}
								</h5>
							</div>
						) : (
							<h5 className="fw-bold fs-5 mt-3 balance__amount">
								No wallets created
							</h5>
						)}
						<div className="flex-column d-flex">
							{user_wallets.length > 1 && (
								<button
									onClick={handleShowModal}
									className="btn btn-red px-sm-4 py-2"
								>
									Fund Wallet
								</button>
							)}

							{location === "dashboard" && (
								<Link to="/wallet" className="mt-3 d-flex text-red fw-bold">
									<p className="text-red text-decoration-underline">
										View Wallet
									</p>
									<span>
										{" "}
										<IoChevronForwardOutline />
									</span>
								</Link>
							)}
						</div>
					</>
				)}
			</div>
			<FundWallet showModal={showModal} handleCancel={handleCancel} />
		</div>
	);
};

export default WalletInfoCard;
