import React, { useEffect, useRef, useState } from "react";
import { Modal, Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
	toggleEditModal,
	updateBeneficiary,
	updateAccount,
	addAccount,
	getBeneficiaryToEdit,
} from "../../../store/actions/beneficiaryActions";
import { useDispatch } from "react-redux";
import FormikError from "../../../components/partials/formikError";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { fetchCountries, fetchBanks } from "../../../store/actions/general";
import { getUserOutbound } from "../../../store/actions/profileActions";
import useGooglePlacesAutocomplete from "../../../hooks/googleAdress.hooks";
import ModalConponent from "../../../components/modal-component/modalConponent";
import AddBeneficiaryComponent from "../../../components/partials/dashboardSendComponents/addBenefeciaryComponent";

const google = (window.google = window.google ? window.google : {});
export default function EditModal() {
	const dispatch = useDispatch();
	const classNames = require("classnames");
	const moment = require("moment");
	const [add, setAdd] = useState(false);
	const [hasCode, setHasCode] = useState(false);
	const [codeName, setCodeName] = useState();
	const [loading, setLoading] = useState(false);
	const [hasExtraField, setHasExtraField] = useState(false);
	const [extraFieldName, setExtraFieldName] = useState("");
	const [beneficiary, setBeneficiary] = useState(false);
	const [addressFocused, setAddressFocused] = useState(false);
	const [toggleOff, setToggleOff] = useState(true);

	const navigate = useNavigate();

	const handleToggleOff = () => {
		if (toggleOff) {
			// setToggleOff(!toggleOff);
			dispatch(toggleEditModal(false));
		}
	};

	const {
		// beneficiary,
		showModal,
		showFields,
		updatingBeneificiary,
		updatingAccount,
		addingAccount,
		addedAccount,
	} = useSelector((state) => state.editBeneficiary);
	// const { beneficiary } = useSelector(state.)
	const { banks } = useSelector((state) => state.general);
	const { address, setAddress } = useGooglePlacesAutocomplete(
		"address",
		true,
		showModal
	);
	const { user_outbound_data } = useSelector((state) => state.outbound);
	const formRef = useRef();
	useEffect(() => {
		setAddress({
			street_no: "",
			street_name: "",
			suburb: "",
			unit_no: "",
			postal_code: "",
			full_address: "",
		});
		dispatch(getUserOutbound());
		if (showFields) {
			setExpandTab("personal");
		}
	}, [showFields]);

  useEffect(() => {
    if (formRef.current && showModal) {
      if (beneficiary?.to_update_address) {
        formRef.current.setValues({
          ...formRef.current.values,
          fullname: beneficiary?.fullname ?? "",
          country_id: beneficiary?.country_id ?? "",
          phone_no: beneficiary?.phone_no ?? "",
          address: address.full_address || "",
          suburb: address.suburb || "",
        });
      } else {
        formRef.current.setValues({
          ...formRef.current.values,
          fullname: beneficiary?.fullname ?? "",
          country_id: beneficiary?.country_id ?? "",
          phone_no: beneficiary?.phone_no ?? "",
          address: address.full_address || beneficiary?.address || "",
          suburb: address.suburb || beneficiary?.suburb || "",
        });
      }
    }
  }, [address, showModal, beneficiary, addressFocused]);

	useEffect(() => {
		const fetchBeneficiary = async () => {
			const beneficiaryUUID = localStorage.getItem("beneficiaryToEdit");
			setLoading(true);
			try {
				const getBeneficiary = await getBeneficiaryToEdit(beneficiaryUUID);
				setBeneficiary(getBeneficiary);
			} catch (err) {
				console.log(err.response.data.message);
			}
			setLoading(false);
		};
		fetchBeneficiary();
	}, [localStorage.getItem("beneficiaryToEdit")]);

	const { msg } = useSelector((state) => state.error);
	const [countryCode, setCountryCode] = useState("");
	const [expandTab, setExpandTab] = useState("");
	const toggleModal = () => {
		dispatch(toggleEditModal(false));
	};

	useEffect(() => {
		const ls = localStorage.getItem("beneficiaryToEdit");
		dispatch(fetchCountries);
		dispatch(fetchBanks(beneficiary?.country_id));
	}, [dispatch, beneficiary]);

	const fullname = beneficiary?.fullname
		? beneficiary?.fullname.split(" ")
		: "";
	let nameAbbr = "";
	if (fullname?.length > 1) nameAbbr = fullname[0][0] + fullname[1][0];
	if (nameAbbr.length === 1) nameAbbr = fullname[0][0];

	const personalValidationSchema = Yup.object({
		fullname: Yup.string("").required("enter beneficiary fullname"),
		country_id: Yup.string("").required("select country"),
		phone_no: Yup.string("").required("enter beneficiary phone"),
		address: Yup.string("Enter address").required("enter beneficiary address"),
		suburb: Yup.string("Enter suburb").required("enter beneficiary suburb"),
	});

	const handleAddAccountSubmit = async (values, { resetForm }) => {
		dispatch(
			addAccount({ ...values, beneficiary_uuid: beneficiary?.beneficiary_uuid })
		).then(async (response) => {
			if (response.success) {
				setLoading(true);
				try {
					const getBeneficiary = await getBeneficiaryToEdit(
						beneficiary.beneficiary_uuid
					);
					setBeneficiary(getBeneficiary);
				} catch (err) {
					console.log(err.response.data.message);
				}
				setLoading(false);

				resetForm();
				setAdd(false);
			}
		});
	};
	const submitPersonal = async (values) => {
		dispatch(
			updateBeneficiary({
				...values,
				beneficiary_uuid: beneficiary?.beneficiary_uuid,
				address_corrected: true,
			})
		).then(async (response) => {
			if (response.success) {
				setLoading(true);
				try {
					const getBeneficiary = await getBeneficiaryToEdit(
						beneficiary.beneficiary_uuid
					);
					setBeneficiary(getBeneficiary);
					toggleModal();
				} catch (err) {
					console.log(err.response.data.message);
				}
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		if (addedAccount) navigate("/beneficiaries");
	}, [addedAccount]);

	const accountValidationSchema = Yup.object({
		account_name: Yup.string().required("enter account name"),
		bank_id: Yup.string().required("select bank"),
		account_number: Yup.number()
			.typeError("invalid account number")
			.required("enter account number"),
		bank_location: Yup.string().required("enter bank location"),
		extra_code: hasCode
			? Yup.string().required(`${codeName} is required`)
			: Yup.string().nullable(),
		extra_field: hasExtraField
			? Yup.string().required(`${extraFieldName} is required`)
			: Yup.string().nullable(),
	});

	const submitAccount = (values) => {
		dispatch(updateAccount(values)).then(async (response) => {
			if (response.success) {
				const getBeneficiary = await getBeneficiaryToEdit(
					beneficiary.beneficiary_uuid
				);
				setBeneficiary(getBeneficiary);
			}
		});
	};

	const countrySelectHandler = (values, setValues) => (value, labelInValue) => {
		setValues({ ...values, country_id: value.value });
		setCountryCode("+" + labelInValue.countryCode);
	};

	const handleCodeCheck = (account, values) => {
		const checkHasCodeName = Boolean(account?.extra_code);
		setHasCode(checkHasCodeName);
		if (checkHasCodeName) {
			setCodeName(account?.code_name);
			return true;
		} else {
			return false;
		}
	};

	const handleExtraFieldCheck = (account, values) => {
		const checkHasExtraName = Boolean(account?.extra_field);
		setHasCode(checkHasExtraName);
		if (checkHasExtraName) {
			setExtraFieldName(account?.extra_code_name);
			return true;
		} else {
			return false;
		}
	};

	const newBeneficiaryCodeCheck = (id, type) => {
		const selectedBank = banks?.find((bank) => bank.id === id);

		if (type === "code") {
			const checkHasCode = Boolean(selectedBank?.is_code);
			setHasCode(checkHasCode);

			if (checkHasCode) {
				setCodeName(selectedBank?.code_name);
				return true;
			} else {
				return false;
			}
		} else if (type === "extra_field") {
			const checkHasExtraField = Boolean(selectedBank?.extra_field_name);
			setHasExtraField(checkHasExtraField);

			if (checkHasExtraField) {
				setExtraFieldName(selectedBank?.code_name);
				return true;
			} else {
				return false;
			}
		}
	};

	return (
		<>
			<ModalConponent
				show={showModal}
				onClose={toggleModal}
				body_clickOff={handleToggleOff}
				// body_clickOff={toggleEditModal(false)}
			>
				<div>
					<>
						{loading ? (
							<div>
								<Spin />
							</div>
						) : (
							""
						)}
						<div>
							<div className="d-flex align-items-center mb-3">
								<div className="flex-shrink-0">
									<span className="name-card fw-bold text-red">
										{/* {fullname ? fullname[0][0] + fullname[1][0] : ""} */}
										{nameAbbr}
									</span>
								</div>
								<div className="flex-grow-1 ms-3">
									<h5 className="fw-bold">{beneficiary?.fullname}</h5>
								</div>
							</div>
							<p>
								{/* Total Amount sent - <span className="text-red">$2,000.78 AUD</span>{" "} */}
								<button
									className="btn btn-red"
									onClick={() => {
										setAdd(true);
									}}
								>
									Add account
								</button>
								&nbsp; &nbsp;&nbsp; Date Created -{" "}
								{moment(beneficiary?.created_at).format("D MMM, YYYY")}
							</p>
							<div className="d-flex justify-content-between my-4">
								<h6 className="fw-bold">Personal Details</h6>
								{expandTab === "personal" ? (
									<Link
										to="#"
										onClick={async () => {
											setExpandTab("");
											setLoading(true);
											try {
												const getBeneficiary = await getBeneficiaryToEdit(
													beneficiary.beneficiary_uuid
												);
												setBeneficiary(getBeneficiary);
											} catch (err) {
												console.log(err.response.data.message);
											}
											setLoading(false);
										}}
										className="text-red"
									>
										Cancel
									</Link>
								) : (
									<Link
										to="#"
										onClick={() => setExpandTab("personal")}
										className="text-red"
									>
										Edit
									</Link>
								)}
							</div>
							{/* User details */}
							<div
								className={classNames({
									"beneficiary-info beneficiary-user-info": true,
									active: expandTab !== "personal",
								})}
							>
								<div className="row">
									<div className="col-lg-4">
										<p className="text-muted mb-1">Fullname</p>
										<p className="fw-bold">{beneficiary?.fullname}</p>
									</div>
									<div className="col-lg-2">
										<p className="text-muted mb-1">Country</p>
										<p className="fw-bold">{beneficiary?.country}</p>
									</div>
									<div className="col-lg-3">
										<p className="text-muted mb-1">Phone Number</p>
										<p className="fw-bold">{beneficiary?.phone_no}</p>
									</div>
									<div className="col-lg-3" title={beneficiary?.address}>
										<p className="text-muted mb-1">Address</p>
										<p className="fw-bold truncate">{beneficiary?.address}</p>
									</div>
								</div>
							</div>
							{/* User Details Edit form */}
							<div
								className={classNames({
									"beneficiary-info beneficiary-user-edit": true,
									active: expandTab === "personal",
								})}
							>
								<Formik
									innerRef={formRef}
									initialValues={{
										fullname: beneficiary?.fullname,
										country_id: beneficiary?.country_id,
										phone_no: beneficiary?.phone_no,
										address: beneficiary?.address,
										suburb: beneficiary?.suburb,
									}}
									validationSchema={personalValidationSchema}
									onSubmit={submitPersonal}
									enableReinitialize
								>
									{(props) => (
										<form onSubmit={props.handleSubmit}>
											<div className="form-group mb-3">
												<label htmlFor="" className="mb-2">
													Fullname (As appears on ID card)
												</label>
												<input
													name="fullname"
													value={props.values.fullname}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													className="form-control min-height"
												/>
												{props.touched.fullname && props.errors.fullname && (
													<FormikError msg={props.errors.fullname} />
												)}
												<FormikError msg={msg?.fullname ?? ""} />
											</div>
											<div className="row">
												{/* Country */}
												<div className="col-lg-6">
													<div className="form-group mb-3">
														<label className="mb-2">Country</label>
														{/* select box */}
														<Select
															showSearch
															placeholder="Select country"
															className="form-control min-height"
															onChange={countrySelectHandler(
																props.values,
																props.setValues
															)}
															disabled
															labelInValue
															value={beneficiary?.country}
														>
															{user_outbound_data?.map((el) => {
																return (
																	<Select.Option
																		key={el.country.id}
																		id={el.country.id}
																		countryCode={el.country.phonecode}
																	>
																		<span
																			className={`flag-icon flag-icon-${el.country.iso2.toLowerCase()} flag-icon-squared`}
																		></span>
																		{"\u00A0"}
																		{el.country.name}
																	</Select.Option>
																);
															})}
														</Select>
														{props.touched.country_id &&
															props.errors.country_id && (
																<FormikError msg={props.errors.country_id} />
															)}
														<FormikError msg={msg?.country_id ?? ""} />
													</div>
												</div>
												{/* Phone number */}
												<div className="col-lg-6">
													<div className="form-group mb-3">
														<label htmlFor="" className="mb-2">
															Phone number
														</label>
														<div className="input-group">
															{/* input box */}
															<span className="text-muted input-group-text bg-white rounded-0">
																{countryCode
																	? countryCode
																	: beneficiary?.country_phonecode}
															</span>
															<input
																name="phone_no"
																value={props.values.phone_no}
																onChange={props.handleChange}
																onBlur={props.handleBlur}
																className="form-control border-start-0 min-height"
															/>
														</div>
														{props.touched.phone_no &&
															props.errors.phone_no && (
																<FormikError msg={props.errors.phone_no} />
															)}
														<FormikError msg={msg?.phone_no ?? ""} />
													</div>
												</div>
											</div>
											{/* Address */}
											<div className="form-group mb-3">
												<label htmlFor="" className="mb-2">
													Address
												</label>
												<input
													name="address"
													id="address"
													value={props.values.address}
													onChange={props.handleChange}
													onFocus={() => {
														setAddressFocused(true);
													}}
													onBlur={(e) => {
														props.handleBlur(e);
														setAddressFocused(false);
													}}
													className="form-control min-height"
													autoComplete="off"
												/>
												{(showFields || beneficiary?.to_update_address) && (
													<p className="fw-6">
														<small>
															<strong>Old address:</strong>
															{"  "} {beneficiary?.address}
														</small>
													</p>
												)}
												{(showFields || props.touched.address) &&
													props.errors.address && (
														<FormikError msg={props.errors.address} />
													)}
												<FormikError msg={msg?.address ?? ""} />
											</div>

                      {/* Suburb */}
                      <div className="form-group mb-3">
                        <label className="mb-2">Suburb/City</label>
                        <input
                          type="text"
                          value={props.values.suburb}
                          placeholder="Beneficiary suburb"
                          className="form-control min-height"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          name="suburb"
                          disabled
                        />
                        {props.touched.suburb && props.errors.suburb && (
                          <FormikError msg={props.errors.suburb} />
                        )}
                        <FormikError msg={msg?.suburb ?? ""} />
                      </div>
                      <div className="text-end py-4">
                        <button type="submit" className="btn btn-red px-5">
                          Update {updatingBeneificiary && <Spin />}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              {beneficiary?.accounts?.map((account) => {
                return (
                  <div kay={account.account_uuid}>
                    <div className="d-flex justify-content-between my-4">
                      <h6 className="fw-bold">Bank Account Details</h6>
                      {expandTab === account.account_uuid ? (
                        <a
                          href="#n"
                          onClick={async () => {
                            setExpandTab("");
                            const getBeneficiary = await getBeneficiaryToEdit(
                              beneficiary.beneficiary_uuid
                            );
                            setBeneficiary(getBeneficiary);
                          }}
                          className="text-red"
                        >
                          Cancel
                        </a>
                      ) : (
                        <a
                          href="#n"
                          onClick={() => setExpandTab(account.account_uuid)}
                          className="text-red"
                        >
                          Edit
                        </a>
                      )}
                    </div>
                    {/* user bank details */}
                    <div
                      className={classNames({
                        "beneficiary-info beneficiary-bank-info": true,
                        active: expandTab !== account.account_uuid,
                      })}
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <p className="text-muted mb-1">Account Name</p>
                          <p className="fw-bold">{account.account_name}</p>
                        </div>
                        <div className="col-lg-2">
                          <p className="text-muted mb-1">Bank Location</p>
                          <p className="fw-bold">{account.bank_location}</p>
                        </div>
                        <div className="col-lg-2">
                          <p className="text-muted mb-1">Bank</p>
                          <p className="fw-bold">{account.bank_name}</p>
                        </div>
                        <div className="col-lg-2">
                          <p className="text-muted mb-1">Account type</p>
                          <p className="fw-bold">Naira Account</p>
                        </div>
                        <div className="col-lg-2">
                          <p className="text-muted mb-1">Account Number</p>
                          <p className="fw-bold">{account.account_number}</p>
                        </div>
                        {/* <div className="col-lg-2">
			  <p className="text-muted mb-1">{codeName}</p>
			  <p className="fw-bold">{account.extra_code}</p>
			</div> */}
											</div>
										</div>

										{/* user bank details edit form */}

										<div
											className={classNames({
												"beneficiary-info beneficiary-bank-edit": true,
												active: expandTab === account.account_uuid,
											})}
										>
											<small className="text-red text-sm">
												Please enter the correct beneficiary account information
												to prevent potential delays in the transaction process.
											</small>
											<Formik
												initialValues={account}
												validationSchema={accountValidationSchema}
												onSubmit={submitAccount}
												enableReinitialize={true}
											>
												{(props) => (
													<form onSubmit={props.handleSubmit}>
														{/* Account name */}
														<div className="form-group mb-3">
															<label htmlFor="" className="mb-2">
																Account name
															</label>
															<input
																type="text"
																placeholder="Bank Account name"
																className="form-control min-height"
																name="account_name"
																value={props.values.account_name}
																onChange={props.handleChange}
																onBlur={props.handleBlur}
															/>
															{props.touched.account_name &&
																props.errors.account_name && (
																	<FormikError
																		msg={props.errors.account_name}
																	/>
																)}
															<FormikError msg={msg?.account_name ?? ""} />
														</div>
														<div className="row">
															<div className="col-lg-6">
																<div className="form-group mb-3">
																	<label htmlFor="" className="mb-2">
																		Bank
																	</label>
																	<Select
																		name="bank_id"
																		className="form-select min-height"
																		showSearch
																		filterOption={(input, option) => {
																			return option.children
																				.toLowerCase()
																				.includes(input.toLowerCase());
																		}}
																		defaultValue={props.values.bank_name}
																		onChange={(value) => {
																			props.setValues({
																				...props.values,
																				bank_id: value,
																			});
																		}}
																	>
																		<Select.Option value="">
																			select
																		</Select.Option>
																		{banks?.map((bank) => (
																			<Select.Option
																				key={bank.id}
																				value={bank.id}
																			>
																				{bank.name}
																			</Select.Option>
																		))}
																	</Select>
																</div>
																{props.touched.bank_id &&
																	props.errors.bank_id && (
																		<FormikError msg={props.errors.bank_id} />
																	)}
																<FormikError msg={msg?.bank_id ?? ""} />
															</div>
															{/* Account Number */}
															<div className="col-lg-6">
																<div className="form-group mb-3">
																	<label htmlFor="" className="mb-2">
																		Account Number
																	</label>
																	<input
																		type="text"
																		className="form-control min-height"
																		name="account_number"
																		value={props.values.account_number}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																	/>
																	{props.touched.account_number &&
																		props.errors.account_number && (
																			<FormikError
																				msg={props.errors.account_number}
																			/>
																		)}
																	<FormikError
																		msg={msg?.account_number ?? ""}
																	/>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="form-group mb-3">
																	<label htmlFor="" className="mb-2">
																		Bank location
																	</label>
																	<input
																		type="text"
																		className="form-control min-height"
																		name="bank_location"
																		value={props.values.bank_location}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		onClick={handleCodeCheck(
																			account,
																			props.values
																		)}
																	/>
																	{props.touched.bank_location &&
																		props.errors.bank_location && (
																			<FormikError
																				msg={props.errors.bank_location}
																			/>
																		)}
																	<FormikError msg={msg?.bank_location ?? ""} />
																</div>
															</div>
															{/* extra code */}
															{handleCodeCheck(account, props.values) && (
																<div className="col-lg-6">
																	<div className="form-group mb-3">
																		<label htmlFor className="mb-2">
																			{codeName}
																		</label>
																		<input
																			type="text"
																			className="form-control min-height"
																			name="extra_code"
																			value={props.values.extra_code}
																			onChange={props.handleChange}
																			onBlur={props.handleBlur}
																		/>
																		{props.touched.extra_code &&
																			props.errors.extra_code && (
																				<FormikError
																					msg={props.errors.extra_code}
																				/>
																			)}
																		<FormikError msg={msg?.extra_code ?? ""} />
																	</div>
																</div>
															)}
															{/* extra field */}
															{handleExtraFieldCheck(account, props.values) && (
																<div className="col-lg-6">
																	<div className="form-group mb-3">
																		<label htmlFor className="mb-2">
																			{extraFieldName}
																		</label>
																		<input
																			type="text"
																			className="form-control min-height"
																			name="extra_field"
																			value={props.values.extra_field}
																			onChange={props.handleChange}
																			onBlur={props.handleBlur}
																		/>
																		{props.touched.extra_field &&
																			props.errors.extra_field && (
																				<FormikError
																					msg={props.errors.extra_field}
																				/>
																			)}
																		<FormikError msg={msg?.extra_field ?? ""} />
																	</div>
																</div>
															)}
														</div>
														<div className="text-end py-4">
															<button
																type="submit"
																id="updateBankBtn"
																className="btn btn-red px-5"
																disabled={updatingAccount}
															>
																Update {updatingAccount && <Spin />}
															</button>
														</div>
													</form>
												)}
											</Formik>
										</div>
										<hr />
									</div>
								);
							})}
						</div>
					</>
					{/* add account Modal */}
					<Modal
						visible={add}
						footer={false}
						onCancel={() => {
							setAdd(false);
						}}
						style={{ top: 0 }}
					>
						<Formik
							validationSchema={accountValidationSchema}
							initialValues={{
								account_name: "",
								bank_id: "",
								account_number: "",
								bank_location: "",
								extra_code: "",
								extra_field: "",
							}}
							onSubmit={handleAddAccountSubmit}
						>
							{(props) => {
								return (
									<form onSubmit={props.handleSubmit}>
										<div className="mb-2">
											<span className="fw-bold  mb-5">Add account details</span>
										</div>
										{/* Account name */}
										<div className="form-group mb-3">
											<label htmlFor="" className="mb-2">
												Account name
											</label>
											<input
												type="text"
												placeholder="Bank Account name"
												className="form-control min-height"
												name="account_name"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.account_name}
											/>
											{props.touched.account_name &&
												props.errors.account_name && (
													<FormikError msg={props.errors.account_name} />
												)}
											<FormikError msg={msg?.account_name ?? ""} />
										</div>

										<div className="row">
											<div className="col-lg-6">
												<div className="form-group mb-3">
													<label htmlFor="" className="mb-2">
														Bank
													</label>
													<Select
														name="bank_id"
														className="form-select min-height"
														value={props.values.bank_id}
														showSearch
														filterOption={(input, option) => {
															return option.children
																.toLowerCase()
																.includes(input.toLowerCase());
														}}
														onChange={(value) => {
															props.setValues({
																...props.values,
																bank_id: value,
															});
														}}
													>
														<Select.Option value="">select</Select.Option>
														{banks?.map((bank) => (
															<Select.Option key={bank.id} value={bank.id}>
																{bank.name}
															</Select.Option>
														))}
													</Select>
													{props.touched.bank_id && props.errors.bank_id && (
														<FormikError msg={props.errors.bank_id} />
													)}
													<FormikError msg={msg?.bank_id ?? ""} />
												</div>
											</div>
										</div>
										{/* Address */}
										<div className="form-group mb-3">
											<label htmlFor="" className="mb-2">
												Address
											</label>
											<input
												name="address"
												id="address"
												value={props.values.address}
												onChange={props.handleChange}
												onFocus={() => {
													setAddressFocused(true);
												}}
												onBlur={(e) => {
													props.handleBlur(e);
													setAddressFocused(false);
												}}
												className="form-control min-height"
												autoComplete="off"
											/>
											{(showFields || beneficiary?.to_update_address) && (
												<p className="fw-6">
													<small>
														<strong>Old address:</strong>
														{"  "} {beneficiary?.address}
													</small>
												</p>
											)}
											{(showFields || props.touched.address) &&
												props.errors.address && (
													<FormikError msg={props.errors.address} />
												)}
											<FormikError msg={msg?.address ?? ""} />
										</div>

										{/* Suburb */}
										<div className="form-group mb-3">
											<label className="mb-2">Suburb/City</label>
											<input
												type="text"
												value={props.values.suburb}
												placeholder="Beneficiary suburb"
												className="form-control min-height"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												name="suburb"
												disabled
											/>
											{props.touched.suburb && props.errors.suburb && (
												<FormikError msg={props.errors.suburb} />
											)}
											<FormikError msg={msg?.suburb ?? ""} />
										</div>
										<div className="text-end py-4">
											<button type="submit" className="btn btn-red px-5">
												Update {updatingBeneificiary && <Spin />}
											</button>
										</div>
									</form>
								);
							}}
						</Formik>

						<Formik
							validationSchema={accountValidationSchema}
							initialValues={{
								account_name: "",
								bank_id: "",
								account_number: "",
								bank_location: "",
								extra_code: "",
								extra_field: "",
							}}
							onSubmit={handleAddAccountSubmit}
						>
							{(props) => (
								<>
									{beneficiary?.accounts?.map((account) => (
										<div key={account.account_uuid}>
											<div className="d-flex justify-content-between my-4">
												<h6 className="fw-bold">Bank Account Details</h6>
												{expandTab === account.account_uuid ? (
													<a
														href="#n"
														onClick={async () => {
															setExpandTab("");
															const getBeneficiary = await getBeneficiaryToEdit(
																beneficiary.beneficiary_uuid
															);
															setBeneficiary(getBeneficiary);
														}}
														className="text-red"
													>
														Cancel
													</a>
												) : (
													<a
														href="#n"
														onClick={() => setExpandTab(account.account_uuid)}
														className="text-red"
													>
														Edit
													</a>
												)}
											</div>
											{/* user bank details */}
											<form
												className={classNames({
													"beneficiary-info beneficiary-bank-info": true,
													active: expandTab !== account.account_uuid,
												})}
											>
												<div className="row">
													<div className="col-lg-4">
														<p className="text-muted mb-1">Account Name</p>
														<p className="fw-bold">{account.account_name}</p>
													</div>
													<div className="col-lg-6">
														<div className="form-group mb-3">
															<label htmlFor="" className="mb-2">
																Bank location
															</label>
															<input
																type="text"
																className="form-control min-height"
																value={props.values.bank_location}
																name="bank_location"
																onChange={props.handleChange}
																onBlur={props.handleBlur}
															/>
															{props.touched.bank_location &&
																props.errors.bank_location && (
																	<FormikError
																		msg={props.errors.bank_location}
																	/>
																)}
															<FormikError msg={msg?.bank_location ?? ""} />
														</div>
													</div>
													{/* extra code */}
													{newBeneficiaryCodeCheck(
														props.values.bank_id,
														"code"
													) && (
														<div className="col-lg-6">
															<div className="form-group mb-3">
																<label htmlFor="" className="mb-2">
																	{codeName}
																</label>
																<input
																	type="text"
																	className="form-control min-height"
																	name="extra_code"
																	value={props.values.extra_code}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																/>
																{props.touched.extra_code &&
																	props.errors.extra_code && (
																		<FormikError
																			msg={props.errors.extra_code}
																		/>
																	)}
																<FormikError msg={msg?.extra_code ?? ""} />
															</div>
														</div>
													)}
													{/* extra field */}
													{newBeneficiaryCodeCheck(
														props.values.bank_id,
														"extra_field"
													) && (
														<div className="col-lg-6">
															<div className="form-group mb-3">
																<label htmlFor="" className="mb-2">
																	{extraFieldName}
																</label>
																<input
																	type="text"
																	className="form-control min-height"
																	name="extra_field"
																	value={props.values.extra_field}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																/>
																{props.touched.extra_field &&
																	props.errors.extra_field && (
																		<FormikError
																			msg={props.errors.extra_field}
																		/>
																	)}
																<FormikError msg={msg?.extra_field ?? ""} />
															</div>
														</div>
													)}
												</div>
												<div className="text-end py-4">
													<button
														type="submit"
														id="updateBankBtn"
														className="btn btn-red px-5"
														disabled={addingAccount}
													>
														Add {addingAccount && <Spin />}
													</button>
												</div>
											</form>
										</div>
									))}
								</>
							)}
						</Formik>
					</Modal>

					{/* ============== */}
				</div>
			</ModalConponent>
		</>
	);
}
