export const FETCHING_SENDERS = "FETCHING_SENDERS";
export const SAVING_SENDERS = "SAVING_SENDERS";
export const SAVED_SENDERS = "SAVED_SENDERS";
export const DELETING_SENDERS = "DELETING_SENDERS";
export const DELETED_SENDERS = "DELETED_SENDERS";
export const UPDATING_SENDERS = "UPDATING_SENDERS";
export const UPDATED_SENDERS = "UPDATED_SENDERS";
export const SET_SENDERS = "SET_SENDERS";
export const SHOW_CREATE_MODAL = "SHOW_CREATE_MODAL";
export const SEARCHING_SENDERS = "SEARCHING_SENDERS";
export const TOGGLING_SENDERS = "TOGGLING_SENDERS";
export const TOGGLED_SENDERS = "TOGGLED_SENDERS";
export const FETCHING_PROFILE = "FETCHING_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const UPDATING_PROFILE = "UPDATING_PROFILE";
export const UPDATED_PROFILE = "UPDATED_PROFILE";
export const LOADING_ACTION = "LOADING_ACTION";
export const COMPLETED_ACTION = "COMPLETED_ACTION";
export const FETCHING_TRANSACTIONS = "FETCHING_TRANSACTIONS";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const FETCHING_BENEFICIARIES = "FETCHING_BENEFICIARIES";
export const SET_BENEFICIARIES = "SET_BENEFICIARIES";
export const FETCHING_KYCS = "FETCHING_KYCS";
export const SET_KYCS = "SET_KYCS";
export const SAVING_KYC = "SAVING_KYC";
export const CHANGED_KYC = "CHANGED_KYC";
export const LOADING = "LOADING";
export const SET_NOTES = "SET_NOTES";
export const FETCHING_NOTES = "FETCHING_NOTES";
export const CHANGED = "CHANGED";
export const FETCHING_BLACK_LIST = "FETCHING_BLACK_LIST";
export const SET_BLACK_LIST = "SET_BLACK_LIST";
export const TOGGLED_BLACK_LIST = "TOGGLED_BLACK_LIST";
export const FETCHING_DOCS = "FETCHING_DOCS";
export const SET_DOCS = "SET_DOCS";
export const FETCHING_LIMITS = "FETCHING_LIMITS";
export const SET_LIMITS = "SET_LIMITS";
export const SET_PROFILE_TRANSACTION_LIMIT_DATE =
	"SET_PROFILE_TRANSACTION_LIMIT_DATE";
export const SET_COUNT_SENDER = "SET_COUNT_SENDER";
export const FETCHING_COMPLIANCE_SENDERS = "FETCHING_COMPLIANCE_SENDERS";
export const SET_COMPLIANCE_SENDERS = "SET_COMPLIANCE_SENDERS";
export const BLACKLISTING = "BLACKLISTING";
export const BLACKLISTED = "BLACKLISTED";
export const VERIFYING_PHONE = "VERIFYING_PHONE";
export const VERIFIED_PHONE = "VERIFIED_PHONE";
export const VERIFIED_EMAIL = "VERIFIED_EMAIL";
export const VERIFYING_EMAIL = "VERIFYING_EMAIL";
export const VERIFYING_BIOMETRICS = "VERIFYING_BIOMETRICS";
export const SET_FACE_VERIFIED_COUNT = "SET_FACE_VERIFIED_COUNT";
export const FETCHING_CHECKOUT_METAS = "FETCHING_CHECKOUT_METAS";
export const SET_CHECKOUT_METAS = "SET_CHECKOUT_METAS";
export const FETCHED_CHECKOUT_METAS = "FETCHED_CHECKOUT_METAS";

export const SUBMITTING_OUTBOUND = "SUBMITTING_OUTBOUND";

export const SET_DATA_OUTBOUNDS = "SET_DATA_OUTBOUNDS";
export const OUTBOUNDS_DATA_SUCCESS = "OUTBOUNDS_DATA_SUCCESS";
export const OUTBOUNDS_DATA_FAILURE = "OUTBOUNDS_DATA_FAILURE";

export const LOADING_RATEDS_COUNTRIES = "LOADING_RATEDS_COUNTRIES";
export const SET_RATEDS_COUNTRIES = "SET_RATEDS_COUNTRIES";
export const RATEDS_COUNTRIES_SUCCESSFUL = "RATEDS_COUNTRIES_SUCCESSFUL";
