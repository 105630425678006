import React from "react";

export default function FormikError({ msg }) {
	let message = msg;
	if (typeof msg === "object")
		message = msg.map((message, index) => {
			return (
				<p className="p-0 m-0" key={index}>
					{message}
				</p>
			);
		});
	return <span className="text-danger formik-error">{message}</span>;
}
