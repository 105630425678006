import React, { useEffect, useState } from "react";
import { useDispatch} from "react-redux"
import { useSearchParams } from "react-router-dom";
import { poliCanceled } from "../../../store/actions/transaction";
import {load} from 'cheerio';
import DOMPurify from "dompurify";

export default function TransactionPending() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [htmlContent, setHtmlContent] = useState('');
  const [resData, setResData] = useState(null)
  // const  response_status_data  = useSelector(state => state.response_status_data);
  useEffect(() => {
    dispatch({ type: "INITIALIZED", payload: false });
    async function fetchData () {
    const data = await poliCanceled(searchParams.get('token'))
    setResData(data);
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (resData) {
      const sanitizedHTML = DOMPurify.sanitize(resData?.response_message, { USE_PROFILES: { html: true } })
      setHtmlContent(sanitizedHTML);
    }
  }, [resData]);
  const receiver = JSON.parse(localStorage.getItem("receiver")) ?? {};
  const trxData = JSON.parse(localStorage.getItem('trx_initData'));
  
//  response_status_data)

  return (
    <div className="container-fluid">
      <div className="card mx-auto success-card shadow rounded border-0">
        <div className="card-body py-4 text-center">
          {/* F A I L E D */}
          <div>
            <div className="success-card-icon pending">
              <i className="fa fa-ellipsis-h" aria-hidden="true" />
            </div>
            <h4 className="fw-bold">{resData?.response_title}</h4>
            {/* <p>Transaction Cancelled</p> */}
            <div dangerouslySetInnerHTML={{ __html: htmlContent }}/>
          </div>
          {/* conversion details */}
          <ul className="list-unstyled hero-card-details my-3">
            {/* Amount */}
            <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Amount</small>
              <span>{localStorage.getItem("trx_sendVal")} {localStorage.getItem("trx_fromCountryCurency")}</span>
            </li>
            <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Transaction reference</small>
              <span>{trxData?.transaction_details?.reference}</span>
            </li>
            {/* Recipent */} 
            <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Recipent</small>
              <span>{receiver?.fullname}</span>
            </li>
            {/* Recipent gets */}
            <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Recipent gets</small>
              <span>{localStorage.getItem("trx_receiveVal")} {localStorage.getItem("trx_toCurency")}</span>
            </li>
            {/* Payment Method */}
            <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Payment Method</small>
              <span>{localStorage.getItem("trx_selectedPayinName")}</span>
            </li>
            {/* Guaranteed Rate (24 hrs) */}
            <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">
                Guaranteed Rate <span className="text-dark">(24 hrs)</span>
              </small>
              <span>1 {localStorage.getItem("trx_fromCountryCurency")} = {localStorage.getItem("trx_rate")}
                {localStorage.getItem("trx_toCurrency")}</span>
            </li>
            {/* fee */}
            <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Fee</small>
              <span>{localStorage.getItem("trx_fee")}{" "}
                {localStorage.getItem("trx_fromCountryCurency")}</span>
            </li>
            {/* amount we'll convert too */}
            {/* <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Amount We'll Convert</small>
              <span>200,000.00 AUD</span>
            </li> */}
            {/* time */}
            <li className="d-flex px-4 justify-content-between py-2">
              <small className="text-muted">Estimated Time</small>
              <span>{localStorage.getItem("trx_eta")} mins</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
