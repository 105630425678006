import { Button, DatePicker, Modal, Select, Spin } from "antd";
import React, { useState } from "react";
import { BiExport } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { exportTransactions } from "../../store/actions/admin/transactions";
import { exportSenders } from "../../store/actions/admin/senders";
import "../../assets/css/filterSelectwithCount.css";
import { useEffect } from "react";
import { fetchCountries } from "../../store/actions/general";
// "../../assets/css/filterSelectwithCount.css";

function ExportButton({
  loading,
  title,
  status,
  className,
  btnText,
  type,
  customDownloadFunction,
}) {
  const dispatch = useDispatch();
  const { exportingData, transactionsCountries } = useSelector(
    (state) => state.adminTransactions
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const [countryID, setCountryID] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // const options = transactionsCountries?.map((el) => ({
  //   value: el.country.id,
  //   flag: el.country.iso2,
  //   label: el.country.iso3,
  //   id: el.country.id,
  // }));
  const { RangePicker } = DatePicker;
  useEffect(() => {
    dispatch(fetchCountries);
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleChange = (dates) => {
    setSelectedRange(dates);
  };

  const handleExport = () => {
    dispatch(exportSenders(status, countryID));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDownload = () => {
    if (selectedRange.length === 2) {
      const startDate = selectedRange[0].format("YYYY-MM-DD");
      const endDate = selectedRange[1].format("YYYY-MM-DD");
      if (customDownloadFunction) {
        customDownloadFunction(startDate, endDate, handleCancel);
      } else {
        dispatch(exportTransactions(status, startDate, endDate));
      }

      // setIsModalOpen(false);
    }
  };
  const options = JSON.parse(localStorage.getItem("cosmo_countries"));
  //   const filteredOptions = options.filter((option) =>
  //   option?.name?.toLowerCase().includes(searchValue.toLowerCase())
  // );
  return (
    <>
      <button
        onClick={showModal}
        className={`py-2 px-4 border bg-white  button btn btn-red min-height  ${className}`}
      >
        {btnText ?? "Export"} <i class="fas fa-download"></i>
      </button>
      {type ? (
        <Modal
          title={`Export ${type} users`}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              return option?.name?.toLowerCase().includes(input.toLowerCase());
            }}
            placeholder="Select country"
            className="form-control min-height"
            onChange={(value) => {
              setCountryID(value?.key);
            }}
            labelInValue
            // onSearch={handleSearch}
          >
            <Select.Option key={""} value="">
              All
            </Select.Option>
            {options?.map((el) => {
              return (
                <Select.Option
                  key={el.id}
                  id={el.id}
                  value={el.name}
                  countryCode={el.phonecode}
                  name={el.name}
                >
                  <span
                    className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                  ></span>
                  {"\u00A0"}
                  {el.name}
                </Select.Option>
              );
            })}
          </Select>

          <div className="d-flex w-100 justify-content-end mt-2 ">
            <button
              disabled={exportingData || loading}
              onClick={handleExport}
              className="py-2 px-4 border bg-white rounded-1 button"
            >
              Download <i class="fas fa-download"></i>{" "}
              {(exportingData || loading) && <Spin />}
            </button>
          </div>
        </Modal>
      ) : (
        <Modal
          title={title ? `Export ${title}` : `Export ${status} transactions`}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
        >
          {/* <Select
          placeholder="Select format"
          className="form-control min-height mb-3"
        >
          <Select.Option value="pdf" key="pdf">
            PDF
          </Select.Option>
          <Select.Option value="excel" key="excel">
            Excel
          </Select.Option>
        </Select> */}
          <RangePicker
            onChange={handleChange}
            className="form-control min-height mb-3"
          />
          <div className="d-flex w-100 justify-content-end">
            <button
              disabled={exportingData || loading}
              onClick={handleDownload}
              className="py-2 px-4 border bg-white rounded-1 button"
            >
              Download {(exportingData || loading) && <Spin />}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ExportButton;
