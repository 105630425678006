import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  _swappings,
  searchWalletTransactions,
} from "../../../store/actions/admin/transactions";
import Pagination from "../../../components/pagination/pagination";
import { Spin, Modal } from "antd";
import AdminSearchBar from "../../../components/partials/adminSearchBar";
import TimeAgo from "react-timeago";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

export default function WalletSwappings() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const dispatch = useDispatch();
  const { wallet_swappings, fetching_wallet_swappings, searchingPending } =
    useSelector((state) => state.adminTransactions);

  useEffect(() => {
    if (searchParam) {
      dispatch(
        searchWalletTransactions({
          param: searchParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(_swappings(1));
    }
    setCurrentPage(pageParam);
  }, [pageParam, searchParam, dispatch]);

  const { msg } = useSelector((state) => state.error);

  localStorage.setItem("searchFor", "swappings");

  // console.log(transactionsCountries);
  return (
    <div className="container-fluid">
      <h5 className="fw-bold">Wallet Swappings</h5>
      <hr />
      <div className="row mb-4">
        <div className="col-sm-5 mb-3">
          <AdminSearchBar
            callback={searchWalletTransactions}
            placeholder={"reference number"}
          />{" "}
        </div>
      </div>

      <div className="table-responsive">
        {fetching_wallet_swappings || searchingPending ? <Spin /> : ""}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Reference</th>
              <th className="text-muted text-uppercase">Send Amount </th>
              <th className="text-muted text-uppercase">Receive Amount</th>
              <th className="text-muted text-uppercase">Fee</th>
              <th className="text-muted text-uppercase">Rate</th>
              <th className="text-muted text-uppercase">Date</th>
              <th className="text-muted text-uppercase">Status</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {wallet_swappings.data?.map((el, i) => {
              const transactionStatus = el?.status;
              let status = "warning";
              let time = el.created_at;
              if (transactionStatus === "successful") {
                status = "success";
              }

              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  {/* <i className="fa-sharp fa-solid fa-circle text-danger" /> */}
                  <td>
                    <>{el?.reference} </>
                  </td>
                  <td>
                    <p className="text-muted text-uppercase">
                      {el?.from_currency?.currency} {"\u00A0"}
                      <span
                        className={`flag-icon flag-icon-${el?.from_currency?.iso2.toLowerCase()} flag-icon-squared`}
                      ></span>
                      {"\u00A0"}
                      {el?.sent_amount}
                    </p>
                  </td>
                  <td>
                    <p className="text-muted text-uppercase">
                      {el?.to_currency?.currency} {"\u00A0"}
                      <span
                        className={`flag-icon flag-icon-${el?.to_currency?.iso2.toLowerCase()} flag-icon-squared`}
                      ></span>
                      {"\u00A0"}
                      {el?.received_amount}
                    </p>
                  </td>
                  <td>{el.fee ?? "--"}</td>
                  <td>{el.rate ?? "--"}</td>

                  <td>{moment(el.created_at).format("YYYY-MM-DD")}</td>
                  <td>
                    <small className={`text-${status} text-capitalize`}>
                      {el.status}
                    </small>
                    <br />
                    <TimeAgo date={time} />
                  </td>  
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#detailsModal"
                            onClick={() => {}}
                          >
                            Verify
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#addNoteModal"
                          >
                            Comment
                          </a>
                        </li>
                        {/* <li><a className="dropdown-item py-2" href="#" onClick={(e) => { e.preventDefault(); setVerify({ show: true, data: el.uuid }) }}>Verify Payment</a></li>
                                            <li><a className="dropdown-item py-2" href="#" onClick={(e) => { e.preventDefault(); setSuspend({ show: true, data: el.uuid }) }}>Suspend Payment</a></li>
                                            <li><a className="dropdown-item py-2" href="#" onClick={(e) => { e.preventDefault(); setRefund({ show: true, data: el.uuid }) }}>Refund Payment</a></li> */}
                        {/* <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deleteModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setTrash({ show: true, data: el.uuid });
                            }}
                          >
                            Trash
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {wallet_swappings?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (wallet_swappings !== null && wallet_swappings?.meta?.per_page) {
              if (searchParam) {
                setSearchParams({
                  param: searchParam,
                  page,
                });
                dispatch(
                  searchWalletTransactions({
                    param: searchParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });
                dispatch(_swappings(page));
              }
            }
          }}
          data={wallet_swappings}
        />
      )}
    </div>
  );
}
