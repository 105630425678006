import React, { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export default function AdminSearchBar({ callback, placeholder }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [param, setParam] = useState("");

  const searchParam = searchParams.get("param");
  useEffect(() => {
    setParam(searchParam ?? "");
  }, [searchParam]);
  const debounced = useDebouncedCallback((value) => {
    if (value) {
      setSearchParams({ param: value.trim() });
    } else {
      searchParams.delete("param");
      setSearchParams(searchParams);
    }
    dispatch(callback({ param: value.trim() }));

    // dispatch(callback(params));
  }, 1000);

  const changeHandler = (e) => {
    setParam(e.target.value);
    debounced(e.target.value);
  };

  return (
    <div className="col-sm order-sm-0 mb-3">
      <div className="input-group">
        <span className="input-group-text bg-white">
          <i className="fas fa-search" />
        </span>
        <input
          value={param}
          type="text"
          className="form-control border-start-0 min-height"
          placeholder={`Search for ${placeholder ?? "anything"}`}
          onChange={changeHandler}
        />
      </div>
    </div>
  );
}
