import React, { useEffect, useState } from "react";
import List from "./list";
import {
  toggleCreateLimitModal,
  createLimit,
  searchLimit,
} from "../../../store/actions/admin/limit";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Modal, Select } from "antd";
import { fetchCountries } from "../../../store/actions/general";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Formik } from "formik";
import AdminSearchBar from "../../../components/partials/adminSearchBar";

export default function Limit() {
  const dispatch = useDispatch();
  const { showCreateModal, savingLimit, savedLimit } =
    useSelector((state) => state.limits);
  const [country, setCountry] = useState();

  useEffect(() => {
    dispatch(fetchCountries);
  });

  useEffect(() => {
    if (savedLimit) {
      dispatch(toggleCreateLimitModal());
      document.getElementById("form").reset();
      setCountry("");
    }
  }, [savedLimit]);

  const { msg } = useSelector((state) => state.error);
  const user = JSON.parse(localStorage.getItem('authRes'));
  const permissions = user.permissions;

  return (
    <div className="container">
      <h5 className="fw-bold">Limits</h5>
      <p className="text-muted mb-4">
        Here is a table containing a list of all your limits. <br /> You can
        delete and edit using the action button on the right.
      </p>
      <div className="row justify-content-between mb-4">
        {permissions['create limit'] && <div className="col-sm order-sm-1 text-end mb-3">
          <button
            className="btn btn-red px-5 min-height"
            onClick={() => {
              dispatch(toggleCreateLimitModal());
            }}
          >
            Create Limit
          </button>
        </div>}
        <AdminSearchBar callback={searchLimit} />
      </div>
      <List />
      <Modal
        visible={showCreateModal}
        footer={false}
        onCancel={() => {
          dispatch(toggleCreateLimitModal());
        }}
      >
        <div>
          <h5 className="fw-bold">Create Limit</h5>
          <Formik
            initialValues={{ for_country: "", days: "", amount: "", times: "", yearly_amount:"" }}
            validationSchema={Yup.object({
              for_country: Yup.string("").required("Select country"),
              days: Yup.string("").required("enter days"),
              amount: Yup.string("").required("enter amount"),
              times: Yup.string("").required("enter times"),
              yearly_amount: Yup.string("").required("enter yearly amount"),
            })}
            onSubmit={(values) => {
              dispatch(createLimit(values));
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} id="form">
                <div className="form-group mb-3">
                  <label>Select To Country</label>
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      return option.name
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    placeholder="Select country"
                    className="form-control min-height"
                    onChange={(value) => {
                      setCountry(value);
                      props.setValues({ ...props.values, for_country: value });
                    }}
                    value={country}
                  >
                    {JSON.parse(localStorage.getItem("cosmo_countries"))?.map(
                      (el) => {
                        return (
                          <Select.Option
                            key={el.id}
                            id={el.id}
                            countryCode={el.phonecode}
                            name={el.name}
                          >
                            <span
                              className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                            ></span>
                            {"\u00A0"}
                            {el.name}
                          </Select.Option>
                        );
                      }
                    )}
                  </Select>
                  {props.touched.for_country && props.errors.for_country && (
                    <FormikError msg={props.errors.for_country} />
                  )}
                  <FormikError msg={msg?.for_country ?? ""} />
                </div>
                <div className="form-group mb-3">
                  <label>Limit Days</label>
                  <input
                    type="number"
                    className="form-control min-height"
                    name="days"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                  {props.touched.days && props.errors.days && (
                    <FormikError msg={props.errors.days} />
                  )}
                  <FormikError msg={msg?.days ?? ""} />
                </div>
                <div className="form-group mb-3">
                  <label>Limit Transaction Times</label>
                  <input
                    type="number"
                    className="form-control min-height"
                    name="times"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                  {props.touched.times && props.errors.times && (
                    <FormikError msg={props.errors.times} />
                  )}
                  <FormikError msg={msg?.times ?? ""} />
                </div>
                <div className="form-group mb-3">
                  <label>Amount</label>
                  <input
                    type="number"
                    className="form-control min-height"
                    name="amount"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                  {props.touched.amount && props.errors.amount && (
                    <FormikError msg={props.errors.amount} />
                  )}
                  <FormikError msg={msg?.amount ?? ""} />
                </div>
                <div className="form-group mb-3">
                  <label>Yearly amount</label>
                  <input
                    type="number"
                    className="form-control min-height"
                    name="yearly_amount"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                  {props.touched.yearly_amount && props.errors.yearly_amount && (
                    <FormikError msg={props.errors.yearly_amount} />
                  )}
                  <FormikError msg={msg?.yearly_amount ?? ""} />
                </div>
                <div className="text-end">
                  <button
                    className="btn btn-outline-red ms-2 px-4"
                    type="button"
                    onClick={() => {
                      dispatch(toggleCreateLimitModal());
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red ms-2 px-4"
                    type="submit"
                    disabled={savingLimit}
                  >
                    Submit{savingLimit && <Spin />}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}
