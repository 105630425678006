import React, { useState } from "react";
import { Modal, Segmented } from "antd";
import SwapMoney from "./swapMoney";
import TransferMoney from "./transferMoney";
import { useDispatch, useSelector } from "react-redux";
import CreateWallet from "../walletComponents/modals/CreateWallet/CreateWallet";
import SuccessfulCreateWallet from "../walletComponents/modals/success/SuccessfulCreateWallet";
import { closeWalletCreatedModal } from "../../../store/actions/walletActions";

export default function DashboardSendMoney({ receiver }) {
	const [view, setView] = useState("transfer");
	const [showModal, setShowModal] = useState(false);
	const [showSwapWarning, setShowSwapWarning] = useState(false);
	const send_option = sessionStorage.getItem("send_option");

	const { canUseWallet, user_wallets, wallet_created } = useSelector(
		(state) => state.wallet
	);
	const dispatch = useDispatch();

	const handleCancel = () => {
		setShowModal(false);
	};
	const handleShowModal = () => {
		setShowModal(true);
	};
	const cancelSuccessfulModal = () => {
		dispatch(closeWalletCreatedModal());
	};
	return (
		<div>
			{/* {send_option === "multiple" && ( */}
			{canUseWallet &&
				send_option ===
					"multiple"(
						<div className="d-flex swiper_container justify-content-between  p-1 align-items-center rounded-2">
							<Segmented
								value={view}
								onChange={(value) => {
									if (value === "swap" && user_wallets?.length < 1) {
										setShowSwapWarning(true);
										setView("transfer");

										return;
									}
									localStorage.setItem(
										"trx_type",
										value === "swap" ? value : ""
									);

									setView(value);
								}}
								className="w-100 swiper bg-transparent "
								size="middle"
								options={[
									{
										label: "Transfer Money",
										value: "transfer",
									},
									{
										label: "Swap Currency",
										value: "swap",
									},
								]}
							/>
						</div>
					)}
			{view === "transfer" ? (
				<TransferMoney receiver={receiver} />
			) : (
				<SwapMoney showModal={showModal} />
			)}

			<CreateWallet showModal={showModal} handleCancel={handleCancel} />
			<SuccessfulCreateWallet
				showModal={wallet_created.created}
				handleCancel={cancelSuccessfulModal}
			/>

			<Modal
				visible={showSwapWarning}
				footer={false}
				onCancel={() => {
					setShowSwapWarning(false);
				}}
				zIndex={1090}
			>
				<div className="d-flex flex-column justify-content-center p-1 gap-3  align-items-center ">
					Please create a Wallet to access this feature
					<button
						onClick={() => {
							handleShowModal();
							setShowSwapWarning(false);
						}}
						className="btn min-height btn-red "
					>
						Click to Create a Wallet
					</button>
				</div>
			</Modal>
		</div>
	);
}
