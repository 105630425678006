import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import moment from "moment";
import { DatePicker, Select, Spin } from "antd";
import { fetchCountries } from "../../../store/actions/general";
import { fetchRoles } from "../../../store/actions/admin/accessControl";
import { createStaff } from "../../../store/actions/admin/staffs";

export default function Create() {
  const [state, setState] = useState({
    profile: null,
    id_front: null,
    id_rear: null,
  });

  const navigate = useNavigate();
  const [rearImgErr, setRearImgErr] = useState(false);
  const [frontImgErr, setFrontImgErr] = useState(false);
  const [profileImgErr, setProfileImgErr] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const { roles, fetchingAcl } = useSelector((state) => state.acl);

  const { savingStaffs,savedStaffs } = useSelector((state) => state.staff);

  useEffect(() => {
    dispatch(fetchCountries);
    dispatch(fetchRoles());
  }, []);

  useEffect(()=>{
    if(savedStaffs)navigate('/staffs')
  },[savedStaffs]);

  const { msg } = useSelector((state) => state.error);

  return (
    <div className="container">
      <h5 class="fw-bold">Create Staff</h5>
      <p class="text-muted mb-4">
        Here is a form where you can add a new staff.
      </p>
      {/* Basic Info */}
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          othernames: "",
          email: "",
          address: "",
          gender: "",
          nationality: "",
          date_joined: "",
          dob: "",
          qualification: "",
          role_id: "",
          password: "",
          id_front: "",
          id_rear: "",
          photo: "",
        }}
        validationSchema={Yup.object({
          firstname: Yup.string("").required("enter firstname"),
          lastname: Yup.string("").required("enter last"),
          othernames: Yup.string(""),
          email: Yup.string("Enter your email")
            .required("Email is required")
            .test("Email", "Enter a valid email", function (value) {
              const emailRegex =
                /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

              let isValidEmail = emailRegex.test(value);
              if (!isValidEmail) {
                return false;
              }

              return true;
            }),
          address: Yup.string("").required("enter address"),
          gender: Yup.string("").required("select gender"),
          nationality: Yup.string("").required("nationality is required"),
          date_joined: Yup.string("").required("select date"),
          dob: Yup.string("").required("select date"),
          qualification: Yup.string("").required("enter qualification"),
          role_id: Yup.string("").required("select role"),
          password: Yup.string("").min(6).required("enter password"),
          id_front: Yup.mixed().required("upload font id"),
          id_rear: Yup.mixed().required("upload rear id"),
          photo: Yup.mixed().required("upload photo"),
        })}
        onSubmit={(values) => {
          dispatch(createStaff(values));
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <div className="profile-image">
                    <img
                      src={
                        state.profile ?? "../assets/images/avatar/avatar.jpeg"
                      }
                      className="profile-img-tag img-fluid"
                      alt="profile pic"
                    />
                    <input
                      type="file"
                      className="form-control min-height"
                      onChange={(e) => {
                        let err = 0;
                        setProfileImgErr(false);
                        if (e.target.files[0].size > 2000000) {
                          err = 1;
                          setProfileImgErr(true);

                          return;
                        }

                        if (!err) {
                          props.setValues({
                            ...props.values,
                            photo: e.target.files[0],
                          });
                          setState({
                            ...state,
                            profile: URL.createObjectURL(e.target.files[0]),
                          });
                        }
                      }}
                      accept=".png,.jpg,.jfif,.jpeg"
                    />
                  </div>
                  <label>Profile Picture</label>
                </div>
                {profileImgErr && (
                    <FormikError msg="image must be less than 2MB" />
                  )}
                  {props.touched.photo && props.errors.photo && (
                    <FormikError msg={props.errors.photo} />
                  )}
                  <FormikError msg={msg?.photo ?? ""} />
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label>First name</label>
                  <input
                    type="text"
                    className="form-control min-height"
                    name="firstname"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.firstname}
                  />
                  {props.touched.firstname && props.errors.firstname && (
                    <FormikError msg={props.errors.firstname} />
                  )}
                  <FormikError msg={msg?.firstname ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor>Last name</label>
                  <input
                    type="text"
                    className="form-control min-height"
                    name="lastname"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.lastname}
                  />
                  {props.touched.lastname && props.errors.lastname && (
                    <FormikError msg={props.errors.lastname} />
                  )}
                  <FormikError msg={msg?.lastname ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor>Other name</label>
                  <input
                    type="text"
                    className="form-control min-height"
                    name="othernames"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.othernames}
                  />
                  {props.touched.othernames && props.errors.othernames && (
                    <FormikError msg={props.errors.othernames} />
                  )}
                  <FormikError msg={msg?.othernames ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor>Email</label>
                  <input
                    type="email"
                    className="form-control min-height"
                    name="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                  />
                  {props.touched.email && props.errors.email && (
                    <FormikError msg={props.errors.email} />
                  )}
                  <FormikError msg={msg?.email ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor>Gender</label>
                  <select
                    className="form-select min-height"
                    name="gender"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    defaultValue={props.values.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="male" selected>
                      Male
                    </option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor>Date of birth</label>
                  <DatePicker
                    onChange={(date, dateString) => {
                      props.setValues({
                        ...props.values,
                        dob: dateString,
                      });
                    }}
                    onBlur={props.handleBlur}
                    className="form-control min-height"
                    name="dob"
                    defaultValue={
                      props.values.dob
                        ? moment(props.values.dob, "YYYY-MM-DD")
                        : false
                    }
                  />
                  {props.touched.dob && props.errors.dob && (
                    <FormikError msg={props.errors.dob} />
                  )}
                  <FormikError msg={msg?.dob ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor>Date Joined</label>
                  <DatePicker
                    onChange={(date, dateString) => {
                      props.setValues({
                        ...props.values,
                        date_joined: dateString,
                      });
                    }}
                    onBlur={props.handleBlur}
                    className="form-control min-height"
                    name="date_joined"
                    defaultValue={
                      props.values.date_joined
                        ? moment(props.values.date_joined, "YYYY-MM-DD")
                        : false
                    }
                  />
                  {props.touched.date_joined && props.errors.date_joined && (
                    <FormikError msg={props.errors.date_joined} />
                  )}
                  <FormikError msg={msg?.date_joined ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="mb-2">Nationality</label>
                  {/* select box */}
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      return option.name
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    placeholder="Select country"
                    className="form-control min-height"
                    onChange={(value) => {
                      props.setValues({
                        ...props.values,
                        nationality: value.key,
                      });
                    }}
                    labelInValue
                  >
                    {JSON.parse(localStorage.getItem("cosmo_countries"))?.map(
                      (el) => {
                        return (
                          <Select.Option
                            key={el.id}
                            id={el.id}
                            value={el.name}
                            countryCode={el.phonecode}
                            name={el.name}
                          >
                            <span
                              className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                            ></span>
                            {"\u00A0"}
                            {el.name}
                          </Select.Option>
                        );
                      }
                    )}
                  </Select>
                  {props.touched.nationality && props.errors.nationality && (
                    <FormikError msg={props.errors.nationality} />
                  )}
                  <FormikError msg={msg?.nationality ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label>Address</label>
                  <textarea
                    rows={3}
                    className="form-control"
                    placeholder="Address"
                    value={props.values.address}
                    name="address"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                  {props.touched.address && props.errors.address && (
                    <FormikError msg={props.errors.address} />
                  )}
                  <FormikError msg={msg?.address ?? ""} />
                </div>
              </div>
            </div>
            <hr />
            <h5 className="fw-bold mt-5 mb-4">Other Info</h5>
            {/* Other Info */}
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor className="mb-2">
                    Upload ID (front view) <span className="text-red">*</span>
                  </label>
                  <div className="input-file-upload">
                    <div className="file-component">
                      <span className="d-block text-center w-100">
                        <i
                          className="fas fa-cloud-upload-alt"
                          style={{ fontSize: 20 }}
                        />
                        <p>
                          <span className="text-primary">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <span className="fw-lighter">
                          JPG, PNG or JPEG (max 2mb)
                        </span>
                      </span>
                      <input
                        type="file"
                        className="form-control"
                        accept=".png,.jpg,.jfif,.jpeg"
                        onChange={(e) => {
                          let err = 0;
                          setFrontImgErr(false);
                          if (e.target.files[0].size > 2000000) {
                            err = 1;
                            setFrontImgErr(true);

                            return;
                          }

                          if (!err) {
                            props.setValues({
                              ...props.values,
                              id_front: e.target.files[0],
                            });
                            setState({
                              ...state,
                              id_front: URL.createObjectURL(e.target.files[0]),
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {frontImgErr && (
                    <FormikError msg="image must be less than 2MB" />
                  )}
                  {props.touched.id_front && props.errors.id_front && (
                    <FormikError msg={props.errors.id_front} />
                  )}
                  <FormikError msg={msg?.id_front ?? ""} />
                  {state.id_front && (
                    <img
                      src={state.id_front}
                      alt="id front pic"
                      className="img-fluid my-3"
                      width="300px"
                      height="400px"
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor className="mb-2">
                    Upload ID (Rear view) <span className="text-red">*</span>
                  </label>
                  <div className="input-file-upload">
                    <div className="file-component">
                      <span className="d-block text-center w-100">
                        <i
                          className="fas fa-cloud-upload-alt"
                          style={{ fontSize: 20 }}
                        />
                        <p>
                          <span className="text-primary">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <span className="fw-lighter">
                          JPG, PNG or JPEG (max 2mb)
                        </span>
                      </span>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => {
                          let err = 0;
                          setRearImgErr(false);
                          if (e.target.files[0].size > 2000000) {
                            err = 1;
                            setRearImgErr(true);

                            return;
                          }

                          if (!err) {
                            props.setValues({
                              ...props.values,
                              id_rear: e.target.files[0],
                            });
                            setState({
                              ...state,
                              id_rear: URL.createObjectURL(e.target.files[0]),
                            });
                          }
                        }}
                        accept=".png,.jpg,.jfif,.jpeg"
                      />
                    </div>
                  </div>
                  {rearImgErr && (
                    <FormikError msg="image must be less than 2MB" />
                  )}
                  {props.touched.id_rear && props.errors.id_rear && (
                    <FormikError msg={props.errors.id_rear} />
                  )}
                  <FormikError msg={msg?.id_rear ?? ""} />
                  {state.id_rear && (
                    <img
                      src={state.id_rear}
                      alt="id rear pic"
                      className="img-fluid my-3"
                      width="300px"
                      height="400px"
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor>Qualification</label>
                  <input
                    type="text"
                    className="form-control min-height"
                    value={props.values.qualification}
                    name="qualification"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                  {props.touched.qualification &&
                    props.errors.qualification && (
                      <FormikError msg={props.errors.qualification} />
                    )}
                  <FormikError msg={msg?.qualification ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor>Role</label>
                  <select
                    className="form-select min-height"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="role_id"
                    value={props.values.role_id}
                  >
                    <option value>Select role</option>
                    {roles.map((role, index) => {
                      return <option value={role.id}>{role.name}</option>;
                    })}
                  </select>
                  {props.touched.role_id && props.errors.role_id && (
                    <FormikError msg={props.errors.role_id} />
                  )}
                  <FormikError msg={msg?.role_id ?? ""} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label>Password</label>
                  <div className="input-group">
                    <input
                      type={`${showPassword ? "text" : "password"}`}
                      className="form-control border-end-0 min-height"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      name="password"
                    />
                    <button
                      className="btn border border-start-0"
                      type="button"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <i class="far fa-eye-slash text-muted"></i>
                      ) : (
                        <i className="far fa-eye text-muted" />
                      )}
                    </button>
                  </div>
                  {props.touched.password && props.errors.password && (
                    <FormikError msg={props.errors.password} />
                  )}
                  <FormikError msg={msg?.password ?? ""} />
                </div>
              </div>
            </div>
            <div className="text-end my-4">
              <button
                className="btn btn-red px-4 min-height"
                type="submit"
                disabled={savingStaffs}
              >
                <i className="fas fa-plus" /> &nbsp; Add Staff{" "}
                {savingStaffs && <Spin />}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
