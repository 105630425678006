import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/admin/dashboard/index";
import Staffs from "../pages/admin/staffs/index";
import Edit from "../pages/admin/staffs/edit";
import Create from "../pages/admin/staffs/create";
import AccessControl from "../pages/admin/access-control/index";
import Rates from "../pages/admin/rates/index";
import Limit from "../pages/admin/limit/index";
import Settings from "../pages/admin/settings/index";
import Payin from "../pages/admin/payin/index";
import Payout from "../pages/admin/payout/index";
import Senders from "../pages/admin/senders/index";
import Details from "../pages/admin/senders/details";
import Pending from "../pages/admin/user-uploads/pending";
import Rejected from "../pages/admin/user-uploads/rejected";
import Approved from "../pages/admin/user-uploads/approved";
import ComplianceIssues from "../pages/admin/senders/compliance_issues";
import Deactivated from "../pages/admin/senders/deactivated";
import Banned from "../pages/admin/senders/banned";
import FailedTrx from "../pages/admin/transaction/failed";
import PendingTrx from "../pages/admin/transaction/pending";
import Refunded from "../pages/admin/transaction/refunded";
import Incompleted from "../pages/admin/transaction/incompleted";
import Suspended from "../pages/admin/transaction/suspended";
import Inprogress from "../pages/admin/transaction/inprogress";
import Completed from "../pages/admin/transaction/completed";
import FailedPayout from "../pages/admin/transaction/failedPayouts";
import RequestedDeactivation from "../pages/admin/senders/requestedDeactivation";
import AdminTransfer from "../pages/admin/admin-transfer";
import WalletFundings from "../pages/admin/wallet-fundings";
import WalletSwappings from "../pages/admin/swappings";
import VerifiedBusinesses from "../pages/admin/businesses";
import UnverifiedBusinesses from "../pages/admin/businesses/unVerifiedBusinesses";
import TrxReports from "../pages/admin/transaction/reports";
import BusinessDocuments from "../pages/admin/businesses/businessDocuments";

export default function AdminRoutes() {
  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user.permissions;
  return (
    JSON.parse(localStorage.getItem("authRes")).profile_type ===
      "aprofiles" && (
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route exact path="access-control" element={<AccessControl />} />
        {permissions["view rate"] && (
          <Route exact path="rates" element={<Rates />} />
        )}
        <Route exact path="limit" element={<Limit />} />
        {permissions["settings"] && (
          <Route exact path="settings" element={<Settings />} />
        )}
        <Route exact path="payin" element={<Payin />} />
        <Route exact path="payout" element={<Payout />} />
        {permissions["can_transfer"] && (
          <Route exact path="admin-transfer" element={<AdminTransfer />} />
        )}

        {/* uploads routes */}
        <Route exact path="pending-uploads" element={<Pending />} />
        <Route exact path="rejected-uploads" element={<Rejected />} />
        <Route exact path="approved-uploads" element={<Approved />} />

        {/* transactions route */}
        <Route exact path="failed-transactions" element={<FailedTrx />} />
        <Route exact path="failed-payouts" element={<FailedPayout />} />
        <Route exact path="pending-transactions" element={<PendingTrx />} />
        <Route exact path="refunded-transactions" element={<Refunded />} />
        <Route exact path="incomplete-transactions" element={<Incompleted />} />
        <Route exact path="suspended-transactions" element={<Suspended />} />
        <Route exact path="inprogress-transactions" element={<Inprogress />} />
        <Route exact path="completed-transactions" element={<Completed />} />
        <Route exact path="wallet-fundings" element={<WalletFundings />} />
        <Route exact path="swappings" element={<WalletSwappings />} />
        <Route exact path="transaction-reports" element={<TrxReports />} />

        {/* senders route */}
        {permissions["view user"] && (
          <Route exact path="senders">
            <Route index element={<Senders />} />
            <Route path="details" element={<Details />} />
            <Route path="compliance-issues" element={<ComplianceIssues />} />
            <Route path="deactivated" element={<Deactivated />} />
            <Route path="banned" element={<Banned />} />
            <Route
              path="requested-deactivation"
              element={<RequestedDeactivation />}
            />
          </Route>
        )}
        {/* business route */}
        {permissions["view user"] && (
          <Route exact path="businesses">
            <Route path="verified" element={<VerifiedBusinesses />} />
            <Route path="unverified" element={<UnverifiedBusinesses />} />
            <Route path="business-documents" element={<BusinessDocuments />} />
          </Route>
        )}
        {/* staffs route */}
        {permissions["view staff"] && (
          <Route path="staffs">
            <Route index element={<Staffs />} />
            <Route path="edit" element={<Edit />} />
            <Route path="create" element={<Create />} />
          </Route>
        )}

        <Route path="*" element="404" />
      </Routes>
    )
  );
}
