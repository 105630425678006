import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Spin, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { saveKyc } from "../../../store/actions/profileActions";
import { fetchIdTypes } from "../../../store/actions/general";
import moment from "moment";
import Front from "../../../assets/images/icon/front.jpg";
import Back from "../../../assets/images/icon/back.jpg";
import { omit } from "lodash";

export default function Kyc() {
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.kyc);
	const { msg } = useSelector((state) => state.error);
	// const { idtypes } = useSelector((state) => state.general);
	const { addresCompleted } = useSelector((state) => state.address);
	const { profile, idtype } = JSON.parse(localStorage.getItem("authRes"));
	const [frontImg, setFrontImg] = useState(null);
	const [rearImg, setRearImg] = useState(null);
	const [frontErr, setFrontErr] = useState(false);
	const [rearErr, setRearErr] = useState(false);
	const [myIdTypes, setMyIdTypes] = useState([]);
	const [showNumber, setShowNumber] = useState(false);
	const [selIdType, setSelIdType] = useState("");
	const [idMetas, setIdMetas] = useState("");
	const [hasMetas, setHasMetas] = useState(false);
	const [metasObj, setMetasObj] = useState({});

	var classNames = require("classnames");

	let imageSelectHandler = (e) => {
		if (e.target.files.length > 0) {
			let src = URL.createObjectURL(e.target.files[0]);
			if (e.target.name === "scanned_id_rear") {
				setRearImg({ image: e.target.files[0], src: src });
			} else {
				setFrontImg({ image: e.target.files[0], src: src });
			}
		}
	};

	const formikRef = useRef();
	const storedIdTypes = JSON.parse(localStorage.getItem("idtypes"));
	useEffect(() => {
		dispatch(fetchIdTypes);
		setSelIdType(idtype?.id);
	}, []);

	useEffect(() => {
		setMyIdTypes(
			storedIdTypes?.filter((el) => el.country_id === profile.country_id)
		);
	}, [storedIdTypes]);

	useEffect(() => {
		const check = myIdTypes?.find((el) => Number(el.id) === Number(selIdType));
		setShowNumber(check?.card_no_required);
		setIdMetas(check?.metas);

		if (idMetas) {
			const metasCheck =
				idtype?.id === Number(selIdType) ? profile?.idtype_metas : {};
			setMetasObj(metasCheck);
			setHasMetas(true);
		}

		formikRef?.current?.setValues({
			...formikRef?.current.values,
			showCardNumber: check?.card_no_required ? true : false,
			idtype_metas: metasObj,
		});
	}, [myIdTypes, idMetas, selIdType]);

	useEffect(() => {
		if (showNumber) {
			formikRef?.props?.setValues({
				...formikRef.props.values,
				showCardNumber: showNumber ? true : false,
				idtype_metas: metasObj,
			});
		}
	}, [showNumber]);

	useEffect(() => {
		setFrontImg(profile?.idscan_path);
		setRearImg(profile?.back_idscan_path);
	}, []);

	const schema = Yup.object({
		showCardNumber: Yup.boolean(),
		id_type: Yup.string()
			.required("Id type is required")
			.typeError("Id type is required"),
		id_number: Yup.string()
			.required("Id number is required")
			.typeError("Valid Id number is required"),
		scanned_id_rear: Yup.mixed(),
		scanned_id_front: Yup.mixed(),
		card_number: Yup.string()
			.nullable()
			.when("showCardNumber", {
				is: true,
				then: Yup.string()
					.required("Enter card number")
					.typeError("Enter card number"),
			}),
		issue_date: Yup.string().nullable(),
		expiry_date: Yup.string()
			.required("Id expiry date is required")
			.typeError("expiry date is required"),
			// .test("expiry date", () => {
			// 	setFrontErr(false);
			// 	setRearErr(false);
			// 	if (!frontImg) {
			// 		setFrontErr(true);
			// 	}
			// 	if (!rearImg) {
			// 		setRearErr(true);
			// 	}
			// 	return true;
			// }),
		idtype_metas:
			hasMetas && idMetas
				? Yup.object().shape(
						Object.fromEntries(
							Object.entries(idMetas.required).map(([key, value]) => [
								key,
								Yup.string().required(`Enter a valid ${value}`),
							])
						)
				  )
				: Yup.object().shape({}), // Set empty object if idMetas is null
	});

	const onSubmit = (values) => {
		if (frontErr || rearErr) {
			return;
		}
		let fd = new FormData();
		fd.append("id_type", values.id_type);
		fd.append("id_number", values.id_number);
		fd.append("issue_date", values.issue_date);
		fd.append("card_number", values.card_number);
		fd.append("expiry_date", values.expiry_date);
		fd.append("scanned_id_front", frontImg.image);
		fd.append("scanned_id_rear", rearImg.image);

		// for (const key in idMetas?.required) {
		//   const value = idMetas.required[key];
		//   console.log(`${key}: ${value}`);
		const idtype_metas = omit(values.idtype_metas, "key");
		const metas = { required: idtype_metas };
		fd.append("idtype_metas", JSON.stringify(metas));
		// }

		dispatch(saveKyc(fd));
	};

	const check = myIdTypes?.find((el) => Number(el.id) === Number(idtype?.id))
		?.card_no_required
		? true
		: false;
	const initialValues = {
		id_type: idtype?.id,
		id_number: profile?.id_number,
		issue_date: profile?.id_issue_date,
		expiry_date: profile?.id_expiry_date,
		card_number: profile?.card_number,
		scanned_id_front: "",
		scanned_id_rear: "",
		showCardNumber: check,
		idtype_metas: metasObj,
	};
	return (
		<div className="accordion-item border-bottom-0">
			<h2 className="accordion-header" id="flush-headingOne">
				<button
					className="accordion-button collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#flush-collapseThree"
					disabled={addresCompleted ? false : true}
				>
					<div className="d-flex align-items-center">
						<div className="flex-shrink-0">
							<img src="../assets/images/icon/Group 779.png" alt="" />
						</div>
						<div className="flex-grow-1 ms-3">
							<h6 className="fw-bold">3. KYC</h6>
							<p className="text-muted">
								Upload a valid form of identification
							</p>
						</div>
					</div>
				</button>
			</h2>
			{addresCompleted && (
				<div
					id="flush-collapseThree"
					className="accordion-collapse collapse"
					aria-labelledby="flush-headingOne"
					data-bs-parent="#accordionFlushExample"
				>
					<div className="accordion-body border-bottom pt-1 pb-4">
						<Formik
							initialValues={initialValues}
							onSubmit={onSubmit}
							validationSchema={schema}
							innerRef={formikRef}
						>
							{(props) => (
								<form onSubmit={props.handleSubmit} className="ms-lg-5">
									<div className="row">
										{/* Type of ID */}
										<div className="col-xl-5 col-md-6">
											<div className="form-group mb-3">
												<label className="mb-2">
													Type of ID <span className="text-red">*</span>
												</label>
												<select
													name="id_type"
													className="form-select min-height"
													onChange={(e) => {
														props.setValues({
															...props.values,
															id_type: e.target.value,
														});
														setSelIdType(e.target.value);
													}}
													onBlur={props.handleBlur}
													value={props.values?.id_type}
												>
													<option value="">--Select--</option>
													{myIdTypes?.map((el) => (
														<option value={el.id} key={el.id}>
															{el.name}
														</option>
													))}
												</select>
												{props.touched.id_type && props.errors.id_type && (
													<FormikError msg={props.errors.id_type} />
												)}
												<FormikError msg={msg?.id_type ?? ""} />
											</div>
										</div>
										{/* ID number */}
										<div className="col-xl-5 col-md-6">
											<div className="form-group mb-3">
												<label className="mb-2">
													ID number <span className="text-red">*</span>
												</label>
												<input
													type="text"
													name="id_number"
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													defaultValue={props.values.id_number}
													className="form-control min-height"
												/>
												{props.touched.id_number && props.errors.id_number && (
													<FormikError msg={props.errors.id_number} />
												)}
												<FormikError msg={msg?.id_number ?? ""} />
											</div>
										</div>

										{/* Card number */}
										{showNumber ? (
											<div className="col-xl-5 col-md-6">
												<div className="form-group mb-3">
													<label className="mb-2">
														Card number <span className="text-red">*</span>
													</label>
													<input
														type="text"
														name="card_number"
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														defaultValue={props.values.card_number}
														className="form-control min-height"
													/>
													{props.touched.card_number &&
														props.errors.card_number && (
															<FormikError msg={props.errors.card_number} />
														)}
													<FormikError msg={msg?.card_number ?? ""} />
												</div>
											</div>
										) : (
											""
										)}

										{/* ID issue Date */}
										<div className="col-xl-5 col-md-6">
											<div className="form-group mb-3">
												<label className="mb-2">
													ID issue Date
												</label>
												<div className="input-group rounded-0">
													<DatePicker
														onChange={(date, dateString) => {
															props.setValues({
																...props.values,
																issue_date: dateString,
															});
														}}
														onBlur={props.handleBlur}
														className="form-control min-height"
														name="issue_date"
														defaultValue={
															props.values.issue_date
																? moment(props.values.issue_date, "YYYY-MM-DD")
																: null
														}
													/>
													<span className="input-group-text end bg-white">
														<i className="far fa-calendar-alt" />
													</span>
												</div>
												{props.touched.issue_date &&
													props.errors.issue_date && (
														<FormikError msg={props.errors.issue_date} />
													)}
												<FormikError msg={msg?.issue_date ?? ""} />
											</div>
										</div>
										{/* ID Expiry Date */}
										<div className="col-xl-5 col-md-6">
											<div className="form-group mb-3">
												<label className="mb-2">
													ID Expiry Date <span className="text-red">*</span>
												</label>
												<div className="input-group rounded-0">
													<DatePicker
														onChange={(date, dateString) => {
															props.setValues({
																...props.values,
																expiry_date: dateString,
															});
														}}
														onBlur={props.handleBlur}
														className="form-control min-height"
														name="expiry_date"
														defaultValue={
															props.values.expiry_date
																? moment(props.values.expiry_date, "YYYY-MM-DD")
																: null
														}
													/>
													<span className="input-group-text end bg-white">
														<i className="far fa-calendar-alt" />
													</span>
												</div>
												{props.touched.expiry_date &&
													props.errors.expiry_date && (
														<FormikError msg={props.errors.expiry_date} />
													)}
												<FormikError msg={msg?.expiry_date ?? ""} />
											</div>
										</div>
										{/* METAS INPUT */}
										{Object.entries(idMetas?.required || {}).map(
											([key, value]) => (
												<div key={key} className="col-xl-5 col-md-6">
													<div className="form-group mb-3">
														<label className="mb-2">
															{value} <span className="text-red">*</span>
														</label>
														<input
															type="text"
															name={`idtype_metas.${key}`}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															defaultValue={
																props.values?.idtype_metas
																	? props.values?.idtype_metas[key]
																	: ""
															}
															className="form-control min-height"
														/>
														{props.touched.idtype_metas?.[key] &&
															props.errors.idtype_metas?.[key] && (
																<FormikError
																	msg={props.errors.idtype_metas[key]}
																/>
															)}
														{/* <FormikError msg={msg?.idtype_metas[key] ?? ""} /> */}
													</div>
												</div>
											)
										)}

										<div className="row">
											<div className="col-xl-5 col-md-6">
												<label>Sample front ID</label>
												<div className="input-file-upload">
													<img src={Front} alt="" width="70%" />
												</div>
											</div>
											<div className="col-xl-5 col-md-6">
												<label>Sample back ID</label>
												<div className="input-file-upload">
													<img src={Back} alt="" width="70%" />
												</div>
											</div>
										</div>
										<div className="row">
											{/* Upload ID */}
											<div className="col-xl-5 col-md-6">
												<div className="form-group mb-3">
													<label htmlFor className="mb-2">
														Upload ID (front view){" "}
														<span className="text-red">*</span>
													</label>
													<div className="input-file-upload">
														<div
															className="file-component"
															style={{ backgroundImage: "red" }}
														>
															<span
																className={classNames({
																	"d-block": true,
																	"text-center ": true,
																	"w-100": true,
																	"d-none": frontImg,
																})}
															>
																<i
																	className="fas fa-cloud-upload-alt"
																	style={{ fontSize: 20 }}
																/>
																<p>
																	<span className="text-primary">
																		Click to upload
																	</span>{" "}
																	or drag and drop
																</p>
																<span className="fw-lighter">
																	JPG, PNG or JPEG (max 8mb)
																</span>
															</span>
															<span
																className={classNames({
																	"d-block": true,
																	"text-center ": true,
																	"w-100": true,
																	"d-none": !frontImg,
																})}
																style={{
																	margin: "auto ",
																	position: "absolute",
																}}
															>
																<i
																	className="fas fa-pen"
																	style={{ fontSize: 20 }}
																/>
															</span>
															<img
																src={frontImg?.src ?? frontImg}
																alt="front"
																width="50%"
																height="100%"
																className={classNames({ "d-none": !frontImg })}
															/>
															<input
																type="file"
																className="form-control"
																onChange={imageSelectHandler}
																name="scanned_id_front"
																accept=".png,.jpg,.jpeg"
															/>
														</div>
														{frontErr && (
															<FormikError msg="Upload front image of ID" />
														)}
														<FormikError msg={msg?.scanned_id_front ?? ""} />
													</div>
												</div>
											</div>
											{/* Upload ID */}
											<div className="col-xl-5 col-md-6">
												<div className="form-group mb-3">
													<label htmlFor className="mb-2">
														Upload ID (rear view){" "}
														<span className="text-red">*</span>
													</label>
													<div className="input-file-upload">
														<div
															className="file-component"
															// style={{
															//   backgroundImage: `url(${rearImg})`,
															//   backgroundSize: "contain",
															//   backgroundRepeat: "no-repeat",
															//   backgroundPosition: "center",
															//   backgroundColor: "transparent",
															// }}
														>
															<span
																className={classNames({
																	"d-block": true,
																	"text-center ": true,
																	"w-100": true,
																	"d-none": rearImg,
																})}
															>
																<i
																	className="fas fa-cloud-upload-alt"
																	style={{ fontSize: 20 }}
																/>
																<p>
																	<span className="text-primary">
																		Click to upload
																	</span>{" "}
																	or drag and drop
																</p>
																<span className="fw-lighter">
																	JPG, PNG or JPEG (max 8mb)
																</span>
															</span>
															<span
																className={classNames({
																	"d-block": true,
																	"text-center ": true,
																	"w-100": true,
																	"d-none": !rearImg,
																})}
																style={{
																	margin: "auto ",
																	position: "absolute",
																}}
															>
																<i
																	className="fas fa-pen"
																	style={{ fontSize: 20 }}
																/>
															</span>
															<img
																src={rearImg?.src ?? rearImg}
																alt="front"
																width="50%"
																height="100%"
																className={classNames({ "d-none": !rearImg })}
															/>
															<input
																type="file"
																className="form-control"
																onChange={imageSelectHandler}
																name="scanned_id_rear"
																accept=".png,.jpg,.jpeg"
																defaultValue={rearImg}
															/>
														</div>
														{rearErr && (
															<FormikError msg="upload rear image of ID" />
														)}
														<FormikError msg={msg?.scanned_id_rear ?? ""} />
													</div>
												</div>
											</div>
										</div>
										{/* Button */}
										<div className="col-xl-10">
											<div className="form-group text-end mb-3">
												<button
													className="btn btn-light px-5 min-height border"
													type="submit"
													disabled={loading}
												>
													Update {loading && <Spin />}
												</button>
											</div>
										</div>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			)}
		</div>
	);
}
