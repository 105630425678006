import React, { useState, useEffect } from "react";
import { register } from "../../../store/actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useSearchParams } from "react-router-dom";
import FormikError from "../../../components/partials/formikError";
import { Spin, Select } from "antd";
import {
  fetchCountries,
  fetchRatedCountries,
} from "../../../store/actions/general";
import { clearError } from "../../../store/actions/errorActions";

export default function SignUpStart() {
  const dispatch = useDispatch();
  const [country, setCountry] = useState({});
  const [showCountryErr, setShowCountryErr] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { loading } = useSelector((state) => state.signup);
  const { ratedCountries, loadingRatedCountries } = useSelector(
    (state) => state.general
  );
  const { msg } = useSelector((state) => state.error);
  const [countryCode, setCountryCode] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const referral_id = searchParams.get("referral_id");

  useEffect(() => {
    dispatch(fetchCountries);
    dispatch(fetchRatedCountries);
  }, []);

  useEffect(() => {
    clearError(dispatch);
  }, []);

  const countrySelectHandler = (value, labelInValue) => {
    setCountryCode("+" + labelInValue.countryCode);
    setShowCountryErr(false);
    setCountry({ id: labelInValue.id, name: labelInValue.name });
  };

  const changePasswordType = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const schema = Yup.object({
    password: Yup.string("Enter Password")
      .min(8)
      .required("Password must be at least 6 characters"),
    referrer: Yup.string("Referral code is incomplete").min(4),
    email: Yup.string("Enter your email")
      .required("Email is required")
      .test("Email", "Enter a valid email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        let isValidEmail = emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        if (!country?.name) {
          setShowCountryErr(true);
          return false;
        }

        return true;
      }),
    phone: Yup.string("Enter phone")
      .required("Phone is required")
      .max(10, "10 digits is required"),
    policy_agreement: Yup.boolean().oneOf(
      [true],
      "You must agree to the terms and privacy policy"
    ),
    // .test("Phone", "Enter a valid phone", function (value) {
    //   const phoneRegex = /^[0-9]/;
    //   let isValidPhone = phoneRegex.test(value);
    //   if (isValidPhone) {
    //     return false;
    //   }
    //   return true;
    // }),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      referrer: referral_id ?? "",
      phone: "",
      policy_agreement: false,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        register({
          ...values,
          country_id: country.id,
          ref_code: values.referrer,
        })
      );
    },
  });

  return (
    <>
      <div className="text-center mt-5 mb-4">
        <h2 className="header4 fw-bold">Sign Up</h2>
        <p className="text-muted">
          Please fill your name as it appears on your official documents and
          government IDs.
        </p>
        <p className="text-muted">
          Already have an account?{" "}
          <Link to="/login" className="text-red">
            Log in here
          </Link>
        </p>
      </div>
      <form onSubmit={handleSubmit} autoComplete="new-password">
        <div className="row">
          {/* Country of residence */}
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="mb-2">
                Country of residence <span className="text-red">*</span>
              </label>
              {/* select box */}
              <Select
                showSearch
                filterOption={(input, option) => {
                  return option.name
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                loading={loadingRatedCountries}
                placeholder="Select country"
                className="form-control min-height"
                onChange={countrySelectHandler}
                labelInValue
                onFocus={() => {
                  !country?.name
                    ? setShowCountryErr(true)
                    : setShowCountryErr(false);
                }}
              >
                {ratedCountries?.from_countries?.map((el) => {
                  return (
                    <Select.Option
                      key={el.id}
                      id={el.id}
                      countryCode={el.phonecode}
                      name={el.name}
                    >
                      <span
                        className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                      ></span>
                      {"\u00A0"}
                      {el.name}
                    </Select.Option>
                  );
                })}
              </Select>
              {showCountryErr && (
                <FormikError msg="Country of residence is required" />
              )}
            </div>
          </div>

          {/* Email */}
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="mb-2">
                Email <span className="text-red">*</span>
              </label>
              <input
                type="email"
                name="email"
                className="form-control min-height"
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="new-password"
              />
              {touched.email && errors.email && (
                <FormikError msg={errors.email} />
              )}
              <FormikError msg={msg?.email ?? ""} />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group ">
              <label htmlFor className="mb-2">
                Phone number <span className="text-red">*</span>
              </label>
              <div className="input-group">
                {/* input box */}
                <span className="text-muted input-group-text bg-white ">
                  {countryCode}
                </span>
                <input
                  type="number"
                  // placeholder="Phone number"
                  className="form-control border-start-0 min-height"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="phone"
                />
              </div>
            </div>
            {touched.phone && errors.phone && (
              <FormikError msg={errors.phone} />
            )}
            <FormikError msg={msg?.phone ?? ""} />
          </div>

          {/* Password */}
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="mb-2">
                Password <span className="text-red">*</span>
              </label>
              <div className="input-group">
                <input
                  type={passwordType}
                  name="password"
                  className="form-control border-end-0 min-height"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <button
                  className="btn border border-start-0"
                  type="button"
                  onClick={changePasswordType}
                >
                  {passwordType === "password" ? (
                    <i className="far fa-eye" />
                  ) : (
                    <i className="far fa-eye-slash text-muted"></i>
                  )}
                </button>
              </div>
              {touched.password && errors.password && (
                <FormikError msg={errors.password} />
              )}
              <FormikError msg={msg?.password ?? ""} />
            </div>
          </div>
		     {/* Referral Code(optional) */}
			 <div className="col-lg-6">
            <div className="form-group mb-3">
              <label className="mb-2">Referral Code(optional)</label>
              <input
                value={values.referrer}
                type="text"
                className="form-control min-height"
                name="referrer"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.referrer && errors.referrer && (
                <FormikError msg={errors.referrer} />
              )}
              <FormikError msg={msg?.ref_code ?? ""} />
            </div>
          </div>
          <div className="">
            <div className="form-group mb-3">
              <label htmlFor="agreementCheck">
                <input
                  name="policy_agreement"
                  type="checkbox"
                  checked={values.policy_agreement}
                  onChange={() =>
                    setFieldValue("policy_agreement", !values.policy_agreement)
                  }
                />
                &nbsp; I agree to the{" "}
                <a
                  href="/Terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-red"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="/Privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-red"
                >
                  Privacy Policy
                </a>
                .
              </label>
              <br />
              {touched.policy_agreement && errors.policy_agreement && (
                <FormikError msg={errors.policy_agreement} />
              )}
              <FormikError msg={msg?.policy_agreement ?? ""} />
            </div>
          </div>
       
        </div>
        <div className="text-end pt-5 mt-5">
          <button
            type="submit"
            className="btn btn-red min-height px-5"
            onClick={() => {}}
            disabled={loading}
          >
            Next {loading && <Spin />}
          </button>
        </div>
      </form>
    </>
  );
}
