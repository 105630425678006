import * as actionTypes from "../../actionTypes/admin/uploadsActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";
var fileDownload = require("js-file-download");

export const fetchPending =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING, payload: true });
      const res = await http.get(
        `/admin/user_uploads/pending/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.SET_PENDING, payload: res.data.data });
      dispatch({ type: actionTypes.FETCHING, payload: false });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
      dispatch({ type: actionTypes.FETCHING, payload: false });
    }
  };

export const fetchApproved =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING, payload: true });
      const res = await http.get(
        `/admin/user_uploads/approved/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.SET_APPROVED, payload: res.data.data });
      dispatch({ type: actionTypes.FETCHING, payload: false });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
      dispatch({ type: actionTypes.FETCHING, payload: false });
    }
  };

export const fetchRejected =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING, payload: true });
      const res = await http.get(
        `/admin/user_uploads/rejected/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.SET_REJECTED, payload: res.data.data });
      dispatch({ type: actionTypes.FETCHING, payload: false });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
      dispatch({ type: actionTypes.FETCHING, payload: false });
    }
  };

export const deleteUpload = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOADING, payload: true });
    dispatch({ type: actionTypes.CHANGED, payload: false });
    const res = await http.post(`/admin/user_uploads/delete/${id}`);
    dispatch({ type: actionTypes.CHANGED, payload: true });
    dispatch({ type: actionTypes.LOADING, payload: false });
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
    dispatch({ type: actionTypes.LOADING, payload: false });
    dispatch({ type: actionTypes.FETCHING, payload: false });
  }
};

export const approveUpload = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOADING, payload: true });
    dispatch({ type: actionTypes.CHANGED, payload: false });
    const res = await http.post(`/admin/user_uploads/approve/${fd.id}`, {
      expiry_period: fd.expiry_period,
    });
    dispatch({ type: actionTypes.CHANGED, payload: true });
    dispatch({ type: actionTypes.LOADING, payload: false });
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
    dispatch({ type: actionTypes.LOADING, payload: false });
    dispatch({ type: actionTypes.FETCHING, payload: false });
  }
};

export const rejectUpload = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOADING, payload: true });
    dispatch({ type: actionTypes.CHANGED, payload: false });
    const res = await http.post(`/admin/user_uploads/reject/${fd.id}`, {
      message: fd.message,
    });
    dispatch({ type: actionTypes.CHANGED, payload: true });
    dispatch({ type: actionTypes.LOADING, payload: false });
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
    dispatch({ type: actionTypes.LOADING, payload: false });
    dispatch({ type: actionTypes.FETCHING, payload: false });
  }
};

export const fetchUploadCounts = async (dispatch) => {
  try {
    const res = await http.get(`admin/admin_metas/user_uploads_count`);
    dispatch({ type: actionTypes.SET_COUNTS, payload: res.data.data });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  }
};


export const download = (type) => async (dispatch) => {
  try {
    const res = await http.post(`admin/user_uploads/export_${type}`);
    fileDownload(res.data, `uploads.xlxn`);
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  }
};
