import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchBeneficiaries,
  deleteBeneficiary,
  setBeneficiaryToEdit,
  toggleEditModal,
  searchBeneficiary,
  getBeneficiaryToEdit,
} from "../../../store/actions/beneficiaryActions";
import { Spin, Modal, notification } from "antd";
import EditModal from "./editModal";
import SearchBar from "../../../components/partials/searchBar";
import Pagination from "../../../components/pagination/pagination";
import SendMoney from "../../../components/partials/sendMoney";
import * as notifications from "../../../components/notifications/notifications";
import TransferMoney from "../../../components/partials/dashboardSendComponents/transferMoney";
import KassiErrorModal from "../../../components/partials/modals/KassiErrorModal";

export default function Beneficiary() {
  const { loading, beneficiaries, searching } = useSelector(
    (state) => state.beneficiaryIndex
  );

  const { profileCompleted } = useSelector((state) => state.personal);

  const { deletingBn, deletedBn } = useSelector(
    (state) => state.deleteBeneficiary
  );

  const { updated, updatedAccount } = useSelector(
    (state) => state.editBeneficiary
  );

  const [sendTo, setSendTo] = useState({});

  const [kassiErrModal, setKassiErrorModal] = useState({
    visible: false,
    uuid: "",
  });

  const dispatch = useDispatch();
  const [bnToDelete, setBnToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleModal = async (el) => {
    localStorage.setItem("beneficiaryToEdit", el.beneficiary_uuid);
    dispatch(setBeneficiaryToEdit(el));
    dispatch(toggleEditModal(false));
  };

  const toggleDeleteModal = (id) => {
    setBnToDelete(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const deleteBnHandler = () => {
    dispatch(deleteBeneficiary(bnToDelete));
  };

  useEffect(() => {
    dispatch(fetchBeneficiaries());
    if (deletedBn) {
      dispatch(fetchBeneficiaries());
      toggleDeleteModal("");
    }
    if (updated) dispatch(fetchBeneficiaries());
    if (updatedAccount) dispatch(fetchBeneficiaries());
  }, [deletedBn, dispatch, updated, updatedAccount]);

  const classNames = require("classnames");

  const profile = JSON.parse(localStorage.getItem("authRes"));
  sessionStorage.setItem("send_option", "single");
  return (
    <div className="container">
      <div className="row mb-4">
        <SearchBar callback={searchBeneficiary} />
        <div className="col-lg-5 text-end">
          {profile.can_transact ? (
            <Link to="/add-new-beneficiary" className="btn btn-red px-5">
              <i className="fas fa-plus" />
              &nbsp; Add New Beneficiary
            </Link>
          ) : (
            <Link
              to="/add-new-beneficiary"
              onClick={(e) => {
                e.preventDefault();
                notifications.warning({
                  title: "Warning",
                  msg: `Dear customer, 

                  Thank you for choosing us,
                  
                  Please be informed that your profile has not been fully verified 
                  
                  
                  Kindly contact the team at <span class="text-red">compliance@cosmoremit.com.au</span> to activate your profile 
                  
                  Thank you`,
                });
              }}
              className="btn btn-red px-5"
            >
              <i className="fas fa-plus" />
              &nbsp; Add New Beneficiary
            </Link>
          )}
        </div>
      </div>
      {/* table */}
      {!profileCompleted && (
        <div className="alert alert-danger text-dark rounded">
          <i className="fas fa-exclamation-circle text-danger" />
          &nbsp; Your Personal Details, Address Information and KYC are all
          required before you can make transactions.
        </div>
      )}
      <div className="table-responsive">
        {loading || searching ? (
          <div className="">
            <Spin />
          </div>
        ) : (
          ""
        )}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Account Name</th>
              <th>Country</th>
              <th />
            </tr>
          </thead>

          <tbody
            className={classNames({
              "bg-white border-0 rounded": true,
              "d-none": loading,
            })}
          >
            {beneficiaries.data?.map((el, index) => {
              let fullname = el.fullname.split(" ");
              fullname =
                fullname.length > 1
                  ? fullname[0][0] + fullname[1][0]
                  : fullname[0][0];

              return (
                <tr className="border-bottom" key={el.beneficiary_uuid}>
                  <td>{index + 1}</td>
                  {/* name */}
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <span className="name-card fw-bold text-red">
                          {fullname}
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <span className="d-block mb-1">{el.fullname}</span>
                        {/* <p className="text-muted mb-0">
                          <span className="pe-2">NGN account</span>
                          <span className="pe-2">USD account</span>
                        </p> */}
                      </div>
                    </div>
                  </td>
                  {/* country */}
                  <td>
                    <span
                      className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    {"\u00A0"}
                    {el.country}
                  </td>
                  {/* action buttons */}
                  <td className="text-end py-4">
                    <button
                      className="btn alert-secondary"
                      onClick={() => {
                        toggleModal(el);
                      }}
                    >
                      <i className="fas fa-eye" />
                      &nbsp; View
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      className="btn alert-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleDeleteModal(el.beneficiary_uuid);
                      }}
                    >
                      <i className="fas fa-trash" />
                      &nbsp; Delete
                    </button>
                    <Link
                      to="#"
                      className="btn alert-success ms-1"
                      onClick={() => {
                        if (!profile.can_transact) {
                          notifications.warning({
                            title: "Warning",
                            msg: `Dear customer, 

                          Thank you for choosing us,
                          
                          Please be informed that your profile has not been fully verified 
                          
                          
                          Kindly contact the team at compliance@cosmoremit.com.au to activate your profile 
                          
                          Thank you`,
                          });
                          return;
                        }
                        dispatch({ type: "SET_ERR", payload: "" });
                        if (!el?.to_update_address) {
                          localStorage.setItem("receiver", JSON.stringify(el));
                          setSendTo({ show: true, to: el });
                        } else {
                          setKassiErrorModal({
                            visible: true,
                            uuid: el.beneficiary_uuid,
                          });
                        }
                      }}
                    >
                      <i className="fas fa-paper-plane" />
                      &nbsp; Send
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {beneficiaries?.links && (
        <Pagination
          onPageChange={(page) => {
            if (beneficiaries !== null && beneficiaries?.meta?.per_page) {
              dispatch(fetchBeneficiaries(true, page));
            }
          }}
          data={beneficiaries}
        />
      )}

      {/* delete modal */}
      <Modal
        visible={showDeleteModal}
        onCancel={() => {
          toggleDeleteModal();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
        // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure you want to delete this recipent</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleDeleteModal();
            }}
            disabled={deletingBn}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={deletingBn}
            onClick={() => {
              deleteBnHandler();
            }}
          >
            Delete {deletingBn && <Spin />}
          </button>
        </div>
      </Modal>

      <EditModal />
      {/* send money Modal */}
      <Modal
        visible={sendTo.show}
        footer={false}
        onCancel={() => {
          setSendTo({ show: false, to: {} });
        }}
      >
        {sendTo.to?.fullname && (
          <span className="send-money-title">
            Send to <span className="text-red">{sendTo.to?.fullname}</span>
          </span>
        )}
        <TransferMoney receiver={sendTo.to} />
      </Modal>

      <KassiErrorModal
        visible={kassiErrModal.visible}
        onClose={() => {
          setKassiErrorModal({ visible: false, uuid: "" });
        }}
        uuid={kassiErrModal.uuid}
      />
    </div>
  );
}
