import * as actionTypes from "../../actionTypes/admin/payoutActionTypes";

export const payoutReducer = (
  state = {
    fetching: true,
    payouts: {},
    saved: false,
    saving: false,
    deleting: false,
    deleted: false,
    toggling: false,
    toggled: false,
    updating: false,
    updated: false,
    showCreateModal: false,
    searching: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHING_PAYOUT:
      return { ...state, fetching: action.payload };
    case actionTypes.SEARCHING_PAYOUT:
      return { ...state, searching: !state.searching };
    case actionTypes.SHOW_CREATE_MODAL:
      return { ...state, showCreateModal: !state.showCreateModal };
    case actionTypes.UPDATING_PAYOUT:
      return { ...state, updating: action.payload };
    case actionTypes.UPDATED_PAYOUT:
      return { ...state, updated: action.payload };
    case actionTypes.SAVING_PAYOUT:
      return { ...state, saving: action.payload };
    case actionTypes.SAVED_PAYOUT:
      return { ...state, saved: action.payload };
    case actionTypes.DELETING_PAYOUT:
      return { ...state, deleting: action.payload };
    case actionTypes.TOGGLING_PAYOUT:
      return { ...state, toggling: action.payload };
    case actionTypes.TOGGLED_PAYOUT:
      return { ...state, toggled: action.payload };
    case actionTypes.DELETED_PAYOUT:
      return { ...state, deleted: action.payload };
    case actionTypes.SET_PAYOUT:
      return { ...state, payouts: action.payload };
    default:
      return state;
  }
};
