import * as actionTypes from "../actionTypes/authActionTypes";
import * as error from "./errorActions";
import { store } from "../store.js";
import { http } from "../../config/axios";
import * as notifications from "../../components/notifications/notifications";
import { Navigate, useNavigate } from "react-router-dom";

export const resetSteps = (dispatch) => {
	dispatch({ type: actionTypes.RESET_STEPS });
};

export const setActiveStep = (step) => (dispatch) => {
	dispatch({ type: actionTypes.SET_ACTIVE_STEP, payload: step });
};

export const nextStep = (dispatch) => {
	const { activeStep, steps } = store.getState().signup;
	dispatch({
		type: actionTypes.UPDATE_DONE_STEPS,
		payload: [...steps, (steps[activeStep]["done"] = true)],
	});
	dispatch({ type: actionTypes.SET_ACTIVE_STEP, payload: activeStep + 1 });
};

export const register = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.SWITCH_LOADING });
		error.clearError(dispatch);
		const res = await http.post("/auth/register", { ...fd });
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch({
			type: actionTypes.SET_REGISTER_RESPONSE,
			payload: { data: res.data.data, regFd: fd },
		});

		nextStep(dispatch);
		// autoLogin(dispatch);
		loginSuccess(res.data.data, dispatch);
		const res2 = await http.get(`metas/states/14`);
		localStorage.setItem("states", JSON.stringify(res2.data.data));

		const res3 = await http.get(`metas/idtypes`);
		localStorage.setItem("idtypes", JSON.stringify(res3.data.data));
	} catch (err) {
		console.log(err);
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch(error.setErrorMsg(err));
	}
};

export const verifyEmail = (fd) => async (dispatch) => {
	try {
		const { regRes } = store.getState().signup;
		dispatch({ type: actionTypes.SWITCH_LOADING });
		const authRes = JSON.parse(localStorage.getItem("authRes"));
		dispatch({ type: "EMAIL_VERIFIED", payload: false });
		// regRes is gotten from the response of the first step of the signup form while authRes is from login
		const res = await http.post(
			`/auth/verify_email_otp/${regRes?.uuid ?? authRes.uuid}`,
			fd
		);
		dispatch({ type: actionTypes.SWITCH_LOADING });
		notifications.success({ msg: res.data.message });
		localStorage.setItem("authRes", JSON.stringify(res.data.data));
		if (localStorage.getItem("from_reverify")) {
			dispatch({ type: "EMAIL_VERIFIED", payload: true });
		} else {
			nextStep(dispatch);
		}
	} catch (err) {
		dispatch({ type: actionTypes.SWITCH_LOADING });
		if (err.response.status === 422) {
			notifications.error({ msg: err.response.data.message });
			return;
		}
		dispatch(error.setErrorMsg(err));
	}
};

export const changeMyEmail = (fd) => async (dispatch) => {
	try {
		const { regRes } = store.getState().signup;
		error.clearError(dispatch);
		dispatch({ type: actionTypes.CHANGED_EMAIL, payload: false });
		dispatch({ type: actionTypes.SWITCH_LOADING });
		await http.post(`/auth/change_email_address/${regRes.uuid}`, fd);
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch({ type: actionTypes.CHANGED_EMAIL, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch(error.setErrorMsg(err));
	}
};

export const resendEmailOtp = async (dispatch) => {
	try {
		const { regRes } = store.getState().signup;
		dispatch({ type: actionTypes.TOGGLE_RESENDING_OTP });
		let user = JSON.parse(localStorage.getItem("authRes"));
		const res = await http.post(
			`/auth/resend_email_otp/${regRes?.uuid ? regRes?.uuid : user?.uuid}`
		);
		dispatch({ type: actionTypes.TOGGLE_RESENDING_OTP });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		console.log(err);
		dispatch({ type: actionTypes.TOGGLE_RESENDING_OTP });
		dispatch(error.setErrorMsg(err));
	}
};

export const resendPhoneOtp = async (dispatch) => {
	try {
		const { regRes } = store.getState().signup;
		let user = JSON.parse(localStorage.getItem("authRes"));
		dispatch({ type: actionTypes.TOGGLE_RESENDING_OTP });
		const res = await http.post(
			`/auth/resend_phone_otp/${regRes?.uuid ? regRes?.uuid : user?.uuid}`
		);
		dispatch({ type: actionTypes.TOGGLE_RESENDING_OTP });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.TOGGLE_RESENDING_OTP });
		dispatch(error.setErrorMsg(err));
	}
};

export const verifyPhone = (fd) => async (dispatch) => {
	try {
		const { regRes } = store.getState().signup;
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch({ type: "PHONE_VERIFIED", payload: false });
		const authRes = JSON.parse(localStorage.getItem("authRes"));
		// regRes is gotten from the response of the first step of the signup form while authRes is from login
		const res = await http.post(
			`/auth/verify_phone_otp/${regRes?.uuid ?? authRes.uuid}`,
			fd
		);
		dispatch({ type: "PHONE_VERIFIED", payload: true });
		dispatch({ type: actionTypes.SWITCH_LOADING });
		notifications.success({ msg: "OTP verified" });
		localStorage.setItem("authRes", JSON.stringify(res.data.data));
	} catch (err) {
		console.log(err);
		dispatch({ type: actionTypes.SWITCH_LOADING });
		if (err.response.status === 422) {
			notifications.error({ msg: err.response.data.message });
			return;
		}
		dispatch(error.setErrorMsg(err));
	}
};

export const changeMyPhone = (fd) => async (dispatch) => {
	try {
		const { regRes } = store.getState().signup;
		error.clearError(dispatch);
		dispatch({ type: actionTypes.CHANGED_PHONE, payload: false });
		dispatch({ type: actionTypes.SWITCH_LOADING });
		await http.post(`/auth/change_phone_number/${regRes.uuid}`, fd);
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch({ type: actionTypes.CHANGED_PHONE, payload: true });
	} catch (err) {
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch(error.setErrorMsg(err));
	}
};

export const autoLogin = async (dispatch) => {
	try {
		const { regRes, regFd } = store.getState().signup;
		dispatch({ type: actionTypes.SWITCH_LOADING });
		const res = await http.post(`/auth/login`, {
			email: regRes.email,
			password: regFd.password,
		});
		dispatch({ type: actionTypes.SWITCH_LOADING });
		const data = res.data.data;
		loginSuccess(data, dispatch);
	} catch (err) {
		dispatch(error.setErrorMsg(err, err.response.data.message));
	}
};

export const login = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch(error.hideError());
		const res = await http.post(`/auth/login`, fd);
		dispatch({ type: actionTypes.SWITCH_LOADING });
		if (res.data.status === "error") {
			dispatch({
				// action type is from error action types
				type: "SET_ERROR_MESSAGE",
				payload: res.data.message,
			});
			error.showError(dispatch);
			notifications.error({ title: "Error", msg: res.data.message });
		} else {
			const data = res.data.data;
			loginSuccess(data, dispatch);
		}
	} catch (err) {
		console.log(err);
		dispatch({ type: actionTypes.SWITCH_LOADING });
		dispatch(error.setErrorMsg(err, err.response.data?.message));
	}
};

const loginSuccess = (data, dispatch) => {
	const { token, ...authRes } = data;
	localStorage.setItem("token", token);
	localStorage.setItem("authRes", JSON.stringify(authRes));

	dispatch({
		type: "ADDRESS_COMPLETED",
		payload: authRes.profile.address_details_at ? true : false,
	});
	dispatch({
		type: "KYC_COMPLETED",
		payload: authRes.profile.kyc_details_at ? true : false,
	});
	dispatch({
		type: "PROFILE_COMPLETED",
		payload: authRes.profile_completed,
	});

	dispatch({
		type: "PERSONAL_COMPLETED",
		payload: authRes.profile.address_details_at,
	});

	dispatch({
		type: "CAN_TRANSACT",
		payload: authRes.can_transact,
	});

	dispatch({ type: "AUTH_LOGIN", payload: token });
	dispatch({ type: "SET_PROFILE_TYPE" });
};

export const logout = (dispatch) => {
	try {
		// localStorage.removeItem("token");
		// localStorage.removeItem("authRes");
		http.post("auth/logout");
		localStorage.clear();
		dispatch({ type: actionTypes.AUTH_LOGOUT });

		// window.location.reload();
	} catch (err) {
		console.log(err);
	}
};

export const sendResetPasswordLink = (email) => async (dispatch) => {
	try {
		dispatch({ type: "SENDING_RESET_LINK", payload: true });
		dispatch({ type: "SENT_LINK", payload: false });
		const res = await http.post("auth/recovery/request_forget_password", {
			email: email,
		});
		dispatch({ type: "SENT_LINK", payload: true });
		dispatch({ type: "SENDING_RESET_LINK", payload: false });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		notifications.error({ title: "Error", msg: err.response.data.message });
		dispatch({ type: "SENDING_RESET_LINK", payload: false });
		// dispatch(error.setErrorMsg(err, err.response.data?.message));
	}
};

export const changePassword = (fd, resetForm) => async (dispatch) => {
	try {
		dispatch({ type: "CHANGING_PASSWORD", payload: true });
		const res = await http.post(`/admin/settings/reset_password`, fd);
		dispatch({ type: "CHANGING_PASSWORD", payload: false });
		resetForm({});
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: "CHANGING_PASSWORD", payload: false });
		notifications.error({ title: "Error", msg: err.response.data.message });
		dispatch(error.setErrorMsg(err, err.response.data?.message));
	}
};
export const resetPassword = (fd) => async (dispatch) => {
	try {
		dispatch({ type: "RESETTING_PASSWORD", payload: true });
		dispatch({ type: "RESETTED_PASSWORD", payload: false });
		const res = await http.post(`auth/recovery/update_password/${fd.token}`, {
			password: fd.password,
			password_confirmation: fd.password_confirmation,
		});
		dispatch({ type: "RESETTED_PASSWORD", payload: true });
		dispatch({ type: "RESETTING_PASSWORD", payload: false });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: "RESETTING_PASSWORD", payload: false });
		notifications.error({ title: "Error", msg: err.response.data.message });
		// dispatch(error.setErrorMsg(err, err.response.data?.message));
	}
};
