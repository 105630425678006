import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Modal } from "antd";
import {
	saveKyc,
	_kycs,
	deleteKyc,
} from "../../../../store/actions/admin/senders";
import Pagination from "../../../../components/pagination/pagination";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../../components/partials/formikError";
import { useLocation } from "react-router-dom";

export default function Kyc() {
	const dispatch = useDispatch();
	const location = useLocation();
	const { fetchingKycs, kycs, changedKyc, savingKyc, loading } = useSelector(
		(state) => state.senders
	);
	const { msg } = useSelector((state) => state.error);
	const [view, setView] = useState({ show: false, kyc: {} });
	const [fileError, setFileError] = useState(false);
	const [showDelete, setShowDelete] = useState({ show: false, id: "" });

	const toggleDelete = (id) => {
		setShowDelete({ show: !showDelete.show, id: id });
	};

	const toggleView = (el) => {
		setView({ show: !view.show, kyc: el });
	};

	const _delete = () => {
		dispatch(deleteKyc(showDelete.id));
	};

	useEffect(() => {
		dispatch(_kycs(1, location?.state?.uuid));
	}, []);

	useEffect(() => {
		if (changedKyc) {
			dispatch(_kycs(1, location?.state?.uuid));
			document.getElementById("kycform").reset();
			setShowDelete({ show: false.show, id: "" });
		}
	}, [changedKyc]);

	return (
		<>
			<h5 className="fw-bold">Upload KYC</h5>
			<p className="text-muted">KYC Document * (.pdf, .doc). Max size: 8MB</p>
			<div className="row">
				<div className="col-lg-6">
					<Formik
						initialValues={{ document: "" }}
						validationSchema={Yup.object({
							document: Yup.array().min(1, "Upload document"),
						})}
						onSubmit={(values) => {
							dispatch(
								saveKyc({
									...values,
									uuid: location.state.uuid,
								})
							);
						}}
					>
						{(props) => {
							return (
								<form onSubmit={props.handleSubmit} id="kycform">
									<div className="form-group mb-3">
										<label htmlFor className="mb-2">
											KYC Document <span className="text-red">*</span>
										</label>
										<div className="input-file-upload">
											<div className="file-component">
												<span className="d-block text-center w-100">
													<i
														className="fas fa-cloud-upload-alt"
														style={{ fontSize: 20 }}
													/>
													<p>
														<span className="text-primary">
															Click to upload
														</span>{" "}
														or drag and drop
													</p>
													<span className="fw-lighter">
														.PDF,.jpeg,.jpg,.png (max 8mb)
													</span>
												</span>
												<input
													type="file"
													className="form-control"
													accept=".png,.jpg,.pdf,.jpeg"
													onChange={(e) => {
														let err = 0;
														setFileError(false);
														for (let i = 0; i < e.target.files.length; i++) {
															if (e.target.files[i].size > 8000000) {
																err = 1;
																setFileError(true);
																return;
															}
														}

														if (!err) {
															for (let i = 0; i < e.target.files.length; i++) {
																props.setValues({
																	...props.values,
																	document: [
																		...props.values.document,
																		e.target.files[0],
																	],
																});
															}
														}
													}}
												/>
											</div>
											{props.touched.document && props.errors.document && (
												<FormikError msg={props.errors.document} />
											)}
											<FormikError msg={msg?.document ?? ""} />
											{fileError && (
												<FormikError msg="File must be less than 8MB" />
											)}
										</div>
										<br />
										<button
											className="btn btn-red"
											type="submit"
											disabled={savingKyc}
										>
											<i className="fas fa-upload" /> &nbsp; Upload{" "}
											{savingKyc && <Spin />}
										</button>
									</div>
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
			<div className="table-responsive">
				{fetchingKycs && <Spin />}
				<table className="table table-borderless">
					<thead>
						<tr>
							<th className="text-uppercase text-muted">#</th>
							<th className="text-uppercase text-muted">Document Type</th>
							<th className="text-uppercase text-muted">Date Uploaded</th>
							<th className="text-uppercase text-muted">Action</th>
						</tr>
					</thead>
					<tbody>
						{kycs.data?.map((el, i) => {
							return (
								<tr className="border-bottom" key={i}>
									<td>{i + 1}</td>
									<td>{el.doc_type}</td>
									<td>{el.created_at}</td>
									<td>
										<a
											href="#view"
											className="btn btn-white"
											onClick={(e) => {
												e.preventDefault();
												toggleView(el.sender_kyc_docs);
											}}
										>
											View
										</a>
										&nbsp;
										<button
											className="btn text-red"
											onClick={() => {
												toggleDelete(el.id);
											}}
										>
											<i className="far fa-trash-alt" />
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{kycs?.links && (
				<Pagination
					onPageChange={(page) => {
						if (kycs !== null && kycs?.meta?.per_page) {
							dispatch(_kycs(page, location.state.uuid));
						}
					}}
					data={kycs}
				/>
			)}
			<Modal
				visible={view.show}
				onCancel={() => {
					toggleView();
				}}
				// width={"60%"}
				footer={false}
			>
				{/* <iframe src={view.kyc} title="docs" height="100%" width="100%" /> */}
				<a href={`${view.kyc}`} className="btn btn-red" target="_blank">
					Click to view document
				</a>
			</Modal>
			{/* Delete Modal */}
			<Modal
				visible={showDelete.show}
				onCancel={() => {
					toggleDelete();
				}}
				bodyStyle={{
					padding: "0px !important",
					borderRadius: "20px !important",
					margin: "0px",
				}}
				closable={false}
				footer={false}
				// width="25%"
			>
				<div className="modal-body text-center">
					<img
						src="../assets/images/components/rafiki.png"
						alt="delete icon"
						className="img-fluid"
					/>
					<p>Are you sure?</p>
					<button
						className="btn btn-outline-red mx-2 px-4"
						type="button"
						onClick={() => {
							toggleDelete();
						}}
						disabled={loading}
					>
						Cancel
					</button>
					<button
						className="btn btn-red mx-2 px-4"
						type="button"
						disabled={loading}
						onClick={() => {
							_delete();
						}}
					>
						Delete {loading && <Spin />}
					</button>
				</div>
			</Modal>
		</>
	);
}
