import * as actionTypes from "../actionTypes/transactionActionTypes";
export const transactionReducer = (
  state = {
    fetchingRateCountries: false,
    ratedCountries: [],
    fetchingDefaultRate: false,
    defaultRates: {},
    fetchingPayinsAttachedToRate: false,
    payinsAttahcedToRate: [],
    fetchingInputFromAmount: false,
    inputFromAmount: {},
    fetchingInputRecepientAmount: false,
    inputRecepientAmount: {},
    fetchedDefault: false,
    fetchedRatedPayins: false,
    fetchedInputFromAmount: false,
    fetchedInputRecepientAmount: false,
    fetchingSwitchCountry: false,
    fetchedSwitchCountry: false,
    switchCountry: {},
    checkoutMetas: {},
    fetchingCheckoutMetas: false,
    fetchedCheckoutMetas: false,
    err: "",
    initializing: false,
    initialized: false,
    initTrxData: {},
    transactions: {},
    loading: false,
    fetchingDashboardMetas: false,
    dashboardMetas: {},
    sendingCurrency: "",
    receivingCurrency: "",
    rate: "",
    promorate: "",
    walletTFsuccess: false,
    fetchingTrxData: true,

    // response_status_data: {}
  },
  action
) => {
  switch (action.type) {
    case "SET_DASHBOARD_METAS":
      return { ...state, dashboardMetas: action.payload };
    case "FETCHING_DASHBOARD_METAS":
      return { ...state, fetchingDashboardMetas: action.payload };
    case "SET_ERR":
      return { ...state, err: action.payload };
    case "LOADING":
      return { ...state, loading: action.payload };
    case "SET_TRANSACTIONS":
      return { ...state, transactions: action.payload };
    case "INITIALIZING":
      return { ...state, initializing: action.payload };
    case "INITIALIZED":
      return { ...state, initialized: action.payload };
    case "INIT_TRX_DATA":
      return { ...state, initTrxData: action.payload };
    case "SET_SENDING_CURRENCY":
      return { ...state, sendingCurrency: action.payload };
    case "SET_RECEIVING_CURRENCY":
      return { ...state, receivingCurrency: action.payload };
    case "SET_RATE":
      return { ...state, rate: action.payload };
    case "SET_PROMO_RATE":
      return { ...state, promorate: action.payload };
    case actionTypes.FETCHING_RATED_COUNTRIES:
      return { ...state, fetchingRateCountries: action.payload };
    case actionTypes.FETCHING_SWITCH_COUNTRY:
      return { ...state, fetchingSwitchCountry: action.payload };
    case actionTypes.SET_SWITCH_COUNTRY:
      return { ...state, switchCountry: action.payload };
    case actionTypes.FETCHING_CHECKOUT_METAS:
      return { ...state, fetchingCheckoutMetas: action.payload };
    case actionTypes.FETCED_CHECKOUT_METAS:
      return { ...state, fetchedCheckoutMetas: action.payload };
    case actionTypes.SET_CHECKOUT_METAS:
      return { ...state, checkoutMetas: action.payload };
    case actionTypes.FETCHED_SWITCH_COUNTRY:
      return { ...state, fetchedSwitchCountry: action.payload };
    case actionTypes.FETCHED_INPUT_RECEPIENT_AMOUNT:
      return { ...state, fetchedInputRecepientAmount: action.payload };
    case actionTypes.FETCHED_INPUT_FROM_AMOUNT:
      return { ...state, fetchedInputFromAmount: action.payload };
    case actionTypes.FETCHED_RATE_PAYINS:
      return { ...state, fetchedRatedPayins: action.payload };
    case actionTypes.FETCED_DEFAULT_RATES:
      return { ...state, fetchedDefault: action.payload };
    case actionTypes.SET_RATED_COUNTRIES:
      return { ...state, ratedCountries: action.payload };
    case actionTypes.FETCHING_DEFAULT_RATES:
      return { ...state, fetchingDefaultRate: action.payload };
    case actionTypes.SET_DEFAULT_RATES:
      return { ...state, defaultRates: action.payload };
    case actionTypes.FETCHING_RATE_PAYINS:
      return { ...state, fetchingPayinsAttachedToRate: action.payload };
    case actionTypes.SET_RATE_PAYINS:
      return { ...state, payinsAttahcedToRate: action.payload };
    case actionTypes.FETCHING_INPUT_FROM_AMOUNT:
      return { ...state, fetchingInputFromAmount: action.payload };
    case actionTypes.SET_INPUT_FROM_AMOUNT:
      return { ...state, inputFromAmount: action.payload };
    case actionTypes.FETCHING_INPUT_RECEPIENT_AMOUNT:
      return { ...state, fetchingInputRecepientAmount: action.payload };
    case actionTypes.SET_INPUT_RECEPIENT_AMOUNT:
      return { ...state, inputRecepientAmount: action.payload };
      case actionTypes.FETCHING_TRX_DATA:
        return { ...state, fetchingTrxData: action.payload };
    case "SHOW_WALLET_SUCCESSFUL":
      return { ...state, walletTFsuccess: action.payload };
    default:
      return state;
  }
};
