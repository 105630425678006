import React from "react";
import { useLocation, Link } from "react-router-dom";
import { logout } from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
export default function DashboardTopNav() {
  const dispatch = useDispatch();
  const location = useLocation();
  const titles = location.pathname.substring(1).split("/");
  const user = JSON.parse(localStorage.getItem("authRes") ?? {});
  return (
    <nav className="navbar navbar-expand-sm py-3 bg-transparent navbar-light">
      <div className="container">
        {/* navbar brand */}
        <Link to="#" className="navbar-brand">
          <h5 className="fw-bold text-capitalize">
            {titles.pop().replaceAll("-", " ")}
          </h5>
        </Link>
        <ul className="navbar-nav d-flex flex-row">
          {/* nav item */}
          <li className="nav-item">
            <Link to="#" className="nav-link d-none d-sm-block">
              {`${user.profile.firstname ?? ""} ${user.profile?.lastname ?? ""
                }`}{" "}
              &nbsp;
              <img
                src="../assets/images/icon/icon-park_five-star-badge.png"
                width={17}
                alt="badge"
              />
            </Link>
          </li>
          {/* nav item */}
          <li className="nav-item dropdown position-relative">
            <Link
              className="nav-link btn bg-light"
              to="#"
              data-bs-toggle="dropdown"
            >
              <i className="far fa-bell" />
            </Link>
            {/* <ul className="dropdown-menu position-absolute shadow-sm border-0 rounded">
              <li>
                <Link className="dropdown-item active" to="#">
                  Action
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  Another action
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  Something else here
                </Link>
              </li>
            </ul> */}
          </li>
          {/* nav item */}
          <li className="nav-item dropdown position-relative">
            <Link
              to="#"
              className="nav-link btn bg-light"
              data-bs-toggle="dropdown"
            >
              <i className="fas fa-ellipsis-h" />
            </Link>
            <ul className="dropdown-menu px-2 position-absolute shadow-sm border-0 rounded">
              <li className="dropdown-item">
                {`${user.profile.firstname ?? ""} ${user.profile?.lastname ?? ""
                  }`}{" "}
                &nbsp;
                <img
                  src="../assets/images/icon/icon-park_five-star-badge.png"
                  width={17}
                  alt="icon"
                />
                <br />
                <small className="text-muted">
                  Member ID ‐ {user.profile.member_id ?? ""}
                </small>
              </li>
              <li>
                <Link className="dropdown-item" to="/my-profile">
                  <span>
                    <img
                      src="../assets/images/icon/Group(2).png"
                      alt="icon"
                      className="img-fluid"
                    />
                  </span>
                  &nbsp; My Profile
                </Link>
              </li>
              {/* <li>
                <Link className="dropdown-item" to="#">
                  <span>
                    <img
                      src="../assets/images/icon/Vector(21).png"
                      alt="icon"
                      className="img-fluid"
                    />
                  </span>
                  &nbsp; Help
                </Link>
              </li> */}
              <li>
                <Link
                  className="dropdown-item border-bottom-0"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(logout);
                  }}
                >
                  <span>
                    <img
                      src="../assets/images/icon/simple-line-icons_logout.svg"
                      alt="logout icon"
                      className="img-fluid"
                    />
                  </span>
                  &nbsp; Logout
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
}
