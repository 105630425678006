import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function MobileSideNav() {
	return (
		<div
			className="offcanvas offcanvas-end"
			tabIndex={-1}
			id="offcanvasNavbar"
			aria-labelledby="offcanvasNavbarLabel"
		>
			<div className="offcanvas-header justify-content-end">
				<button
					type="button"
					className="btn-close"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				/>
			</div>
			<div className="offcanvas-body">
				<ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
					{/* nav item */}
					<li className="nav-item py-1">
						{/* nav link */}
						<a href="faqs" className="nav-link">
							FAQs
						</a>
					</li>
					{/* nav item */}
					<li className="nav-item py-1">
						{/* nav link */}
						<a className="nav-link" href="help-center">
							Help Center
						</a>
					</li>
					{/* nav item */}
					<li className="nav-item py-1">
						{/* nav link */}
						<a href="login" className="btn px-4 btn-white">
							Login
						</a>
						&nbsp;
						{/* nav link */}
						<a href="signup" className="btn px-3 btn-red">
							Sign Up
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}
