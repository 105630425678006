import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PiCodesandboxLogoLight } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosCloudUpload } from "react-icons/io";
import { Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  fetchBusinessCategories,
  fetchBusinessJurisdictions,
  registerBusiness,
} from "./../../../store/actions/businessAction";
import useGooglePlacesAutocomplete from "../../../hooks/googleAdress.hooks";
import { fetchRatedCountries } from "../../../store/actions/general";

const CreateBusinessAccount = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.businessRegistration);
  const [addressFocused, setAddressFocused] = useState(false);

  const { jurisdictions, categories } = useSelector(
    (state) => state.fetchBusinessDataStore
  );
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [close, setClose] = useState(true);
  const navigate = useNavigate();
  const formRef = useRef();

  const { address } = useGooglePlacesAutocomplete("business_location");

  useEffect(() => {
    if (formRef.current && address?.full_address && address?.suburb) {
      formRef.current.setValues({
        ...formRef.current.values,
        business_location: address.full_address,
      });
    } else {
      formRef?.current?.setValues({
        ...formRef.current.values,
        business_location: "",
        suburb: "",
      });
    }
  }, [address, addressFocused]);
  useEffect(() => {
    dispatch(fetchBusinessCategories);
    dispatch(fetchBusinessJurisdictions);
  }, []);
  const handleClose = () => {
    setClose(false);
  };

  const handleBackButton = () => {
    navigate("/dashboard");
  };

  const handleSuccessfulSubmit = () => {
    navigate("/business-account");
    setImagePreviewUrl(null); // Reset image preview
  };

  const validationSchema = Yup.object().shape({
    business_email: Yup.string("Enter business email")
      .required("Business email is required")
      .test("business_email", "Enter a valid business email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        let isValidEmail = emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        return true;
      }),
    business_name: Yup.string().required("Business Name is required"),
    contact_number: Yup.string().required("Business Contact is required"),

    business_location: Yup.string().required("Business Location is required"),
    business_category: Yup.string().required("Business Category is required"),
    incorporation_date: Yup.date().required(
      "Date of Incorporation is required"
    ),
    certificate_image: Yup.mixed().required(
      "Certificate of Incorporation is required"
    ),
    company_registration_number: Yup.string()
      .required("Company registration number is required")
      .min(3, "Company registration number must be at least 3 characters"),
    company_jurisdiction_code: Yup.string()
      .required("Company jurisdiction code is required")
      .min(2, "Company jurisdiction code must be at least 2 characters"),
  });

  const initialValues = {
    business_email: "",
    business_name: "",
    business_location: "",
    business_category: "",
    incorporation_date: "",
    certificate_image: null,
    company_registration_number: "",
    company_jurisdiction_code: "",
    contact_number: "",
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("certificate_image", file);

    if (file) {
      if (file?.type === "application/pdf") {
        const pdfFileUrl = URL.createObjectURL(file);
        setImagePreviewUrl(pdfFileUrl);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = async (values) => {
    // const updatedValues = {
    //   ...values,
    // };

    dispatch(registerBusiness(values, handleSuccessfulSubmit));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize // Add this line
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ values, errors, touched, handleBlur, setFieldValue }) => (
        <Form className="section_wrap_business_create_acount">
          <div className="create_acount_title">
            <h5 className="fw-bold">Create a Business Account</h5>
          </div>
          {close && (
            <div className="sub_section_info">
              <div className="notification__message-temporary">
                <div className="icon__message_paragraph">
                  <div className="icon_message-wrap">
                    <PiCodesandboxLogoLight className="icon_message" />
                  </div>
                  <p className="w-100">
                    Please ensure all required fields, indicated by an asterisk
                    (*), are completed before submitting the form.
                  </p>
                </div>
                <div className="close_err-message-wrap">
                  <AiOutlineClose
                    size={16}
                    className="close_err-message"
                    onClick={handleClose}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="sub__section_data_info">
            <div className="inputs_business_info">
              <label htmlFor="business_name">Business Email</label>&nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                type="text"
                id="business_email"
                name="business_email"
                placeholder="Enter Business Email"
                style={{ maxWidth: "1500px", FontFace: "bold" }}
                onBlur={handleBlur}
              />
              {touched.business_email && errors.business_email && (
                <div className="error-message text-danger">
                  {errors.business_email}
                </div>
              )}
            </div>
            <div className="inputs_business_info">
              <label htmlFor="business_name">Business Name</label>&nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                type="text"
                id="business_name"
                name="business_name"
                placeholder="Enter Business Name"
                style={{ maxWidth: "1500px", FontFace: "bold" }}
                onBlur={handleBlur}
              />
              {touched.business_name && errors.business_name && (
                <div className="error-message text-danger">
                  {errors.business_name}
                </div>
              )}
            </div>
            <div className="inputs_business_info">
              <label htmlFor="business_name">Contact Number</label>&nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                type="text"
                id="contact_number"
                name="contact_number"
                placeholder="Enter Business Contact Number"
                style={{ maxWidth: "1500px", FontFace: "bold" }}
                onBlur={handleBlur}
              />
              {touched.contact_number && errors.contact_number && (
                <div className="error-message text-danger">
                  {errors.contact_number}
                </div>
              )}
            </div>
            <div className="business_account_column_inputs"></div>
            <div className="inputs_business_info">
              <label htmlFor="business_location">
                Business Location (Full Address)
              </label>
              &nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                as="input"
                type="text"
                id="business_location"
                name="business_location"
                placeholder="Enter Full Business Address"
                style={{ maxWidth: "1500px" }}
                onFocus={() => {
                  setAddressFocused(true);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  setAddressFocused(false);
                }}
                value={values.business_location}
              />
              {touched.business_location && errors.business_location && (
                <div className="error-message text-danger">
                  {errors.business_location}
                </div>
              )}
            </div>

            <div className="inputs_business_info inputs_business_info_select">
              <label htmlFor="business_category">Business Category</label>
              &nbsp;
              <span style={{ color: "red" }}>*</span>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Select Category"
                className="form-control min-height"
                name="business_category"
                onChange={(value) => setFieldValue("business_category", value)}
                value={values.business_category}
                onBlur={handleBlur}
              >
                {categories?.map((category) => (
                  <Select.Option
                    title={category.description}
                    key={category.id}
                    value={category.name}
                  >
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
              {touched.business_category && errors.business_category && (
                <div className="error-message text-danger">
                  {errors.business_category}
                </div>
              )}
            </div>
            <div className="inputs_business_info">
              <label htmlFor="company_registration_number">
                Company Registration Number
              </label>
              &nbsp;<span style={{ color: "red" }}>*</span>
              <Field
                type="text"
                id="company_registration_number"
                name="company_registration_number"
                placeholder="Enter Company Registration Number"
                style={{ maxWidth: "1500px" }}
              />
              <ErrorMessage
                name="company_registration_number"
                component="div"
                className="error-message text-danger"
              />
            </div>

            <div className="inputs_business_info">
              <label htmlFor="company_jurisdiction_code">
                Company Jurisdiction Code
              </label>
              &nbsp;<span style={{ color: "red" }}>*</span>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                className="form-control min-height"
                name="company_jurisdiction_code"
                placeholder="Select Jurisdiction Code"
                onChange={(value) =>
                  setFieldValue("company_jurisdiction_code", value)
                }
                value={values.company_jurisdiction_code}
                onBlur={handleBlur}
              >
                {jurisdictions?.map((el) => (
                  <Select.Option key={el.code} value={el.code} label={el.name}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
              <ErrorMessage
                name="company_jurisdiction_code"
                component="div"
                className="error-message text-danger"
              />
            </div>
            <div className="inputs_business_info">
              <label htmlFor="incorporation_date">Date of Incorporation</label>
              &nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                type="date"
                id="incorporation_date"
                name="incorporation_date"
                placeholder="Enter Date of Incorporation"
                style={{ maxWidth: "1500px" }}
                onBlur={handleBlur}
              />
              {touched.incorporation_date && errors.incorporation_date && (
                <div className="error-message text-danger">
                  {errors.incorporation_date}
                </div>
              )}
            </div>
            <div className="business_account_upload_file">
              <label htmlFor="certificate_image">ASIC extract</label>
              &nbsp;
              <span style={{ color: "red" }}>*</span>
              <div className="business_upload_file_wrapper">
                <div className="business_files_drag_drop">
                  <IoIosCloudUpload className="cloud_icon" />
                  <label htmlFor="certificate_image" className="button_span">
                    <div className="file_selector">Click to Upload</div>
                    <span> or drag and drop</span>
                  </label>
                  <input
                    id="certificate_image"
                    name="certificate_image"
                    type="file"
                    className="hidden"
                    accept=".png,.jpg,.jpeg,.pdf"
                    onChange={(event) =>
                      handleImageChange(event, setFieldValue)
                    }
                  />
                  {touched.certificate_image && errors.certificate_image && (
                    <div className="error-message text-danger">
                      {errors.certificate_image}
                    </div>
                  )}
                </div>
                <div className="business_files_drag_drop">
                  <div className="drop_here">
                    JPG, JPEG, PNG or PDF (max 8mb)
                  </div>
                </div>
              </div>
              <div className="business_account_numbers_of_files_uploaded">
                {imagePreviewUrl &&
                values?.certificate_image?.type === "application/pdf" ? (
                  <a
                    href={`${imagePreviewUrl}`}
                    className="btn btn-red me-2"
                    target="_blank"
                  >
                    Click to view document
                  </a>
                ) : (
                  <div className="uploaded_image_preview">
                    <img src={imagePreviewUrl} alt="Certificate Preview" />
                  </div>
                )}
                {values.certificate_image && (
                  <div className="uploaded_file_name">
                    {values.certificate_image.name}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="Business_create_account_back ">
            <button
              className="back_button min-height px-5"
              type="button"
              disabled={loading}
              onClick={handleBackButton}
            >
              Go Back
            </button>
            <button
              type="submit"
              className="btn btn-red float-end min-height px-5"
              disabled={loading}
            >
              <span className="px-5 px-lg-2">
                {" "}
                Create Account
                {loading && <Spin />}
              </span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateBusinessAccount;
