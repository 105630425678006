import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  _transactions,
  downloadTransaction,
} from "../../../../store/actions/admin/senders";
import { Spin, Modal } from "antd";
import Pagination from "../../../../components/pagination/pagination";
import TimeAgo from "react-timeago";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";

export default function Transactions({ uuid }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchingTransactions, transactions } = useSelector(
    (state) => state.senders
  );
  const { exportingData } = useSelector((state) => state.adminTransactions);
  useEffect(() => {
    dispatch(_transactions(1, location?.state?.uuid));
  }, []);

  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user.permissions;
  const [currentPage, setCurrentPage] = useState(1);
  const [trnx, setTrnx] = useState({ show: false, trnx: {} });
  return (
    <>
      {permissions["download transaction report"] && (
        <div className="text-end mb-3">
          <button
            onClick={(e) => {
              dispatch(downloadTransaction(uuid));
            }}
            className="btn btn-red min-height px-4"
          >
            Download Transactions {exportingData && <Spin />}
          </button>
        </div>
      )}
      <div className="table-responsive">
        {fetchingTransactions && <Spin />}
        {/* <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-uppercase text-muted">#</th>
              <th className="text-uppercase text-muted">Reference</th>
              <th className="text-uppercase text-muted">Status</th>
              <th className="text-uppercase text-muted">Sent Amount</th>
              <th className="text-uppercase text-muted">Transfer Type</th>
              <th className="text-uppercase text-muted">Recipient Name</th>
              <th className="text-uppercase text-muted">Recieved Amount</th>
              <th className="text-uppercase text-muted">Sent at</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.data?.map((el, i) => {
              let status = "warning";
              let icon = "fa-exclamation-triangle";
              if (el.status === "successful") {
                status = "success";
                icon = "fa-check-circle";
              }
              if (el.status === "failed" || el.status === "canceled") {
                status = "danger";
                icon = "fa-close";
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{i + 1}</td>
                  <td>{el.reference}</td>
                  <td>
                    <i className={`fas ${icon}  text-${status}`} /> &nbsp;
                    <small className={`text-${status}`}>{el.status}</small>
                  </td>
                  <td>
                    <span
                      className={`flag-icon flag-icon-${el.from_country.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    &nbsp; {`${el.from_country.currency} ${el.send_amount}`}
                  </td>
                  <td>{el.payin}</td>
                  <td>
                    <p className="mb-0">{el.meta.beneficiary.fullname}</p>
                    <small>
                      {el.meta.account.account_name} -{" "}
                      {el.meta.account.account_number}
                    </small>
                  </td>
                  <td>
                    <span
                      className={`flag-icon flag-icon-${el.to_country.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    &nbsp; {`${el.to_country.currency} ${el.received_amount}`}
                  </td>
                  <td>{el.created_at}</td>
                </tr>
              );
            })}
          </tbody>
        </table> */}

        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Reference</th>
              <th className="text-muted text-uppercase">Sender | Rate </th>
              <th className="text-muted text-uppercase">Payin</th>
              <th className="text-muted text-uppercase">Reciepent </th>
              <th className="text-muted text-uppercase">Risk Score</th>
              <th className="text-muted text-uppercase">Sent at</th>
              <th className="text-muted text-uppercase">Status</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {transactions?.data?.map((el, i) => {
              const verified = el.verify_bank_transfer;
              let status = "warning";
              let time = el.created_at;
              if (verified) {
                status = "success";
                time = el.verify_bank_transfer;
              }
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }

              let color = "warning";
              if (el.status === "successful") {
                color = "success";
              }
              if (el.status === "failed" || el.status === "canceled") {
                color = "danger";
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  {/* <i className="fa-sharp fa-solid fa-circle text-danger" /> */}
                  <td>
                    <>
                      {el.kaasi_metas && <span className="text-danger">k</span>}
                      {/* <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setTrnx({ show: true, trnx: el });
                        }}
                      >
                        {el?.reference}
                      </span>{" "} */}
                      <a
                        href="#view"
                        onClick={(e) => {
                          e.preventDefault();
                          setTrnx({ show: true, trnx: el });
                        }}
                      >
                        {el?.reference}
                      </a>
                    </>
                    <p>{el.payout}</p>
                  </td>
                  <td>
                    <a
                      onClick={() => {
                        localStorage.setItem("senders_userUuid", el.user.uuid);
                        localStorage.setItem(
                          "senders_profile",
                          JSON.stringify(el.user.profile)
                        );
                        navigate("/senders/details");
                      }}
                    >
                      {el?.sender_fullname}
                    </a>{" "}
                    <br />
                    <small className="text-muted text-uppercase">
                      {el?.from_country?.currency}
                    </small>
                    {"\u00A0"}
                    <span
                      className={`flag-icon flag-icon-${el?.from_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    {"\u00A0"}
                    {el.send_amount} | {el.rate}
                  </td>
                  <td>{el.payin}</td>
                  <td>
                    {`${el.meta.beneficiary?.first_name} ${el.meta?.beneficiary?.last_name}`}{" "}
                    <br />
                    <small className="text-muted text-uppercase">
                      {el?.to_country?.currency}
                    </small>
                    {"\u00A0"}
                    <span
                      className={`flag-icon flag-icon-${el?.to_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    {"\u00A0"}
                    {el.received_amount}
                  </td>
                  <td
                    className={`${el?.risk_type
                      .split("_")[0]
                      .toLowerCase()}`}
                  >
                    {el?.risk_score}
                    <br />
                    <small>{el?.risk_type.replace("_", " ")}</small>
                  </td>
                  <td>{moment(el.created_at).format("YYYY-MM-DD")}</td>
                  <td>
                    <small className={`text-${color} text-capitalize`}>
                      {el.status}
                    </small>
                    <br />
                    <TimeAgo date={time} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {transactions?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (transactions !== null && transactions?.meta?.per_page) {
              dispatch(_transactions(page, location.state.uuid));
            }
          }}
          data={transactions}
        />
      )}

      {/* view trnx modal */}
      <Modal
        visible={trnx.show}
        footer={false}
        onCancel={() => {
          setTrnx({ show: false, trnx: {} });
        }}
      >
        <h6 className="fw-bold">Transaction Details</h6>

        <div className="py-4">
          <p className="text-muted">Reference: #{trnx.trnx.reference}</p>
          <div className="table-responsive">
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Bank Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Account number</th>
                  <td>{trnx.trnx?.meta?.account.account_number}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank name</th>
                  <td>{trnx.trnx?.bank_name}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank city</th>
                  <td>{trnx.trnx?.bank_city}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank country</th>
                  <td>{trnx.trnx?.bank_country}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Recipient details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Name</th>
                  <td>{`${trnx.trnx?.meta?.beneficiary?.first_name} ${trnx.trnx?.meta?.beneficiary?.last_name}`}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Mobile number</th>
                  <td>{trnx.trnx?.meta?.beneficiary?.phone_no}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Email</th>
                  <td>{trnx.trnx?.meta?.beneficiary.email}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Sender details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>MemberID</th>
                  <td>{trnx.trnx?.sender_memberid}</td>
                </tr>
                <tr className="border-bottom">
                  <th>FullName</th>
                  <td>{trnx.trnx?.sender_fullname}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Mobile number</th>
                  <td>{trnx.trnx?.sender_mobile_no}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Email</th>
                  <td>{trnx.trnx?.sender_email}</td>
                </tr>
                <tr className="border-bottom">
                  <th>ID type</th>
                  <td>{trnx.trnx?.sender_idtype}</td>
                </tr>
                <tr className="border-bottom">
                  <th>ID details</th>
                  <td>{trnx.trnx?.sender_iddetails}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Scanned ID</th>
                  <td>...</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Sent
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Amount</th>
                  <td>
                    {" "}
                    {trnx.trnx?.send_amount} {trnx.trnx?.from_country?.currency}{" "}
                    ({" "}
                    <span
                      className={`flag-icon flag-icon-${trnx.trnx?.from_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>{" "}
                    - {trnx.trnx?.from_country?.name})
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Rate</th>
                  <td>
                    {" "}
                    {trnx.trnx?.rate} ({trnx.trnx?.from_country?.currency} -{" "}
                    {trnx.trnx?.from_country?.name})
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Reason</th>
                  <td>{trnx.trnx?.comment}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center text-capitalize"
                    colspan="2"
                  >
                    {trnx.trnx?.status}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Amount</th>
                  <td>
                    {" "}
                    {trnx.trnx?.received_amount}{" "}
                    {trnx.trnx?.to_country?.currency} (
                    <span
                      className={`flag-icon flag-icon-${trnx.trnx?.to_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>{" "}
                    - {trnx.trnx.to_country?.name}){" "}
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Reference</th>
                  <td>#{trnx.trnx?.reference}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Transfer type</th>
                  <td>{trnx.trnx?.payin}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Transfer message</th>
                  <td>Completed</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-end">
            <button
              className="btn btn-red"
              type="button"
              onClick={() => {
                setTrnx({ show: false, trnx: {} });
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
