import React, { useEffect, useState } from "react";
import { Modal, Select, Spin } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { __fundWallet } from "../../../../../store/actions/walletActions";
import "../../../../../assets/css/fundWallet.css";
import { useMoneyTransfer } from "../../../../../hooks/send.hooks";
import FormikError from "../../../formikError";
import { useNavigate } from "react-router-dom";
import * as notifications from "../../../../notifications/notifications";

const FundWallet = ({ showModal, handleCancel }) => {
	const dispatch = useDispatch();
	const [selectedPayin, setSelectedPayin] = useState("");
	const [fromCountry, setFromCountry] = useState("");
	const [fromCountryCurency, setFromCountryCurrency] = useState("");
	const [fromCountryIso, setFromCountryIso] = useState("");
	const [error, setError] = useState("");
	const [fee, setFee] = useState(0);

	const [redirecting, setRedirecting] = useState(false);
	const navigate = useNavigate();

	const [amount, setAmount] = useState(500);
	const { initialized, initializing, initTrxData } = useSelector(
		(state) => state.transaction
	);
	const { wallet_funding, user_wallets } = useSelector((state) => state.wallet);
	const { payins } = useMoneyTransfer();
	const authRes = JSON.parse(localStorage.getItem("authRes"));

	useEffect(() => {
		if (initialized) {
			const transaction_type = localStorage.getItem("trx_type") ?? "";
			if (initTrxData.for_payin.plugin === "poli") {
				setRedirecting(true);
				if (!initTrxData.for_payin.NavigateURL) navigate("/transaction-failed");
				if (initTrxData.for_payin.plugin === "poli")
					window.location.replace(initTrxData.for_payin.NavigateURL);
			} else {
				navigate("/transaction-notice");
			}
		}
	}, [initialized]);

	useEffect(() => {
		const defaultWallet = user_wallets.find(
			(wallet) => wallet.default_wallet === 1
		);
		setFee(defaultWallet?.fee);
		setFromCountry(defaultWallet?.country?.id);
		setFromCountryCurrency(defaultWallet?.country?.currency);
		setFromCountryIso(defaultWallet?.country?.iso2);

		setSelectedPayin(payins?.[0]?.id);
	}, [payins, user_wallets]);

	const handleAmountChange = (event) => {
		setAmount(event.target.value);
	};

	const getPopupContainer = (node) => {
		return node.parentNode;
	};

	const handleFundWallet = () => {
		if (!authRes.can_transact) {
			notifications.warning({
				title: "Warning",
				msg: `Dear customer, 

          Thank you for choosing us,
          
          Please be informed that your profile has not been fully verified 
          
          
          Kindly contact the team at <span class="text-red">compliance@cosmoremit.com.au</span> to activate your profile 
          
          Thank you`,
			});
			return;
		}
		if (!amount || !selectedPayin) {
			setError("Please fill in all empty fields");
		} else {
			setError("");
			localStorage.setItem("trx_sendVal", amount);
			localStorage.setItem("trx_fee", fee);
			localStorage.setItem("trx_fromCountryCurency", fromCountryCurency);

			dispatch(
				__fundWallet({ payin_id: selectedPayin, amount: amount }, handleCancel)
			);
		}
	};

	return (
		<div>
			<Modal
				title="Fund Your Wallet"
				visible={showModal}
				onCancel={handleCancel}
				footer={null} // Hide footer, as we're using a form submit button
				// width={494}
				width={450}
			>
				<div className="form__wrapper">
					<form>
						<div className="form_container">
							<div className="only__AUD_Paragraph">
								<small>
									Only <span>{fromCountryCurency} Wallet</span>(primary wallet
									currency) can be funded.
								</small>
							</div>
							<FormikError msg={error ?? ""} />

							<div className="form-floating mt-3 mb-3 beneficiary-select-container d-flex ">
								{/* payment method */}
								<Select
									value={selectedPayin}
									name="payin"
									id="paymentMethod"
									className="form-select beneficiary-select"
									placeholder="Select a Payin"
									onChange={(e) => {
										setSelectedPayin(e);
									}}
									getPopupContainer={getPopupContainer}
								>
									{payins?.map((el, i) => (
										<Select.Option key={el.id} value={el.id} payin={el.name}>
											{el.name}
										</Select.Option>
									))}
								</Select>
								<label htmlFor="paymentMethod">Payment Method</label>
							</div>

							{/* Amount */}
							<div className="input-group position-relative mt-3 mb-3">
								<div className="form-floating">
									<input
										type="text"
										className="form-control rounded-end-0"
										id="reciepientGets"
										placeholder={0}
										value={amount}
										onChange={handleAmountChange}
										name="down"
									/>
									<label htmlFor="reciepientGets">Amount</label>
								</div>

								<div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
									<button
										key={fromCountry}
										className="btn form-control border-0 "
										type="button"
										aria-expanded="false"
									>
										<span
											// className={`flag-icon flag-icon-${"au"} flag-icon-squared`}
											className={`flag-icon flag-icon-${fromCountryIso} flag-icon-squared`}
										></span>
										&nbsp;
										{/* {"AUD"} */}
										{fromCountryCurency}
									</button>
								</div>
							</div>

							<div className="Charge_fee">
								<div className="dt_fe">
									<div className="dot__"></div>
									<span className="span_fee">Fee</span>
								</div>
								<div>
									<p className="mb-0 text-dark fs-9 _p__fee">
										{fee} {fromCountryCurency}
									</p>
								</div>
							</div>

							<div className="error__message">
								<div className="err__icon">
									<AiOutlineInfoCircle />
								</div>
								<p className="err__info">
									it is not our culture to delay customers' transactions. 90% of
									our transactions are completed within minutes.
									<br />
									<br />
									However, Nigerian banks usually experience networking downtime
									by the end of the month. Please expect some delays during
									these times. Rest assured that your transaction will still be
									processed the same day. Thanks
								</p>
							</div>
						</div>
					</form>

					<div className="BUTTON_btn">
						<button
							disabled={wallet_funding}
							type="submit"
							className="btn min-height btn-red w-100 text-3xl font-bold "
							onClick={handleFundWallet}
						>
							Fund Wallet {wallet_funding && <Spin />}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default FundWallet;
