import * as actionTypes from "../actionTypes/settingsActionTypes";
import { http } from "../../config/axios";
import * as notifications from "../../components/notifications/notifications";
import * as error from "./errorActions";

export const updatePassword = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_PASSSORD });
    error.clearError(dispatch);
    const res = await http.post("/settings/update_password", fd);
    dispatch({ type: actionTypes.UPDATING_PASSSORD });
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_PASSSORD });
    dispatch(error.setErrorMsg(err));
  }
};

export const updateEmail = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_EMAIL });
    error.clearError(dispatch);
    dispatch({ type: actionTypes.UPDATED_EMAIL, payload: false });

    await http.post("/settings/update_email", fd);
    dispatch({ type: actionTypes.UPDATING_EMAIL });
    dispatch({ type: actionTypes.SET_EMAIL, payload: fd.email });
    dispatch({ type: actionTypes.UPDATED_EMAIL, payload: true });
    // notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_EMAIL });
    dispatch(error.setErrorMsg(err));
  }
};

export const verifyEmailOtp = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CONFIRMED_EMAIL_OTP, payload: false });
    dispatch({ type: actionTypes.CONFIRMING_EMAIL_OTP });
    error.clearError(dispatch);
    const res = await http.post("/settings/verify_email_otp", fd);
    notifications.success({ title: "Success", msg: res.data.message });
    dispatch({ type: actionTypes.CONFIRMING_EMAIL_OTP });
    dispatch({ type: actionTypes.CONFIRMED_EMAIL_OTP, payload: true });
  } catch (err) {
    console.log(err);
    dispatch({ type: actionTypes.CONFIRMING_EMAIL_OTP });
    dispatch(error.setErrorMsg(err));
  }
};

export const verifyPhoneOtp = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CONFIRMED_PHONE_OTP, payload: false });
    dispatch({ type: actionTypes.CONFIRMING_PHONE_OTP });
    error.clearError(dispatch);
    const res = await http.post("/settings/verify_phone_otp", fd);
    notifications.success({ title: "Success", msg: res.data.message });
    dispatch({ type: actionTypes.CONFIRMING_PHONE_OTP });
    dispatch({ type: actionTypes.CONFIRMED_PHONE_OTP, payload: true });
  } catch (err) {
    console.log(err);
    dispatch({ type: actionTypes.CONFIRMING_PHONE_OTP });
    dispatch(error.setErrorMsg(err));
  }
};

export const updatePhone = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_PHONE });
    error.clearError(dispatch);
    dispatch({ type: actionTypes.UPDATED_PHONE, payload: false });

    await http.post("/settings/update_phone", fd);
    dispatch({ type: actionTypes.UPDATING_PHONE });
    dispatch({ type: actionTypes.SET_PHONE, payload: fd.phone });
    dispatch({ type: actionTypes.UPDATED_PHONE, payload: true });
    // notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_PHONE });
    dispatch(error.setErrorMsg(err));
  }
};

export const updateNotification = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_NOTIFICATION });
    error.clearError(dispatch);
    const res = await http.post("/settings/notifications", fd);
    notifications.success({ title: "Success", msg: res.data.message });
    dispatch({ type: actionTypes.UPDATING_NOTIFICATION });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_NOTIFICATION });
    dispatch(error.setErrorMsg(err));
  }
};

export const updateLanguage = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_LANGUAGE });
    error.clearError(dispatch);
    const res = await http.post("/settings/language", fd);
    notifications.success({ title: "Success", msg: res.data.message });
    localStorage.setItem("authRes", JSON.stringify(res.data.data));
    dispatch({ type: actionTypes.UPDATING_LANGUAGE });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_LANGUAGE });
    dispatch(error.setErrorMsg(err));
  }
};

export const deactivateAccount = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DEACTIVATING_ACCOUNT });
     dispatch({ type: actionTypes.DEACTIVATED_ACCOUNT,payload:false });
    error.clearError(dispatch);
    const res = await http.post("/settings/deactivate", {reason_id:[fd.reason],comment:fd.comment});
    dispatch({ type: actionTypes.DEACTIVATED_ACCOUNT,payload:true });
    notifications.success({ title: "Success", msg: res.data.message });
    dispatch({ type: actionTypes.DEACTIVATING_ACCOUNT });
  } catch (err) {
    dispatch({ type: actionTypes.DEACTIVATING_ACCOUNT });
    dispatch(error.setErrorMsg(err));
  }
};

export const cancelDeactivation = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CANCELING });
     dispatch({ type: actionTypes.CANCELED,payload:false });
    error.clearError(dispatch);
    const res = await http.post("/settings/cancel_deactivate");
    dispatch({ type: actionTypes.CANCELED,payload:true });
    notifications.success({ title: "Success", msg: res.data.message });
    dispatch({ type: actionTypes.CANCELING });
  } catch (err) {
    dispatch({ type: actionTypes.CANCELING });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleEmailOtpModal = (dispatch) => {
  dispatch({ type: actionTypes.UPDATED_EMAIL, payload: false });
};

export const togglePhoneOtpModal = (dispatch) => {
  dispatch({ type: actionTypes.UPDATED_PHONE, payload: false });
};

export const toggleTheDeactivateModal = (dispatch)=>{
  dispatch({type:actionTypes.TOGGLE_DEACTIVATE_MODAL});
}