import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const ModalComponent = ({ show, onClose, children, body_clickOff }) => {
	if (!show) {
		return null;
	}

	return (
		<div className="modal-backdrop" onClick={body_clickOff}>
			<div className="modal-content" onClick={(e) => e.stopPropagation()}>
				<button className="modal-close" onClick={onClose}>
					<AiOutlineClose className="closeModal_icon" />
				</button>
				{children}
			</div>
		</div>
	);
};

export default ModalComponent;
