import React from "react";
import privacyImage from "../../assets/images/components/privacyIcon.png";

function PrivacyPolicy() {
  return (
    <div>
      <div className="privacy-container">
        <img
          className="privacy-image"
          src={privacyImage}
          alt="logo"
          srcset=""
        />
        <div>
          <h1>
            Privacy Policy <br /> COSMOREMIT PTY LTD
          </h1>

          <h3>
            Level 14, 3 Parramatta Square 153 Macquarie Street Parramatta NSW
            2150
          </h3>
          <p>This Policy was last reviewed and updated in: June 2022</p>
        </div>

        <div>
          <section>
            <h5>Privacy Policy</h5>
            <p>
              CosmoRemit values and respects the privacy of the people we deal
              with. CosmoRemit is committed to protecting your privacy and
              complying with the Privacy Act 1988 (Privacy Act) and other
              applicable privacy laws and regulations.
            </p>
            <p>
              This Privacy Policy (Policy) describes how we collect, hold, use
              and disclose your personal information, and how we maintain the
              quality and security of your personal information.
            </p>
            <h5>What is personal information?</h5>
            <p>
              “Personal information” means any information or opinion, whether
              true or not, and whether recorded in a material form or not, about
              an identified individual or an individual who is reasonably
              identifiable. In general terms, this includes information or an
              opinion that personally identifies you either directly (e.g. your
              name) or indirectly.
            </p>
            <h5> What personal information do we collect? </h5>
            <p>
              The personal information we collect about you depends on the
              nature of your dealings with us or what you choose to share with
              us.
            </p>
            <p> The personal information we collect about you may include: </p>
            <ul>
              <li> Full Legal name </li>
              <li>
                Contact information such as email address, postal address and
                telephone number{" "}
              </li>
              <li>
                Demographic information such as date of birth, age and gender
              </li>
              <li>
                Evidence of identity (Ex: Passport copy, Driver’s License, Photo
                ID){" "}
              </li>
              <li>
                Payment details and other financial data (Ex: BSB & Account
                Number, Bank name and Poli ID)
              </li>
              <li>
                Information about your visit to our website and login history
              </li>
            </ul>
            <p>
              {" "}
              We will not collect sensitive information about you without your
              consent unless an exception in the Act applies. These exceptions
              include if the collection is required or authorised by law or
              necessary to take appropriate action in relation to suspected
              unlawful activity or serious misconduct.
            </p>
            <p>
              {" "}
              Under certain circumstances, CosmoRemit may need to collect
              sensitive information about you. This might include any
              information or opinion about your racial or ethnic origin,
              political opinions, political association, religious or
              philosophical beliefs, membership of a trade union or other
              professional body, sexual preferences, criminal record, or health
              information.
            </p>
            <p>
              {" "}
              If we collect your sensitive information, we will do so only with
              your consent, if it is necessary to prevent a serious and imminent
              threat to life or health, or as otherwise required or authorised
              by law, and we take appropriate measures to protect the security
              of this information.
            </p>
            <p>
              You do not have to provide us with your personal information.
              Where possible, we will give you the option to interact with us
              anonymously or by using a pseudonym. However, if you choose to
              deal with us in this way or choose not to provide us with your
              personal information, we may not be able to provide you with our
              services or otherwise interact with you.
            </p>
            <h5>How do we collect your personal information?</h5>
            <p>
              We collect your personal information directly from you when you:
            </p>
            <ul>
              <li> Interact with us over the phone </li>
              <li>Interact with us online (Email, Live chat or WhatsApp)</li>
              <li>Sign up on our website</li>
              <li> Subscribe to our mailing list </li>
            </ul>
            <h5>Collecting personal information from third parties</h5>
            <p>
              We may also collect your personal information from third parties
              or through publicly available sources. We collect your personal
              information from these third parties for Id verification and
              compliance purposes.
            </p>
            <h5>How do we use your personal information?</h5>
            <p>
              We use personal information for many purposes in connection with
              our functions and activities, including the following purposes:
            </p>
            <ul>
              <li>
                Provide you with remittance services that you request from us
              </li>
              <li>
                {" "}
                Deliver to you a more personalised experience and service
                offering
              </li>
              <li>Compliance and verification purposes</li>
              <li>Improve the quality of the services we offer</li>
              <li>Internal administrative purposes</li>
              <li>Marketing and research purposes</li>
            </ul>
            <h5>Disclosure of personal information to third parties</h5>
            <p>
              {" "}
              We may disclose your personal information to third parties in
              accordance with this Policy in circumstances where you would
              reasonably expect us to disclose your information. For example, we
              may disclose your personal information to:
            </p>
            <ul>
              <li> Our affiliates (Ex: Novatti and Flutterwave)</li>
              <li>Our financial service partners (Flexewallet , Poli)</li>
              <li>Our marketing providers </li>
              <li> Our professional services advisors</li>
            </ul>
            <h5>Transfer of personal information overseas</h5>
            <p>
              Some of the third-party service providers we disclose personal
              information to may be based in or have servers located outside of
              Australia, including in UK, Dubai and Nigeria.{" "}
            </p>
            <p>
              {" "}
              Where we disclose your personal information to third parties
              overseas, we will take reasonable steps to ensure that data
              security and appropriate privacy practices are maintained. We will
              only disclose to overseas third parties if:
            </p>
            <ul>
              <li>
                You have given us your consent to disclose personal information
                to that third party; or
              </li>
              <li>
                We reasonably believe that:
                <ul>
                  <li>
                    {" "}
                    the overseas recipient is subject to a law or binding scheme
                    that is, overall, substantially similar to the APPs; and
                  </li>
                  <li>the law or binding scheme can be enforced; or</li>
                </ul>
              </li>
              <li>
                The disclosure is required or authorised by an Australian law or
                court / tribunal order.
              </li>
            </ul>
            <h5> How do we protect your personal information?</h5>
            <p>
              CosmoRemit will take reasonable steps to ensure that the personal
              information that we hold about you is kept confidential and
              secure, including by:
            </p>
            <ul>
              <li>
                Having a robust physical security of our premises and databases
                / records
              </li>
              <li>
                Taking measures to restrict access to only personnel who need
                that personal information to effectively provide services to you
              </li>
              <li>
                Having technological measures in place (for example, anti-virus
                software, fire walls)
              </li>
            </ul>
            <h5>Online activity</h5>
            <h6>Cookies</h6>
            <p>
              The CosmoRemit website uses cookies. A cookie is a small file of
              letters and numbers the website puts on your device if you allow
              it. These cookies recognise when your device has visited our
              website(s) before, so we can distinguish you from other users of
              the website. This improves your experience and the CosmoRemit
              website.
            </p>
            <p>
              {" "}
              We do not use cookies to identify you, just to improve your
              experience on our website(s). If you do not wish to use the
              cookies, you can amend the settings on your internet browser so it
              will not automatically download cookies. However, if you remove or
              block cookies on your computer, please be aware that your browsing
              experience and our website’s functionality may be affected.
            </p>
            <h6> Website analytics</h6>
            <p>
              Our website uses website analytics tools to help us better
              understand visitor traffic, so we can improve our services.
              Although this data is mostly anonymous, it is possible that under
              certain circumstances, we may connect it to you.
            </p>
            <h6>Direct marketing</h6>
            <p>
              {" "}
              We may send you direct marketing communications and information
              about our services, opportunities, or events that we consider may
              be of interest to you if you have requested or consented to
              receive such communications. These communications may be sent in
              various forms, including mail, SMS, fax and email, in accordance
              with applicable marketing laws, such as the Australian Spam Act
              2003. You consent to us sending you those direct marketing
              communications by any of those methods. If you indicate a
              preference for a method of communication, we will endeavour to use
              that method whenever practical to do so.
            </p>
            <p>
              {" "}
              You may opt-out of receiving marketing communications from us at
              any time by following the instructions to “unsubscribe'' set out
              in the relevant communication or contacting us.
            </p>
            <p>
              In addition, we may also use your personal information or disclose
              your personal information to third parties for the purposes of
              advertising, including online behavioural advertising, website
              personalisation, and to provide targeted or retargeted advertising
              content to you (including through third party websites).
            </p>
            <h5>Retention of personal information</h5>
            <p>
              We will not keep your personal information for longer than we need
              to. In most cases, this means that we will only retain your
              personal information for the duration of your relationship with
              us. However, we have specific retention periods if you are a
              customer. We will retain your information for as long as you
              remain a customer and we keep your information for seven years
              after the date that your account was closed.
            </p>
            <h5> How to access and correct your personal information</h5>
            <p>
              CosmoRemit will endeavour to keep your personal information
              accurate, complete and up to date.
            </p>
            <p>
              {" "}
              If you wish to make a request to access and / or correct the
              personal information we hold about you, you should make a request
              by contacting us at{" "}
              <a href="mailto:office@cosmoremit.com.au">
                office@cosmoremit.com.au
              </a>{" "}
              and we will usually respond within 3 days. You can access and / or
              correct the personal information by requesting us through an email
              and providing us your proof of identity.
            </p>
            <h5>Inquiries and complaints</h5>
            <p>
              For complaints about how CosmoRemit handles, processes or manages
              your personal information, please contact{" "}
              <a href="mailto:office@cosmoremit.com.au">
                office@cosmoremit.com.au
              </a>
              <br />
              <b>
                {" "}
                Note we may require proof of your identity and full details of
                your request before we can process your complaint.
              </b>
            </p>
            <p>
              Please allow up to 5 days for CosmoRemit to respond to your
              complaint. It will not always be possible to resolve a complaint
              to everyone’s satisfaction. If you are not satisfied with
              CosmoRemit’s response to a complaint, you have the right to
              contact the Office of Australian Information Commissioner (at
              {"  "}
              <a href="www.oaic.gov.au/">www.oaic.gov.au/</a> ) to lodge a
              complaint.{" "}
            </p>
            <h5>How to contact us</h5>
            <p>
              {" "}
              If you have a question or concern in relation to our handling of
              your personal information or this Policy, you can contact us for
              assistance as follows:{" "}
            </p>
            <h6>Email</h6>
            <a href="mailto:office@cosmoremit.com.au">
              office@cosmoremit.com.au
            </a>
            <h6>Contact number</h6>
            <a href="tel:+ +61 296 159 832"> +61 296 159 832</a> <br />
            <a href="tel:+ +61 406 539 757"> +61 406 539 757</a>
            <h6>Post Address:</h6>
            <address>
              {" "}
              Level 14, 3 Parramatta Square ,153 Macquarie Street, Parramatta
              NSW 2150
            </address>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
