import React,{ useEffect, useState } from "react";
import List from "./list";
import { Modal, Spin, Select } from "antd";
import { create, search } from "../../../store/actions/admin/payout";
import {
  fetchCountries,
  fetchPayoutClasses,
} from "../../../store/actions/general";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Formik } from "formik";
import AdminSearchBar from "../../../components/partials/adminSearchBar";
import { useSelector, useDispatch } from "react-redux";


export default function Payout() {

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const { saved, saving } = useSelector((state) => state.payouts);
  const { msg } = useSelector((state) => state.error);
  const { loadingPayoutClasses, payoutClasses } = useSelector(
    (state) => state.general
  );

  const [country, setCountry] = useState();

  useEffect(() => {
    if (payoutClasses.length < 1) dispatch(fetchPayoutClasses);
    dispatch(fetchCountries);
  }, [payoutClasses]);

  useEffect(() => {
    if (saved) {
      toggleModal();
      setCountry("");
      document.getElementById("form").reset();
    }
  }, [saved]);

  return (
    <div className="container-fluid">
      <h5 className="fw-bold">Payouts</h5>
      <p className="text-muted mb-4">
        Here is a table containing a list of all your payouts. <br /> You can
        delete and edit using the action button on the right.
      </p>
      <div className="row justify-content-between mb-4">
        <div className="col-sm order-sm-1 text-end mb-3">
          <button
            className="btn btn-red px-5 min-height"
            onClick={() => {
              toggleModal();
            }}
          >
            Create Payout
          </button>{" "}
          &nbsp;
        </div>
        <AdminSearchBar callback={search} />
      </div>
      <List />
      {/* create modal */}
      <Modal
        visible={showModal}
        footer={false}
        onCancel={() => {
          dispatch(toggleModal());
        }}
      >
        <div>
          <h5 className="fw-bold">Create Payout</h5>
          <Formik
            initialValues={{
              name: "",
              bootstrap_class: "",
              inner_name: "",
              display_name: "",
              slug: "",
              eta: "",
              info: "",
              deactivated_at: false,
              country: "",
              is_default: false,
              is_plugin: false,
              fee: "",
              auto_settle:false,
              manual:false
            }}
            validationSchema={Yup.object({
              name: Yup.string("").required("Payin name is required"),
              bootstrap_class: Yup.string("").required("Class is required"),
              inner_name: Yup.string("").required("Inner name is required"),
              display_name: Yup.string("").required("Display name is required"),
              slug: Yup.string("").required("Slug is required"),
              eta: Yup.string("").required("Eta is required"),
              fee: Yup.string("").required("Fee is required"),
              info: Yup.string("").required("Info is required"),
              deactivated_at: Yup.string(""),
              is_default: Yup.string(""),
              is_plugin: Yup.string(""),
              country: Yup.string("").required("Country is required"),
            })}
            onSubmit={(values) => {
              dispatch(create(values));
            }}
          >
            {(props) => (
              <form id="form" onSubmit={props.handleSubmit}>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor>Payout name</label>
                      <input
                        type="text"
                        className="form-control min-height"
                        name="name"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                      {props.touched.name && props.errors.name && (
                        <FormikError msg={props.errors.name} />
                      )}
                      <FormikError msg={msg?.name ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor>Bootstrap class</label>
                      <select
                        name="bootstrap_class"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="form-select min-height"
                      >
                        <option value="">--select--</option>
                        {payoutClasses.map((el, i) => {
                          return (
                            <option key={i} value={el}>
                              {el}
                            </option>
                          );
                        })}
                      </select>
                      {loadingPayoutClasses && <i>...loading</i>}
                      {props.touched.bootstrap_class &&
                        props.errors.bootstrap_class && (
                          <FormikError msg={props.errors.bootstrap_class} />
                        )}
                      <FormikError msg={msg?.bootstrap_class ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor>Inner name</label>
                      <input
                        type="text"
                        className="form-control min-height"
                        name="inner_name"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </div>
                    {props.touched.inner_name && props.errors.inner_name && (
                      <FormikError msg={props.errors.inner_name} />
                    )}
                    <FormikError msg={msg?.inner_name ?? ""} />
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor>Display name</label>
                      <input
                        type="text"
                        className="form-control min-height"
                        name="display_name"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </div>
                    {props.touched.display_name &&
                      props.errors.display_name && (
                        <FormikError msg={props.errors.display_name} />
                      )}
                    <FormikError msg={msg?.display_name ?? ""} />
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor>slug</label>
                      <input
                        type="text"
                        className="form-control min-height"
                        name="slug"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </div>
                    {props.touched.slug && props.errors.slug && (
                      <FormikError msg={props.errors.slug} />
                    )}
                    <FormikError msg={msg?.slug ?? ""} />
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor>Estimate Time Arrival</label>
                      <input
                        type="text"
                        className="form-control min-height"
                        name="eta"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </div>
                    {props.touched.eta && props.errors.eta && (
                      <FormikError msg={props.errors.eta} />
                    )}
                    <FormikError msg={msg?.eta ?? ""} />
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor>Fee</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="fee"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </div>
                    {props.touched.fee && props.errors.fee && (
                      <FormikError msg={props.errors.fee} />
                    )}
                    <FormikError msg={msg?.fee ?? ""} />
                  </div>
                  <div className="col-md-6" />
                  <div className="col-md-6">
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="_default"
                        name="is_default"
                        value={props.values.is_default}
                        onChange={(e) => {
                          if (e.target.checked) {
                            props.setValues({
                              ...props.values,
                              is_default: true,
                            });
                          } else {
                            props.setValues({
                              ...props.values,
                              is_default: false,
                            });
                          }
                        }}
                        onBlur={props.handleBlur}
                      />

                      <label className="form-check-label" htmlFor="_default">
                        Default
                      </label>
                    </div>
                    {props.touched.is_default && props.errors.is_default && (
                      <FormikError msg={props.errors.is_default} />
                    )}
                    <FormikError msg={msg?.is_default ?? ""} />
                  </div>
                  <div className="col-md-6">
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="_isplugin"
                        name="is_plugin"
                        onBlur={props.handleBlur}
                        value={props.values.is_plugin}
                        onChange={(e) => {
                          if (e.target.checked) {
                            props.setValues({
                              ...props.values,
                              is_plugin: true,
                            });
                          } else {
                            props.setValues({
                              ...props.values,
                              is_plugin: false,
                            });
                          }
                        }}
                      />

                      <label className="form-check-label" htmlFor="_isplugin">
                        Is Plugin
                      </label>
                    </div>
                    {props.touched.is_plugin && props.errors.is_plugin && (
                      <FormikError msg={props.errors.is_plugin} />
                    )}
                    <FormikError msg={msg?.is_plugin ?? ""} />
                  </div>
                  <div className="col-md-6">
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="_auto_settle"
                        name="auto_settle"
                        onBlur={props.handleBlur}
                        value={props.values.auto_settle}
                        onChange={(e) => {
                          if (e.target.checked) {
                            props.setValues({
                              ...props.values,
                              auto_settle: true,
                            });
                          } else {
                            props.setValues({
                              ...props.values,
                              auto_settle: false,
                            });
                          }
                        }}
                      />

                      <label className="form-check-label" htmlFor="_auto_settle">
                        Auto settle
                      </label>
                    </div>
                    {props.touched.auto_settle && props.errors.auto_settle && (
                      <FormikError msg={props.errors.auto_settle} />
                    )}
                    <FormikError msg={msg?.auto_settle ?? ""} />
                  </div>
                  <div className="col-md-6">
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="_manual"
                        name="manual"
                        onBlur={props.handleBlur}
                        value={props.values.manual}
                        onChange={(e) => {
                          if (e.target.checked) {
                            props.setValues({
                              ...props.values,
                              manual: true,
                            });
                          } else {
                            props.setValues({
                              ...props.values,
                              manual: false,
                            });
                          }
                        }}
                      />

                      <label className="form-check-label" htmlFor="_manual">
                        Manual
                      </label>
                    </div>
                    {props.touched.manual && props.errors.manual && (
                      <FormikError msg={props.errors.manual} />
                    )}
                    <FormikError msg={msg?.manual ?? ""} />
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor>Info</label>
                      <textarea
                        rows={5}
                        className="form-control"
                        name="info"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </div>
                    {props.touched.info && props.errors.info && (
                      <FormikError msg={props.errors.info} />
                    )}
                    <FormikError msg={msg?.info ?? ""} />
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor>Country</label>
                      <Select
                        showSearch
                        filterOption={(input, option) => {
                          return option.name
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        placeholder="Select country"
                        className="form-control min-height"
                        onChange={(value) => {
                          setCountry(value);
                          props.setValues({
                            ...props.values,
                            country: value,
                          });
                        }}
                        value={country}
                      >
                        {JSON.parse(
                          localStorage.getItem("cosmo_countries")
                        )?.map((el) => {
                          return (
                            <Select.Option
                              key={el.id}
                              id={el.id}
                              countryCode={el.phonecode}
                              name={el.name}
                            >
                              <span
                                className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                              ></span>
                              {"\u00A0"}
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      {props.touched.country && props.errors.country && (
                        <FormikError msg={props.errors.country} />
                      )}
                      <FormikError msg={msg?.country ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="_suspenduser"
                        name="deactivated_at"
                        onBlur={props.handleBlur}
                        value={props.values.is_plugin}
                        onChange={(e) => {
                          if (e.target.checked) {
                            props.setValues({
                              ...props.values,
                              deactivated_at: true,
                            });
                          } else {
                            props.setValues({
                              ...props.values,
                              deactivated_at: false,
                            });
                          }
                        }}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="_suspenduser"
                      >
                        Deactivate
                      </label>
                    </div>
                    {props.touched.deactivated_at &&
                      props.errors.deactivated_at && (
                        <FormikError msg={props.errors.deactivated_at} />
                      )}
                    <FormikError msg={msg?.deactivated_at ?? ""} />
                  </div>
                </div>
                <div className="text-end">
                  <button
                    className="btn btn-outline-red ms-2 px-4"
                    type="button"
                    onClick={() => {
                      toggleModal();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red ms-2 px-4"
                    type="submit"
                    disabled={saving}
                  >
                    Submit {saving && <Spin />}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}
