import React, { useState } from "react";
import { Button, Modal } from "antd";
import { IoCheckmark } from "react-icons/io5";
import "../../../../../assets/css/successfullCreateWallet.css";
import { useNavigate } from "react-router-dom";

const SuccessfulCreateWallet = ({
  walletCurrency,
  showModal,
  handleCancel,
}) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    handleCancel();
    navigate("/wallet");
  };
  return (
    <>
      <Modal
        title={null}
        visible={showModal}
        onCancel={handleCancel}
        footer={null} // remove all the buttons below and disable them
        closable={false} // remove the (X) icon and functionality
        width={400} // resize the width of the modal
      >
        <div className="successful__container">
          <div className="succeess__i_tag">
            {" "}
            <div className="icon_round">
              <span className="icon_check"></span>
              {/* <IoCheckmark className="icon_check" /> */}
            </div>
            <h4>Successful</h4>
            <small>Wallet Created Successfully</small>
          </div>
          <div className="paragraph_info_p">
            <p>
              {walletCurrency} Wallet has been created you can now perform
              transactions in and with this wallet currency
            </p>
          </div>

          <div className="BUTTON_btn_success">
            <button
              onClick={handleButtonClick}
              type="submit"
              className="btn min-height btn-red w-100"
            >
              Go to Wallet
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SuccessfulCreateWallet;
