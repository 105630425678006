import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin, Modal } from "antd";
import {
  updatePassword,
  updateEmail,
  toggleEmailOtpModal,
  updatePhone,
  verifyEmailOtp,
  togglePhoneOtpModal,
  verifyPhoneOtp,
  updateLanguage,
  updateNotification,
  toggleTheDeactivateModal,
  deactivateAccount,
  cancelDeactivation
} from "../../../store/actions/settingsActions";
import { fetchDeactivationReasons } from "../../../store/actions/general";
import { logout } from "../../../store/actions/authActions";

export default function Settings() {
  const { msg } = useSelector((state) => state.error);
  const {
    updatingPassword,
    updatingEmail,
    newEmail,
    newPhone,
    updatedEmail,
    updatedPhone,
    updatingPhone,
    confirmingEmailOtp,
    confirmingPhoneOtp,
    confirmedPhoneOtp,
    confirmedEmailOtp,
    // updatingLanguage,
    showDeactivateModal,
    deactivating,
    deactivated,
    updatingNotification,
    cacelingDeactivation,
    canceledDeactivation
  } = useSelector((state) => state.settings);

  const { reasons } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const { email, profile, country, settings, requested_deactivation } =
    JSON.parse(localStorage.getItem("authRes"));

  const navigate = useNavigate();

  const [currentPasswordType, setCurrentPasswordType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypePhone, setPasswordTypePhone] = useState("password");
  const [passwordTypeEmail, setPasswordTypeEmail] = useState("password");
  const [deactivationModal, showCancelDeativationModal] = useState(false);

  useEffect(() => {
    dispatch(fetchDeactivationReasons());
  }, []);

  useEffect(() => {
    if (deactivated) {
      toggleDeactivateModal();
      // dispatch(logout);
      // navigate("login");
    }
    if(canceledDeactivation){
      showCancelDeativationModal(false)
    }
  }, [deactivated, dispatch,canceledDeactivation]);

  useEffect(() => {
    if (confirmedEmailOtp) dispatch(toggleEmailOtpModal);
    if (confirmedPhoneOtp) dispatch(togglePhoneOtpModal);
  }, [confirmedPhoneOtp, confirmedEmailOtp, dispatch]);

  const passwordSchema = Yup.object({
    current_password: Yup.string("").required("enter current password"),
    new_password: Yup.string("").required("enter new password"),
  });

  const emailSchema = Yup.object({
    current_password: Yup.string("").required("enter current password"),
    email: Yup.string("")
      .required("enter new email")
      .required("email is required")
      .test("Email", "enter a valid email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        let isValidEmail = emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        return true;
      }),
  });

  const toggleDeactivateModal = (e) => {
    e?.preventDefault();
    if (deactivating) return;
    dispatch(toggleTheDeactivateModal);
  };

  return (
    <div className="container">
      <div
        className="accordion settings rounded overflow-hidden accordion-flush mb-3"
        id="accordionFlushExample"
      >
        {/* Change Password */}
        <div className="accordion-item border-bottom-0">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="../assets/images/icon/Group 779.png" alt="pics" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="fw-bold">Change Password</h6>
                  <span className="text-muted">********</span>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body border-bottom pt-1 pb-4">
              <Formik
                validationSchema={passwordSchema}
                initialValues={{ current_password: "", new_password: "" }}
                onSubmit={(values) => {
                  dispatch(updatePassword(values));
                }}
              >
                {(props) => (
                  <form className="ms-lg-5" onSubmit={props.handleSubmit}>
                    <div className="row">
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-3">
                          <label className="mb-2">Current password</label>
                          <div className="input-group">
                            <input
                              type={currentPasswordType}
                              className="form-control border-end-0 min-height"
                              name="current_password"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              autoComplete="new-password"
                            />
                            <button
                              className="btn border border-start-0"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentPasswordType(
                                  currentPasswordType === "password"
                                    ? "text"
                                    : "password"
                                );
                              }}
                            >
                              <svg
                                className="svg-inline--fa fa-eye text-muted"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="eye"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                data-fa-i2svg
                              >
                                <path
                                  fill="currentColor"
                                  d="M572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM288 432c-99.48 0-191.2-67.5-239.6-175.1C97.01 147.4 188.6 80 288 80c99.48 0 191.2 67.5 239.6 175.1C478.1 364.6 387.4 432 288 432zM288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 336c-44.11 0-80-35.89-80-80c0-.748 .1992-1.441 .2207-2.184C213.3 255.1 218.5 256 224 256c35.35 0 64-28.65 64-64c0-5.48-.875-10.72-2.184-15.78C286.6 176.2 287.3 176 288 176c44.11 0 80 35.89 80 80.05C368 300.1 332.1 336 288 336z"
                                />
                              </svg>
                              {/* <i class="far fa-eye text-muted"></i> Font Awesome fontawesome.com */}
                              {/* <i class="far fa-eye-slash text-muted"></i> */}
                            </button>
                          </div>
                          {props.touched.current_password &&
                            props.errors.current_password && (
                              <FormikError
                                msg={props.errors.current_password}
                              />
                            )}
                          <FormikError msg={msg?.current_password ?? ""} />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-3">
                          <label className="mb-2">New password</label>
                          <div className="input-group">
                            <input
                              type={passwordType}
                              className="form-control border-end-0 min-height"
                              name="new_password"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              autoComplete="new-password"
                            />
                            <button
                              className="btn border border-start-0"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setPasswordType(
                                  passwordType === "password"
                                    ? "text"
                                    : "password"
                                );
                              }}
                            >
                              <svg
                                className="svg-inline--fa fa-eye text-muted"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="eye"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                data-fa-i2svg
                              >
                                <path
                                  fill="currentColor"
                                  d="M572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM288 432c-99.48 0-191.2-67.5-239.6-175.1C97.01 147.4 188.6 80 288 80c99.48 0 191.2 67.5 239.6 175.1C478.1 364.6 387.4 432 288 432zM288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 336c-44.11 0-80-35.89-80-80c0-.748 .1992-1.441 .2207-2.184C213.3 255.1 218.5 256 224 256c35.35 0 64-28.65 64-64c0-5.48-.875-10.72-2.184-15.78C286.6 176.2 287.3 176 288 176c44.11 0 80 35.89 80 80.05C368 300.1 332.1 336 288 336z"
                                />
                              </svg>
                              {/* <i class="far fa-eye text-muted"></i> Font Awesome fontawesome.com */}
                              {/* <i class="far fa-eye-slash text-muted"></i> */}
                            </button>
                          </div>
                          {props.touched.new_password &&
                            props.errors.new_password && (
                              <FormikError msg={props.errors.new_password} />
                            )}
                          <FormikError msg={msg?.new_password ?? ""} />
                        </div>
                      </div>
                      <div className="col-lg-2 mb-3 py-4 ">
                        <button
                          type="submit"
                          className="btn btn-light min-height px-5 border "
                          disabled={updatingPassword}
                        >
                          Update {updatingPassword && <Spin />}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* end of change password */}
        {/* change email */}
        <div className="accordion-item border-bottom-0">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img
                    src="../assets/images/icon/Group 781.png"
                    alt="Email icon"
                  />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="fw-bold">Change Email</h6>
                  <span className="text-muted">{email}</span>{" "}
                  <a
                    href="#changeEmailModal"
                    data-bs-toggle="modal"
                    className="text-red fw-light"
                  >
                    <span>
                      You will need to verify your new email before the change
                      is confirmed
                    </span>
                  </a>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body border-bottom pt-1 pb-4">
              <Formik
                onSubmit={(values) => {
                  dispatch(updateEmail(values));
                }}
                validationSchema={emailSchema}
                initialValues={{ current_password: "", email: "" }}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit} className="ms-lg-5">
                    <p className="fw-bold my-3">
                      We’ll send a verifcation code to your new email
                    </p>
                    <div className="row">
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-3">
                          <label className="mb-2">Your password</label>
                          <div className="input-group">
                            <input
                              type={passwordTypeEmail}
                              className="form-control border-end-0 min-height"
                              name="current_password"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              autoComplete="new-password"
                            />
                            <button
                              className="btn border border-start-0"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setPasswordTypeEmail(
                                  passwordTypeEmail === "password"
                                    ? "text"
                                    : "password"
                                );
                              }}
                            >
                              <svg
                                className="svg-inline--fa fa-eye text-muted"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="eye"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                data-fa-i2svg
                              >
                                <path
                                  fill="currentColor"
                                  d="M572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM288 432c-99.48 0-191.2-67.5-239.6-175.1C97.01 147.4 188.6 80 288 80c99.48 0 191.2 67.5 239.6 175.1C478.1 364.6 387.4 432 288 432zM288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 336c-44.11 0-80-35.89-80-80c0-.748 .1992-1.441 .2207-2.184C213.3 255.1 218.5 256 224 256c35.35 0 64-28.65 64-64c0-5.48-.875-10.72-2.184-15.78C286.6 176.2 287.3 176 288 176c44.11 0 80 35.89 80 80.05C368 300.1 332.1 336 288 336z"
                                />
                              </svg>
                              {/* <i class="far fa-eye text-muted"></i> Font Awesome fontawesome.com */}
                              {/* <i class="far fa-eye-slash text-muted"></i> */}
                            </button>
                          </div>
                          {props.touched.current_password &&
                            props.errors.current_password && (
                              <FormikError
                                msg={props.errors.current_password}
                              />
                            )}
                          <FormikError msg={msg?.current_password ?? ""} />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-3">
                          <label className="mb-2">New email</label>
                          <input
                            type="email"
                            className="form-control min-height"
                            name="email"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            autoComplete="new-password"
                          />
                          {props.touched.email && props.errors.email && (
                            <FormikError msg={props.errors.email} />
                          )}
                          <FormikError msg={msg?.email ?? ""} />
                        </div>
                      </div>
                      <div className="col-lg-2 mb-3 py-4 ">
                        <button
                          type="submit"
                          className="btn btn-light min-height px-5 border "
                          disabled={updatingEmail}
                        >
                          Update {updatingEmail && <Spin />}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* end of change email */}
        {/* change phone */}
        <div className="accordion-item border-bottom-0">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="../assets/images/icon/Group 783.png" alt="img" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="fw-bold">Change Phone Number</h6>
                  <span className="text-muted">
                    {profile.mobile_phone}
                  </span>{" "}
                  <a
                    href="#changephoneModal"
                    data-bs-toggle="modal"
                    className="text-red fw-light"
                  >
                    <span>
                      You will need to verify your new phone number before the
                      change is confirmed
                    </span>
                  </a>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body border-bottom pt-1 pb-4">
              <Formik
                initialValues={{ current_password: "", phone: "" }}
                validationSchema={Yup.object({
                  current_password: Yup.string("").required(
                    "enter current passord"
                  ),
                  phone: Yup.number()
                    .typeError("invalid phone number")
                    .required("enter phone number"),
                })}
                onSubmit={(values) => {
                  dispatch(updatePhone(values));
                }}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit} className="ms-lg-5">
                    <p className="fw-bold my-3">
                      We’ll send a verifcation code to your new phone number
                    </p>
                    <div className="row">
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-3">
                          <label className="mb-2">Your Password</label>
                          <div className="input-group">
                            <input
                              type={passwordTypePhone}
                              className="form-control border-end-0 min-height"
                              name="current_password"
                              onChange={props.handleChange}
                              onBlur={props.handleChange}
                              autoComplete="new-password"
                            />
                            <button
                              className="btn border border-start-0"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setPasswordTypePhone(
                                  passwordTypePhone === "password"
                                    ? "text"
                                    : "password"
                                );
                              }}
                            >
                              <svg
                                className="svg-inline--fa fa-eye text-muted"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="eye"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                data-fa-i2svg
                              >
                                <path
                                  fill="currentColor"
                                  d="M572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM288 432c-99.48 0-191.2-67.5-239.6-175.1C97.01 147.4 188.6 80 288 80c99.48 0 191.2 67.5 239.6 175.1C478.1 364.6 387.4 432 288 432zM288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 336c-44.11 0-80-35.89-80-80c0-.748 .1992-1.441 .2207-2.184C213.3 255.1 218.5 256 224 256c35.35 0 64-28.65 64-64c0-5.48-.875-10.72-2.184-15.78C286.6 176.2 287.3 176 288 176c44.11 0 80 35.89 80 80.05C368 300.1 332.1 336 288 336z"
                                />
                              </svg>
                              {/* <i class="far fa-eye text-muted"></i> Font Awesome fontawesome.com */}
                              {/* <i class="far fa-eye-slash text-muted"></i> */}
                            </button>
                          </div>
                          {props.touched.current_password &&
                            props.errors.current_password && (
                              <FormikError
                                msg={props.errors.current_password}
                              />
                            )}
                          <FormikError msg={msg?.current_password ?? ""} />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-3">
                          <label className="mb-2">New Phone number</label>
                          <div className="input-group">
                            {/* input box */}
                            <span className="text-muted input-group-text bg-white ">
                              +{country.phonecode}
                            </span>
                            <input
                              type="text"
                              placeholder="Phone number"
                              className="form-control border-start-0 min-height"
                              onChange={props.handleChange}
                              onBlur={props.handleChange}
                              name="phone"
                              autoComplete="new-password"
                            />
                          </div>
                          {props.touched.phone && props.errors.phone && (
                            <FormikError msg={props.errors.phone} />
                          )}
                          <FormikError msg={msg?.phone ?? ""} />
                        </div>
                      </div>
                      <div className="col-lg-2 mb-3 py-4 ">
                        <button
                          type="submit"
                          className="btn btn-light min-height px-5 border "
                          disabled={updatingPhone}
                        >
                          Update {updatingPhone && <Spin />}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* end of change phone */}

        {/* Notification */}
        <div className="accordion-item border-bottom-0">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="../assets/images/icon/Group 784.png" alt="img" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="fw-bold">Notification</h6>
                  <span className="text-muted">
                    Choose notification preferences and how you would like to be
                    contacted.
                  </span>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body border-bottom pt-2 pb-4">
              <Formik
                initialValues={{
                  sms: settings?.notifications?.sms,
                  email: settings?.notifications?.email,
                }}
              >
                {(props) => (
                  <form className="ms-lg-5">
                    {/* email notification */}
                    <div className="d-flex justify-content-between">
                      <label>Email Notifications</label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="email"
                          defaultChecked={props.values.email}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              email: e.target.checked ? true : false,
                            });
                          }}
                        />
                      </div>
                    </div>

                    {/* smsm notification */}
                    <div className="d-flex justify-content-between">
                      <label>SMS Notifications</label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="sms"
                          defaultChecked={props.values.sms}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              sms: e.target.checked ? true : false,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="smsNotification"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label></label>
                      <div className="form-check form-switch">
                        <button
                          className="btn btn-light min-height px-5 border"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(updateNotification(props.values));
                          }}
                        >
                          Update {updatingNotification && <Spin />}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* end of Notification */}
        {/* Language */}
        {/* <div className="accordion-item border-bottom-0">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="../assets/images/icon/Group 782.png" alt="img" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="fw-bold">Language</h6>
                  <span className="text-muted">
                    Set default language preference
                  </span>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="flush-collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body border-bottom pt-1 pb-4">
              <Formik
                initialValues={{ language: settings.language }}
                onSubmit={(values) => {
                  dispatch(updateLanguage(values));
                }}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit} className="ms-lg-5">
                    <div className="row">
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-3">
                          <label className="mb-2">Select Language</label>
                          <select
                            name="language"
                            className="form-select min-height"
                            defaultValue={props.values.language}
                            onChange={(e) => {
                              props.setValues({ language: e.target.value });
                            }}
                          >
                            <option value="en">English (UK)</option>
                            <option value="es">Spain</option>
                            <option value="fr">French</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 mb-3 align-self-end">
                        <button
                          type="submit"
                          className="btn btn-light min-height px-5 border"
                        >
                          Update {updatingLanguage && <Spin />}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div> */}
        {/* end of Language */}
        {/* Device history */}
        {/* <div className="accordion-item border-bottom-0">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSix"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="../assets/images/icon/Group 780.png" alt="History" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="fw-bold">Device history</h6>
                  <span className="text-muted">
                    Control your login activity across all your devices.
                  </span>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="flush-collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body border-bottom pt-1 pb-4">
              <div className="ms-lg-5">
                <div className="d-flex mb-3">
                  <div className="flex-shrink-0">
                    <div style={{ width: 20 }}>
                      <img src="../assets/images/icon/Group(1).png" alt="Computer icon" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <p className="mb-0">
                          Windows(Chrome) &nbsp; 160.252.121.192
                        </p>
                        <small className="text-muted">
                          20, Sept 2021 at 09:15
                        </small>
                      </div>
                      <p>Current activity</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="flex-shrink-0">
                    <div style={{ width: 20 }}>
                      <img src="../assets/images/icon/Mobile phone.png" alt="Pone icon" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <p className="mb-0">SM-A022F &nbsp; 160.252.121.192</p>
                        <small className="text-muted">
                          20, Sept 2021 at 09:15
                        </small>
                      </div>
                      <a href="#" className="text-red">
                        log out device
                      </a>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button className="btn btn-red min-height px-4">
                    Log out of all device
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* end of Device history */}
      </div>
      {requested_deactivation ? (
        <div className="ps-4">
          <a
            className="d-block text-red ms-lg-5"
            onClick={() => {
              showCancelDeativationModal(true);
            }}
            href="deactivate"
          >
            Cancel account deactivation
          </a>
        </div>
      ) : (
        <div className="ps-4">
          <a
            className="d-block text-red ms-lg-5"
            onClick={toggleDeactivateModal}
            href="deactivate"
          >
            Deactivate Account
          </a>
        </div>
      )}

      {/* cancel deactivation modal */}
      <Modal
        visible={deactivationModal}
        footer={false}
        onCancel={() => {
          showDeactivateModal(false);
        }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <p className="text-muted">Are you sure you want to cancel account deactivation?</p>
          </div>
          <button
              className="btn btn-outline-red mx-2 px-4"
              type="button"
              onClick={() => {
                showDeactivateModal(false);
              }}
              disabled={cacelingDeactivation}
            >
              Cancel
            </button>
            <button
              className="btn btn-red mx-2 px-4"
              type="button"
              disabled={cacelingDeactivation}
              onClick={() => {
                dispatch(cancelDeactivation())
              }}
            >
              Proceed {cacelingDeactivation ? <Spin /> : ""}
            </button>
        </div>
      </Modal>
      {/* end cancel deactivation modal */}

      {/*  email otp verification modal */}
      <Modal
        visible={updatedEmail}
        onCancel={() => {
          dispatch(toggleEmailOtpModal);
        }}
        closable={true}
        footer={false}
      >
        <div className="row justify-content-center mt-3 pt-4 pb-4">
          <div className="col-lg-12">
            <h6 className="fw-bold">Verification Code</h6>
            <p className="text-muted">
              Type in the six-digit code sent to <strong>{newEmail}</strong>
            </p>
            <Formik
              initialValues={{ otp: "" }}
              validationSchema={Yup.object({
                email_otp: Yup.string("").required("enter otp"),
              })}
              onSubmit={(values) => {
                dispatch(verifyEmailOtp(values));
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="form-group mb-4">
                    <label className="mb-2">Code</label>
                    <input
                      type="text"
                      className="form-control min-height"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      name="email_otp"
                    />
                    {props.touched.otp && props.errors.otp && (
                      <FormikError msg={props.errors.otp} />
                    )}
                    <FormikError msg={msg?.email_otp ?? ""} />
                  </div>
                  <button
                    className="btn btn-red min-height w-100"
                    type="submit"
                  >
                    Confirm {confirmingEmailOtp && <Spin />}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
      {/* confirm phone otp */}
      <Modal
        visible={updatedPhone}
        onCancel={() => {
          dispatch(togglePhoneOtpModal);
        }}
        closable={true}
        footer={false}
      >
        <div className="row justify-content-center mt-3 pt-4 pb-4">
          <div className="col-lg-12">
            <h6 className="fw-bold">Verification Code</h6>
            <p className="text-muted">
              Type in the six-digit code sent to <strong>{newPhone}</strong>
            </p>
            <Formik
              initialValues={{ phone_otp: "" }}
              validationSchema={Yup.object({
                phone_otp: Yup.string("").required("enter otp"),
              })}
              onSubmit={(values) => {
                dispatch(verifyPhoneOtp(values));
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="form-group mb-4">
                    <label className="mb-2">Code</label>
                    <input
                      type="text"
                      className="form-control min-height"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      name="phone_otp"
                    />
                    {props.touched.otp && props.errors.otp && (
                      <FormikError msg={props.errors.otp} />
                    )}
                    <FormikError msg={msg?.phone_otp ?? ""} />
                  </div>
                  <button
                    className="btn btn-red min-height w-100"
                    type="submit"
                  >
                    Confirm {confirmingPhoneOtp && <Spin />}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>

      {/* deactivte modal */}
      <Modal
        visible={showDeactivateModal}
        onCancel={(e) => {
          toggleDeactivateModal(e);
        }}
        closable={true}
        footer={false}
        title={<h6 className="fw-bold">Deactivate Account</h6>}
      >
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <p className="text-muted">Kindly tell us why you’re leaving</p>
            <Formik
              initialValues={{ reason: "", comment: "" }}
              validationSchema={Yup.object({
                comment: Yup.string("").nullable("enter otp"),
                reason: Yup.string().required("please select a reason"),
              })}
              onSubmit={(values) => {
                dispatch(deactivateAccount(values));
              }}
            >
              {(props) => (
                <form className="pt-3" onSubmit={props.handleSubmit}>
                  {reasons.map((el, index) => {
                    return (
                      <div key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            value={el.id}
                            name="reason"
                            id={`reason${index}`}
                          />
                          <label
                            className="form-check-label adjust"
                            htmlFor={`reason${index}`}
                          >
                            {el.reason}
                          </label>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                  {props.touched.reason && props.errors.reason && (
                    <FormikError msg={props.errors.reason} />
                  )}
                  <FormikError msg={msg?.reason_id ?? ""} />
                  <div className="form-group mb-4">
                    <label className="mb-2">
                      Any other additional info (Optional)
                    </label>
                    <textarea
                      className="form-control min-height"
                      name="comment"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      rows={4}
                    />
                    {props.touched.comment && props.errors.comment && (
                      <FormikError msg={props.errors.comment} />
                    )}
                  </div>
                  <button
                    type="submit"
                    disabled={deactivating}
                    className="btn btn-red min-height w-100"
                  >
                    Deactivate {deactivating && <Spin />}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
