import { useState, useEffect, useRef } from "react";

const google = (window.google = window.google ? window.google : {});

const useGooglePlacesAutocomplete = (inputId, isModal, showModal) => {
	const [address, setAddress] = useState({
		street_no: "",
		street_name: "",
		suburb: "",
		unit_no: "",
		postal_code: "",
		full_address: "",
		business_location: "",
	});
	const autocompleteRef = useRef(null);

	useEffect(() => {
		if (!window.google) {
			console.error("Google Maps JavaScript API library must be loaded.");
			return;
		}
		if ((isModal && showModal) || !isModal) {
			try {
				const addressField = document.getElementById(inputId);
				if (addressField) {
					autocompleteRef.current = new google.maps.places.Autocomplete(
						addressField
					);
					autocompleteRef.current.addListener("place_changed", () => {
						const place = autocompleteRef.current.getPlace();
						const addressComponents = parseAddressComponents(
							place?.address_components
						);
						setAddress({
							...addressComponents,
							full_address: place?.formatted_address,
							business_location: place?.formatted_address,
						});
					});
				}
			} catch (err) {
				console.error("Error initializing Google Places Autocomplete:", err);
			}
		}

		// Cleanup listener on component unmount
		return () => {
			if (autocompleteRef.current) {
				google.maps.event.clearInstanceListeners(autocompleteRef.current);
			}
		};
	}, [inputId, showModal, isModal]);

	const parseAddressComponents = (components) => {
		const addressObj = {
			street_no: "",
			street_name: "",
			suburb: "",
			unit_no: "",
			postal_code: "",
		};

		for (const component of components) {
			if (component.types.includes("subpremise")) {
				addressObj.unit_no = component.long_name;
			}
			if (component.types.includes("street_number")) {
				addressObj.street_no = component.long_name;
			}
			if (component.types.includes("route")) {
				addressObj.street_name = component.long_name;
			}
			if (
				component.types.includes("locality") ||
				component.types.includes("administrative_area_level_4") ||
				component.types.includes("administrative_area_level_1")
			) {
				addressObj.suburb = component.long_name;
			}
			if (component.types.includes("postal_code")) {
				addressObj.postal_code = component.long_name;
			}
		}

		return addressObj;
	};

	return {
		address,
		autocompleteRef,
		setAddress,
	};
};

export default useGooglePlacesAutocomplete;
