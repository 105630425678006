export const FETCHING_RATED_COUNTRIES = "FETCHING_RATED_COUNTRIES";
export const SET_RATED_COUNTRIES = "SET_RATED_COUNTRIES";
export const FETCHING_DEFAULT_RATES = "FETCHING_DEFAULT_RATES";
export const SET_DEFAULT_RATES = "SET_DEFAULT_RATES";
export const FETCHING_RATE_PAYINS = "FETCHING_RATE_PAYINS";
export const SET_RATE_PAYINS = "SET_RATE_PAYINS";
export const FETCHING_INPUT_FROM_AMOUNT = "FETCHING_INPUT_FROM_AMOUNT";
export const SET_INPUT_FROM_AMOUNT = "SET_INPUT_FROM_AMOUNT";
export const FETCHING_INPUT_RECEPIENT_AMOUNT =
  "FETCHING_INPUT_RECEPIENT_AMOUNT";
export const SET_INPUT_RECEPIENT_AMOUNT = "SET_INPUT_RECEPIENT_AMOUNT";
export const FETCED_DEFAULT_RATES = "FETCED_DEFAULT_RATES";
export const FETCHED_RATE_PAYINS = "FETCHED_RATE_PAYINS";
export const FETCHED_INPUT_FROM_AMOUNT = "FETCHED_INPUT_FROM_AMOUNT";
export const FETCHED_INPUT_RECEPIENT_AMOUNT = "FETCHED_INPUT_RECEPIENT_AMOUNT";
export const FETCHING_SWITCH_COUNTRY = "FETCHING_SWITCH_COUNTRY";
export const FETCHED_SWITCH_COUNTRY = "FETCHED_SWITCH_COUNTRY";
export const SET_SWITCH_COUNTRY = "SET_SWITCH_COUNTRY";
export const FETCHING_CHECKOUT_METAS = "FETCHING_CHECKOUT_METAS";
export const FETCED_CHECKOUT_METAS = "FETCED_CHECKOUT_METAS";
export const SET_CHECKOUT_METAS = "SET_CHECKOUT_METAS";
export const FETCHING_DASHBOARD_METAS = "FETCHING_DASHBOARD_METAS";
export const SET_DASHBOARD_METAS = "SET_DASHBOARD_METAS";
export const TRANSACTION_LIMIT = "TRANSACTION_LIMIT";
export const SET_TRANSACTION_TYPE = "SET_TRANSACTION_TYPE";
export const FETCHING_TRX_DATA = "FETCHING_TRX_DATA";
