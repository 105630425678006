import React, { useState, useEffect } from "react";
import {
	fetchDefaultRates,
	fetchRatedCountries,
	_inputFromAmount,
	_inputRecepientAmount,
	_switchCountry,
} from "../../store/actions/transaction";
import { useSelector, useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import FormikError from "./formikError";

export default function LandingSendMoney(props) {
	const [sendVal, setSendVal] = useState(0);
	const [receiveVal, setReceiveVal] = useState(0);
	const [fee, setFee] = useState(0);
	const [fromCountry, setFromCountry] = useState();
	const [fromCountryCurency, setFromCountryCurrency] = useState();
	const [toCountry, setToCountry] = useState();
	const [payins, setPayins] = useState([]);
	const [selectedPayin, setSelectedPayin] = useState();
	const [rate, setRate] = useState();
	const [fromCurrency, setFromCurrency] = useState();
	const [toCurrency, setToCurrency] = useState();
	const [fromCountryListFilter, setFromCountryListFilter] = useState([]);
	const [toCountryListFilter, setToCountryListFilter] = useState([]);
	const [fromCountryIso, setFromCountryIso] = useState("");
	const [toCountryIso, setToCountryIso] = useState("");

	const dispatch = useDispatch();

	const {
		ratedCountries,
		defaultRates,
		inputFromAmount,
		inputRecepientAmount,
		fetchedDefault,
		fetchedInputFromAmount,
		fetchedInputRecepientAmount,
		fetchedSwitchCountry,
		switchCountry,
		err,
	} = useSelector((state) => state.transaction);

	const { msg } = useSelector((state) => state.error);

	useEffect(() => {
		const currentLocation = window.location.pathname;
		dispatch(fetchDefaultRates(currentLocation));
		dispatch(fetchRatedCountries());
	}, []);
	useEffect(() => {
		// reset initialied for send
		dispatch({ type: "INITIALIZED", payload: false });
	}, []);

	const profile = JSON.parse(localStorage.getItem("authRes"));

	useEffect(() => {
		if (fetchedDefault) {
			setSendVal(defaultRates.send_amount);
			setReceiveVal(defaultRates.receipent_amount);
			setFee(defaultRates.fee);
			setFromCountry(defaultRates.from_country.id);
			setFromCountryCurrency(defaultRates.from_country.currency);
			setFromCountryIso(defaultRates.from_country.iso2);
			setToCountryIso(defaultRates.to_country.iso2);
			setToCountry(defaultRates.to_country.id);
			setPayins(defaultRates.payins);
			setSelectedPayin(defaultRates?.default_payin?.id);
			setRate(defaultRates.rate);
			setFromCurrency(defaultRates.from_country.currency);
			setToCurrency(defaultRates.to_country.currency);

			localStorage.setItem("FROM_COUNTRY", defaultRates?.from_country?.id);
			localStorage.setItem("TO_COUNTRY", defaultRates?.to_country?.id);
			dispatch({
				type: "SET_RECEIVING_CURRENCY",
				payload: defaultRates?.to_country.currency,
			});
			dispatch({
				type: "SET_SENDING_CURRENCY",
				payload: defaultRates?.from_country.currency,
			});
			dispatch({ type: "SET_RATE", payload: defaultRates?.rate });
			dispatch({ type: "SET_PROMO_RATE", payload: defaultRates?.promorate });
		}
	}, [fetchedDefault]);

	useEffect(() => {
		setFromCountryListFilter(ratedCountries.from_countries);
		setToCountryListFilter(ratedCountries.to_countries);
	}, [ratedCountries]);

	useEffect(() => {
		if (fetchedInputFromAmount) {
			setFee(inputFromAmount?.fee);
			setFromCountry(inputFromAmount?.from_country?.id);
			setFromCountryCurrency(inputFromAmount?.from_country?.currency);
			setToCountry(inputFromAmount?.to_country?.id);
			setReceiveVal(inputFromAmount?.receipent_amount);
			setRate(inputFromAmount?.rate);
			setFromCountryIso(inputFromAmount?.from_country?.iso2);
			setToCountryIso(inputFromAmount?.to_country?.iso2);
			dispatch({ type: "SET_RATE", payload: inputFromAmount?.rate });
			dispatch({ type: "SET_PROMO_RATE", payload: inputFromAmount?.promorate });
		}
	}, [fetchedInputFromAmount]);

	useEffect(() => {
		if (fetchedSwitchCountry) {
			setFee(switchCountry?.fee);
			setRate(switchCountry?.rate);
			setFromCountry(switchCountry?.from_country.id);
			setFromCountryCurrency(switchCountry?.from_country.currency);
			setToCountry(switchCountry?.to_country?.id);
			setReceiveVal(switchCountry?.receipent_amount);
			setSendVal(switchCountry?.send_amount);
			setPayins(switchCountry?.payins);
			setSelectedPayin(switchCountry?.default_payin?.id);
			setFromCountryIso(switchCountry?.from_country?.iso2);
			setToCountryIso(switchCountry?.to_country?.iso2);
			dispatch({ type: "SET_RATE", payload: switchCountry?.rate });
			dispatch({ type: "SET_PROMO_RATE", payload: switchCountry?.promorate });
		}
	}, [fetchedSwitchCountry]);

	useEffect(() => {
		if (fetchedInputRecepientAmount) {
			setFee(inputRecepientAmount?.fee);
			setRate(inputRecepientAmount?.rate);
			setFromCountry(inputRecepientAmount?.from_country?.id);
			setFromCountryCurrency(inputRecepientAmount?.from_country?.currency);
			setToCountry(inputRecepientAmount?.to_country?.id);
			setSendVal(inputRecepientAmount?.send_amount);
			setFromCountryIso(inputRecepientAmount?.from_country?.iso2);
			setToCountryIso(inputRecepientAmount?.to_country?.iso2);
			dispatch({ type: "SET_RATE", payload: inputRecepientAmount?.rate });
			dispatch({
				type: "SET_PROMO_RATE",
				payload: inputRecepientAmount?.promorate,
			});
		}
	}, [fetchedInputRecepientAmount]);

	const debounced = useDebouncedCallback((value, callback) => {
		dispatch(callback);
	}, 2000);

	const inputChangeHandler = (e) => {
		if (e.target.name === "up") {
			setSendVal(e.target.value);
			debounced(
				e.target.value,
				_inputFromAmount({
					send_amount: e.target.value,
					from_country: fromCountry,
					to_country: toCountry,
					payin_id: selectedPayin,
				})
			);
		}

		if (e.target.name === "down") {
			setReceiveVal(e.target.value);
			debounced(
				e.target.value,
				_inputRecepientAmount({
					recipient_amount: e.target.value,
					from_country: fromCountry,
					to_country: toCountry,
					payin_id: selectedPayin,
					// send_amount: sendVal,
				})
			);
		}
	};

	const payinChange = (e) => {
		setSelectedPayin(e.target.value);
		const sel = payins?.find((el) => {
			return el?.id.toString() === e.target.value;
		});

		dispatch(
			_inputFromAmount({
				send_amount: sendVal,
				from_country: fromCountry,
				to_country: toCountry,
				payin_id: e.target.value,
			})
		);
	};

	const countryFromChange = (value) => {
		setFromCountry(value);
		dispatch(
			_switchCountry({
				from_country: value,
				to_country: toCountry,
				send_amount: sendVal,
			})
		);
	};

	const countryToChange = (value) => {
		setToCountry(value);
		dispatch(
			_switchCountry({
				from_country: fromCountry,
				to_country: value,
				send_amount: sendVal,
			})
		);
	};

	const fromSearch = (val) => {
		let countries = [...ratedCountries.from_countries];
		if (!val) {
			setFromCountryListFilter(countries);
			return;
		}
		countries = countries.filter(
			(el) =>
				el.name.toLowerCase().includes(val.toLowerCase()) ||
				el.currency.toLowerCase().includes(val.toLowerCase())
		);
		setFromCountryListFilter(countries);
	};

	const toSearch = (val) => {
		let countries = [...ratedCountries.to_countries];
		if (!val) {
			setToCountryListFilter(countries);
			return;
		}
		countries = countries.filter(
			(el) =>
				el.name.toLowerCase().includes(val.toLowerCase()) ||
				el.currency.toLowerCase().includes(val.toLowerCase())
		);
		setToCountryListFilter(countries);
	};

	return (
		<form action="auth/login.html" className="py-4">
			{/* you send */}
			<div className="mb-4">
				<div className="input-group floating-group mb-2">
					<div className="form-floating">
						<input
							type="text"
							className="form-control rounded-end-0"
							id="youSend"
							placeholder={0}
							value={sendVal}
							name="up"
							onChange={inputChangeHandler}
						/>
						<label htmlFor="youSend">You send</label>
					</div>{" "}
					<div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
						<button
							className="btn form-control border-0 end dropdown-toggle text-start"
							type="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<span
								className={`flag-icon flag-icon-${fromCountryIso?.toLowerCase()} flag-icon-squared`}
							></span>
							&nbsp;
							{fromCountryCurency}
						</button>
						<ul className="dropdown-menu p-3 position-absolute end-0 country-dropdown shadow">
							<li>
								<div className="input-group mb-2">
									<span className="input-group-text text-muted">
										<i className="fas fa-search" />
									</span>
									<input
										type="text"
										placeholder="Search"
										onChange={(e) => {
											fromSearch(e.target.value);
										}}
										className="form-control border-start-0 min-height"
									/>
								</div>
							</li>
							{fromCountryListFilter?.map((el, i) => {
								return (
									<li key={i}>
										<button
											className="dropdown-item py-3 pe-5"
											style={{ cursor: "pointer" }}
											type="button"
											onClick={() => {
												setFromCountryIso(el.iso2);
												setFromCountryCurrency(el.currency);
												countryFromChange(el.id);
												localStorage.setItem("FROM_COUNTRY", el?.id);
												dispatch({
													type: "SET_SENDING_CURRENCY",
													payload: el?.currency,
												});
											}}
										>
											<span
												className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
											></span>
											&nbsp;
											{el.currency}{" "}
											<small className="text-muted">{el.name}</small>
										</button>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<FormikError msg={err ?? ""} />
			</div>

			{/* payment method */}
			<div className="form-floating">
				<select
					name="payin"
					id="paymentMethod"
					className="form-select"
					value={selectedPayin}
					onChange={(e) => {
						payinChange(e);
					}}
				>
					{payins.map((el, i) => {
						return (
							<option value={el.id} key={i} payin={el.name}>
								{el.name}
							</option>
						);
					})}
				</select>
				<label htmlFor="paymentMethod">Payment Method</label>
			</div>
			{/* conversion details */}
			<ul className="list-unstyled hero-card-details my-3">
				{props.page === "user" && (
					<li className="d-flex px-4 justify-content-between py-2">
						<small className="text-muted">Rate</small>
						<span>
							{typeof fromCurrency !== "undefined" && (
								<span>
									1 {fromCountryCurency} = {rate} {toCurrency}
								</span>
							)}
						</span>
					</li>
				)}
				{/* fee */}
				<li className="d-flex px-4 justify-content-between py-2">
					<small className="text-muted">Fee</small>
					<span>
						{fee} {fromCountryCurency}
					</span>
				</li>
			</ul>
			{/* Reciepient Gets */}
			<div className="input-group position-relative mb-3">
				<div className="form-floating">
					<input
						type="text"
						className="form-control rounded-end-0"
						id="reciepientGets"
						placeholder={0}
						value={receiveVal}
						onChange={inputChangeHandler}
						name="down"
					/>
					<label htmlFor="reciepientGets">Reciepient Gets</label>
				</div>
				<div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
					<button
						className="btn form-control border-0 end dropdown-toggle text-start"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<span
							className={`flag-icon flag-icon-${toCountryIso?.toLowerCase()} flag-icon-squared`}
						></span>
						&nbsp;
						{toCurrency}
					</button>
					<ul className="dropdown-menu p-3 position-absolute end-0 country-dropdown shadow">
						<li>
							<div className="input-group mb-2">
								<span className="input-group-text text-muted">
									<i className="fas fa-search" />
								</span>
								<input
									type="text"
									placeholder="Search"
									onChange={(e) => {
										toSearch(e.target.value);
									}}
									className="form-control border-start-0 min-height"
								/>
							</div>
						</li>
						{toCountryListFilter?.map((el, i) => {
							return (
								<li key={i}>
									<button
										className="dropdown-item py-3 pe-5"
										type="button"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setToCountryIso(el?.iso2);
											setToCurrency(el?.currency);
											countryToChange(el?.id);
											localStorage.setItem("TO_COUNTRY", el?.id);
											dispatch({
												type: "SET_RECEIVING_CURRENCY",
												payload: el?.currency,
											});
										}}
									>
										<span
											className={`flag-icon flag-icon-${el?.iso2?.toLowerCase()} flag-icon-squared`}
										></span>
										&nbsp;
										{el.currency}{" "}
										<small className="text-muted">{el?.name}</small>
									</button>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</form>
	);
}
