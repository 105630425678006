import React, { useEffect } from "react";
import Profile from "./profile";
import { useSelector } from "react-redux";
import Transactions from "./transactions";
import Beneficiaries from "./beneficiaries";
import Kyc from "./kyc";
import Notes from "./notes";
import BlackList from "./blackList";
import Compliance from "./compliance";
import Documents from "./documents";
import Settings from "./settings";

export default function Details() {
  const { profile, fetchingProfile } = useSelector((state) => state.senders);
  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user.permissions;
  return (
    <div className="container-fluid">
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div>
          <h5 className="fw-bold">Profile</h5>
          {!fetchingProfile && profile && (
            <p className="text-muted">{`${profile.profile.firstname} ${
              profile.profile.othernames ?? ""
            } ${profile.profile.lastname}`}</p>
          )}
          <p className="">
            Face:{" "}
            <span
              className={`text-${
                profile?.profile?.face_verified_at ? "success" : "red "
              }`}
            >
              {profile?.profile?.face_verified_at
                ? `${
                    profile?.profile?.face_verification_mode === "Auto"
                      ? "Auto"
                      : "Manually"
                  } Verified `
                : "Unverified"}
            </span>
          </p>
          <p className="mb-4">PayId: {profile?.payid}</p>
        </div>
        <div className="">
          Can transact ? {profile ? (profile?.can_transact ? "Yes" : "No") : ""}{" "}
          <p>
            User Risk Score:{" "}
            <span
              className={`${profile?.risk_type.split("_")[0].toLowerCase()}`}
            >
              {profile?.risk_score}
            </span>
          </p>
          <p className="">PayId Account No: {profile?.payid_bank_account_number}</p>

        </div>
        <div>
          Member ID: {profile?.profile?.member_id}{" "}
          <p>
            User Risk Type:{" "}
            <span
              className={`${profile?.risk_type.split("_")[0].toLowerCase()}`}
            >
              {profile?.risk_type.replace("_", " ")}
            </span>
          </p>{" "}
        </div>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <i className="fas fa-wallet fa-3x text-success" />
          </div>
          <div className="flex-grow-1 ms-3">
            <h4 className="fw-bold mb-0">
              {!fetchingProfile && profile && profile.profile.walletbalance}
            </h4>
            <p className="text-muted mb-0">Wallet Balance</p>
          </div>
        </div>
      </div>
      <hr />
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            data-bs-toggle="pill"
            data-bs-target="#profilePill"
            type="button"
          >
            Profile
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#transactionsPill"
            type="button"
          >
            Transactions
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#beneficiaryPill"
            type="button"
          >
            Beneficiaries
          </button>
        </li>
        {permissions["upload kyc"] && (
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#kycPill"
              type="button"
            >
              KYC
            </button>
          </li>
        )}
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#notePill"
            type="button"
          >
            Notes
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#loginPill"
            type="button"
          >
            Login Trail
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#blackListPill"
            type="button"
          >
            Black List
          </button>
        </li>
        {permissions["approve compliance"] && (
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#compliancePill"
              type="button"
            >
              Compliance
            </button>
          </li>
        )}
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#documentsPill"
            type="button"
          >
            Documents
          </button>
        </li>
        {permissions["update user transaction limit"] && (
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#settingsPill"
              type="button"
            >
              Settings
            </button>
          </li>
        )}
      </ul>
      <div className="tab-content py-5">
        <div className="tab-pane fade show active" id="profilePill">
          <Profile />
        </div>
        <div className="tab-pane fade" id="transactionsPill">
          <Transactions uuid={profile?.uuid} />
        </div>
        <div className="tab-pane fade" id="beneficiaryPill">
          <Beneficiaries />
        </div>
        <div className="tab-pane fade" id="notePill">
          <Notes />
        </div>
        <div className="tab-pane fade" id="kycPill">
          <Kyc />
        </div>
        <div className="tab-pane fade" id="blackListPill">
          <div className="table-responsive">
            <BlackList />
          </div>
        </div>
        <div className="tab-pane fade" id="compliancePill">
          <Compliance />
        </div>
        <div className="tab-pane fade" id="documentsPill">
          <Documents />
        </div>
        <div className="tab-pane fade" id="settingsPill">
          <Settings />
        </div>
      </div>
    </div>
  );
}
