import { http } from "../../config/axios";
import * as actionTypes from "../actionTypes/generalActionTypes";

export const fetchCountries = async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_COUNTRIES, payload: true });
		const res = await http.get("/metas/countries");
		localStorage.setItem("cosmo_countries", JSON.stringify(res.data.data));
		dispatch({ type: actionTypes.LOADING_COUNTRIES, payload: false });
		dispatch({ type: actionTypes.SET_COUNTRIES, payload: res.data.data });
	} catch (e) {
		dispatch({ type: actionTypes.LOADING_COUNTRIES, payload: false });
	}
};

export const fetchToCountries = async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_COUNTRIES, payload: true });
		const res = await http.get("/metas/countries/to_countries");
		dispatch({ type: actionTypes.LOADING_COUNTRIES, payload: false });
		dispatch({ type: actionTypes.SET_COUNTRIES, payload: res.data.data });
	} catch (e) {
		dispatch({ type: actionTypes.LOADING_COUNTRIES, payload: false });
	}
};

export const fetchRatedCountries = async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_RATED_COUNTRIES, payload: true });
		const res = await http.get("/rate_calculator/populate_rated_countries");
		localStorage.setItem("cosmo_ratedCountries", JSON.stringify(res.data.data));
		dispatch({ type: actionTypes.LOADING_RATED_COUNTRIES, payload: false });
		dispatch({ type: actionTypes.SET_RATED_COUNTRIES, payload: res.data.data });
	} catch (e) {
		dispatch({ type: actionTypes.LOADING_RATED_COUNTRIES, payload: false });
	}
};

export const fetchStates = async (dispatch) => {
	try {
		// if (JSON.parse(localStorage.getItem("states"))) return;
		dispatch({ type: actionTypes.LOADING_STATES, payload: true });
		const res = await http.get(`metas/states`);
		localStorage.setItem("states", JSON.stringify(res.data.data));
		dispatch({ type: actionTypes.LOADING_STATES, payload: false });
		dispatch({ type: actionTypes.SET_STATES, payload: res.data.data });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_STATES, payload: false });
	}
};

export const fetchIdTypes = async (dispatch) => {
	try {
		// if (JSON.parse(localStorage.getItem("idtypes"))) return;
		dispatch({ type: actionTypes.LOADING_ID_TYPES, payload: true });
		const res = await http.get(`metas/idtypes`);
		localStorage.setItem("idtypes", JSON.stringify(res.data.data));
		dispatch({ type: actionTypes.LOADING_ID_TYPES, payload: false });
		dispatch({ type: actionTypes.SET_ID_TYPES, payload: res.data.data });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_ID_TYPES, payload: false });
	}
};

export const fetchBanks = (country_id) => async (dispatch) => {
	try {
		// if (JSON.parse(localStorage.getItem("banks"))) return;
		dispatch({ type: actionTypes.LOADING_BANKS, payload: true });
		const res = await http.get(`metas/banks/${country_id}`);
		// localStorage.setItem("banks", JSON.stringify(res.data.data));
		dispatch({ type: actionTypes.LOADING_BANKS, payload: false });
		dispatch({ type: actionTypes.SET_BANKS, payload: res.data.data });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_BANKS, payload: true });
	}
};

export const fetchDeactivationReasons = () => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_REASONS, payload: true });
		const res = await http.get(`metas/deactivation_reasons`);
		localStorage.setItem("reasons", JSON.stringify(res.data.data));
		dispatch({ type: actionTypes.LOADING_REASONS, payload: false });
		dispatch({ type: actionTypes.SET_REASONS, payload: res.data.data });
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_REASONS, payload: true });
	}
};

export const fetchPayinClasses = async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_PAYIN_CLASSS, payload: true });
		const res = await http.get(`admin/admin_metas/payin_classes`);
		dispatch({ type: actionTypes.LOADING_PAYIN_CLASSS, payload: false });
		dispatch({
			type: actionTypes.SET_PAYIN_CLASSES,
			payload: res.data.data.values,
		});
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_PAYIN_CLASSS, payload: true });
	}
};

export const fetchPayoutClasses = async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOADING_PAYOUT_CLASSS, payload: true });
		const res = await http.get(`admin/admin_metas/payout_classes`);
		dispatch({ type: actionTypes.LOADING_PAYOUT_CLASSS, payload: false });
		dispatch({
			type: actionTypes.SET_PAYOUT_CLASSES,
			payload: res.data.data.values,
		});
	} catch (err) {
		dispatch({ type: actionTypes.LOADING_PAYOUT_CLASSS, payload: true });
	}
};
