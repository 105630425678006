import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { fetchUploadCounts } from "../../store/actions/admin/uploads";
import { fetchSendersCounts } from "../../store/actions/admin/senders";
import { useSelector, useDispatch } from "react-redux";
import { fetchTransactionCounts } from "../../store/actions/admin/transactions";
import { fetchBusinessCounts } from "../../store/actions/admin/businesses";

export default function DashboardSideNavAdmin() {
  const dispatch = useDispatch();
  const { uploadCounts, changed } = useSelector((state) => state.uploads);
  const { sendersCount, changed: sendersChanged } = useSelector(
    (state) => state.senders
  );
  const { businessCount } = useSelector(
    (state) => state.adminBusiness
  );
  const { transactionsCounts } = useSelector(
    (state) => state.adminTransactions
  );

  useEffect(() => {
    dispatch(fetchUploadCounts);
    dispatch(fetchSendersCounts);
    dispatch(fetchTransactionCounts);
    dispatch(fetchBusinessCounts);
  }, []);
  useEffect(() => {
    if (changed) {
      dispatch(fetchUploadCounts);
    }
  }, [changed]);
  useEffect(() => {
    if (changed) {
      dispatch(fetchSendersCounts);
    }
  }, [sendersChanged]);

  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user?.permissions;

  return (
    <section className="admin-sidebar">
      <div className="sidebar-header">
        <a href="#" className="sidebar-brand">
          <img
            src="../assets/images/logo/logo.png"
            className="sidebar-logo-full"
            width={120}
            alt="logo"
          />
          <img
            src="../assets/images/logo/logo_small.png"
            className="sidebar-logo-half"
            width={50}
            alt="logo"
          />
        </a>
        <button
          className="btn sidebar-toggle-btn me-5 d-lg-none"
          onClick={() => {
            dispatch({ type: "TOGGLE_SIDE_BAR" });
          }}
        >
          <i className="fas fa-chevron-left" />
        </button>
      </div>
      <div className="sidebar-body">
        <ul className="sidebar-nav list-unstyled">
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="dashboard"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <i className="fas fa-tachometer" />
              </span>
              <span className="sidebar-text">Dashboard</span>
            </NavLink>
          </li>
          {/* sidebar item */}
          {permissions["view user"] && (
            <li className="sidebar-item">
              <a
                className="sidebar-link"
                data-bs-toggle="collapse"
                href="#sendersCollapse"
              >
                <span className="sidebar-icon">
                  <i className="fas fa-hand-holding-dollar" />
                </span>
                <span className="sidebar-text">
                  Senders
                  <span className="sidebar-arrow">
                    <i className="fas fa-chevron-down" />
                  </span>
                </span>
              </a>
              {/* sidebar collapse */}
              <div className="collapse" id="sendersCollapse">
                <div className="card card-body rounded sidebar-dropdown p-2">
                  <ul className="list-unstyled">
                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="senders"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Compliant
                          <span className="badge rounded-1 bg-secondary">
                            {sendersCount?.senders_count}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="senders/compliance-issues"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Not compliant
                          <span className="badge rounded-1 bg-secondary">
                            {" "}
                            {sendersCount?.compliance_issues_count}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="senders/banned"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Banned
                          <span className="badge rounded-1 bg-secondary">
                            {" "}
                            {sendersCount?.banned_count}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="senders/deactivated"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Deactivated
                          <span className="badge rounded-1 bg-secondary">
                            {" "}
                            {sendersCount?.deactivated_count}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="senders/requested-deactivation"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Requested deactivation
                          <span className="badge rounded-1 bg-secondary">
                            {" "}
                            {sendersCount?.deactivation_request_count}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          )}
          {/* sidebar item */}
          {permissions["view user"] && (
            <li className="sidebar-item">
              <a
                className="sidebar-link"
                data-bs-toggle="collapse"
                href="#businessCollapse"
              >
                <span className="sidebar-icon">
                  <i className="fas fa-hand-holding-dollar" />
                </span>
                <span className="sidebar-text">
                  Businesses
                  <span className="sidebar-arrow">
                    <i className="fas fa-chevron-down" />
                  </span>
                </span>
              </a>
              {/* sidebar collapse */}
              <div className="collapse" id="businessCollapse">
                <div className="card card-body rounded sidebar-dropdown p-2">
                  <ul className="list-unstyled">
                    {/* sidebar item */}

                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="businesses/verified"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Verified
                          <span className="badge rounded-1 bg-secondary">
                            {" "}
                            {businessCount?.verified ?? 0}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="sidebar-item">
                      <NavLink
                        to="businesses/unverified"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Unverified
                          <span className="badge rounded-1 bg-secondary">
                            {" "}
                            {businessCount?.unverified ?? 0}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          )}
          {/* sidebar item */}
          {permissions["view staff"] && (
            <li className="sidebar-item">
              <NavLink
                to="staffs"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <i className="fas fa-user-tie" />
                </span>
                <span className="sidebar-text">Staffs</span>
              </NavLink>
            </li>
          )}
          <hr />
          {/* sidebar item */}
          {permissions["view rate"] && (
            <li className="sidebar-item">
              <NavLink
                to="rates"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <i className="fas fa-chart-line" />
                </span>
                <span className="sidebar-text">Manage Rates</span>
              </NavLink>
            </li>
          )}
          {/* sidebar item */}
          {
            <li className="sidebar-item">
              <NavLink
                to="limit"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <i className="fas fa-minus" />
                </span>
                <span className="sidebar-text">Manage Limits</span>
              </NavLink>
            </li>
          }
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="payin"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <i class="fas fa-hand-holding-usd"></i>
              </span>
              <span className="sidebar-text">Pay in</span>
            </NavLink>
          </li>
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="payout"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <i class="fas fa-coins"></i>
              </span>
              <span className="sidebar-text">Pay out</span>
            </NavLink>
          </li>
          {/* sidebar item */}
          {
            <li className="sidebar-item">
              <NavLink
                to="wallet-fundings"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <i class="fas fa-wallet"></i>
                </span>
                <span className="sidebar-text">Wallet funding requests</span>
              </NavLink>
            </li>
          }
          {/* sidebar item */}
          {
            <li className="sidebar-item">
              <NavLink
                to="swappings"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <i class="fas fa-exchange-alt"></i>
                </span>
                <span className="sidebar-text">Swappings</span>
              </NavLink>
            </li>
          }
          {/* sidebar item */}
          {permissions["view transactions"] && (
            <li className="sidebar-item">
              <a
                data-bs-toggle="collapse"
                href="#transactionCollapse"
                className="sidebar-link"
              >
                <span className="sidebar-icon">
                  <i className="fas fa-money-bill-1-wave" />
                </span>
                <span className="sidebar-text">
                  Transactions
                  <span className="sidebar-arrow">
                    <i className="fas fa-chevron-down" />
                  </span>
                </span>
              </a>
              {/* sidebar collapse */}
              <div className="collapse" id="transactionCollapse">
                <div className="card card-body rounded sidebar-dropdown p-2">
                  <ul className="list-unstyled">
                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="suspended-transactions"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Suspended{" "}
                          <span className="badge rounded-1 bg-secondary">
                            {transactionsCounts.suspended}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    {/* sidebar item */}
                    {permissions["pending transactions"] && (
                      <li className="sidebar-item">
                        <NavLink
                          to="pending-transactions"
                          className={`sidebar-link ${(isActive) =>
                            isActive ? "active" : ""}`}
                        >
                          <span className="sidebar-text">
                            Pending
                            <span className="badge rounded-1 bg-secondary">
                              {transactionsCounts.pending}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {/* sidebar item */}
                    {permissions["inprogress transactions"] && (
                      <li className="sidebar-item">
                        <NavLink
                          to="inprogress-transactions"
                          className={`sidebar-link ${(isActive) =>
                            isActive ? "active" : ""}`}
                        >
                          <span className="sidebar-text">
                            Inprogress
                            <span className="badge rounded-1 bg-secondary">
                              {transactionsCounts.in_progress}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {/* sidebar item */}
                    {permissions["completed transactions"] && (
                      <li className="sidebar-item">
                        <NavLink
                          to="completed-transactions"
                          className={`sidebar-link ${(isActive) =>
                            isActive ? "active" : ""}`}
                        >
                          <span className="sidebar-text">
                            Completed
                            <span className="badge rounded-1 bg-secondary">
                              {transactionsCounts.complete}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {/* sidebar item */}
                    {permissions["failed transactions"] && (
                      <li className="sidebar-item">
                        <NavLink
                          to="failed-transactions"
                          className={`sidebar-link ${(isActive) =>
                            isActive ? "active" : ""}`}
                        >
                          <span className="sidebar-text">
                            Failed
                            <span className="badge rounded-1 bg-secondary">
                              {transactionsCounts.failed}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {/* sidebar item */}
                    {permissions["incomplete transactions"] && (
                      <li className="sidebar-item">
                        <NavLink
                          to="incomplete-transactions"
                          className={`sidebar-link ${(isActive) =>
                            isActive ? "active" : ""}`}
                        >
                          <span className="sidebar-text">
                            Incomplete
                            <span className="badge rounded-1 bg-secondary">
                              {transactionsCounts.incomplete}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {/* sidebar item */}
                    {permissions["refunded transactions"] && (
                      <li className="sidebar-item">
                        <NavLink
                          to="refunded-transactions"
                          className={`sidebar-link ${(isActive) =>
                            isActive ? "active" : ""}`}
                        >
                          <span className="sidebar-text">
                            Refunded
                            <span className="badge rounded-1 bg-secondary">
                              {transactionsCounts.refunded}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {permissions["failed transactions"] && (
                      <li className="sidebar-item">
                        <NavLink
                          to="failed-payouts"
                          className={`sidebar-link ${(isActive) =>
                            isActive ? "active" : ""}`}
                        >
                          <span className="sidebar-text">
                            Failed payouts
                            <span className="badge rounded-1 bg-secondary">
                              {transactionsCounts.failed_payout}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    )}
                     { (
                      <li className="sidebar-item">
                        <NavLink
                          to="transaction-reports"
                          className={`sidebar-link ${(isActive) =>
                            isActive ? "active" : ""}`}
                        >
                          <span className="sidebar-text">
                            Transaction Reports
                            {/* <span className="badge rounded-1 bg-secondary">
                              {transactionsCounts.failed_payout}
                            </span> */}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {/* sidebar item */}
                    {/* {permissions['failed-transactions'] && <li className="sidebar-item">
                    <NavLink
                      to="refunded-transactions"
                      className={`sidebar-link ${(isActive) =>
                        isActive ? "active" : ""}`}
                    >
                      <span className="sidebar-text">
                        Faild
                        <span className="badge rounded-1 bg-secondary">
                          {transactionsCounts.failed}
                        </span>
                      </span>
                    </NavLink>
                  </li>} */}
                  </ul>
                </div>
              </div>
            </li>
          )}
          <hr />
          {/* sidebar item */}
          {permissions["can_transfer"] && (
            <li className="sidebar-item">
              <NavLink
                to="admin-transfer"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <i class="fas fa-credit-card"></i>
                </span>
                <span className="sidebar-text">Admin Transfer</span>
              </NavLink>
            </li>
          )}
          {/* sidebar item */}
          {/* {
            <li className="sidebar-item">
              <a href="#" className="sidebar-link">
                <span className="sidebar-icon">
                  <i className="fas fa-file-export" />
                </span>
                <span className="sidebar-text">Report Export</span>
              </a>
            </li>
          } */}
          {/* sidebar item */}
          {permissions["user uploads"] && (
            <li className="sidebar-item">
              <a
                data-bs-toggle="collapse"
                href="#uploadCollapse"
                className="sidebar-link"
              >
                <span className="sidebar-icon">
                  <i className="fas fa-upload" />
                </span>
                <span className="sidebar-text">
                  User Upload
                  <span className="sidebar-arrow">
                    <i className="fas fa-chevron-down" />
                  </span>
                </span>
              </a>
              {/* sidebar collapse */}
              <div className="collapse" id="uploadCollapse">
                <div className="card card-body rounded sidebar-dropdown p-2">
                  <ul className="list-unstyled">
                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="pending-uploads"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Pending
                          <span className="badge rounded-1 bg-secondary">
                            {uploadCounts?.pending_count}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="approved-uploads"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Approved
                          <span className="badge rounded-1 bg-secondary">
                            {uploadCounts?.approved_count}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    {/* sidebar item */}
                    <li className="sidebar-item">
                      <NavLink
                        to="rejected-uploads"
                        className={`sidebar-link ${(isActive) =>
                          isActive ? "active" : ""}`}
                      >
                        <span className="sidebar-text">
                          Rejected
                          <span className="badge rounded-1 bg-secondary">
                            {uploadCounts?.rejected_count}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          )}
          <hr />
          {/* sidebar item */}
          {permissions["view roles"] && (
            <li className="sidebar-item">
              <NavLink
                to="access-control"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <i className="fas fa-user-gear" />
                </span>
                <span className="sidebar-text">Access Control</span>
              </NavLink>
            </li>
          )}
          {/* sidebar item */}
          {permissions["settings"] && (
            <li className="sidebar-item">
              <NavLink
                to="settings"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <i className="fas fa-cogs" />
                </span>
                <span className="sidebar-text">Settings</span>
              </NavLink>
            </li>
          )}
          {/* sidebar item */}
          <li className="sidebar-item">
            <a href="#" className="sidebar-link">
              {/* <span className="sidebar-icon">
                <i className="fas fa-sign-out-alt" />
              </span> */}
              {/* <span className="sidebar-text">Logout</span> */}
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}
