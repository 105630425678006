import React, { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/pagination";
import { useSelector, useDispatch } from "react-redux";
import {
	_transactions,
	search,
	filterByDate,
} from "../../../store/actions/transaction";
import { Spin, DatePicker } from "antd";
import { useDebouncedCallback } from "use-debounce";
import moment from "moment";

export default function Transactions() {
	const dispatch = useDispatch();
	const { loading, transactions } = useSelector((state) => state.transaction);
	const [dateStr, setDateStr] = useState();

	useEffect(() => {
		dispatch(_transactions());
	}, []);

	let params;
	const debounced = useDebouncedCallback((value) => {
		if (value.trim().length === 0) {
			params = false;
		} else {
			params = value;
		}

		dispatch(search(params));
	}, 1000);

	const changeHandler = (e) => {
		debounced(e.target.value);
	};
	const [currentPage, setCurrentPage] = useState(1);

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				{/* top search, date picker */}
				<div className="col-xl-9">
					<div className="row justify-content-between">
						<div className="col-xl-6 col-lg-5 mb-3">
							<div className="input-group rounded-0">
								<span className="input-group-text bg-white">
									<i className="fas fa-search" />
								</span>
								<input
									type="text"
									placeholder="Search"
									className="form-control border-start-0"
									onChange={changeHandler}
								/>
							</div>
						</div>
						<div className="col-xl-3 col-lg-4 col-sm-6 mb-3">
							<div className="input-group rounded-0">
								<DatePicker
									onChange={(date, dateString) => {
										if (!dateString) {
											dispatch(filterByDate(""));
											// dispatch(filterByDate("dateStr"))
										}
										setDateStr(dateString);
									}}
									className="form-control "
									name="filter_date"
									// suffixIcon={false}
								/>
								{/* <span className="input-group-text end bg-white">
                  <i className="far fa-calendar-alt" />
                </span> */}
							</div>
						</div>
						<div className="col-xl-3 col-lg-3 col-sm-6 mb-3">
							<button
								className="btn bg-white border px-4"
								type="button"
								onClick={() => {
									dispatch(filterByDate(dateStr));
								}}
							>
								<img
									src="../assets/images/icon/clarity_filter-2-line.png"
									className="ps-4"
									alt
								/>
								{/* &nbsp; */}
								<span className="ps-2 pe-4">Filter</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* table */}
			<div className="table-responsive">
				{loading && <Spin />}
				<table className="table table-borderless">
					<thead>
						<tr>
							<th scope="col">S/N</th>
							<th scope="col">Reference</th>
							<th scope="col">Amount Sent</th>
							<th scope="col">Rate</th>
							<th scope="col">Channel</th>
							<th scope="col">Reciepient</th>
							<th scope="col">Amount Received</th>
							<th scope="col">Status</th>
							<th scope="col">Date Sent</th>
						</tr>
					</thead>
					<tbody className="bg-white border-0 rounded">
						{/* data */}
						{transactions.data?.map((el, i) => {
							let status = "warning";
							if (el.status === "successful") status = "success";
							if (el.status === "failed" || el.status === "cancelled")
								status = "danger";

							let count = i + 1;
							if (currentPage > 1) {
								if (count === 10) {
									count = Number(`${currentPage - 1}${i}`) + 1;
								} else {
									count = `${currentPage - 1}${count}`;
								}
							}
							return (
								<tr className="border-bottom">
									<td className="py-4">{count}</td>
									<td className="py-4">{el.reference}</td>
									{/* amount sent */}
									<td className="py-4">
										<span
											className={`flag-icon flag-icon-${el.from_country.iso2.toLowerCase()} flag-icon-squared`}
										></span>
										&nbsp; {`${el.from_country.currency} ${el.send_amount}`}
									</td>
									{/* rate */}
									<td className="py-4">
										<span
											className={`flag-icon flag-icon-${el.to_country.iso2.toLowerCase()} flag-icon-squared`}
										></span>
										&nbsp; {`${el.to_country.currency} ${el.rate}`}
									</td>
									<td className="text-muted py-4">{el.payin}</td>
									<td className="text-muted py-4">
										<p className="mb-0">{el.meta.beneficiary.fullname}</p>
										<small>
											{el.meta.account.account_name} -{" "}
											{el.meta.account.account_number}
										</small>
									</td>
									{/* rate */}
									<td className="py-4">
										<span
											className={`flag-icon flag-icon-${el.to_country.iso2.toLowerCase()} flag-icon-squared`}
										></span>
										&nbsp; {`${el.to_country.currency} ${el.received_amount}`}
									</td>
									<td className="py-4">
										<span
											className={`px-4 py-2 status-width rounded alert-${status} fw-bold text-capitalize`}
										>
											{el.status}
										</span>
									</td>
									<td className="text-muted py-4">{el.created_at}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				{transactions?.links && (
					<Pagination
						onPageChange={(page) => {
							setCurrentPage(page);
							if (transactions !== null && transactions?.meta?.per_page) {
								dispatch(_transactions(page));
							}
						}}
						data={transactions}
					/>
				)}
			</div>
		</div>
	);
}
