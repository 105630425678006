import * as actionTypes from "../actionTypes/beneficiaryActionTypes";
import * as error from "./errorActions";
import { store } from "../store.js";
import { http } from "../../config/axios";
import * as notifications from "../../components/notifications/notifications";

export const fetchBeneficiaries =
	(paginate = true, page = 1) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.FETCHING_BENEFICIARIES, payload: true });
			dispatch({ type: actionTypes.SAVED_BENEFICIARY, payload: false });
			dispatch({ type: actionTypes.DELETED_BENEFICIARY, payload: false });
			let url = `/beneficiaries/beneficiary`;
			if (paginate) url = `/beneficiaries/beneficiary/paginate/10?page=${page}`;
			// console.log(url);
			const res = await http.get(url);
			dispatch({ type: actionTypes.FETCHING_BENEFICIARIES, payload: false });
			dispatch({
				type: actionTypes.SET_BENEFICIARIES,
				payload: res.data.data.beneficiaries,
			});
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_BENEFICIARIES });
			dispatch(error.setErrorMsg(err));
		}
	};

export const saveBeneficiary = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.SAVING_BENEFICIARY });
		dispatch({ type: actionTypes.SAVED_BENEFICIARY, payload: false });
		const res = await http.post("/beneficiaries/beneficiary", fd);
		dispatch({ type: actionTypes.SAVING_BENEFICIARY });
		dispatch({ type: actionTypes.SAVED_BENEFICIARY, payload: true });
		error.clearError(dispatch);
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		dispatch({ type: actionTypes.SAVING_BENEFICIARY });
		dispatch(error.setErrorMsg(err));
	}
};

export const deleteBeneficiary = (uuid) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.DELETING_BENEFICIARY });
		dispatch({ type: actionTypes.DELETED_BENEFICIARY, payload: false });
		const res = await http.get(`/beneficiaries/delete/${uuid}`);
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.DELETED_BENEFICIARY, payload: true });
		dispatch({ type: actionTypes.DELETING_BENEFICIARY });
	} catch (err) {
		dispatch({ type: actionTypes.DELETING_BENEFICIARY });
		dispatch(error.setErrorMsg(err));
	}
};

export const toggleEditModal = (showFields) => (dispatch) => {
	dispatch({ type: actionTypes.TOGGLE_EDIT_MODAL, payload: showFields });
};

export const setBeneficiaryToEdit = (beneficiary) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_BENEFICIARY_TO_EDIT,
		payload: beneficiary,
	});
};

export const getBeneficiaryToEdit = async (beneficiary_uuid) => {
	try {
		const res = await http.get(`/beneficiaries/show/${beneficiary_uuid}`);
		return res.data.data;
	} catch (err) {
		console.log(err.response.data.message);
	}
};

export const checkBeneficiaryAddressStatus =
	(beneficiary_uuid) => async (dispatch) => {
		try {
			dispatch({ type: actionTypes.CHECKING_BENEFICIARY_UPDATE_STATUS });
			const res = await http.post(
				`/beneficiaries/is_address_updated/${beneficiary_uuid}`
			);

			dispatch({
				type: actionTypes.SET_BENEFICIARY_ADDRESS_STATUS,
				payload: res.data.data.to_update_address,
			});
		} catch (err) {
			console.log(err.response.data.message);
			dispatch(error.setErrorMsg(err));
		} finally {
			dispatch({ type: actionTypes.CHECKING_BENEFICIARY_UPDATE_STATUS });
		}
	};

export const updateBeneficiary = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.UPDATING_BENEFICIARY });
		dispatch({ type: actionTypes.UPDATED_BENEFICIARY, payload: false });
		const res = await http.post(`/beneficiaries/update_beneficiary`, fd);
		dispatch({ type: actionTypes.UPDATED_BENEFICIARY, payload: true });
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch(checkBeneficiaryAddressStatus(fd.beneficiary_uuid));
		dispatch({ type: actionTypes.UPDATING_BENEFICIARY });
		return { success: true };
	} catch (err) {
		dispatch({ type: actionTypes.UPDATING_BENEFICIARY });
		dispatch(error.setErrorMsg(err));
		return { success: false };
	}
};

export const updateAccount = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.UPDATING_ACCOUNT });
		dispatch({ type: actionTypes.UPDATED_ACCOUNT, payload: false });
		const res = await http.post(
			`/beneficiaries/upadte_beneficiary_account`,
			fd
		);
		dispatch({ type: actionTypes.UPDATED_ACCOUNT, payload: true });
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.UPDATING_ACCOUNT });
		return { success: true };
	} catch (err) {
		dispatch({ type: actionTypes.UPDATING_ACCOUNT });
		dispatch(error.setErrorMsg(err));
		return { success: false };
	}
};

export const addAccount = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.ADDING_ACCOUNT, payload: true });
		dispatch({ type: actionTypes.ADDED_ACCOUNT, payload: false });
		const res = await http.post(`/beneficiaries/add_account`, fd);
		dispatch({ type: actionTypes.ADDED_ACCOUNT, payload: true });
		notifications.success({ title: "Success", msg: res.data.message });
		dispatch({ type: actionTypes.ADDING_ACCOUNT, payload: false });
		return { success: true };
	} catch (err) {
		dispatch({ type: actionTypes.ADDING_ACCOUNT, payload: false });
		dispatch(error.setErrorMsg(err));
		return { success: false };
	}
};

export const searchBeneficiary = (params) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.SEARCHING_BENEFICIARY });
		const res = await http.post(`/beneficiaries/search`, { param: params });
		dispatch({
			type: actionTypes.SET_BENEFICIARIES,
			payload: res.data.data.beneficiaries,
		});
		dispatch({ type: actionTypes.SEARCHING_BENEFICIARY });
	} catch (error) {
		dispatch({ type: actionTypes.SEARCHING_BENEFICIARY });
	}
};
