import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchCountries,
	fetchRatedCountries,
} from "../../../store/actions/general";
import SendMoney from "../../partials/sendMoney";

const LandingSendCard = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchCountries);
		dispatch(fetchRatedCountries);
	}, []);

	const {
		defaultRates,
		fetchingDefaultRate,
		sendingCurrency,
		receivingCurrency,
		rate,
		err,
		promorate,
	} = useSelector((state) => state.transaction);

	return (
		<div className="col-xxl-4 col-xl-5 position-relative col-lg-6 my-lg-3 my-5">
			{/* glass rectangle */}
			<img
				draggable="false"
				src="assets/images/components/Rectangle 2886.png"
				className="img-fluid d-none d-sm-block hero-rectangle"
				alt="cosmoremit "
			/>
			{/* hero card */}
			<div className="card border-0 hero-content-card  rounded shadow">
				<div className="card-body">
					{/*  */}
					<div className="d-flex justify-content-between align-items-center">
						<div>
							<h6 className="fw-bold">Rate</h6>

							<span className="badge alert-danger rounded-pill">
								Best rate guarantee
							</span>
						</div>
						<div>
							<h6 className="fw-bold">
								{" "}
								{defaultRates?.rate && (
									<span>
										1 {sendingCurrency} = {!err ? rate : 0} {receivingCurrency}
									</span>
								)}
							</h6>
							{+promorate > +rate && (
								<p style={{ fontSize: "11px" }} className="">
									{" "}
									{defaultRates?.rate && (
										<span>
											New customer rate: {!err ? promorate : 0}{" "}
											{receivingCurrency}
										</span>
									)}
								</p>
							)}
						</div>
					</div>
					{/* F O R M */}
					<SendMoney page="landing" />
				</div>
			</div>
		</div>
	);
};

export default LandingSendCard;
