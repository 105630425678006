import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { CacheBuster } from "react-cache-buster/dist/CacheBuster";
// import { version } from "../package.json";

const version = require("../package.json").version;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true}
    isVerboseMode={false}
    metaFileDirectory={"."}
  >
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
