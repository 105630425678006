import React, { useState, useEffect } from "react";
import LandingSendMoney from "./landingSendMoney";
import DashboardSendMoney from "./dashboardSendComponents/dashboardSendMoney";

export default function SendMoney(props) {
	const [currentLocation, setCurrentLocation] = useState("");
	useEffect(() => {
		const currentLocation = window.location.pathname;
		setCurrentLocation(currentLocation);
	}, []);

	return (
		<>
			{currentLocation === "/" ? (
				<LandingSendMoney props={props} />
			) : (
				<DashboardSendMoney receiver={props.receiver} />
			)}
		</>
	);
}
