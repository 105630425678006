import * as actionTypes from "../../actionTypes/admin/staffActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";

export  const fetchStaffs = (page) => async (dispatch)=> {
    try{
        dispatch({type:actionTypes.FETCHING_STAFFS,payload:true})
        dispatch({type:actionTypes.SAVED_STAFFS,payload:false})
        const res = await http.get(`admin/staff/list/paginate/10?page=${page}`);
        dispatch({type:actionTypes.FETCHING_STAFFS,payload:false})
        dispatch({type:actionTypes.SET_STAFFS,payload:res.data.data.staffs})
    }catch(err){
        dispatch({type:actionTypes.FETCHING_STAFFS,payload:false})
        dispatch(error.setErrorMsg(err));
    }
}

export const createStaff=(fd)=>async(dispatch)=>{
    try{
        const formData = new FormData();
        formData.append("firstname",fd.firstname);
        formData.append("lastname",fd.lastname);
        formData.append("othernames",fd.othernames);
        formData.append("email",fd.email);
        formData.append("address",fd.address);
        formData.append("gender",fd.gender);
        formData.append("nationality",fd.nationality);
        formData.append("date_joined",fd.date_joined);
        formData.append("dob",fd.dob);
        formData.append("qualification",fd.qualification);
        formData.append("role_id",fd.role_id);
        formData.append("password",fd.password);
        formData.append("id_front",fd.id_front);
        formData.append("id_rear",fd.id_rear);
        formData.append("photo",fd.photo);
        formData.append("profile_uuid",fd.profile_uuid);
        dispatch({type:actionTypes.SAVING_STAFFS,payload:true})
        const res = await http.post('admin/staff/store_staff',formData);
        dispatch({type:actionTypes.SAVING_STAFFS,payload:false})
        dispatch({type:actionTypes.SAVED_STAFFS,payload:true})
        error.clearError(dispatch);
        notifications.success({ title: "Success", msg: res.data.message });
    }catch(err){
        dispatch({type:actionTypes.SAVING_STAFFS,payload:false})
        dispatch({type:actionTypes.SAVED_STAFFS,payload:false})
        dispatch(error.setErrorMsg(err));
    }
}

export const updateStaff=(fd)=>async(dispatch)=>{
    try{
        const formData = new FormData();
        formData.append("firstname",fd.firstname);
        formData.append("lastname",fd.lastname);
        formData.append("othernames",fd.othernames);
        formData.append("email",fd.email);
        formData.append("address",fd.address);
        formData.append("gender",fd.gender);
        formData.append("nationality",fd.nationality);
        formData.append("date_joined",fd.date_joined);
        formData.append("dob",fd.dob);
        formData.append("qualification",fd.qualification);
        formData.append("role_id",fd.role_id);
        formData.append("password",fd.password);
        formData.append("id_front",fd.id_front);
        formData.append("id_rear",fd.id_rear);
        formData.append("photo",fd.photo);
        formData.append("profile_uuid",fd.profile_uuid);
        dispatch({type:actionTypes.UPDATING_STAFF,payload:true})
        dispatch({type:actionTypes.UPDATED_STAFF,payload:false})
        const res = await http.post('admin/staff/update_staff',formData);
        dispatch({type:actionTypes.UPDATING_STAFF,payload:false})
        dispatch({type:actionTypes.UPDATED_STAFF,payload:true})
        error.clearError(dispatch);
        notifications.success({ title: "Success", msg: res.data.message });
    }catch(err){
        dispatch({type:actionTypes.UPDATING_STAFF,payload:false})
        dispatch({type:actionTypes.UPDATED_STAFF,payload:false})
        dispatch(error.setErrorMsg(err));
    }
}

export const searchStaffs =(fd)=>async(dispatch)=>{
    try{
        dispatch({type:actionTypes.FETCHING_STAFFS,payload:true})
        const res = await http.post('admin/staff/search',fd);
        dispatch({type:actionTypes.FETCHING_STAFFS,payload:false})
        dispatch({type:actionTypes.SET_STAFFS,payload:res.data.data.staffs})
    }catch(err){
        dispatch({type:actionTypes.FETCHING_STAFFS,payload:false})
        dispatch(error.setErrorMsg(err));
    }
}

