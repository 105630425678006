export const SET_BUSINESS_COUNTS = "SET_BUSINESS_COUNTS";
export const FETCHING_BUSINESSES = "FETCHING_BUSINESSES";
// export const FETCHING_UNVERIFIED_BUSINESSES = "FETCHING_UNVERIFIED_BUSINESSES";
export const SET_VERIFIED_BUSINESS = "SET_VERIFIED_BUSINESS";
export const SET_UNVERIFIED_BUSINESS = "SET_UNVERIFIED_BUSINESS";
export const SET_BUSINESS_DATA = "SET_BUSINESS_DATA";
export const FETCHING_USER_BUSINESSES = "FETCHING_USER_BUSINESSES";
export const VERIFYING_BUSINESSES = "VERIFYING_BUSINESSES";
export const FETCHING_BUSINESS_DOCUMENTS = "FETCHING_BUSINESS_DOCUMENTS";
export const SET_BUSINESS_DOCUMENTS = "SET_BUSINESS_DOCUMENTS";
export const UPLOADING_BUSINESS_DOCUMENTS = "UPLOADING_BUSINESS_DOCUMENTS";
