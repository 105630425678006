import React, { useState, useEffect, useRef } from "react";
import LogoDesign1 from "../../assets/images/components/Vector13.png";
import LogoDesign2 from "../../assets/images/components/Vector12.png";
import Logo from "../../assets/images/logo/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { Spin, Modal } from "antd";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import { login, sendResetPasswordLink } from "../../store/actions/authActions";
import FormikError from "../../components/partials/formikError";
import Alert from "../../components/alerts/alert";
import { clearError } from "../../store/actions/errorActions";

import useIdleLogout from "../../hooks/idleLogout";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, loading, sendingLink, sentLink } = useSelector(
    (state) => state.auth
  );
  const [passwordType, setPasswordType] = useState("password");
  const [showReset, setShowReset] = useState(false);
  const changePasswordType = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const { idleChecker, observer } = useIdleLogout();

  useEffect(() => {
    idleChecker();
    observer();
  }, []);

  useEffect(() => {
    clearError(dispatch);
  }, []);

  useEffect(() => {
    if (sentLink) {
      setShowReset(false);
      formikRef.current.setValues({ email: "" });
      document.getElementById("resetForm")?.reset();
    }
  }, [sentLink]);

  useEffect(() => {
    const authRes = JSON.parse(localStorage.getItem("authRes"));
    if (token) {
      if (authRes?.profile_type === "aprofiles") {
        navigate("/dashboard");
      } else {
        if (!authRes.phone_otp_verified_at || !authRes.email_otp_verified_at) {
          if (!authRes.phone_otp_verified_at) {
            navigate("/verify-phone");
          } else if (!authRes.email_otp_verified_at) {
            navigate("/verify-email");
          }
        } else {
          // if (state?.calledFrom === "send_money_on_landing_page") navigate("/");
          if (
            !authRes.profile.address_details_at ||
            !authRes.profile.personal_details_at ||
            !authRes.profile.kyc_details_at
          ) {
            navigate("/my-profile");
          } else {
            navigate("/dashboard");
          }
        }
      }
    }
  }, [token, navigate]);

  const formikRef = useRef();

  const schema = Yup.object({
    password: Yup.string("Enter Password").required("Enter password"),
    email: Yup.string("Enter your email")
      .required("Enter email")
      .test("Email", "Enter a valid email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        let isValidEmail = emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        return true;
      }),
  });

  const { handleSubmit, handleChange, handleBlur, touched, errors } = useFormik(
    {
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: schema,
      onSubmit: (values) => {
        dispatch(login(values));
      },
    }
  );
  return (
    <>
      <div>
        {/*
         *     N A V B A R     *
         */}
        <nav className="navbar navbar-light fixed-top bg-transparent">
          <div className="container">
            {/* Navbar brand */}
            <Link className="navbar-brand mx-auto" to="/">
              {/* logo img */}
              <img
                src={Logo}
                width={110}
                className="img-fluid my-4"
                alt="cosmoremit logo"
              />
            </Link>
          </div>
        </nav>
        {/*
         *     M A I N     B O D Y     *
         */}
        <main>
          <img
            src={LogoDesign1}
            className="login-design login-design-1"
            alt="logo design"
          />
          <img
            src={LogoDesign2}
            className="login-design login-design-2"
            alt="logo design"
          />
          <section className="login-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-4 col-md-6">
                  <div className="text-center">
                    <h2 className="header-4 fw-bold">Welcome Back!</h2>
                    <p className="text-muted mb-4">
                      New to CosmoRemit?{" "}
                      <Link to="/signup" className="text-red">
                        Sign up here
                      </Link>
                    </p>
                    <Formik></Formik>
                    <form onSubmit={handleSubmit}>
                      <Alert type="danger" />
                      <div className="form-group text-start mb-3">
                        <label className="mb-2">Email</label>
                        <input
                          type="email"
                          className="form-control min-height"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email && (
                          <FormikError msg={errors.email} />
                        )}
                      </div>
                      <div className="form-group text-start mb-3">
                        <label className="mb-2">Password</label>
                        <div className="input-group">
                          <input
                            type={passwordType}
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control border-end-0 min-height"
                          />
                          <button
                            className="btn border border-start-0"
                            type="button"
                            onClick={changePasswordType}
                          >
                            {passwordType === "password" ? (
                              <i className="far fa-eye" />
                            ) : (
                              <i className="far fa-eye-slash text-muted"></i>
                            )}
                          </button>
                        </div>
                        {touched.password && errors.password && (
                          <FormikError msg={errors.password} />
                        )}
                      </div>
                      <button
                        className="btn btn-red w-100 min-height mb-3"
                        disabled={loading}
                      >
                        Login {loading && <Spin />}
                      </button>
                      <Link
                        to="/forgot-password"
                        className="text-muted"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowReset(true);
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Modal
        visible={showReset}
        onCancel={() => {
          setShowReset(false);
        }}
        footer={false}
      >
        <div className="row justify-content-center mt-3">
          <div className="col-lg-11">
            <h6 className="fw-bold">Forgot Password?</h6>
            <p className="text-muted">
              Enter your email address and we'll send a password reset link to
              you.
            </p>
            <Formik
              validationSchema={Yup.object({
                email: Yup.string("Enter your email")
                  .required("Enter email")
                  .test("Email", "Enter a valid email", function (value) {
                    const emailRegex =
                      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                    let isValidEmail = emailRegex.test(value);
                    if (!isValidEmail) {
                      return false;
                    }

                    return true;
                  }),
              })}
              onSubmit={(values) => {
                dispatch(sendResetPasswordLink(values.email));
              }}
              initialValues={{ email: "" }}
              innerRef={formikRef}
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit} id="resetForm">
                    <div className="form-group mb-4">
                      <label className="mb-2">Email Address</label>
                      <input
                        type="text"
                        name="email"
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        className="form-control min-height"
                      />
                      {props.touched.email && props.errors.email && (
                        <FormikError msg={props.errors.email} />
                      )}
                    </div>
                    <button
                      className="btn btn-red min-height w-100"
                      type="submit"
                      disabled={sendingLink}
                    >
                      Send password reset link {sendingLink && <Spin />}
                    </button>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
}
