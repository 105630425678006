export const FETCHING_PAYOUT = "FETCHING_PAYOUT";
export const SAVING_PAYOUT = "SAVING_PAYOUT";
export const SAVED_PAYOUT = "SAVED_PAYOUT";
export const DELETING_PAYOUT = "DELETING_PAYOUT";
export const DELETED_PAYOUT = "DELETED_PAYOUT";
export const UPDATING_PAYOUT = "UPDATING_PAYOUT";
export const UPDATED_PAYOUT = "UPDATED_PAYOUT";
export const SET_PAYOUT = "SET_PAYOUT";
export const SHOW_CREATE_MODAL = "SHOW_CREATE_MODAL";
export const SEARCHING_PAYOUT = "SEARCHING_PAYOUT";
export const TOGGLING_PAYOUT = "TOGGLING_PAYOUT";
export const TOGGLED_PAYOUT = "TOGGLED_PAYOUT";