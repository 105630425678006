import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/website/Home";
import Faqs from "./pages/website/Faqs";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/signup/Index";
import "antd/dist/antd.css";
import "./assets/css/antdcustom.css";
import RequireAuth from "./components/layout/requireAuth";
import AppLayout from "./components/layout/appLayout";
import WebsiteLayout from "./components/layout/websiteLayout";
import UserRoutes from "./routes/userRoutes";
import AdminRoutes from "./routes/adminRoutes";
import Helpcenter from "./pages/website/Helpcenter";
import Preview from "./pages/user/documentUpload/preview";
import { useSelector } from "react-redux";
import ShuftiRes from "./pages/user/profile/shuftiRes";
import ResetPasword from "./pages/auth/reset-password";
import ReverifyEmail from "./pages/auth/ReverifyEmail";
import ReverifyPhone from "./pages/auth/ReverifyPhone";
import PrivacyPolicy from "./pages/website/PrivacyPolicy";
import Terms from "./pages/website/Terms";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useEffect, useRef, useState } from "react";

function App() {
	const [widgetLoad, setWidgetLoad] = useState(false);
	const { profile } = useSelector((state) => state.auth);
	require("./assets/css/style.css");

	const tawkMessengerRef = useRef();
	const location = useLocation();
	const widgetRoutes = ["/", "/faqs", "/help-center"];
	const handleWidgetLoad = (e) => {
		setWidgetLoad(true);
	};
	useEffect(() => {
		if (widgetLoad) {
			if (widgetRoutes.includes(location.pathname)) {
				tawkMessengerRef.current.showWidget();
			} else {
				tawkMessengerRef.current.hideWidget();
			}
		}
	}, [location.pathname, widgetLoad]);

	if (profile === "aprofiles") {
		require("./assets/css/admin-style.css");
	} else if (profile === "profiles") {
		require("./assets/css/dashboard.css");
	}

	return (
		<>
			<Routes>
				<Route
					element={
						<RequireAuth>
							<AppLayout />
						</RequireAuth>
					}
				>
					{profile === "profiles" ? (
						<Route path="/*" element={<UserRoutes />} />
					) : (
						<Route path="/*" element={<AdminRoutes />} />
					)}
					<Route exact path="document-preview" element={<Preview />} />
					<Route exact path="shufti-res" element={<ShuftiRes />} />
				</Route>

				<Route element={<WebsiteLayout></WebsiteLayout>}>
					<Route path="/" element={<Home />}></Route>
					<Route path="/faqs" element={<Faqs />}></Route>
					<Route path="/help-center" element={<Helpcenter />}></Route>
					<Route path="/Privacy-policy" element={<PrivacyPolicy />}></Route>
					<Route path="/Terms" element={<Terms />}></Route>
				</Route>

				<Route path="/signup" element={<SignUp />}></Route>
				<Route path="/login" element={<Login />}></Route>
				<Route path="/verify-email" element={<ReverifyEmail />}></Route>
				<Route path="/verify-phone" element={<ReverifyPhone />}></Route>
				<Route path="/reset-password" element={<ResetPasword />}></Route>
				<Route path="*" element="404" />
			</Routes>
			{/* <TransactionSetting /> */}
			<TawkMessengerReact
				propertyId={process.env.REACT_APP_WIDGET_PROPERTY_ID}
				widgetId={process.env.REACT_APP_WIDGET_WIDGET_ID}
				onLoad={handleWidgetLoad}
				ref={tawkMessengerRef}
			/>
		</>
	);
}

export default App;
