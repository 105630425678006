import * as actionTypes from "../../actionTypes/admin/staffActionTypes"

export const staffReducer =(state={fetchingStaffs:true,
    staffs:{},
    savedStaffs:false,
    savingStaffs:false,
    deleting:false,
    deleted:false,
    updatingStaff:false,
    updatedStaff:false,
},action)=>{
    switch (action.type){
        case actionTypes.FETCHING_STAFFS:
            return {...state,fetchingStaffs:action.payload};
        case actionTypes.UPDATING_STAFF:
            return {...state,updatingStaff:action.payload};
        case actionTypes.UPDATED_STAFF:
            return {...state,updatedStaff:action.payload};
        case actionTypes.SAVING_STAFFS:
            return {...state,savingStaffs:action.payload};
        case actionTypes.SAVED_STAFFS:
            return {...state,savedStaffs:action.payload};
        case actionTypes.DELETING_STAFFS:
            return {...state,deleting:action.payload};
        case actionTypes.DELETED_STAFFS:
            return {...state,deleted:action.payload};
        case actionTypes.SET_STAFFS:
            return{...state,staffs:action.payload}
        default: return state
    }
}