import * as actionTypes from "../actionTypes/walletActionTypes";
export const walletReducer = (
  state = {
    loading: false,
    funding_history: [],
    swapping_history: [],
    currency_wallets: [],
    fetching_currencies_for_wallets: true,
    fetched_currency_wallets: false,
    creating_wallet: false, 
    wallet_created: {
      wallet_currency: "", 
      wallet_currency_name: "",
      created: false,
    },
    user_wallets: [],
    fetching_user_wallets: false,
    fetched_user_wallet: false,
    swapping_currency: false,
    swapSuccessful: false,
    // response_status_data: {}
    wallet_funding: false,
    wallet_funded: false,
    canUseWallet: undefined,
    setResponseData: {},
  },
  action
) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: action.payload };
    case actionTypes.SET_FUNDING_HISTORY:
      return { ...state, funding_history: action.payload };
    case actionTypes.SET_SWAPPING_HISTORY:
      return { ...state, swapping_history: action.payload };
    case actionTypes.SET_CURRENCY_WALLETS:
      return { ...state, currency_wallets: action.payload };
    case actionTypes.FETCHING_CURRENCY_WALLETS:
      return { ...state, fetching_currencies_for_wallets: action.payload };
    case actionTypes.FETCHED_CURRENCY_WALLETS:
      return { ...state, fetched_currency_wallets: action.payload };
    case actionTypes.CREATING_WALLET:
      return { ...state, creating_wallet: action.payload };
    case actionTypes.WALLET_CREATED:
      return { ...state, wallet_created: action.payload };
    case actionTypes.USER_WALLETS:
      return { ...state, user_wallets: action.payload };
    case actionTypes.FETCHING_USER_WALLETS:
      return { ...state, fetching_user_wallets: action.payload };
    case actionTypes.FETCHED_USER_WALLETS:
      return { ...state, fetched_user_wallet: action.payload };
    case actionTypes.SWAPPING_CURRENCY:
      return { ...state, swapping_currency: action.payload };
    case actionTypes.SWAP_SUCCESSFUL:
      return { ...state, swapSuccessful: action.payload };
    case actionTypes.WALLET_FUNDING:
      return { ...state, wallet_funding: action.payload };
    case actionTypes.SET_WALLET_FUNDED:
      return { ...state, wallet_funded: action.payload };
    case actionTypes.SET_CAN_USE_WALLET:
      return { ...state, canUseWallet: action.payload };
    case actionTypes.SET_RESPONSE_DATA:
      return { ...state, setResponseData: action.payload };
    default:
      return state;
  }
};
