import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { blacklistList } from "../../../../store/actions/admin/senders";
import { Spin } from "antd";
import Pagination from "../../../../components/pagination/pagination";
import { useLocation } from "react-router-dom";


export default function BlackList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {fetchingBlackList,blacklists}=useSelector((state) => state.senders)

  useEffect(()=>{
    dispatch(blacklistList(1, location?.state?.uuid))
  },[]);

    return (
   <div className="table-responsive">
    {fetchingBlackList && <Spin/>}
  <table className="table table-borderless">
    <thead>
      <tr>
        <th className="text-muted text-uppercase">#</th>
        <th className="text-muted text-uppercase">reason</th>
        <th className="text-muted text-uppercase">date</th>
        <th className="text-muted text-uppercase">period</th>
      </tr>
    </thead>
    <tbody>
      {blacklists?.data?.map((el,i)=>{
        return  <tr className="border-bottom">
        <td>{i+1}</td>
        <td>{el.reason}</td>
        <td>{el.created_at}</td>
        <td>{el.period}</td>
      </tr>
      })}
     
    </tbody>
  </table>
  {blacklists?.links && (
        <Pagination
          onPageChange={(page) => {
            if (blacklists !== null && blacklists.per_page) {
              dispatch(
                blacklistList(page, location.state.uuid)
              );
            }
          }}
          data={blacklists}
        />
      )}
</div>
  )
}
