export const UPDATING_PASSSORD = "UPDATING_PASSSORD";
export const UPDATING_EMAIL = "UPDATING_EMAIL";
export const UPDATING_PHONE = "UPDATING_PHONE";
export const UPDATING_LANGUAGE = "UPDATING_LANGUAGE";
export const UPDATING_NOTIFICATION = "UPDATING_NOTIFICATION";
export const CONFIRMING_EMAIL_OTP = "CONFIRMING_EMAIL_OTP";
export const CONFIRMING_PHONE_OTP = "CONFIRMING_PHONE_OTP";
export const UPDATED_EMAIL = "UPDATED_EMAIL";
export const UPDATED_PHONE = "UPDATED_PHONE";
export const CONFIRMED_PHONE_OTP = "CONFIRMED_PHONE_OTP";
export const CONFIRMED_EMAIL_OTP = "CONFIRMED_EMAIL_OTP";
export const SET_EMAIL = "SET_EMAIL";
export const SET_PHONE = "SET_PHONE";
export const TOGGLE_DEACTIVATE_MODAL = "TOGGLE_DEACTIVATE_MODAL";
export const DEACTIVATING_ACCOUNT = "DEACTIVATING_ACCOUNT";
export const DEACTIVATED_ACCOUNT = "DEACTIVATED_ACCOUNT";
export const CANCELING = "CANCELING";
export const CANCELED = "CANCELED";
