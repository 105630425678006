import React from "react";
import List from "./list";
import { Link } from "react-router-dom";
import FilterSearch from "../../../components/partials/filterSearch";
import { fetchStaffs, searchStaffs } from "../../../store/actions/admin/staffs";

export default function Staffs() {
  const user = JSON.parse(localStorage.getItem('authRes'));
  const permissions = user.permissions;

  return (
    <div className="container">
      <div className="row justify-content-between mb-4">
        {permissions['create staff'] && <div className="col-sm order-sm-1 text-end mb-3">
          <Link to="create" className="btn text-muted px-3 min-height mb-2">
            <i className="fas fa-plus" /> &nbsp; Add new Staff
          </Link>{" "}
          <br />
          <a href="#" className="btn btn-red px-5 min-height">
            Export Record
          </a>
        </div>}
        <div className="col-sm order-sm-0 mb-3">
          <h5 className="fw-bold">Staffs</h5>
          <p className="text-muted mb-4">
            Here is a table containing a list of all your staffs. <br /> You can
            view and edit using the action button on the right hand side.
          </p>
          <FilterSearch callback={searchStaffs} resetFunction={fetchStaffs} options={['name', 'email', 'gender']} />
        </div>
      </div>
      <List />
    </div>
  );
}
