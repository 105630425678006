import * as actionTypes from "../../actionTypes/admin/limitActionTypes";
import * as error from "../errorActions";
import { http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";

export const fetchLimits =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_LIMIT, payload: true });
      dispatch({ type: actionTypes.SAVED_LIMIT, payload: false });
      const res = await http.get(
        `admin/transaction_limits/list/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.FETCHING_LIMIT, payload: false });
      dispatch({ type: actionTypes.SET_LIMIT, payload: res.data.data.limits });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_LIMIT, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const createLimit = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_LIMIT, payload: true });
    const res = await http.post("admin/transaction_limits/create_limit", fd);
    dispatch({ type: actionTypes.SAVING_LIMIT, payload: false });
    dispatch({ type: actionTypes.SAVED_LIMIT, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SAVING_LIMIT, payload: false });
    dispatch({ type: actionTypes.SAVED_LIMIT, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const updateLimit = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATING_LIMIT, payload: true });
    dispatch({ type: actionTypes.UPDATED_LIMIT, payload: false });
    const res = await http.post("admin/transaction_limits/update_limit", fd);
    dispatch({ type: actionTypes.UPDATING_LIMIT, payload: false });
    dispatch({ type: actionTypes.UPDATED_LIMIT, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.UPDATING_LIMIT, payload: false });
    dispatch({ type: actionTypes.UPDATED_LIMIT, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const toggleCreateLimitModal = () => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_CREATE_MODAL });
};

export const deleteLimit = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETING_LIMIT, payload: true });
    dispatch({ type: actionTypes.DELETED_LIMIT, payload: false });
    const res = await http.get(`admin/transaction_limits/delete/${fd}`);
    dispatch({ type: actionTypes.DELETING_LIMIT, payload: false });
    dispatch({ type: actionTypes.DELETED_LIMIT, payload: true });
    error.clearError(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.DELETING_LIMIT, payload: false });
    dispatch({ type: actionTypes.DELETED_LIMIT, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const searchLimit = (params) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SEARCHING_LIMIT });
    const res = await http.post(`admin/transaction_limits/search?page=${params?.page}`, {
      param: params?.param,
    });
    dispatch({ type: actionTypes.SET_LIMIT, payload: res.data.data.limits });
    dispatch({ type: actionTypes.SEARCHING_LIMIT });
  } catch (error) {
    dispatch({ type: actionTypes.SEARCHING_LIMIT });
  }
};