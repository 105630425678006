export const FETCHING_BENEFICIARIES = "FETCHING_BENEFICIARIES";
export const SET_BENEFICIARIES = "SET_BENEFICIARIES";
export const SAVING_BENEFICIARY = "SAVING_BENEFICIARY";
export const SAVED_BENEFICIARY = "SAVED_BENEFICIARY";
export const DELETING_BENEFICIARY = "DELETING_BENEFICIARY";
export const DELETED_BENEFICIARY = "DELETED_BENEFICIARY";
export const TOGGLE_EDIT_MODAL = "TOGGLE_EDIT_MODAL";
export const SET_BENEFICIARY_TO_EDIT = "SET_BENEFICIARY_TO_EDIT";
export const UPDATING_BENEFICIARY = "UPDATING_BENEFICIARY";
export const UPDATED_BENEFICIARY = "UPDATED_BENEFICIARY";
export const UPDATING_ACCOUNT = "UPDATING_ACCOUNT";
export const UPDATED_ACCOUNT = "UPDATED_ACCOUNT";
export const SEARCHING_BENEFICIARY = "SEARCHING_BENEFICIARY";
export const ADDED_ACCOUNT = "ADDED_ACCOUNT";
export const ADDING_ACCOUNT = "ADDING_ACCOUNT";
export const SET_BENEFICIARY_ADDRESS_STATUS = "SET_BENEFICIARY_ADDRESS_STATUS";
export const CHECKING_BENEFICIARY_UPDATE_STATUS = "CHECKING_BENEFICIARY_UPDATE_STATUS";


