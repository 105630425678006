import * as actionTypes from "../actionTypes/beneficiaryActionTypes";

export const indexReducer = (
  state = { loading: true, beneficiaries: {}, searching: false },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHING_BENEFICIARIES:
      return { ...state, loading: action.payload };
    case actionTypes.SET_BENEFICIARIES:
      return { ...state, beneficiaries: action.payload };
    case actionTypes.SEARCHING_BENEFICIARY:
      return { ...state, searching: !state.searching };
    default:
      return state;
  }
};

export const saveBeneficiaryReducer = (
  state = { loading: false, saved: false },
  action
) => {
  switch (action.type) {
    case actionTypes.SAVING_BENEFICIARY:
      return {
        ...state,
        loading: !state.loading,
      };
    case actionTypes.SAVED_BENEFICIARY:
      return {
        ...state,
        saved: action.payload,
      };
    default:
      return state;
  }
};

export const deleteBeneficiaryReducer = (
  state = { deletingBn: false, deletedBn: false },
  action
) => {
  switch (action.type) {
    case actionTypes.DELETING_BENEFICIARY:
      return {
        ...state,
        deletingBn: !state.deletingBn,
      };
    case actionTypes.DELETED_BENEFICIARY:
      return {
        ...state,
        deletedBn: action.payload,
      };
    default:
      return state;
  }
};

export const editBeneficiaryReducer = (
  state = {
    showModal: false,
    showFields: false,
    beneficiary: {},
    updatingBeneificiary: false,
    updated: false,
    updatingAccount: false,
    updatedAccount: false,
    addingAccount: false,
    addedAccount: false,
    beneficiaryAddressStatus: false,
    checkingAddressStatus: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.TOGGLE_EDIT_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
        showFields: action.payload,
      };
    case actionTypes.ADDED_ACCOUNT:
      return {
        ...state,
        addedAccount: action.payload,
      };
    case actionTypes.ADDING_ACCOUNT:
      return {
        ...state,
        addingAccount: action.payload,
      };
    case actionTypes.UPDATING_BENEFICIARY:
      return {
        ...state,
        updatingBeneificiary: !state.updatingBeneificiary,
      };
    case actionTypes.UPDATING_ACCOUNT:
      return {
        ...state,
        updatingAccount: !state.updatingAccount,
      };
    case actionTypes.UPDATED_BENEFICIARY:
      return {
        ...state,
        updated: action.payload,
      };
    case actionTypes.UPDATED_ACCOUNT:
      return {
        ...state,
        updatedAccount: action.payload,
      };
    case actionTypes.SET_BENEFICIARY_TO_EDIT:
      return {
        ...state,
        beneficiary: action.payload,
      };
    case actionTypes.SET_BENEFICIARY_ADDRESS_STATUS:
      return {
        ...state,
        beneficiaryAddressStatus: action.payload,
      };
    case actionTypes.CHECKING_BENEFICIARY_UPDATE_STATUS:
      return {
        ...state,
        checkingAddressStatus: !state.checkingAddressStatus,
      };
    default:
      return state;
  }
};
