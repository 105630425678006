import { Card } from "antd";
import React from "react";

function BlogCard({ imgUrl, title, description, url }) {
  const { Meta } = Card;
  return (
    <a rel="noreferrer" target="_blank" href={url}>
      <Card
        hoverable
        style={{
          width: 240,
        }}
        cover={<img alt={title} src={imgUrl} />}
      >
        <Meta title={title} description={description} />
      </Card>
    </a>
  );
}

export default BlogCard;
