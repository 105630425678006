import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Spin, DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { savePersonal } from "../../../store/actions/profileActions";
import moment from "moment";
export default function Personal() {
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.personal);
	const { msg } = useSelector((state) => state.error);
	const { profile } = JSON.parse(localStorage.getItem("authRes"));
	const checkoutMetas = JSON.parse(localStorage.getItem("checkoutMetas"));
	const schema = Yup.object({
		firstname: Yup.string("Enter firstname")
			.required("first name is required")
			.typeError("enter firstname"),
		lastname: Yup.string("Enter lastname")
			.required("last name is required")
			.typeError("enter lastname"),
		othernames: Yup.string("Enter other names").nullable(),
		dob: Yup.string("Select date of birth")
			.required("date of birth is required")
			.typeError("enter dob"),
		gender: Yup.string("Select gender")
			.required("gender is required")
			.typeError("select gender"),
		occupation: Yup.string("Enter occupation")
			.required("occupation is required")
			.typeError("enter occupation"),
		occupation_industry: Yup.string("Select occupation industry")
			.required("occupation industry is required")
			.typeError("select occupation industry"),
	});

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > moment().subtract(18, "years");
	};
	const initialValues = {
		firstname: profile.firstname ?? "",
		lastname: profile.lastname ?? "",
		othernames: profile.othernames ?? "",
		dob: profile.dob ?? "",
		gender: profile.gender ?? "",
		occupation: profile.occupation ?? "",
		occupation_industry: profile?.occupation_industry ?? "",
	};

	const getSelectedIndustry = (industryLabel) => {
		const selectedIndustry = checkoutMetas?.occupation_industry?.find(
			(industry) => industry.industry === industryLabel
		);
		return selectedIndustry;
	};
	return (
		<div className="accordion-item border-bottom-0">
			<h2 className="accordion-header" id="flush-headingOne">
				<button
					className="accordion-button collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#flush-collapseOne"
				>
					<div className="d-flex align-items-center">
						<div className="flex-shrink-0">
							<img src="../assets/images/icon/Group 779(1).png" alt />
						</div>
						<div className="flex-grow-1 ms-3">
							<h6 className="fw-bold">1. Personal Details</h6>
							<p className="text-muted">
								Legal names, Date of Birth, Gender, Occupation
							</p>
						</div>
					</div>
				</button>
			</h2>
			<div
				id="flush-collapseOne"
				className="accordion-collapse collapse"
				aria-labelledby="flush-headingOne"
				data-bs-parent="#accordionFlushExample"
			>
				<div className="accordion-body border-bottom pt-1 pb-4">
					<Formik
						onSubmit={(values) => {
							dispatch(
								savePersonal({
									...values,
									occupation_industry_id:
										getSelectedIndustry(values.occupation_industry)?.id ?? "",
								})
							);
						}}
						initialValues={initialValues}
						validationSchema={schema}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit} className="ms-lg-5">
								<div className="row">
									{/* firstname */}
									<div className="col-xl-5 col-md-6">
										<div className="form-group mb-3">
											<label className="mb-2">
												First name <span className="text-red">*</span>
											</label>
											<input
												type="text"
												name="firstname"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												className="form-control min-height"
												defaultValue={props.values.firstname}
											/>
											{props.touched.firstname && props.errors.firstname && (
												<FormikError msg={props.errors.firstname} />
											)}
											<FormikError msg={msg?.firstname ?? ""} />
										</div>
									</div>
									{/* lastname */}
									<div className="col-xl-5 col-md-6">
										<div className="form-group mb-3">
											<label className="mb-2">
												Last name <span className="text-red">*</span>
											</label>
											<input
												type="text"
												name="lastname"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												className="form-control min-height"
												defaultValue={props.values.lastname}
											/>
											{props.touched.lastname && props.errors.lastname && (
												<FormikError msg={props.errors.lastname} />
											)}
											<FormikError msg={msg?.lastname ?? ""} />
										</div>
									</div>
									{/* Other name */}
									<div className="col-xl-5 col-md-6">
										<div className="form-group mb-3">
											<label className="mb-2">Other name</label>
											<input
												type="text"
												name="othernames"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												className="form-control min-height"
												defaultValue={props.values.othernames}
											/>
											{props.touched.othernames && props.errors.othernames && (
												<FormikError msg={props.errors.othernames} />
											)}
											<FormikError msg={msg?.othernames ?? ""} />
										</div>
									</div>
									{/* Date of Birth */}
									<div className="col-xl-5 col-md-6">
										<div className="form-group mb-3">
											<label className="mb-2">
												Date of Birth <span className="text-red">*</span>
											</label>
											<div className="input-group rounded-0">
												<DatePicker
													onChange={(date, dateString) => {
														props.setValues({
															...props.values,
															dob: dateString,
														});
													}}
													onBlur={props.handleBlur}
													className="form-control min-height"
													name="dob"
													disabledDate={disabledDate}
													defaultValue={
														props.values.dob
															? moment(props.values.dob, "YYYY-MM-DD")
															: moment().subtract(18, "year")
													}
												/>
												<span className="input-group-text end bg-white">
													<i className="far fa-calendar-alt" />
												</span>
											</div>
											{props.touched.dob && props.errors.dob && (
												<FormikError msg={props.errors.dob} />
											)}
											<FormikError msg={msg?.dob ?? ""} />
										</div>
									</div>
									{/* Gender */}
									<div className="col-xl-5 col-md-6">
										<div className="form-group mb-3">
											<label className="mb-2">
												Gender <span className="text-red">*</span>
											</label>
											<select
												name="gender"
												className="form-select min-height"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												defaultValue={props.values.gender}
											>
												<option value="">--Select--</option>
												<option value="male">male</option>
												<option value="female">female</option>
											</select>
											{props.touched.gender && props.errors.gender && (
												<FormikError msg={props.errors.gender} />
											)}
											<FormikError msg={msg?.gender ?? ""} />
										</div>
									</div>

									{/* Occupation */}
									<div className="col-xl-5 col-md-6">
										<div className="form-group mb-3">
											<label className="mb-2">
												Occupation <span className="text-red">*</span>
											</label>
											<input
												type="text"
												name="occupation"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												className="form-control min-height"
												defaultValue={props.values.occupation}
											/>
											{props.touched.occupation && props.errors.occupation && (
												<FormikError msg={props.errors.occupation} />
											)}
											<FormikError msg={msg?.occupation ?? ""} />
										</div>
									</div>
									{/* Occupation Industry */}
									<div className="col-xl-5 col-md-6">
										<div className="form-group mb-3">
											<label className="mb-2">
												Occupation Industry <span className="text-red">*</span>
											</label>
											<Select
												name="occupation_industry"
												className="form-control min-height"
												value={props.values.occupation_industry?.toString()}
												onChange={(value) => {
													props.setValues({
														...props.values,
														occupation_industry: value,
													});
												}}
												onBlur={props.handleBlur}
											>
												<Select.Option value={""}>
													--Select an Industry
												</Select.Option>
												{checkoutMetas?.occupation_industry?.map((el) => (
													<Select.Option key={el.industry} value={el.industry}>
														{el.industry}{" "}
													</Select.Option>
												))}
											</Select>

											{props.touched.occupation_industry &&
												props.errors.occupation_industry && (
													<FormikError msg={props.errors.occupation_industry} />
												)}
											<FormikError msg={msg?.occupation_industry ?? ""} />
										</div>
									</div>
									<div className="col-xl-10">
										<div className="form-group text-end mb-3">
											<button
												className="btn btn-light px-5 min-height border"
												type="submit"
												disabled={loading}
											>
												Save {loading && <Spin />}
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
