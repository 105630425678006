import React from "react";

import { useDebouncedCallback } from "use-debounce";
import { useDispatch } from "react-redux";
export default function SearchBar({ callback }) {
  const dispatch = useDispatch();

  let params;
  const debounced = useDebouncedCallback((value) => {
    if (value.trim().length === 0) {
      params = false;
    } else {
      params = value;
    }

    dispatch(callback(params));
  }, 1000);

  const changeHandler = (e) => {
    debounced(e.target.value);
  };
  
  return (
    <div className="col-lg-7">
      <div className="d-flex flex-wrap justify-content-between">
        <div className="input-group w-75 mb-3 rounded-0">
          <span className="input-group-text bg-white">
            <i className="fas fa-search" />
          </span>
          <input
            type="text"
            placeholder="Search by name"
            className="form-control border-start-0"
            onChange={changeHandler}
          />
        </div>
      </div>
    </div>
  );
}
