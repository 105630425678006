import React, { useState, useEffect } from 'react'
import { Spin } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../components/partials/formikError";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { resetPassword } from "../../store/actions/authActions";
import { useNavigate } from 'react-router-dom';

export default function ResetPasword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { msg } = useSelector((state) => state.error);
    const { resettingPassword, resetedPassword } = useSelector((state) => state.auth);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");

    useEffect(() => {
        if (resetedPassword) {
            navigate('/login')
        }
    }, [resetedPassword])

    return (
        <>
            <nav className="navbar navbar-light fixed-top bg-transparent">
                <div className="container">
                    {/* Navbar brand */}
                    <a className="navbar-brand mx-auto" href="/">
                        {/* logo img */}
                        <img src="../assets/images/logo/logo.png" width={110} className="img-fluid my-4" alt="logo" />
                    </a>
                </div>
            </nav>

            <main>
                <img src="../assets/images/components/Vector(13).png" className="login-design login-design-1" alt="logo" />
                <img src="../assets/images/components/Vector(12).png" className="login-design login-design-2" alt="logo" />
                <section className="login-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-5 col-md-6">
                                <div className="card card-body p-4 border-0 shadow rounded">
                                    {/* <h6 className="fw-bold mt-4">ekemeakwa@gmail.com</h6> */}
                                    <p className="text-muted mb-3">Set your new password</p>
                                    <Formik
                                        initialValues={{ password: "", password_confirmation: "", token: searchParams.get('data') }}
                                        validationSchema={Yup.object({
                                            password: Yup.string("Enter Password").required("Enter password"),
                                            password_confirmation: Yup.string("Enter Password").required("Confirm password"),
                                        })}
                                        onSubmit={(values) => { dispatch(resetPassword(values)) }}
                                    >
                                        {props => <form onSubmit={props.handleSubmit} id="resetPForm">
                                            <div className="form-group text-start mb-4">
                                                <label className="mb-2">Password</label>
                                                <div className="input-group">
                                                    <input type={passwordType} name="password" onChange={props.handleChange} onBlur={props.handleBlur} className="form-control border-end-0 min-height" />
                                                    <button className="btn border border-start-0" type="button" onClick={() => {
                                                        if (passwordType === "password") {
                                                            setPasswordType("text")
                                                        } else {
                                                            setPasswordType("password")
                                                        }
                                                    }}>
                                                        {passwordType === "password" ? <i className="far fa-eye text-muted" /> : <i className="far fa-eye-slash text-muted"></i>}
                                                    </button>
                                                </div>
                                                {props.touched.password && props.errors.password && (
                                                    <FormikError msg={props.errors.password} />
                                                )}
                                                <FormikError msg={msg?.password ?? ""} />
                                            </div>
                                            <div className="form-group text-start mb-4">
                                                <label className="mb-2">Confirm Password</label>
                                                <div className="input-group">
                                                    <input type={passwordType2} name="password_confirmation" className="form-control border-end-0 min-height" onChange={props.handleChange} onBlur={props.handleBlur} />
                                                    <button className="btn border border-start-0" type="button" onClick={() => {
                                                        if (passwordType2 === "password") {
                                                            setPasswordType2("text")
                                                        } else {
                                                            setPasswordType2("password")
                                                        }
                                                    }}>
                                                        {passwordType2 === "password" ? <i className="far fa-eye text-muted" /> : <i className="far fa-eye-slash text-muted"></i>}
                                                    </button>
                                                </div>
                                                {props.touched.password_confirmation && props.errors.password_confirmation && (
                                                    <FormikError msg={props.errors.password_confirmation} />
                                                )}
                                                {/* <FormikError msg={msg?.password_confirmation ?? ""} /> */}
                                                {msg?.password?.map((el) => {
                                                    return <FormikError msg={el} />
                                                })}
                                            </div>
                                            <button className="btn btn-red w-100 min-height mb-3" type="submit" disabled={resettingPassword}>Set Password {resettingPassword && <Spin />}</button>
                                        </form>}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>


    )
}
