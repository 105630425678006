import * as actionTypes from "../actionTypes/documentUploadActionTypes";
import * as error from "./errorActions";
import { store } from "../store.js";
import { http } from "../../config/axios";
import * as notifications from "../../components/notifications/notifications";

// start user actions
export const fetchDocuments =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch({ type: actionTypes.LOAD_DOCUMENTS, payload: true });
			const res = await http.get(`/user_upload/1/10?page=${page}`);
			dispatch({
				type: actionTypes.SET_DOCUMENTS,
				payload: res.data.data.data,
			});
			dispatch({ type: actionTypes.LOAD_DOCUMENTS, payload: false });
		} catch (err) {
			dispatch({ type: actionTypes.LOAD_DOCUMENTS, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const searchDocs = (fd) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.LOAD_DOCUMENTS, payload: true });
		const res = await http.post("/user_upload/search", fd);
		dispatch({ type: actionTypes.SET_DOCUMENTS, payload: res.data.data });
		dispatch({ type: actionTypes.LOAD_DOCUMENTS, payload: false });
	} catch (err) {
		dispatch({ type: actionTypes.LOAD_DOCUMENTS, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const toggleCreateModal = (dispatch) => {
	dispatch({ type: actionTypes.TOGGLE_CREATE_MODAL });
};

export const saveDocs = (vals) => async (dispatch) => {
	try {
		dispatch({ type: actionTypes.SUBMITTING });
		dispatch({ type: actionTypes.SUBMITTED, payload: false });
		const fd = new FormData();
		fd.append("title", vals.title);
		fd.append("message", vals.message);
		// vals.file.forEach((el) => {
		//   console.log(el);
		//   fd.append("docs[]", el);
		// });

		for (let i = 0; i < vals.file.length; i++) {
			fd.append("docs[]", vals.file[i]);
		}

		const res = await http.post("/user_upload/upload", fd);
		error.clearError(dispatch);
		dispatch({ type: actionTypes.SUBMITTED, payload: true });
		dispatch({ type: actionTypes.SUBMITTING });
		notifications.success({ title: "Success", msg: res.data.message });
	} catch (err) {
		console.log(err);
		dispatch({ type: actionTypes.SUBMITTING });
		dispatch(error.setErrorMsg(err));
	}
};

// start admin actions
