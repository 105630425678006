import * as actionTypes from "../actionTypes/authActionTypes";
export const signupReducer = (
  state = {
    activeStep: 0,
    steps: [{ done: false }, { done: false }, { done: false }],
    loading: false,
    regRes: null,
    changedEmail: false,
    changedPhone: false,
    resendingOtp: false,
    regFd: null,
    phoneVerified: false,
    emailVerified: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.RESET_STEPS:
      return {
        ...state,
        activeStep: 0,
        steps: [{ done: false }, { done: false }, { done: false }],
      };
    case actionTypes.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case actionTypes.UPDATE_DONE_STEPS:
      return { ...state, steps: [...action.payload] };
    case actionTypes.TOGGLE_RESENDING_OTP:
      return { ...state, resendingOtp: !state.resendingOtp };
    case actionTypes.SWITCH_LOADING:
      return { ...state, loading: !state.loading };
    case actionTypes.SET_REGISTER_RESPONSE:
      return {
        ...state,
        regRes: action.payload.data,
        regFd: action.payload.regFd,
      };
    case actionTypes.CHANGED_EMAIL:
      return {
        ...state,
        changedEmail: action.payload,
      };
    case actionTypes.CHANGED_PHONE:
      return {
        ...state,
        changedPhone: action.payload,
      };
    case "EMAIL_VERIFIED":
      return {
        ...state,
        emailVerified: action.payload,
      };
    case "PHONE_VERIFIED":
      return {
        ...state,
        phoneVerified: action.payload,
      };

    default:
      return state;
  }
};

export const loginReducer = (
  state = {
    token: localStorage.getItem("token") ?? null,
    loading: false,
    profile: JSON.parse(localStorage.getItem("authRes"))?.profile_type,
    sendingLink: false,
    sentLink: false,
    resettingPassword: false,
    resetedPassword: false,
    changingPassword: false,
    user: {}
  },
  action
) => {
  switch (action.type) {
    case "RESETTED_PASSWORD":
      return { ...state, resetedPassword: action.payload };
    case "RESETTING_PASSWORD":
      return { ...state, resettingPassword: action.payload };
    case "SENDING_RESET_LINK":
      return { ...state, sendingLink: action.payload };
    case "SENT_LINK":
      return { ...state, sentLink: action.payload };
    case actionTypes.SWITCH_LOADING:
      return { ...state, loading: !state.loading };
    case actionTypes.AUTH_LOGOUT:
      return { ...state, token: null };
    case actionTypes.AUTH_LOGIN:
      return { ...state, token: action.payload };
    case "SET_PROFILE_TYPE":
      return {
        ...state,
        profile: JSON.parse(localStorage.getItem("authRes"))?.profile_type,
      };
      case "UPDATE_USER":
        return {
          ...state,
          user: JSON.parse(localStorage.getItem("authRes")),
        };
    case "CHANGING_PASSWORD":
      return { ...state, changingPassword: action.payload };
    default:
      return state;
  }
};
