import * as actionTypes from "../../actionTypes/admin/accessControlActionTypes";

export const aclReducer = (
  state = {
    fetchingAcl: true,
    roles: [],
    permissions: [],
    savedRoles: false,
    savingRoles: false,
    deleting: false,
    deleted: false,
    updating: false,
    updated: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.UPDATED_ROLES:
      return { ...state, updated: action.payload };
    case actionTypes.UPDATING_ROLES:
      return { ...state, updating: action.payload };
    case actionTypes.FETCHING_ROLES:
      return { ...state, fetchingAcl: action.payload };
    case actionTypes.SAVING_ROLES:
      return { ...state, savingRoles: action.payload };
    case actionTypes.SAVED_ROLES:
      return { ...state, savedRoles: action.payload };
    case actionTypes.DELETING_ROLES:
      return { ...state, deleting: action.payload };
    case actionTypes.DELETED_ROLES:
      return { ...state, deleted: action.payload };
    case actionTypes.SET_ROLES:
      return { ...state, roles: action.payload };
    case actionTypes.SET_PERMISSIONS:
      return { ...state, permissions: action.payload };
    default:
      return state;
  }
};
