import * as actionTypes from "../actionTypes/walletActionTypes";
import { http } from "../../config/axios";
import * as notifications from "../../components/notifications/notifications";
import * as error from "./errorActions";
import { store } from "../store";
import axios from "axios";
import { FETCHING_TRX_DATA } from "../actionTypes/transactionActionTypes";

export const __fundingHistory =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING", payload: true });
      const res = await http.get(
        `wallets/fundings?paginate=1&per_page=10&page=${page}`
      );
      dispatch({
        type: actionTypes.SET_FUNDING_HISTORY,
        payload: res.data.data,
      });
      dispatch({ type: "LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "LOADING", payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const __swappingHistory =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING", payload: true });
      const res = await http.get(
        `wallets/swappings?paginate=1&per_page=10&page=${page}`
      );
      dispatch({
        type: actionTypes.SET_SWAPPING_HISTORY,
        payload: res.data.data,
      });
      dispatch({ type: "LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "LOADING", payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };
export const search = (param, type) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING", payload: true });
    const res = await http.post(`wallets/search_${type}`, {
      reference: param?.reference,
      start_date: param?.start_date,
      end_date: param?.end_date,
    });
    if (type === "swappings") {
      dispatch({
        type: actionTypes.SET_SWAPPING_HISTORY,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: actionTypes.SET_FUNDING_HISTORY,
        payload: res.data.data,
      });
    }
    dispatch({ type: "LOADING", payload: false });
  } catch (err) {
    dispatch({ type: "LOADING", payload: false });
    dispatch(error.setErrorMsg(err));
  }
};
export const fetchActiveCurrencyWallets = () => async (dispatch) => {
  try {
    error.clearError(dispatch);
    dispatch({ type: FETCHING_TRX_DATA, payload: true });

    dispatch({ type: actionTypes.FETCHING_CURRENCY_WALLETS, payload: true });
    const res = await http.get(`wallets/currency_wallets`);
    dispatch({
      type: actionTypes.SET_CURRENCY_WALLETS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({ type: actionTypes.FETCHING_CURRENCY_WALLETS, payload: false });
    dispatch({ type: FETCHING_TRX_DATA, payload: false });
  }
};

export const createWallet = (fd, closeModal) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATING_WALLET, payload: true });
    const res = await http.post("wallets/create_wallet", {
      country_wallet_id: fd,
    });
    dispatch({ type: actionTypes.CREATING_WALLET, payload: false });
    closeModal();
    dispatch({
      type: actionTypes.WALLET_CREATED,
      payload: { wallet_currency: fd, wallet_currency_name: "", created: true },
    });
    dispatch(fetchActiveCurrencyWallets());
  } catch (err) {
    console.log(err);
    dispatch(error.setErrorMsg(err));
    dispatch({ type: actionTypes.CREATING_WALLET, payload: false });
  }
};

export const geUserWallets = () => async (dispatch) => {
  try {
    error.clearError(dispatch);
    dispatch({ type: FETCHING_TRX_DATA, payload: true });
    dispatch({ type: actionTypes.FETCHING_USER_WALLETS, payload: true });
    const res = await http.get(`wallets`);
    dispatch({ type: actionTypes.FETCHING_USER_WALLETS, payload: false });
    dispatch({ type: FETCHING_TRX_DATA, payload: false });
    dispatch({
      type: actionTypes.USER_WALLETS,
      payload: res?.data?.data,
    });
    dispatch({ type: actionTypes.FETCHED_USER_WALLETS, payload: true });
  } catch (err) {
    dispatch({ type: actionTypes.FETCHING_USER_WALLETS, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const swapCurrency = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SWAPPING_CURRENCY, payload: true });
    const res = await http.post("wallets/currency_swap", fd);
    // localStorage.setItem("trx_initData", JSON.stringify(res.data.data));
    // console.log(res.data);
    dispatch({ type: actionTypes.SET_RESPONSE_DATA, payload: res.data.data });
    dispatch({
      type: actionTypes.SWAP_SUCCESSFUL,
      payload: true,
    });
  } catch (err) {
    console.log(err);
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({ type: actionTypes.SWAPPING_CURRENCY, payload: false });
  }
};

export const __fundWallet = (values, closeModal) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.WALLET_FUNDING, payload: true });
    const res = await http.post(`wallets/fund_wallet`, values);
    localStorage.setItem("trx_initData", JSON.stringify(res.data.data));
    if (
      !res.data.data.for_payin.NavigateURL &&
      res.data.data.for_payin.plugin === "poli"
    ) {
      // if the plugin is polipull and url is null recall poli
      const res2 = await http.post("transactions/recall_poli", {
        ref: res.data.data.transaction_details.reference,
      });
      localStorage.setItem("trx_initData", JSON.stringify(res2.data.data));
    }
    closeModal();
    // dispatch({
    //   type: actionTypes.SET_WALLET_FUNDED,
    //   payload: true,
    // });
    dispatch({ type: actionTypes.WALLET_FUNDING, payload: false });
    dispatch({ type: "INIT_TRX_DATA", payload: res.data.data });
    dispatch({ type: "INITIALIZED", payload: true });
  } catch (err) {
    dispatch({ type: actionTypes.WALLET_FUNDING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const closeWalletCreatedModal = () => (dispatch) => {
  dispatch(geUserWallets());
  dispatch({
    type: actionTypes.WALLET_CREATED,
    payload: {
      wallet_currency: "",
      wallet_currency_name: "",
      created: false,
    },
  });
};

export const closeSwapSuccessModal = () => (dispatch) => {
  dispatch(geUserWallets());
  dispatch({
    type: actionTypes.SWAP_SUCCESSFUL,
    payload: false,
  });
};

export const closeFundSuccessModal = () => (dispatch) => {
  dispatch(geUserWallets());
  dispatch({
    type: actionTypes.SET_WALLET_FUNDED,
    payload: false,
  });
};
