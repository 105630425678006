import * as actionTypes from "../actionTypes/businessActionTypes";

const initialState = {
  loading: false,
  data: {},
};

export const businessRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATING_BUSINESS_ACCOUNT:
      return {
        ...state,
        loading: !state.loading,
      };

    default:
      return state;
  }
};

const initialStateEditState = {
  loading: false,
};

export const editDetailsReducer = (state = initialStateEditState, action) => {
  switch (action.type) {
    case actionTypes.UPDATING_BUSINESS_DETAILS:
      return {
        ...state,
        loading: !state.loading,
      };
    default:
      return state;
  }
};

const initialFetchBusinessState = {
  loading: false,
  businessData: {},
  jurisdictions: [],
  categories: [],
};

export const fetchBusinessDataDetails = (
  state = initialFetchBusinessState,
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHING_BUSINESS_DETAILS:
      return {
        ...state,
        loading: !state.loading,
      };
    case actionTypes.FETCH_DATA_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        businessData: action.payload,
      };
    case actionTypes.SET_BUSINESS_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case actionTypes.SET_BUSINESS_JURISDICTIONS:
      return {
        ...state,
        jurisdictions: action.payload,
      };
    case "RESET_BUSINESS_DATA":
      return {
        ...state,
        businessData: {},
      };

    default:
      return state;
  }
};

const initialDeactivateBusinessState = {
  loading: false,
  accountDeleted: false,
  error: "",
};

export const deactivateBusinessAccount = (
  state = initialDeactivateBusinessState,
  action
) => {
  switch (action.type) {
    case actionTypes.DELETING_BUSINESS_ACCOUNT:
      return {
        ...state,
        loading: !state.loading,
      };

    default:
      return state;
  }
};
