export const FETCHING_WALLET_HISTORY = "FETCHING_WALLET_HISTORY";
export const SET_FUNDING_HISTORY = "SET_FUNDING_HISTORY";
export const SET_SWAPPING_HISTORY = "SET_SWAPPING_HISTORY";
export const SET_CURRENCY_WALLETS = "SET_CURRENCY_WALLETS";
export const FETCHING_CURRENCY_WALLETS = "FETCHING_CURRENCY_WALLETS";
export const FETCHED_CURRENCY_WALLETS = "FETCHED_CURRENCY_WALLETS";
export const CREATING_WALLET = "CREATING_WALLET";
export const WALLET_CREATED = "WALLET_CREATED";
export const USER_WALLETS = "USER_WALLETS";
export const FETCHING_USER_WALLETS = "FETCHING_USER_WALLETS";
export const FETCHED_USER_WALLETS = "FETCHED_USER_WALLETS";
export const SWAPPING_CURRENCY = "SWAPPING_CURRENCY";
export const SWAP_SUCCESSFUL = "SWAP_SUCCESSFUL";
export const WALLET_FUNDING = "WALLET_FUNDING"; 
export const SET_WALLET_FUNDED = "SET_WALLET_FUNDED";
export const SET_CAN_USE_WALLET = "SET_CAN_USE_WALLET";
export const SET_RESPONSE_DATA = "SET_RESPONSE_DATA";
