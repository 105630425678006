import React, { useEffect, useState } from "react";
import { Button, Modal, Select, Spin } from "antd";
import { RiArrowDropDownLine } from "react-icons/ri";
import "../../../../../assets/css/createWallet.css";
import { useDispatch, useSelector } from "react-redux";
import {
	createWallet,
} from "../../../../../store/actions/walletActions";

const CreateWallet = ({ showModal, handleCancel }) => {
	const [optionSelected, setOptionSelected] = useState("");
	const { currency_wallets, creating_wallet, fetching_currencies_for_wallets } =
		useSelector((state) => state.wallet);

  const dispatch = useDispatch();
  const Change = (e) => {
    setOptionSelected(e);
  };

  useEffect(() => {
    setOptionSelected(currency_wallets?.[0]?.id);
  }, [currency_wallets]);
  const handleCreateWallet = () => {
    dispatch(createWallet(optionSelected, handleCancel));
  };

  const getPopupContainer = (node) => {
    return node.parentNode;
  };
  return (
    <Modal
      title="Create Wallet"
      visible={showModal}
      onCancel={handleCancel}
      footer={null} // Hide footer, as we're using a form submit button
      width={450}
    >
      <div className="create_wallet_container">
        <div className="create_modal_title">
          <small>
            You can create a wallet for{" "}
            <span className="text-danger fs-7 Create__span">
              {" "}
              Currencies that CosmoRemit
            </span>{" "}
            supports
          </small>
        </div>

        <div className="form-floating mt-3  mb-3 beneficiary-select-container d-flex ">
          <Select
            value={optionSelected}
            onChange={Change}
            name="wallet_creation"
            id="wallet_creation"
            className="form-select beneficiary-select" 
            placeholder="Select a currency"
            getPopupContainer={getPopupContainer}
          >
            <Select.Option value={""}>Select a currency</Select.Option>
            {currency_wallets?.map((wallet, index) => {
              return (
                <Select.Option key={index} value={wallet?.id}>
                  {wallet?.currency_name}
                </Select.Option>
              );
            })}
          </Select>
          <label htmlFor="beneficiary">Wallet Currency</label>
        </div>

        <div className="BUTTON_btn_wallet">
          <button
            onClick={handleCreateWallet}
            disabled={creating_wallet}
            type="submit"
            className="btn min-height btn-red w-100"
          >
            Create a Wallet
            {creating_wallet && <Spin />}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateWallet;
