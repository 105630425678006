import * as actionTypes from "../actionTypes/documentUploadActionTypes";
export const documentUploadReducer = (
	state = {
		loading: false,
		documents: [],
		showCreateModal: false,
		submitting: false,
		saved: false,
	},
	action
) => {
	switch (action.type) {
		case actionTypes.LOAD_DOCUMENTS:
			return { ...state, loading: action.payload };
		case actionTypes.SET_DOCUMENTS:
			return { ...state, documents: action.payload };
		case actionTypes.SUBMITTED:
			return { ...state, saved: action.payload };
		case actionTypes.SUBMITTING:
			return { ...state, submitting: !state.submitting };
		case actionTypes.TOGGLE_CREATE_MODAL:
			return { ...state, showCreateModal: !state.showCreateModal };
		default:
			return state;
	}
};
