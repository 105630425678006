import { Spin } from "antd";
import React from "react";

function LoadingOverlay() {
  return (
    <div className={`loading-overlay`}>
      <Spin tip="Loading..." size={"large"}  style={{color: "#e91908"}} />
    </div>
  );
}

export default LoadingOverlay;
