import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchApproved,
  deleteUpload,
  download,
} from "../../../store/actions/admin/uploads";
import Pagination from "../../../components/pagination/pagination";
import { Spin, Modal } from "antd";
import { useNavigate,useSearchParams } from "react-router-dom";

export default function Approved() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");

  const { approved, fetching, changed, loading } = useSelector(
    (state) => state.uploads
  );
  useEffect(() => {
    dispatch(fetchApproved(pageParam ?? 1));
    setCurrentPage(pageParam);
  }, [pageParam]);

  const [showDelete, setShowDelete] = useState({ show: false, id: "" });

  const toggleDelete = (id) => {
    setShowDelete({ show: !showDelete.show, id: id });
  };

  useEffect(() => {
    if (changed) {
      setShowDelete({ show: false, id: "" });
      dispatch(fetchApproved());
    }
  }, [changed]);

  const _delete = () => {
    dispatch(deleteUpload(showDelete.id));
  };

  return (
    <div className="container-fluid">
      <h5 className="fw-bold mb-4">Approved Uploads</h5>
      <div className="row justify-content-between mb-4">
        <div className="col-sm order-sm-1 text-end mb-3">
          <button
            className="btn btn-red px-5 min-height"
            onClick={() => {
              dispatch(download("approved"));
            }}
          >
            Export Record
          </button>
        </div>
        <div className="col-sm order-sm-0 mb-3">
          <div className="input-group">
            <span className="input-group-text bg-white">
              <i className="fas fa-search" />
            </span>
            {/* Search text input */}
            <input
              type="text"
              className="form-control border-start-0 min-height"
              placeholder="Search for anything"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        {fetching && <Spin />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Member Id</th>
              <th className="text-muted text-uppercase">Sender</th>
              <th className="text-muted text-uppercase">Message</th>
              <th className="text-muted text-uppercase">Status</th>
              <th className="text-muted text-uppercase">Created At</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {approved?.data?.map((el, i) => {
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom">
                  <td>{count}</td>
                  <td>{el.member_id}</td>
                  <td>{el.sender}</td>
                  <td className="wrap">
                    <strong>Title:</strong>{" "}
                    <span className="text-muted">{el.title}</span> <br />
                    <strong>Body:</strong>
                    <span className="text-muted">{el.message}</span>
                  </td>
                  <td>
                    <i className="fas fa-check-circle text-success" /> &nbsp;
                    <small className="text-success">confirmed</small>
                  </td>
                  <td>Tues 15th Sept, 2022</td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a className="dropdown-item py-2" href="#">
                            Reject
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#view"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/document-preview", {
                                state: { files: el.files },
                              });
                            }}
                          >
                            View
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deleteModal"
                            data-bs-toggle="modal"
                            onClick={() => {
                              toggleDelete(el.id);
                            }}
                          >
                            Trash
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {approved?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (approved !== null && approved?.meta?.per_page) {
              setSearchParams({ page });
              dispatch(fetchApproved(page));
            }
          }}
          data={approved}
        />
      )}

      {/* Delete Modal */}
      <Modal
        visible={showDelete.show}
        onCancel={() => {
          toggleDelete();
        }}
        bodyStyle={{
          padding: "0px !important",
          borderRadius: "20px !important",
          margin: "0px",
        }}
        closable={false}
        footer={false}
      // width="25%"
      >
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              toggleDelete();
            }}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={loading}
            onClick={() => {
              _delete();
            }}
          >
            Delete {loading && <Spin />}
          </button>
        </div>
      </Modal>
    </div>
  );
}
