import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBusinessData } from "../store/actions/businessAction";

export const useFetchBusinessData = () => {
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem("authRes")) ?? {};

	const { businessData, loading } = useSelector(
		(state) => state.fetchBusinessDataStore
	);
	const { fetching_profile } = useSelector((state) => state.personal);
	useEffect(() => {
		if (user?.has_business_account && !fetching_profile) {
			dispatch(fetchBusinessData());
		}
	}, [dispatch, user?.has_business_account, fetching_profile]);
	return { businessData, loading };
};
