import React from "react";
import { connect } from "react-redux";
import { hideError, clearError } from "../../store/actions/errorActions";

class Alert extends React.Component {
	closeAlertHandler = () => {
		this.props.hideError();
	};

	componentWillMount() {
		this.props.clearError();
		this.props.hideError();
	}

	render() {
		let type = this.props.type;
		let msg = this.props.error.msg;
		if (msg) {
			if (typeof msg === "object")
				msg = Object.keys(msg).map((key) => {
					return msg[key].map((message, index) => (
						<p className="p-0 m-0" key={index}>
							{message}
						</p>
					));
				});
		}

		return this.props.error.showError ? (
			<div className={`alert alert-${type} alert-dismissible fade show`}>
				{msg}
				{/* <button
          type="button"
          className="bs-close"
          onClick={this.closeAlertHandler}
        ></button> */}
			</div>
		) : (
			""
		);
	}
}

const mapStateToProps = (state) => {
	return {
		error: state.error,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		hideError: () => dispatch(hideError()),
		clearError: () => clearError(dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
