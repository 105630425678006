import * as actionTypes from "../../actionTypes/admin/payinActionTypes";

export const payinReducer = (
  state = {
    fetching: true,
    payins: {},
    saved: false,
    saving: false,
    deleting: false,
    deleted: false,
    toggling: false,
    toggled: false,
    updating: false,
    updated: false,
    showCreateModal: false,
    searching: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHING_PAYIN:
      return { ...state, fetching: action.payload };
    case actionTypes.SEARCHING_PAYIN:
      return { ...state, searching: !state.searching };
    case actionTypes.SHOW_CREATE_MODAL:
      return { ...state, showCreateModal: !state.showCreateModal };
    case actionTypes.UPDATING_PAYIN:
      return { ...state, updating: action.payload };
    case actionTypes.UPDATED_PAYIN:
      return { ...state, updated: action.payload };
    case actionTypes.SAVING_PAYIN:
      return { ...state, saving: action.payload };
    case actionTypes.SAVED_PAYIN:
      return { ...state, saved: action.payload };
    case actionTypes.DELETING_PAYIN:
      return { ...state, deleting: action.payload };
    case actionTypes.TOGGLING_PAYIN:
      return { ...state, toggling: action.payload };
    case actionTypes.TOGGLED_PAYIN:
      return { ...state, toggled: action.payload };
    case actionTypes.DELETED_PAYIN:
      return { ...state, deleted: action.payload };
    case actionTypes.SET_PAYIN:
      return { ...state, payins: action.payload };
    default:
      return state;
  }
};
