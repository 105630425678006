import React from "react";
import FormikError from "../../../components/partials/formikError";
import { Field } from "formik";
import { useSelector } from "react-redux";
import { Select } from "antd";

export default function AccountForm({
	errors,
	index,
	touched,
	banks,
	values,
	setValues,
	handleHasCode,
	handleHasExtraField,
}) {
	const { msg } = useSelector((state) => state.error);

	const accountErrors =
		(errors.accounts?.length && errors.accounts[index]) || {};
	const accountTouched =
		(touched.accounts?.length && touched.accounts[index]) || {};

	const selectedBank = banks?.find(
		(bank) => bank.id === values.accounts[index].bank_id
	);
	const hasCode = selectedBank && selectedBank?.is_code;
	const hasExtraField = !!selectedBank && selectedBank.require_extra_field;

	const codeName = selectedBank?.code_name;
	const extraFieldName = selectedBank?.extra_field_name;

	handleHasCode(hasCode, codeName);
	handleHasExtraField(hasExtraField, extraFieldName);
	return (
		<div className="row" key={index}>
			<div className="col-lg-6">
				<div className="form-group mb-3">
					<label htmlFor className="mb-2">
						Account name
					</label>
					<Field
						type="text"
						placeholder="Bank Account name"
						className="form-control min-height"
						name={`accounts.[${index}].account_name`}
						value={values.accounts[index].account_name}
						onChange={(e) =>
							setValues({
								...values,
								accounts: values.accounts.map((acc, i) =>
									i === index
										? {
												...acc,
												account_name: isNaN(e.target.value)
													? e.target.value
													: "",
										  }
										: acc
								),
							})
						}
					/>

					{accountErrors.account_name && accountTouched.account_name && (
						<FormikError msg={accountErrors.account_name} />
					)}
					{/* <FormikError msg={`${msg}.account.[${index}].account_name`} /> */}
				</div>
			</div>
			{/* Bank */}
			<div className="col-lg-6">
				<div className="form-group mb-3">
					<label htmlFor className="mb-2">
						Bank
					</label>
					<Select
						name={`accounts.${index}.bank_id`}
						className="form-select min-height"
						showSearch
						filterOption={(input, option) => {
							return option.children
								.toLowerCase()
								.includes(input.toLowerCase());
						}}
						onChange={(value) => {
							const acc = [
								...values.accounts,
								(values.accounts[index] = {
									...values.accounts[index],
									bank_id: value,
								}),
							];
							setValues({ ...values, acc });
						}}
					>
						<Select.Option value="">select</Select.Option>
						{banks?.map((bank) => (
							<Select.Option key={bank.id} value={bank.id}>
								{bank.name}
							</Select.Option>
						))}
					</Select>
					{accountErrors.bank_id && accountTouched.bank_id && (
						<FormikError msg={accountErrors.bank_id} />
					)}
					{/* <FormikError msg={`${msg}.account.[${index}].bank_id`} /> */}
				</div>
			</div>
			{/* Account Number */}
			<div className="col-lg-6">
				<div className="form-group mb-3">
					<label htmlFor className="mb-2">
						Account Number
					</label>
					<Field
						type="text"
						className="form-control min-height"
						name={`accounts.${index}.account_number`}
					/>
					{accountErrors.account_number && accountTouched.account_number && (
						<FormikError msg={accountErrors.account_number} />
					)}
					{/* <FormikError msg={`msg.account.[${index}].account_number`} /> */}
				</div>
			</div>
			<div className="col-lg-6">
				<div className="form-group mb-3">
					<label htmlFor className="mb-2">
						Bank Location
					</label>
					<Field
						type="text"
						className="form-control min-height"
						name={`accounts.${index}.bank_location`}
					/>
					{accountErrors.bank_location && accountTouched.bank_location && (
						<FormikError msg={accountErrors.bank_location} />
					)}
					{/* <FormikError msg={`${msg}?.account.[${index}].bank_location`} /> */}
				</div>
			</div>
			{/* Extra Code */}
			{hasCode && (
				<div className="col-lg-6">
					<div className="form-group mb-3">
						<label htmlFor className="mb-2">
							{selectedBank.code_name}
						</label>
						<Field
							type="text"
							className="form-control min-height"
							name={`accounts.${index}.extra_code`}
						/>
						{accountErrors.extra_code && accountTouched.extra_code && (
							<FormikError msg={accountErrors.extra_code} />
						)}
						{/* <FormikError msg={`${msg}?.account.[${index}].bank_location`} /> */}
					</div>
				</div>
			)}
			{hasExtraField && (
				<div className="col-lg-6">
					<div className="form-group mb-3">
						<label htmlFor className="mb-2">
							{selectedBank.extra_field_name}
						</label>
						<Field
							type="text"
							className="form-control min-height"
							name={`accounts.${index}.extra_field`}
						/>
						{accountErrors.extra_field && accountTouched.extra_field && (
							<FormikError msg={accountErrors.extra_field} />
						)}
						{/* <FormikError msg={`${msg}?.account.[${index}].bank_location`} /> */}
					</div>
				</div>
			)}
			<hr />
		</div>
	);
}
