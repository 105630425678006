import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { IoIosCloudUpload } from "react-icons/io";
import * as Yup from "yup";
import moment from "moment";
import CustomSelect from "../../../components/modal-component/selectComponent";
import { useFetchBusinessData } from "../../../hooks/fetchBusinessData";
import { fetchRatedCountries } from "../../../store/actions/general";
import {
  fetchBusinessCategories,
  fetchBusinessJurisdictions,
  update_BusinessAccount,
} from "../../../store/actions/businessAction";
import useGooglePlacesAutocomplete from "../../../hooks/googleAdress.hooks";

const { Option } = Select;

const EditBusinessBeneficiaryAccount = () => {
  const { businessData, loading: loadingBusinessData } = useFetchBusinessData();
  const [addressFocused, setAddressFocused] = useState(false);

  const { jurisdictions, categories } = useSelector(
    (state) => state.fetchBusinessDataStore
  );

  const { loading } = useSelector((state) => state.edit_business_details);
  useEffect(() => {
    dispatch(fetchBusinessCategories);
    dispatch(fetchBusinessJurisdictions);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const businessUUID = businessData?.uuid;

  const { address } = useGooglePlacesAutocomplete("business_location");

  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  useEffect(() => {
    if (formRef.current && address?.full_address && address?.suburb) {
      formRef.current.setValues({
        ...formRef.current.values,
        business_location: address.full_address,
      });
    } else {
      formRef?.current?.setValues({
        ...formRef.current.values,
        business_location: businessData?.business_location || "",
      });
    }
  }, [address, addressFocused]);
  useEffect(() => {
    if (businessData?.certificate_image?.basename) {
      const imageUrl = `${businessData?.certificate_image.dirname}/${businessData?.certificate_image.basename}`;
      setImagePreviewUrl(imageUrl);
    }
  }, [businessData]);

  const validationSchema = Yup.object().shape({
    business_email: Yup.string("Enter business email")
      .required("Business email is required")
      .test("business_email", "Enter a valid business email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        let isValidEmail = emailRegex.test(value);
        if (!isValidEmail) {
          return false;
        }
        return true;
      }),
    business_name: Yup.string().required("Business Name is required"),
    business_location: Yup.string().required("Business Location is required"),
    business_category: Yup.string().required("Business Category is required"),
    incorporation_date: Yup.date().required(
      "Date of Incorporation is required"
    ),
    contact_number: Yup.string().required("Business Contact is required"),
    certificate_image: Yup.mixed(),
    company_registration_number: Yup.string()
      .required("Company registration number is required")
      .min(3, "Company registration number must be at least 3 characters"),
    company_jurisdiction_code: Yup.string()
      .required("Company jurisdiction code is required")
      .min(2, "Company jurisdiction code must be at least 2 characters"),
  });

  const initialValues = {
    business_email: businessData?.business_email || "",
    business_name: businessData?.business_name || "",
    business_location: businessData?.business_location || "",
    business_category: businessData?.business_category || "",
    incorporation_date: businessData?.incorporation_date
      ? moment(businessData?.corporation_date).format("YYYY-MM-DD")
      : "",
    certificate_image: null,
    company_registration_number: businessData?.registration_number || "",
    company_jurisdiction_code: businessData?.jurisdiction_code || "",
    contact_number: businessData?.contact_number || "",
  };

  const handleSubmit = (values) => {
    const updatedValues = {
      ...values,
      business_location: values.business_location || address?.full_address, // Use full address if available, otherwise use the current value
    };

    if (businessUUID) {
      dispatch(
        update_BusinessAccount(businessUUID, updatedValues, updateSuccess)
      );
    }
  };

  const updateSuccess = () => {
    formRef.current?.resetForm();
    navigate("/business-account");
  };
  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("certificate_image", file);

    if (file) {
      if (file?.type === "application/pdf") {
        const pdfFileUrl = URL.createObjectURL(file);
        setImagePreviewUrl(pdfFileUrl);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handlebackbutton = () => {
    navigate("/business-account");
  };

  return (
    <section className="section_wrap_business_create_acount">
      <div className="create_acount_title">
        <h5 className="fw-bold">Edit Business Details</h5>
      </div>
      {loadingBusinessData && <Spin />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleBlur, touched, errors }) => (
          <Form className="sub__section_data_info">
            <div className="inputs_business_info">
              <label htmlFor="business_name">Business Email</label>&nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                type="text"
                id="business_email"
                name="business_email"
                placeholder="Enter Business Email"
                style={{ maxWidth: "1500px", FontFace: "bold" }}
              />
              {touched.business_email && errors.business_email && (
                <div className="error-message text-danger">
                  {errors.business_email}
                </div>
              )}
            </div>
            <div className="inputs_business_info">
              <label htmlFor="business_name">Business Name</label>&nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                type="text"
                id="business_name"
                name="business_name"
                placeholder="Enter Business Name"
                style={{ maxWidth: "1500px" }}
              />
              <ErrorMessage
                name="business_name"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <div className="inputs_business_info">
              <label htmlFor="business_name">Contact Number</label>&nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                type="text"
                id="contact_number"
                name="contact_number"
                placeholder="Enter Business Contact Number"
                style={{ maxWidth: "1500px", FontFace: "bold" }}
                onBlur={handleBlur}
              />
              <ErrorMessage
                name="contact_number"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <div className="inputs_business_info">
              <label htmlFor="business_location">
                Business Location (Full Address)
              </label>
              &nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                as="input"
                type="text"
                id="business_location"
                name="business_location"
                placeholder="Enter Full Business Address"
                style={{ maxWidth: "1500px" }}
                onFocus={() => {
                  setAddressFocused(true);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  setAddressFocused(false);
                }}
                value={values.business_location}
              />
              {/* <ErrorMessage
								name="business_location"
								component="div"
								style={{ color: "red" }}
							/> */}
              {touched.business_location && errors.business_location && (
                <div className="error-message text-danger">
                  {errors.business_location}
                </div>
              )}
            </div>

            <div className="inputs_business_info inputs_business_info_select">
              <label htmlFor="business_category">Business Category</label>
              &nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                as={Select}
                name="business_category"
                showSearch
                placeholder="Fashion"
                className="form-control min-height"
                onChange={(value) => setFieldValue("business_category", value)}
                value={values.business_category}
              >
                {categories?.map((category) => (
                  <Select.Option
                    title={category.description}
                    key={category.id}
                    value={category.name}
                  >
                    {category.name}
                  </Select.Option>
                ))}
              </Field>
              <ErrorMessage
                name="business_category"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <div className="inputs_business_info">
              <label htmlFor="company_registration_number">
                Company Registration Number
              </label>
              &nbsp;<span style={{ color: "red" }}>*</span>
              <Field
                type="text"
                id="company_registration_number"
                name="company_registration_number"
                placeholder="Enter Company Registration Number"
                style={{ maxWidth: "1500px", fontWeight: "bold" }}
              />
              <ErrorMessage
                name="company_registration_number"
                component="div"
                className="error-message text-danger"
              />
            </div>

            <div className="inputs_business_info">
              <label htmlFor="company_jurisdiction_code">
                Company Jurisdiction Code
              </label>
              &nbsp;<span style={{ color: "red" }}>*</span>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="form-control min-height"
                name="company_jurisdiction_code"
                placeholder="Select Jurisdiction Code"
                onChange={(value) =>
                  setFieldValue("company_jurisdiction_code", value)
                }
                value={values.company_jurisdiction_code}
                defaultValue={values.company_jurisdiction_code}
                onBlur={handleBlur}
              >
                {jurisdictions?.map((el) => (
                  <Select.Option key={el.code} value={el.code} label={el.name}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
              <ErrorMessage
                name="company_jurisdiction_code"
                component="div"
                className="error-message text-danger"
              />
            </div>
            <div className="inputs_business_info">
              <label htmlFor="incorporation_date">Date of Incorporation</label>
              &nbsp;
              <span style={{ color: "red" }}>*</span>
              <Field
                type="date"
                id="incorporation_date"
                name="incorporation_date"
                placeholder="Enter Date of Incorporation"
                style={{ maxWidth: "1500px" }}
              />
              <ErrorMessage
                name="incorporation_date"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <div className="business_account_upload_file">
              <label htmlFor="certificate_image">ASIC extract</label>
              &nbsp;
              <span style={{ color: "red" }}>*</span>
              <div className="business_upload_file_wrapper">
                <div className="business_files_drag_drop">
                  <IoIosCloudUpload className="cloud_icon" />
                  <div className="button_span">
                    <button
                      className="file_selector"
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("fileInput").click();
                      }}
                    >
                      Click to Upload
                    </button>
                    <span> or drag and drop</span>
                  </div>
                  <input
                    id="fileInput"
                    name="certificate_image"
                    type="file"
                    className=""
                    accept=".png,.jpg,.jpeg,.pdf"
                    onChange={(event) =>
                      handleImageChange(event, setFieldValue)
                    }
                  />
                </div>
                <div className="business_files_drag_drop">
                  <div className="drop_here">
                    JPG, JPEG, PNG or PDF (max 8mb)
                  </div>
                </div>
              </div>
              {!values?.certificate_image &&
              businessData?.certificate_image?.extension === "pdf" ? (
                <a
                  href={`${businessData?.certificate_image.dirname}/${businessData?.certificate_image.basename}`}
                  className="btn btn-red me-2"
                  target="_blank"
                >
                  Click to view document
                </a>
              ) : (
                <div className="business_account_numbers_of_files_uploaded">
                  {imagePreviewUrl &&
                  values?.certificate_image?.type === "application/pdf" ? (
                    <a
                      href={`${imagePreviewUrl}`}
                      className="btn btn-red me-2"
                      target="_blank"
                    >
                      Click to view document
                    </a>
                  ) : (
                    <div className="uploaded_image_preview">
                      <img src={imagePreviewUrl} alt="Certificate Preview" />
                    </div>
                  )}
                  {values.certificate_image && (
                    <div className="uploaded_file_name">
                      {values.certificate_image.name}
                    </div>
                  )}
                </div>
              )}
              <ErrorMessage
                name="certificate_image"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <div className="Business_create_account_back ">
              <button
                className="back_button min-height px-5"
                type="button"
                disabled={loading}
                onClick={handlebackbutton}
              >
                Go Back
              </button>
              <button
                type="submit"
                className="btn btn-red float-end min-height px-5"
                disabled={loading}
              >
                <span className="px-2">
                  {" "}
                  Update Account
                  {loading && <Spin />}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditBusinessBeneficiaryAccount;
