import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Pagination from "../../../components/pagination/pagination";
import { Spin, Modal } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchBusinessDocuments } from "../../../store/actions/admin/businesses";
import LoadingOverlay from "../../../components/common/Loader";
import UploadBusinessDocumentModal from "./uploadBusinessDocumentModal";

export default function BusinessDocuments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showUpload, setShowUpload] = useState({ show: false, name: "" });

  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const businessID = searchParams.get("id");

  const { fetchingDocuments, businessDocuments } = useSelector(
    (state) => state.adminBusiness
  );
  useEffect(() => {
    dispatch(fetchBusinessDocuments(pageParam ?? 1, businessID));
    setCurrentPage(pageParam);
  }, [pageParam]);

  const toggleUploadDocument = () => {
    setShowUpload({
      show: !showUpload.show,
      name: businessDocuments?.data?.[0]?.business_details?.business_name,
    });
  };

  //   const _delete = () => {
  //     dispatch(deleteUpload(showDelete.id));
  //   };

  return (
    <div className="container-fluid">
      <h5 className="fw-bold mb-1">
        {businessDocuments?.data?.[0]?.business_details?.business_name}
        uploaded documents{" "}
      </h5>
      <h6 className=" mb-4">
        Reference:{" "}
        {businessDocuments?.data?.[0]?.business_details?.business_reference_id}
      </h6>
      <div className="row justify-content-between mb-4">
        <div className="col-sm order-sm-1 text-end mb-3">
          <button
            className="btn btn-red px-5 min-height"
            onClick={toggleUploadDocument}
          >
            Upload Document
          </button>
        </div>
        <div className="col-sm order-sm-0 mb-3">
          <div className="input-group">
            <span className="input-group-text bg-white">
              <i className="fas fa-search" />
            </span>
            {/* Search text input */}
            <input
              type="text"
              className="form-control border-start-0 min-height"
              placeholder="Search for anything"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        {fetchingDocuments && <LoadingOverlay />}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Title</th>
              <th className="text-muted text-uppercase">Body</th>
              <th className="text-muted text-uppercase">Comment</th>
              <th className="text-muted text-uppercase">Created At</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {businessDocuments?.data?.map((el, i) => {
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom">
                  <td>{count}</td>
                  <td>{el.document_title}</td>
                  <td className="wrap">{el.description}</td>

                  <td>{el.comment ?? "-----"}</td>
                  <td>{el.created_at}</td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#view"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/document-preview", {
                                state: { files: el.uploaded_files },
                              });
                            }}
                          >
                            View
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {businessDocuments?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (
              businessDocuments !== null &&
              businessDocuments?.meta?.per_page
            ) {
              setSearchParams({ page });
              dispatch(fetchBusinessDocuments(page, businessID));
            }
          }}
          data={businessDocuments}
        />
      )}

      {/* Upload Document Modal */}
      <UploadBusinessDocumentModal
        visible={showUpload.show}
        name={showUpload.name}
        onClose={toggleUploadDocument}
        id={businessID}
      />
    </div>
  );
}
