import React, { useState, useEffect } from "react";
import Group3 from "../../../assets/images/icon/Group(3).png";
import Group4 from "../../../assets/images/icon/Group(4).png";
import Group5 from "../../../assets/images/icon/Group(5).png";
import List from "./list";
import { Modal, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDocuments,
  toggleCreateModal,
  saveDocs,
} from "../../../store/actions/documentUploadActions";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";

export default function DocumentUpload() {
  const { showCreateModal, saved, submitting } = useSelector(
    (state) => state.documentUpload
  );
  const { msg } = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const toggleTheCreatemodal = () => {
    if (showCreateModal) document.getElementById("form").reset();
    dispatch(toggleCreateModal);
  };

  const [levelModal, setLevelModal] = useState({ show: false, level: "" });

  const toggleLevelModal = (level) => {
    setLevelModal({ show: !levelModal.show, level: level });
  };

  const [fileError, setFileError] = useState(false);

  const { profileCompleted } = useSelector((state) => state.personal);

  useEffect(() => {
    if (saved) {
      dispatch(fetchDocuments());
      toggleTheCreatemodal();
      document.getElementById("form")?.reset();
    }
  }, [saved]);

  const goldInfo = "Gold info";
  const bronzeInfo = "Bronze info";
  const silverInfo = "Silver info";

  return (
    <section className="py-4">
      <div className="container">
        {/* <p className="text-red">Current Level</p> */}
        {/* <div className="row mb-5">
          <div className="col-lg-4">
            <div className="card rounded border-0 overflow-hidden upload-level upload-level-one">
              <div
                className="card-body p-5"
                onClick={() => {
                  toggleLevelModal("bronze");
                }}
              >
                <h5 className="card-title mb-4">
                  Bronze Level Member{" "}
                  <img src={Group3} width={10} alt="Bronze"  />
                </h5>
                 <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p>
                <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p>
                <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p> 
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card rounded border-0 overflow-hidden upload-level upload-level-two">
              <div
                className="card-body p-5"
                onClick={() => {
                  toggleLevelModal("silver");
                }}
              >
                <h5 className="card-title mb-4">
                  Silver Level Member{" "}
                  <img src={Group4} width={10} alt="silver" />
                </h5>
                <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p>
                <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p>
                <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p> 
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card rounded border-0 overflow-hidden upload-level upload-level-three">
              <div
                className="card-body p-5"
                onClick={() => {
                  toggleLevelModal("gold");
                }}
              >
                <h5 className="card-title mb-4">
                  Gold Level Member <img src={Group5} width={10} alt="Gold" />
                </h5>
                <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p>
                <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p>
                <p className="mb-1">
                  Vestibulum ac diam sit amet quam vehicula ele
                </p> 
              </div>
            </div>
          </div>
        </div> */}

        <div className="text-end">
          <button
            className="btn btn-red px-4"
            onClick={toggleTheCreatemodal}
            disabled={!profileCompleted}
          >
            <i className="fas fa-upload" />
            &nbsp; Upload Document(s)
          </button>
        </div>
        {!profileCompleted && (
          <div className="alert alert-danger text-dark rounded mt-5">
            <i className="fas fa-exclamation-circle text-danger" />
            &nbsp; Your Personal Details, Address Information and KYC are all
            required before you can make transactions.
          </div>
        )}
        <List />
      </div>
      <Modal
        onCancel={() => {
          toggleLevelModal();
        }}
        footer={false}
        title={<h5 className="text-capitalize">{levelModal.level} level</h5>}
        visible={levelModal.show}
      >
        {levelModal.level === "gold" && goldInfo}
        {levelModal.level === "bronze" && bronzeInfo}
        {levelModal.level === "silver" && silverInfo}
      </Modal>

      <Modal
        onCancel={toggleTheCreatemodal}
        visible={showCreateModal}
        title={<h5 className="fw-bold mb-3">Upload Document(s)</h5>}
        footer={false}
      >
        <Formik
          onSubmit={(values) => {
            dispatch(saveDocs(values));
          }}
          initialValues={{
            title: "",
            file: [],
            message: "",
          }}
          validationSchema={Yup.object({
            title: Yup.string("").required("enter document title"),
            message: Yup.string("").required("enter message"),
            file: Yup.array().min(1, "minimum of 1 file is require"),
          })}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} id="form">
              {/* document type name */}
              <div className="form-group mb-3">
                <label htmlFor className="mb-2">
                  Document title eg. Payslip
                </label>
                <input
                  name="title"
                  className="form-control min-height"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                {props.touched.title && props.errors.title && (
                  <FormikError msg={props.errors.title} />
                )}
                <FormikError msg={msg?.title ?? ""} />
              </div>
              {/* Upload Documents */}
              <div className="form-group mb-3">
                <label htmlFor className="mb-2">
                  Upload Document(s)
                </label>
                <div className="input-file-upload">
                  <div className="file-component">
                    <span className="d-block text-center w-100">
                      <i
                        className="fas fa-cloud-upload-alt"
                        style={{ fontSize: 20 }}
                      />
                      <p>
                        <span className="text-primary">Click to upload</span> or
                        drag and drop
                      </p>
                      <span className="fw-lighter">
                        PDF, JPG, PNG (max 2mb)
                      </span>
                    </span>
                    <input
                      type="file"
                      className="form-control"
                      name="file"
                      accept=".png,.jpg,.pdf,.jpeg"
                      multiple
                      onChange={(e) => {
                        let err = 0;
                        setFileError(false);
                        for (let i = 0; i < e.target.files.length; i++) {
                          if (e.target.files[i].size > 2000000) {
                            err = 1;
                            setFileError(true);
                            return;
                          }
                        }

                        if (!err) {
                          for (let i = 0; i < e.target.files.length; i++) {
                            props.setValues({
                              ...props.values,
                              file: [...props.values.file, e.target.files[0]],
                            });
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                {props.values.file.length > 0 &&
                  `${props.values.file.length} file(s) selected`}
                {fileError && (
                  <FormikError msg="all files must be less than 2MB" />
                )}
                {props.touched.file && props.errors.file && (
                  <FormikError msg={props.errors.file} />
                )}
              </div>
              {/* Message */}
              <div className="form-group mb-4">
                <label htmlFor className="mb-2">
                  Message
                </label>
                <textarea
                  className="form-control min-height"
                  name="message"
                  rows={5}
                  defaultValue={""}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                {props.touched.message && props.errors.message && (
                  <FormikError msg={props.errors.message} />
                )}
                <FormikError msg={msg?.message ?? ""} />
              </div>
              <div className="form-group mb-3">
                <button
                  type="submit"
                  className="min-height btn btn-red w-100"
                  disabled={submitting}
                >
                  Upload {submitting && <Spin />}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </section>
  );
}
