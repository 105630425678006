import React from 'react'
import { Outlet } from "react-router-dom";
import TopNav from "../nav/topNav"
import MobileSideNav from "../nav/mobileSideNav"

export default function WebsiteLayout() {
  React.useEffect(() => {
    const navbar = document.querySelector('.navbar')
    window.onscroll = (() => {
      if (document.documentElement.scrollTop > 400) {
        navbar.classList.add('bg-white')
        navbar.classList.remove('bg-transparent')
      } else {
        navbar.classList.add('bg-transparent')
        navbar.classList.remove('bg-white')
      }
    })
  }, [])
  return (
    <>
      <TopNav />
      <MobileSideNav />
      <Outlet />
    </>
  )
}
