import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileInfo from "../profile/profileInfo";
import { Modal, Spin } from "antd";
import ModalConponent from "../../../components/modal-component/modalConponent";
import { deletingBusinessAccount } from "../../../store/actions/businessAction";
import { useFetchBusinessData } from "../../../hooks/fetchBusinessData";
import { FaExclamation } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa";
import { verifyBusiness } from "../../../store/actions/businessAction";

const BusinessDashboardProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [
    showToggleVerificationResponseProceed,
    setShowToggleVerificationResponseProceed,
  ] = useState(false);
  const [cantEdit, setCantEdit] = useState(false);
  const [imageProfileUrl, setImageProfileUrl] = useState(null);
  const [profileData, setProfileData] = useState({
    certificate_image: imageProfileUrl,
  });
  const { businessData, loading: loadingBusinessData } = useFetchBusinessData();
  const { loading } = useSelector((state) => state.deactivatingBusinessAccount);
  const user = JSON.parse(localStorage.getItem("authRes")) ?? {};

  useEffect(() => {
    if (!user?.has_business_account) {
      navigate("/create-business-account");
    }
  }, [navigate, user?.has_business_account]);

  useEffect(() => {
    if (
      businessData?.certificate_image?.dirname !== undefined ||
      businessData?.certificate_image?.basename !== undefined
    ) {
      const imageUrl = `${businessData?.certificate_image.dirname}/${businessData?.certificate_image.basename}`;
      setImageProfileUrl(imageUrl);
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        certificate_image: imageUrl,
      }));
    }
  }, [businessData]);

  const openModalVerificationProceedMessge = () => {
    setShowToggleVerificationResponseProceed(true);
  };

  const closeModalVerificationProceedMessge = () => {
    setShowToggleVerificationResponseProceed(false);
  };

  const handleDeleteAccount = async () => {
    dispatch(deletingBusinessAccount(handleOnSuccessfulDelete));
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleOnSuccessfulDelete = () => {
    handleCloseDeleteModal();
    navigate("/dashboard");
  };

  const handleOpenEdit = () => {
    setCantEdit(true);
  };

  const handleCloseCanEdit = () => {
    setCantEdit(false);
  };

  const handleEditClick = () => {
    if (businessData?.business_verify_status === "processing") {
      handleOpenEdit();
    } else {
      navigate("/edit-business-details");
    }
  };

  return (
    <>
      {loadingBusinessData ? (
        <Spin />
      ) : (
        <>
          <div className="container">
            <ProfileInfo
              EditBusinessDetails="Edit Business Details"
              onClickEdit={handleEditClick}
              certificate_image={profileData.certificate_image}
              business_name={businessData?.business_name}
              business_location={businessData?.business_location}
              registration_no={businessData?.registration_number}
              business_category={businessData?.business_category}
              incorporation_date={businessData?.incorporation_date}
              status={
                <>
                  {" "}
                  <div className="d-flex custom___gap">
                    Account:
                    <p
                      className={`business-${businessData?.business_verify_status} d-flex align-items-center gap-1 `}
                    >
                      {businessData?.business_verify_status === "pending" ? (
                        <FaExclamation />
                      ) : businessData?.business_verify_status ===
                        "processing" ? (
                        <FaSpinner size={20} color="#007bff" />
                      ) : businessData?.business_verify_status === "failed" ? (
                        <FaTimesCircle />
                      ) : (
                        <FaCheckSquare />
                      )}{" "}
                      {businessData?.business_verify_status}
                    </p>
                  </div>
                </>
              }
              total_sent="0"
            />
          </div>
          <div className="f-flex">
            <div className="w-100 mb-3 d-flex justify-content-end align-items-end px-5">
              <button
                type="button"
                className="text-danger border-0 fw-bold cursor-pointer"
                onClick={openDeleteModal}
              >
                Deactivate Business Account ?
              </button>
            </div>
          </div>

          <Modal
            destroyOnClose={true}
            title={null}
            visible={showDeleteModal}
            onCancel={handleCloseDeleteModal}
            footer={null}
            closable={true}
          >
            <div className="d-flex flex-column align-items-center">
              <div className="text-center">
                <p className="fs-5 text-danger">Deactivate Business Account</p>
                <p className="mt-3 mb-4">
                  Are you sure you want to{" "}
                  <span className="text-danger fw-bold">deactivate </span>your
                  business account? This action is irreversible and cannot be
                  undone.
                </p>
              </div>
              <div className="d-flex gap-4">
                <button
                  disabled={loading}
                  onClick={handleCloseDeleteModal}
                  className="btn  mt-4 px-sm-4 py-2"
                >
                  Cancel
                </button>
                <button
                  disabled={loading}
                  className="btn btn-red mt-4 px-sm-4 py-2"
                  onClick={handleDeleteAccount}
                >
                  Yes, deactivate
                  {loading && <Spin />}
                </button>
              </div>
            </div>
          </Modal>

          {showToggleVerificationResponseProceed && (
            <ModalConponent
              show={openModalVerificationProceedMessge}
              onClose={closeModalVerificationProceedMessge}
              body_clickOff={closeModalVerificationProceedMessge}
            >
              <>
                <div className="text-center">
                  <>
                    <p className="fs-5 text-danger">
                      Account details updated successful
                    </p>
                    <p>
                      Please re-verify your account before continuing to use
                      your account again, by clicking the button above to{" "}
                      <span className="text-danger">verify now</span>
                    </p>
                  </>
                </div>
              </>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="m-2 border py-2 px-3 btn btn-danger text-light"
                  onClick={closeModalVerificationProceedMessge}
                >
                  Close Now
                </button>
              </div>
            </ModalConponent>
          )}

          {cantEdit ? (
            <ModalConponent
              show={handleOpenEdit}
              onClose={handleCloseCanEdit}
              body_clickOff={handleCloseCanEdit}
            >
              <>
                <div className="text-center">
                  <p className="fs-6 mt-4">
                    Sorry verification in progress you can not continue editing
                    or update your account, please try again later after your
                    account has been verified.{" "}
                    <span className="text-success">Thank you !!!</span>
                  </p>
                </div>
              </>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="m-2 border py-2 px-3 btn btn-danger text-light"
                  onClick={handleCloseCanEdit}
                >
                  Close Now
                </button>
              </div>
            </ModalConponent>
          ) : null}
        </>
      )}
    </>
  );
};

export default BusinessDashboardProfile;
