import React, { useEffect, useState } from "react";
import List from "./list";
import { Modal, DatePicker, Spin } from "antd";
import {
  toggleCreatePopMessageModal,
  createPopMessage,
  searchLimit,
} from "../../../../store/actions/admin/popMessages";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import FormikError from "../../../../components/partials/formikError";
import { Formik } from "formik";
import moment from "moment";

export default function PopMessage() {
  const dispatch = useDispatch();
  const { showCreateModal, savingPopMessages, savedPopMessages } = useSelector(
    (state) => state.popMessages
  );

  const [date, setDate] = useState("");

  useEffect(() => {
    if (savedPopMessages) {
      dispatch(toggleCreatePopMessageModal());
      document.getElementById("form").reset();
      setDate("");
    }
  }, [savedPopMessages]);

  const { msg } = useSelector((state) => state.error);

  return (
    <div className="tab-pane fade show active" id="messagePill">
      <div className="text-end mb-5">
        <button
          className="btn btn-red px-4 min-height"
          data-bs-toggle="modal"
          data-bs-target="#addNoteModal"
          onClick={() => {
            dispatch(toggleCreatePopMessageModal());
          }}
        >
          Create New Message
        </button>
      </div>
      <List />
      <Modal
        visible={showCreateModal}
        footer={false}
        onCancel={() => {
          dispatch(toggleCreatePopMessageModal());
        }}
      >
        <h6 className="fw-bold">Create Pop Message</h6>
        <Formik
          initialValues={{
            message: "",
            type: "",
            title: "",
            expired_at: "",
            purpose: "",
          }}
          validationSchema={Yup.object({
            message: Yup.string("").required("Enter message"),
            title: Yup.string("").required("Enter title"),
            type: Yup.string("").required("Select type"),
            purpose: Yup.string("").required("Select purpose"),
            expired_at: Yup.string("").required("Select expiry"),
          })}
          onSubmit={(values) => {
            dispatch(createPopMessage(values));
          }}
        >
          {(props) => (
            <form className="py-4" onSubmit={props.handleSubmit} id="form">
              <div className="form-group mb-3">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control min-height"
                  placeholder="Title"
                  name="title"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                {props.touched.title && props.errors.title && (
                  <FormikError msg={props.errors.title} />
                )}
                <FormikError msg={msg?.title ?? ""} />
              </div>
              <div className="form-group mb-3">
                <label>Message</label>
                <textarea
                  rows={3}
                  className="form-control"
                  placeholder="Body"
                  defaultValue={""}
                  name="message"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                {props.touched.message && props.errors.message && (
                  <FormikError msg={props.errors.message} />
                )}
                <FormikError msg={msg?.message ?? ""} />
              </div>
              <div className="form-group mb-3">
                <label>Expiry Date</label>
                <DatePicker
                  className="form-control min-height"
                  name="expired_at"
                  onChange={(date, dateString) => {
                    props.setValues({
                      ...props.values,
                      expired_at: dateString,
                    });
                    setDate(dateString);
                  }}
                  value={date ? moment(date, "YYYY-MM-DD") : false}
                />
                {props.touched.expired_at && props.errors.expired_at && (
                  <FormikError msg={props.errors.expired_at} />
                )}
                <FormikError msg={msg?.expired_at ?? ""} />
              </div>

              <div className="form-group mb-3">
                <label>Type</label><br/>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="warningCheck"
                    defaultValue="warning"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="type"
                  />
                  <label
                    className="form-check-label text-warning"
                    htmlFor="warningCheck"
                  >
                    Warning
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="infoCheck"
                    defaultValue="info"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="type"
                  />
                  <label
                    className="form-check-label text-info"
                    htmlFor="infoCheck"
                  >
                    Info
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="dangerCheck"
                    defaultValue="danger"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="type"
                  />
                  <label
                    className="form-check-label text-danger"
                    htmlFor="dangerCheck"
                  >
                    Danger
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="successCheck"
                    defaultValue="success"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="type"
                  />
                  <label
                    className="form-check-label text-success"
                    htmlFor="successCheck"
                  >
                    Success
                  </label>
                </div>
                <br />
                {props.touched.type && props.errors.type && (
                  <FormikError msg={props.errors.type} />
                )}
                <FormikError msg={msg?.type ?? ""} />
              </div>
              <div className="form-group mb-3">
                <label>Purpose</label><br/>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="popCheck"
                    defaultValue="pop"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="purpose"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="popCheck"
                  >
                    Pop
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="stickyCheck"
                    defaultValue="sticky"
                    onChange={props.handleChange}
                    onBlur={props.handleBLur}
                    name="purpose"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="stickyCheck"
                  >
                    Sticky
                  </label>
                </div><br/>
                {props.touched.purpose && props.errors.purpose && (
                  <FormikError msg={props.errors.purpose} />
                )}
                <FormikError msg={msg?.purpose ?? ""} />
              </div>
              <div className="text-end">
                <button
                  className="btn btn-outline-red ms-2 px-4"
                  data-bs-dismiss="modal"
                  type="button"
                  onClick={() => dispatch(toggleCreatePopMessageModal())}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-red ms-2 px-4"
                  disabled={savingPopMessages}
                  type="submit"
                >
                  Save {savingPopMessages && <Spin />}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
