import React, { useState } from "react";
import { SlBriefcase } from "react-icons/sl";

import { useNavigate } from "react-router-dom";

const DashboardFeatureForNewUser = () => {
  const [close, setClose] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/create-business-account");
  };

  const handleCancel = () => {
    setClose(true);
  };

  return (
    <section className="px-xxl-5 px-xl-4 ">
      {!close && (
        <div className="new_to_business_account_wrapper">
          <div className="newly_dashboard_icon_info">
            <div className="newly_icon">
              <SlBriefcase className="newly__icon" />
            </div>
            <div className="bus_info_new ">
              <h5 className="fw-bold">
                Introducing CosmoRemit Business Account
              </h5>
              <p className="">
                Business account gives you the ability to send money as your
                business, with some other benefits.
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 mt-2 mt-lg-0">
            <button
              className="create_account_user_dismiss fw-bold whitespace-nowrap"
              onClick={handleCancel}
            >
              Dismiss Notice
            </button>
            <button
              onClick={handleNavigation}
              className="btn btn-red float-end  px-3 notice_btn"
            >
              <span className="px-2 whitespace-nowrap"> Create Now</span>
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default DashboardFeatureForNewUser;
