import * as actionTypes from "../../actionTypes/admin/ratesActionTypes";

export const ratesReducer = (
  state = {
    fetchingRates: true,
    rates: {},
    rateManagers: [],
    savedRates: false,
    savingRates: false,
    deleting: false,
    deleted: false,
    updatingRates: false,
    updatedRates: false,
    showCreateModal: false,
    fetchingPayins: false,
    fetchingPayouts: false,
    payouts: [],
    payins: [],
    payoutsEdit: {},
    payinsEdit: {},
    searching: false,
    settingDefault: false,
    setDefault: false,
    rate: {},
    fetchingSingleRate: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SETTING_DEFAULT_RATES:
      return { ...state, settingDefault: action.payload };
    case actionTypes.SET_DEFAULT_RATES:
      return { ...state, setDefault: action.payload };
    case actionTypes.SEARCHING_RATE:
      return { ...state, searching: action.payload };
    case actionTypes.FETCHING_RATES:
      return { ...state, fetchingRates: action.payload };
    case actionTypes.FETCHING_PAYINS:
      return { ...state, fetchingPayins: action.payload };
    case actionTypes.SET_PAYINS:
      return { ...state, payins: action.payload };
    case actionTypes.SET_PAYOUTS:
      return { ...state, payouts: action.payload };
    case actionTypes.SET_PAYINS_EDIT:
      return { ...state, payinsEdit: action.payload };
    case actionTypes.SET_PAYOUTS_EDIT:
      return { ...state, payoutsEdit: action.payload };
    case actionTypes.FETCHING_PAYOUTS:
      return { ...state, fetchingPayouts: action.payload };
    case actionTypes.SHOW_CREATE_MODAL:
      return { ...state, showCreateModal: !state.showCreateModal };
    case actionTypes.UPDATING_RATES:
      return { ...state, updatingRates: action.payload };
    case actionTypes.UPDATED_RATES:
      return { ...state, updatedRates: action.payload };
    case actionTypes.SAVING_RATES:
      return { ...state, savingRates: action.payload };
    case actionTypes.SAVED_RATES:
      return { ...state, savedRates: action.payload };
    case actionTypes.DELETING_RATES:
      return { ...state, deleting: action.payload };
    case actionTypes.DELETED_RATES:
      return { ...state, deleted: action.payload };
    case actionTypes.SET_RATES:
      return { ...state, rates: action.payload };
    case actionTypes.SET_RATE_MANAGERS:
      return { ...state, rateManagers: action.payload };
    case actionTypes.FETCHING_SINGLE_RATE:
      return { ...state, fetchingSingleRate: !state.fetchingSingleRate };
    case actionTypes.SET_RATE_TO_VIEW:
      return { ...state, rate: action.payload };
    default:
      return state;
  }
};
